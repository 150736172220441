import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HeaderEn } from '../../components/HeaderEn';
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';



export const MapasTramosEn = () => {
  return (
    <div className="fade-in">
      <HeaderEn></HeaderEn>

      <Grid container xs={12} md={12} direction="column" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
        <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center">
          <h1>MAP OF THE SECTIONS</h1>
        </Grid>
        <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center">
          <h3>Maps are not available :(</h3>
        </Grid>
        {/* <Grid container direction="column" xs={10} md={6} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
          <h2>TC1 - SAGRAT COR - CARTONAJES</h2>
          <iframe src="https://www.google.com/maps/d/u/0/embed?mid=17Wy6vmQn7z5kudz_dQVnVMAXfKtihhE&ehbc=2E312F&noprof=1" style={{ minWidth: '100%', minHeight: '50vh' }}></iframe>
        </Grid>

        <Grid container direction="column" xs={10} md={6} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
          <h2>TC2 - CAMI LES FORQUES - CAMI LES VINYES DE PIQUER</h2>
          <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1uTqI6K9vl6sPtBkSUfiXM2q4vVLpMik&ehbc=2E312F&noprof=1" style={{ minWidth: '100%', minHeight: '50vh' }}></iframe>
        </Grid>

        <Grid container direction="column" xs={10} md={6} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
          <h2>TC3 - VERSALLES - BARTOLO</h2>
          <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1zQF9IusIFXztrmlJaaVs2uM4p7QwZBY&ehbc=2E312F&noprof=1" style={{ minWidth: '100%', minHeight: '50vh' }}></iframe>
        </Grid>

        <Grid container direction="column" xs={10} md={6} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
          <h2>TC4 - CAMI ARTANA - VERSALLES</h2>
          <iframe src="https://www.google.com/maps/d/u/0/embed?mid=15v9CNtd1vAJxUAp92SRr5TbFy72QBV8&ehbc=2E312F&noprof=1" style={{ minWidth: '100%', minHeight: '50vh' }}></iframe>
        </Grid>

        <Grid container direction="column" xs={10} md={6} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
          <h2>TC5 - EL VALLET -SANT ANTONI</h2>
          <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1_ON31D0delrpKQ7WWS75KSyptfeFOvA&ehbc=2E312F&noprof=1" style={{ minWidth: '100%', minHeight: '50vh' }}></iframe>
        </Grid>

        <Grid container direction="column" xs={10} md={6} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
          <h2>TC6 - 6 SANT ANTONI</h2>
          <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1EvcTgfKrqXl2jMhPpgkwvHNlYr4M7m8&ehbc=2E312F&noprof=1" style={{ minWidth: '100%', minHeight: '50vh' }}></iframe>
        </Grid> */}

      </Grid>



      <PiePagina></PiePagina>
    </div>
  )
}
