import React from 'react'
import { useNavigate } from 'react-router-dom';

export const TiemposEquipo = () => {
    const Navigate = useNavigate();

    return (
        <div>
            <div className='return-button' onClick={() => Navigate("/")}>
                &nbsp;Volver
            </div>
        </div>)
}

