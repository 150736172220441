import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import EditIcon from '@mui/icons-material/Edit';
import Autocomplete from '@mui/material/Autocomplete';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Swal from 'sweetalert2';
import Grid from '@mui/material/Grid';


export const EditarEquipo = ({ infoEquipo, innerIndex, edicion, editarEquipo, setEditarEquipo, equipos, setEquipos }) => {

    const fileInputRef = useRef(null);


    const [loaded, setLoaded] = useState(false);
    const [editar, setEditar] = useState(false);
    const [componentes, setComponentes] = useState([]);
    const [opcionesComp, setOpcionesComp] = useState([]);
    const [piloto, setPiloto] = useState(null);
    const [copiloto, setCopiloto] = useState(null);
    const [camara, setCamara] = useState(false);
    const [imagenSeleccionada, setImagenSeleccionada] = useState(null);


    const [detallesEquipo, setDetallesEquipo] = useState({
        categoria: infoEquipo.categoria,
        codigoCategoria: infoEquipo.codigoCategoria,
        codigoequipo: infoEquipo.codigoequipo,
        confirmado: infoEquipo.confirmado,
        descalificado: infoEquipo.descalificado,
        dniCopi: infoEquipo.dniCopi,
        dniPiloto: infoEquipo.dniPiloto,
        dorsal: infoEquipo.dorsal,
        imagen: infoEquipo.imagen,
        nacimientoCopiloto: infoEquipo.nacimientoCopiloto,
        nacimientoPiloto: infoEquipo.nacimientoPiloto,
        nombreCop: infoEquipo.nombreCop,
        nombrePiloto: infoEquipo.nombrePiloto,
        nombreEquipo: infoEquipo.nombreEquipo,
        ubicacion: infoEquipo.ubicacion,
        edicion: edicion,
        codigocarrera: 1
    });


    const updateEquipo = () => {
        // console.log(detallesEquipo);

        if (imagenSeleccionada) detallesEquipo.imagen = (imagenSeleccionada);


        axios.put('https://www.transbetxi.com/WS/equipoinfo.php', detallesEquipo)
            .then(function (response) {
                // console.log(response.data)
                if (response.data.success == true) {
                    let temp = [...equipos];
                    let idArr = (temp.findIndex((tmp) => tmp.codigoequipo == detallesEquipo.codigoequipo));
                    temp[idArr] = detallesEquipo;
                    Swal.fire({
                        icon: "success",
                        title: "Equipo creado correctamente",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    setEquipos(temp);
                    setEditarEquipo(false);
                    // console.log(response.data.data);

                }
            })
            .catch(function (error) {
                console.error(error)
            });
        // setEditarEquipo(false);

    }


    const cargarComponentes = () => {
        axios.get('https://www.transbetxi.com/WS/componente.php', {
            headers: {
                'code': 'pass23sd2aASED6',

            }
        })
            .then(function (response) {
                if (response.status == 200) {
                    formarListaComponentes(response.data);
                    setComponentes(response.data);

                }
            })
            .catch(function (response) {
                console.error(response)
            })
    }

    const formarListaComponentes = (componentes) => {
        let arrayFormateado = [];
        componentes.map((componente, index) => {
            let temp = {
                label: componente.dni + " " + componente.nombre + " " + componente.apellidos,
                id: componente.dni
            }
            arrayFormateado.push(temp);
        })
        setOpcionesComp(arrayFormateado);
    }

    const handleLoopIconClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleOnChange = event => {
        const { name, value } = event.target;

        setDetallesEquipo(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            const image = new Image();
            image.src = reader.result;
            image.onload = () => {
                const canvas = document.createElement('canvas');
                const maxDimension = 400;
                let width = image.width;
                let height = image.height;

                if (width > height) {
                    if (width > maxDimension) {
                        height *= maxDimension / width;
                        width = maxDimension;
                    }
                } else {
                    if (height > maxDimension) {
                        width *= maxDimension / height;
                        height = maxDimension;
                    }
                }

                canvas.width = width;
                canvas.height = height;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(image, 0, 0, width, height);

                canvas.toBlob((blob) => {
                    if (blob.size > 500 * 1024) {
                        // Si el tamaño de la imagen comprimida es aún mayor que 500 KB, 
                        // puedes realizar más compresión o reducir la calidad aquí si es necesario
                        console.log("La imagen aún es demasiado grande después de la compresión.");
                    } else {
                        setImagenSeleccionada(canvas.toDataURL('image/jpeg'));
                    }
                }, 'image/jpeg', 0.7); // 0.7 es la calidad de compresión

                setCamara(true);
            };
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };



    useEffect(() => {
        // console.log(infoEquipo);
        cargarComponentes();
    }, [])

    return (
        <div>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={1}
            >
                <EditIcon
                    onClick={() => setLoaded(true)}
                />
            </Grid>

            {/* {loaded === false && (
                <div className='flex-center'>
                    <div className='loading-spinner'></div>
                </div>
            )} */}
            {loaded === true && (
                <div className='detalles-equipo' key={innerIndex} style={{ minHeight: '0vh' }}>
                    <div className='overlay-crear-equipo' >
                        <div style={{ borderRadius: '15px', backgroundColor: '#C9C9C9', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                            <div className='detalles-componente-container'>
                                <div className='tramo-container'>
                                    <div className='edit-equipo-element'>
                                        Nombre:
                                        <TextField
                                            onChange={handleOnChange}
                                            name='nombreEquipo'
                                            value={detallesEquipo.nombreEquipo}
                                            disabled={editar}
                                            id="outlined-basic"
                                            variant="outlined"
                                            className='field'
                                        />
                                    </div>
                                    <div className="edit-equipo-element">
                                        Piloto:&nbsp;
                                        <Autocomplete
                                            disablePortal
                                            name='dni_piloto'
                                            options={opcionesComp}
                                            className='field'
                                            sx={{ width: 300 }}
                                            value={infoEquipo.nombrePiloto}
                                            onChange={(event, newValue) => {
                                                setPiloto(newValue)
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Piloto" />}
                                        />
                                    </div>
                                    <div className="edit-equipo-element">
                                        Copiloto: &nbsp;
                                        <Autocomplete
                                            disablePortal
                                            name='dni_copiloto'
                                            id="combo-box-demo"
                                            options={opcionesComp}
                                            className='field'
                                            sx={{ width: 300 }}
                                            value={infoEquipo.nombreCop}
                                            onChange={(event, newValue) => {
                                                setCopiloto(newValue)
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Copiloto" />}
                                        />
                                    </div>
                                    <div className='edit-equipo-element column'>
                                        Foto equipo:
                                        {camara === false && (
                                            <div className='button-aux' onClick={handleLoopIconClick} style={{ maxWidth: '2px' }}>
                                                <CameraAltIcon className='flex-center' />
                                            </div>
                                        )}
                                        {camara === true && (
                                            <>
                                                <div className='button-aux' onClick={() => { setImagenSeleccionada(null); setCamara(false); }}>
                                                    <DeleteForeverIcon className='flex-center' />
                                                </div>
                                                <div className='flex-center' onClick={() => setCamara(true)}>
                                                    <img src={imagenSeleccionada} alt="Imagen seleccionada" className='miniatura' />
                                                </div>
                                            </>
                                        )}
                                        <div className='edit-equipo-element'>
                                            {detallesEquipo.imagen ? <img className="avatar" src={detallesEquipo.imagen} /> : ''}
                                            <input
                                                ref={fileInputRef}
                                                type='file'
                                                style={{ display: 'none' }}
                                                accept='image/*'
                                                onChange={handleFileInputChange}
                                            />

                                        </div>

                                    </div>
                                    <div className='edit-equipo-element'>
                                        {(editar === true) && (
                                            <div className='button' onClick={() => setEditar(false)}>
                                                <EditIcon />    Editar
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='tramo-container'>
                                    <div className='edit-equipo-element'>
                                        ID edicion:
                                        <TextField
                                            onChange={handleOnChange}
                                            name='edicion'
                                            value={edicion}
                                            disabled={true}
                                            id="outlined-basic"
                                            variant="outlined"
                                            className='field'
                                        />
                                    </div>
                                    <div className='edit-equipo-element'>
                                        Dorsal:
                                        <TextField
                                            onChange={handleOnChange}
                                            name='dorsal'
                                            value={detallesEquipo.dorsal}
                                            disabled={editar}
                                            id="outlined-basic"
                                            variant="outlined"
                                            className='field'
                                        />
                                    </div>
                                    <div className='edit-equipo-element'>
                                        Categoría:
                                        <Select
                                            value={detallesEquipo.codigoCategoria}
                                            name="codigoCategoria"
                                            onChange={handleOnChange}
                                            className='field'
                                            disabled={editar}
                                        >
                                            <MenuItem value={1}>PROTOTIPO</MenuItem>
                                            <MenuItem value={2}>CLASICA</MenuItem>
                                            <MenuItem value={3}>AGRIA</MenuItem>
                                        </Select>
                                    </div>
                                    <div className='edit-equipo-element'>
                                        URL Ubicacion:
                                        <TextField
                                            onChange={handleOnChange}
                                            name='ubicacion'
                                            value={detallesEquipo.ubicacion}
                                            disabled={editar}
                                            id="outlined-basic"
                                            variant="outlined"
                                            className='field'
                                        />
                                    </div>
                                </div>
                                <div className='buttons-container'>
                                    {!editar && (
                                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignContent: 'center', alignItems: 'center', justifyContent: 'center', gap: '1em', marginBottom: '1em' }}>
                                            <div className="button-adm"
                                                onClick={() => updateEquipo()}
                                            >
                                                Editar
                                            </div>
                                            <div className="button-adm"
                                                onClick={() => setLoaded(false)}
                                            >
                                                Cancelar
                                            </div>
                                        </div>
                                    )}
                                    {editar && (
                                        <div className='button-component-dissabled'>
                                            Guardar Componente
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>

    )
}
