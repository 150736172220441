import React from 'react'
import { HeaderGer } from '../../components/HeaderGer';
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';


export const DondeComerGer = () => {
  return (
    <div className="fade-in">
      <HeaderGer></HeaderGer>

      <Grid container xs={12} md={12} direction="column" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
        <Grid container direction="column" xs={10} md={6} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
          <h1> Wo sollen wir essen</h1>
          <p style={{ color: '#EAC474' }}>Bars und Restaurants, die mit der Transbetxi-Rallye zusammenarbeiten.</p>
        </Grid>
        <Grid container xs={10} sm={8} md={6} direction="column" justifyContent="center" alignItems="center">
          <iframe src="https://www.google.com/maps/d/embed?mid=13coRg-nFDtMpOaAUtESLIJVmGeQliQw&ehbc=2E312F&noprof=1" style={{ minWidth: '100%', minHeight: '50vh' }}></iframe>
        </Grid>
      </Grid>

      <PiePagina></PiePagina>
    </div>
  )
}
