import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import TrapFocus from '@mui/material/Unstable_TrapFocus';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const CookiesGer = () => {

    const [showDialog, setShowDialog] = useState(false);

    useEffect(() => {
        // Verificar si la cookie correspondiente está presente
        const hasConsented = localStorage.getItem('cookieConsent');

        // Si la cookie está presente y el usuario ha aceptado las cookies, no mostrar el cuadro de diálogo
        if (!hasConsented) {
            setShowDialog(true);
        }
    }, []);

    const handleAcceptCookies = () => {
        // Guardar el consentimiento del usuario en una cookie
        localStorage.setItem('cookieConsent', 'true');
        setShowDialog(false);
    };


    return (
        <div className='fade-in top' style={{ zIndex: '1000 !important' }}>
            <TrapFocus open disableAutoFocus disableEnforceFocus className='top'>
                <Fade appear={false} in={showDialog}>
                    <Paper
                        role="dialog"
                        aria-modal="false"
                        aria-label="Cookie banner"
                        square
                        variant="outlined"
                        tabIndex={1}
                        sx={{
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            m: 0,
                            p: 2,
                            borderWidth: 0,
                            borderTopWidth: 1,
                        }}
                        style={{ backdropFilter: 'blur(10px)', background: '#C9C9C9B9' }}
                    >
                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            justifyContent="space-between"
                            gap={2}
                        >
                            <Box
                                sx={{
                                    flexShrink: 1,
                                    alignSelf: { xs: 'flex-start', sm: 'center' },
                                }}
                            >
                                <Typography fontWeight="bold">Diese Website verwendet Cookies</Typography>
                                <Typography variant="body2">
                                    transbetxi.com setzt Cookies ein, um Ihr Erlebnis zu verbessern.
                                </Typography>
                            </Box>
                            <Stack
                                gap={2}
                                direction={{
                                    xs: 'row-reverse',
                                    sm: 'row',
                                }}
                                sx={{
                                    flexShrink: 0,
                                    alignSelf: { xs: 'flex-end', sm: 'center' },
                                }}
                            >
                                <Button size="small" onClick={handleAcceptCookies} variant="contained" style={{ background: '#EAC474' }}>
                                    <strong style={{ color: '#2E2E2E' }}>Alles erlauben</strong>
                                </Button>
                                <Button size="small" onClick={handleAcceptCookies}>
                                    <strong style={{ color: '#2E2E2E' }}>Alles ablehnen</strong>
                                </Button>
                            </Stack>
                        </Stack>
                    </Paper>
                </Fade>
            </TrapFocus>

        </div>
    )
}
