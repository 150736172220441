import React, { useEffect, useState } from 'react'
import JC from '../media/JC.png';
import { useNavigate } from 'react-router-dom';
import { useMyContext } from '../MyProvider '; //Variable global del idioma


export const PiePagina = () => {

  const Navigate = useNavigate();

  const { miVariableGlobal, setMiVariableGlobal } = useMyContext();


  return (
    <div className='flex-vertical' style={{ backgroundColor: '#2E2E2E', paddingTop: '2em', gap: '1em', textAlign: 'center' }}>
      {(miVariableGlobal == 1) && (
        <strong onClick={() => Navigate("/politica-cookies")} style={{ cursor: 'pointer' }}>Política de cookies</strong>
      )}

      {(miVariableGlobal == 2) && (
        <strong onClick={() => Navigate("/politica-cookies")} style={{ cursor: 'pointer' }}>Política de cookies</strong>

      )}

      {(miVariableGlobal == 3) && (
        <strong onClick={() => Navigate("/politica-cookies")} style={{ cursor: 'pointer' }}>Cookies policy</strong>

      )}

      {(miVariableGlobal == 4) && (
        <strong onClick={() => Navigate("/politica-cookies")} style={{ cursor: 'pointer' }}>Cookie-Richtlinie</strong>

      )}

      {(miVariableGlobal == 1) && (
        <small style={{color: '#EAC474'}}> Si tienes alguna sugerencia o has encontrado algún error contacta con nosotros a través de: <strong>web.master@transbetxi.com</strong></small>
      )}

      {(miVariableGlobal == 2) && (
        <small style={{color: '#EAC474'}}> Si tens algun suggeriment o has trobat algun error contacta amb nosaltres a través de: <strong></strong>web.master@transbetxi.com</small>

      )}

      {(miVariableGlobal == 3) && (
        <small style={{color: '#EAC474'}}> If you have any suggestions or have found any errors, contact us through: <strong></strong>web.master@transbetxi.com</small>

      )}

      {(miVariableGlobal == 4) && (
        <small style={{color: '#EAC474'}}> Wenn Sie Vorschläge haben oder Fehler gefunden haben, kontaktieren Sie uns über: <strong></strong>web.master@transbetxi.com</small>

      )}

      <img src={JC} alt="" style={{ maxWidth: '50%' }} />
    </div>
  )
}
