import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { SelectorIdioma } from '../components/SelectorIdioma';
import { Header } from '../components/Header';
import { HeaderEn } from '../components/HeaderEn';
import { HeaderVal } from '../components/HeaderVal';
import { HeaderGer } from '../components/HeaderGer';
import { PiePagina } from '../components/PiePagina';
import Select from '@mui/material/Select';
import { MenuItem } from '@mui/material';
import axios from 'axios';
import casco from '../media/casco.png';
import dorsal from '../media/dorsal.png';
import PlaceIcon from '@mui/icons-material/Place';
import { useMyContext } from '../MyProvider '; //Variable global del idioma
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { DetallesEquipo } from './DetallesEquipo';
import { EquipoCard } from './EquipoCard';
import { ComponenteWp } from './ComponenteWp';


export const EquiposWp = () => {

    const { miVariableGlobal, setMiVariableGlobal } = useMyContext();
    const [ediciones, setEdiciones] = useState([]);
    const [edicion, setEdicion] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [equipos, setEquipos] = useState([]);
    const [mostrarPersonas, setMostrarPersonas] = useState(false);


    const Navigate = useNavigate();

    const equiposInfo = () => {
        axios.get('https://www.transbetxi.com/WS/equiposinfo.php', {
            headers: {
                'code': 'pass23sd2aASED6',

            }
        })
            .then(function (response) {
                // manejar respuesta exitosa
                setEquipos(response.data);
                setLoaded(true);

            })
            .catch(function (error) {
                // manejar error
                console.log(error);
            })
            .finally(function () {
                // siempre sera executado
            });
    }



    const redirect = (url) => {
        window.location.href = url;
    }

    const edicionesLoad = () => {
        axios.get('https://www.transbetxi.com/WS/edicion.php')
            .then(function (response) {
                // manejar respuesta exitosa
                let temp = [];
                (response.data).map((edicion, index) => {
                    const edicionOption = { label: " " + edicion.nombre + " ", id: edicion.codigoedicion }
                    temp.push(edicionOption);
                })
                setEdiciones(temp);
                setEdicion(temp[temp.length - 1]);
            })
            .catch(function (error) {
                // manejar error
                console.log(error);
            })
            .finally(function () {
                // siempre sera executado
            });
    }



    useEffect(() => {
        equiposInfo();
        setLoaded(true)
        edicionesLoad();
    }, [])

    const handleChange = (event) => {
        switch (event.target.name) {
            case "edicion": setEdicion(event.target.value);
                break;

        }
    };

    const mostrarInf = (id) => {
        <DetallesEquipo id={id}></DetallesEquipo>
    }


    return (
        <div className='flex-column'>
            {(loaded === false) && (
                <div className='flex-container spinner'>
                    <div className='loading-spinner'>
                    </div>
                </div>
            )}

            {(loaded === true) && (
                <>
                    <SelectorIdioma idioma={miVariableGlobal} ></SelectorIdioma>

                    {(miVariableGlobal == 1) && (
                        <>
                            <Header></Header>
                            <div className='flex-center'>
                                <Grid container xs={4} md={4} direction="row" justifyContent="center" alignItems="center">
                                    <div className="boton_formulario" onClick={mostrarPersonas ? () => setMostrarPersonas(false) : () => setMostrarPersonas(true)}>
                                        {mostrarPersonas ? 'Ir a Equipos' : 'Ir a Participantes'}
                                    </div>
                                </Grid>


                            </div>
                            <div className="flex-center">
                                <h1>{mostrarPersonas ? 'Participantes' : 'Equipos'}</h1>
                            </div>
                        </>
                    )}

                    {(miVariableGlobal == 2) && (
                        <>
                            <HeaderVal></HeaderVal>
                            <div className='flex-center'>
                                <Grid container xs={4} md={4} direction="row" justifyContent="center" alignItems="center">
                                    <div className="boton_formulario" onClick={mostrarPersonas ? () => setMostrarPersonas(false) : () => setMostrarPersonas(true)}>
                                        {mostrarPersonas ? 'Anar a Equips' : 'Anar a Participants'}
                                    </div>
                                </Grid>


                            </div>
                            <div className="flex-center">
                                <h1>{mostrarPersonas ? 'Participants ' : 'Equips'}</h1>
                            </div>
                        </>

                    )}

                    {(miVariableGlobal == 3) && (
                        <>
                            <HeaderEn></HeaderEn>
                            <div className='flex-center'>
                                <Grid container xs={4} md={4} direction="row" justifyContent="center" alignItems="center">
                                    <div className="boton_formulario" onClick={mostrarPersonas ? () => setMostrarPersonas(false) : () => setMostrarPersonas(true)}>
                                        {mostrarPersonas ? 'Go to Teams' : 'Go to Participants'}
                                    </div>
                                </Grid>


                            </div>
                            <div className="flex-center">
                                <h1>{mostrarPersonas ? 'Participants' : 'Teams'}</h1>
                            </div>
                        </>

                    )}

                    {(miVariableGlobal == 4) && (
                        <>
                            <HeaderGer></HeaderGer>
                            <div className='flex-center'>
                                <Grid container xs={4} md={4} direction="row" justifyContent="center" alignItems="center">
                                    <div className="boton_formulario" onClick={mostrarPersonas ? () => setMostrarPersonas(false) : () => setMostrarPersonas(true)}>
                                        {mostrarPersonas ? 'Gehen Sie zu Teams' : 'Gehen Sie zu Teilnehmer'}
                                    </div>
                                </Grid>


                            </div>
                            <div className="flex-center">
                                <h1>{mostrarPersonas ? 'Teilnehmer' : 'Ausrüstung'}</h1>
                            </div>
                        </>

                    )}


                    <div className="spacer"></div>
                    {(mostrarPersonas === true) && (
                        <ComponenteWp />
                    )}
                    {(mostrarPersonas === false) && (
                        <>
                            <div className='flex-center'>
                                <Select
                                    className='select'
                                    id="demo-simple-select"
                                    value={edicion}
                                    label="Age"
                                    name="edicion"
                                    onChange={handleChange}
                                >
                                    {ediciones.map((edicionElement, index) => (
                                        <MenuItem key={index} value={edicionElement}>{edicionElement.label}</MenuItem>
                                    ))}
                                </Select>
                            </div>


                            {(edicion !== null) && (
                                <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center">
                                    {equipos.map((equipo, index) => (
                                        <Grid container xs={12} md={10} direction="row" justifyContent="center" alignItems="center" key={index}>
                                            {equipo.edicion == edicion.id && (
                                                equipo.equipos.map((equi, innerIndex) => (
                                                    <EquipoCard equi={equi} innerIndex={innerIndex} edicion={edicion} casco={casco} dorsal={dorsal} />
                                                ))
                                            )}
                                        </Grid>
                                    ))}

                                </Grid>
                            )}
                        </>
                    )}
                    <PiePagina></PiePagina>
                </>
            )}
        </div>
    )
}
