import { MenuItem, TextField } from '@mui/material';
import React, { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Select from '@mui/material/Select';
import CancelIcon from '@mui/icons-material/Cancel';
import Swal from 'sweetalert2';
import axios from 'axios';


export const CompnenteCard = ({ componente, index, componentes, setComponentes }) => {

    const [editar, setEditar] = useState(true);
    const [detalles, setDetalles] = useState(false);
    const [detallesComponente, setDetallesComponente] = useState(componente);

    const eliminarComponente = (id) => {
        console.log(id)
        Swal.fire({
            title: "Estas seguro/a?",
            text: "Vas a ELIMINAR permanentemente el Equipo " + id + " !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#dab158',
            cancelButtonColor: '#gray',
            confirmButtonText: "SI, Eliminalo!",
            cancelButtonText: "No, no lo Elimines"
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete('https://www.transbetxi.com/WS/componente.php', {
                    data: {
                        code: 'pass23sd2aASED/6&',
                        dni: id
                    }
                })
                    .then(function (response) {
                        console.log(response.data.success);
                        if (response.data.success === true) {
                            let temp = [...componentes];
                            console.log(response.data)
                            setComponentes(temp.filter((tmp) => tmp.dni != response.data.id))
                            Swal.fire({
                                icon: "success",
                                title: "El Equipo ha sido eliminado correctamente",
                                showConfirmButton: false,
                                timer: 1500
                            });



                        } else {
                            Swal.fire({
                                icon: "error",
                                title: "Error inesperado al intentar eliminar ",
                                showConfirmButton: false,
                                timer: 1500
                            });
                        }
                    })
                    .catch(function (response) {
                        console.error(response);
                    })
            }
        });

    }

    const handleOnChange = event => {
        const { name, value } = event.target;

        setDetallesComponente(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <>
            <div className='componente-card' index={index}>
                <div className='tramo-container'>
                    <div className='edit-equipo-element'>
                        Nombre: {detallesComponente.nombre.toUpperCase()}
                    </div>
                    <div className='edit-equipo-element'>
                        Apellidos: {detallesComponente.apellidos.toUpperCase()}
                    </div>
                    <div className='edit-equipo-element'>
                        DNI: {detallesComponente.dni}
                    </div>
                    <div className='edit-equipo-element'>
                        seg_social: {detallesComponente.seg_social}
                    </div>
                    <div className='edit-equipo-element'>
                        nacimiento: {detallesComponente.nacimiento}
                    </div>
                    <div className='edit-equipo-element'>
                        cod_postal: {detallesComponente.cod_postal}
                    </div>
                    <div className='botones-componente'>
                        <div className='button-component' onClick={detalles ? () => setDetalles(false) : () => setDetalles(true)}>
                            <VisibilityIcon />
                        </div>
                        <div className='button-component' onClick={editar ? () => { setEditar(false); setDetalles(true) } : () => setEditar(true)}>
                            <EditIcon />
                        </div>
                        <div className='button-component' onClick={() => eliminarComponente(detallesComponente.dni)}>
                            <DeleteIcon />
                        </div>
                    </div>
                </div>
            </div>
            {detalles === true && (
                <div className='overlay'>
                    <div className='detalles-componente-container'>
                        <div className='tramo-container'>
                            <div className='edit-equipo-element'>
                                DNI:
                                <TextField
                                    onChange={handleOnChange}
                                    name='dni'
                                    value={detallesComponente.dni}
                                    disabled={editar}
                                    id="outlined-basic"
                                    variant="outlined"
                                    className='field'
                                />
                            </div>
                            <div className='edit-equipo-element'>
                                Nombre:
                                <TextField
                                    onChange={handleOnChange}
                                    name='nombre'
                                    value={detallesComponente.nombre}
                                    disabled={editar}
                                    id="outlined-basic"
                                    variant="outlined"
                                    className='field'
                                />
                            </div>
                            <div className='edit-equipo-element'>
                                Apellidos:
                                <TextField
                                    onChange={handleOnChange}
                                    name='apellidos'
                                    value={detallesComponente.apellidos}
                                    disabled={editar}
                                    id="outlined-basic"
                                    variant="outlined"
                                    className='field'
                                />
                            </div>
                            <div className='edit-equipo-element'>
                                Direccion:
                                <TextField
                                    onChange={handleOnChange}
                                    name='direccion'
                                    value={detallesComponente.direccion}
                                    disabled={editar}
                                    id="outlined-basic"
                                    variant="outlined"
                                    className='field'
                                />
                            </div>

                            <div className='edit-equipo-element'>
                                Telefono:
                                <TextField
                                    onChange={handleOnChange}
                                    name='telefono'
                                    value={detallesComponente.telefono}
                                    disabled={editar}
                                    id="outlined-basic"
                                    variant="outlined"
                                    className='field'
                                />
                            </div>
                        </div>
                        <div className='tramo-container'>
                            <div className='edit-equipo-element'>
                                Poblacion:
                                <TextField
                                    onChange={handleOnChange}
                                    name='poblacion'
                                    value={detallesComponente.poblacion}
                                    disabled={editar}
                                    id="outlined-basic"
                                    variant="outlined"
                                    className='field'
                                />
                            </div>
                            <div className='edit-equipo-element'>
                                Seguridad social:
                                <TextField
                                    onChange={handleOnChange}
                                    name='seg_social'
                                    value={detallesComponente.seg_social}
                                    disabled={editar}
                                    id="outlined-basic"
                                    variant="outlined"
                                    className='field'
                                />
                            </div>
                            <div className='edit-equipo-element'>
                                Fecha Nacimiento:
                                <TextField
                                    onChange={handleOnChange}
                                    name='nacimiento'
                                    value={detallesComponente.nacimiento}
                                    disabled={editar}
                                    id="outlined-basic"
                                    variant="outlined"
                                    className='field'
                                />
                            </div>
                            <div className='edit-equipo-element'>
                                Codigo Postal:
                                <TextField
                                    onChange={handleOnChange}
                                    name='cod_postal'
                                    value={detallesComponente.cod_postal}
                                    disabled={editar}
                                    id="outlined-basic"
                                    variant="outlined"
                                    className='field'
                                />
                            </div>
                            <div className='botones-componente'>
                                <div className='button-component' onClick={detalles ? () => { setDetalles(false); setEditar(true) } : () => setDetalles(true)}>
                                    <VisibilityIcon />
                                </div>
                                <div className='button-component' onClick={editar ? () => setEditar(false) : () => setEditar(true)}>
                                    <EditIcon />
                                </div>
                                <div className='button-component' onClick={() => eliminarComponente(detallesComponente.dni)}>
                                    <DeleteIcon />
                                </div>
                                {!editar && (
                                    <div className='button-component'>
                                        Guardar cambios
                                    </div>
                                )}
                                {editar && (
                                    <div className='button-component-dissabled'>
                                        Guardar cambios
                                    </div>
                                )}

                            </div>
                        </div>
                        <div className='close' onClick={() => { setDetalles(false); setEditar(false); }}>
                            <CancelIcon />
                        </div>
                    </div>
                </div>
            )}
        </>
    )

}
