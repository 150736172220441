import React from 'react';
import { SelectorIdioma } from '../components/SelectorIdioma';
import { ActividadesEs } from '../landing_page/actividades/ActividadesEs';
import { ActividadesEn } from '../landing_page/actividades/ActividadesEn';
import { ActividadesVal } from '../landing_page/actividades/ActividadesVal';
import { ActividadesGer } from '../landing_page/actividades/ActividadesGer';
import { useMyContext } from '../MyProvider '; //Variable global del idioma

export const Actividades = () => {

  const { miVariableGlobal, setMiVariableGlobal } = useMyContext();

  return (
    <div className="fade-in">
      <SelectorIdioma idioma={miVariableGlobal} ></SelectorIdioma>


      {(miVariableGlobal == 1) && (
        <ActividadesEs></ActividadesEs>
      )}

      {(miVariableGlobal == 2) && (
        <ActividadesVal></ActividadesVal>
      )}

      {(miVariableGlobal == 3) && (
        <ActividadesEn></ActividadesEn>
      )}

      {(miVariableGlobal == 4) && (
        <ActividadesGer></ActividadesGer>
      )}
    </div>
  )
}
