import React, { useEffect } from 'react';
import { HeaderVal } from '../../components/HeaderVal';
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';

export const ReglamentoTecnicoVal = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div className="fade-in" >
            <HeaderVal></HeaderVal>
            <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E' }}>
                <h1>REGLAMENT TÈCNIC</h1>
            </Grid>


            <Grid container xs={12} md={12} direction="column" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E' }}>
                <Grid container xs={10} md={10} direction="row" justifyContent="center" alignItems="center">

                    {/* <object data="https://transbetxi.com//REGLAMENTO_TÉCNICO_ca.pdf" type="application/pdf" style={{ minHeight: '100vh', minWidth: '100%' }}>
                        <p><a href="https://transbetxi.com//REGLAMENTO_TÉCNICO_ca.pdf"></a></p>
                    </object> */}

                    <iframe src="https://drive.google.com/viewerng/viewer?embedded=true&url=https://transbetxi.com//REGLAMENTO_TÉCNICO_ca.pdf" style={{ minHeight: '100vh', minWidth: '100%' }}></iframe>


                    {/* <header id="header" className="header-blog" style={{ maxWidth: '100%', color: '#EAC474' }}>
                        <div className="top-header">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-4 col-xs-8">
                                        <header id="header" className="header-blog">
                                            <div className="top-header">
                                                <div className="container">
                                                    <div className="row" />
                                                </div>
                                            </div>
                                            <div className="bottom-header bloc">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <h1 />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </header>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm-8 col-sm-offset-2">
                                                    <section id="blog">
                                                        <article
                                                            id="post-233"
                                                            className="blog-post post-233 page type-page status-publish hentry"
                                                        >
                                                            <div className="blog-post-entry markup-format">
                                                                <h3>
                                                                    1.&nbsp;&nbsp;&nbsp;&nbsp; PRESCRIPCIONS GENERALS
                                                                </h3>
                                                                <h4>1.1) Vehicles admesos:</h4>
                                                                <p>Són admesos a participar en aquesta prova els vehicles comercialitzats sota la denominació de motocultor i que encaixin amb la definició del punt 1. És important tenir en compte que han de complir les següents limitacions i/o característiques tècniques:</p>
                                                                <ul>
                                                                    <li>
                                                                        Ser motocultors de sèrie o tradicionals i que hagin estat comercialitzats amb la denominació de motocultor per a ús agrícola, evolucionats o no, que conservin la motorització i la caixa de canvis originals.
                                                                    </li>
                                                                    <li>
                                                                        El motor no pot presentar canvis externs no recollits al reglament ni refrigeració líquida de cap tipus.
                                                                    </li>
                                                                    <li>
                                                                        La data de fabricació ha de ser anterior a l'any 2000.
                                                                    </li>
                                                                    <li>
                                                                        Són admesos els motocultors amb un sol eix motriu. Aquest eix motriu ha de ser el davanter. No s'admeten altres fórmules de tracció (excepte els convidats de l'organització).
                                                                    </li>
                                                                </ul>

                                                                <ul>
                                                                    <li>
                                                                        Les mesures màximes admeses són:
                                                                        <ul>
                                                                            <li>
                                                                                1200 mil·límetres per a l'amplada de vies davanter comptant des de l'exterior del pneumàtic.
                                                                            </li>
                                                                            <li>
                                                                                1500 mil·límetres per a l'amplada de vies del darrere comptant des de l'exterior del pneumàtic.
                                                                            </li>
                                                                            <li>
                                                                                2200 mil·límetres de batalla, entre centres d'eix davanter i del darrere.
                                                                            </li>
                                                                            <li>
                                                                                14 polzades de diàmetre de llanta. Només seran admesos pneumàtics amb càmera d'aire, tubeless i musse no sent mai massitzats. </li>
                                                                        </ul>
                                                                    </li>
                                                                    <li>
                                                                        No es permet cap ajuda elèctrica o mecànica per augmentar la força motriu. Per exemple, motors elèctrics recolzats per bateries carregades per frenada regenerativa o altres mitjans (estil KERS).
                                                                    </li>
                                                                    <li>
                                                                        No es permet cap ajut elèctric o mecànic a la direcció. Per exemple, servodirecció hidràulica.
                                                                    </li>
                                                                </ul>

                                                                <ul>
                                                                    <li>
                                                                        Amb caràcter general, és obligatori que tots els vehicles participants conservin la resta de les seves característiques de vehicle tradicional, com ara la direcció, el sistema d'unió motriu carro, etc.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Queda limitada la cilindrada màxima als següents
                                                                        valors:
                                                                        <ul>
                                                                            <li>Motors 2 temps benzina: màxim 260c.c.</li>
                                                                            <li>Motors 4 temps benzina: màxim 260c.c.</li>
                                                                            <li>Motors 4 temps dièsel: màxim 500c.c.</li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                                Nota: Es respectaran la cursa i els diàmetres originals.
                                                                <ul>
                                                                    <li>
                                                                        És deure dels concursants provar davant dels
                                                                        Comissaris Tècnics i els Comissaris Esportius la
                                                                        conformitat total del seu Motocultor amb els reglaments
                                                                        en tot moment de la prova. Els concursants deuen
                                                                        assegurar que els seus Motocultors compleixen les condicions
                                                                        de conformitat i seguretat durant tota la durada de
                                                                        la prova.
                                                                    </li>
                                                                </ul>
                                                                <h3>2.&nbsp;&nbsp;&nbsp;&nbsp; MODIFICACIONS</h3>
                                                                <ul>
                                                                    <li>
                                                                        S'entén per modificació qualsevol operació
                                                                        tendent a canviar l'aspecte inicial, dimensions,
                                                                        plànols, composició o funció duna part original.
                                                                    </li>
                                                                    <li>
                                                                        S'entén com a modificació prohibida aquella que no estigui autoritzada en aquest reglament,
                                                                        ho incompleixi o sigui determinada així per criteri dels CC.DD. de la prova. Sent penalitzable
                                                                        segons el reglament de la prova.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.1) Suspensió</h4>
                                                                <ul>
                                                                    <li>
                                                                        Els elements de suspensió mecànics, hidràulics o
                                                                        pneumàtics estan permesos a tot el Motocultor,
                                                                        havent d'estar correctament soldats i/o ancorats.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.2) Frens</h4>
                                                                <ul>
                                                                    <li>
                                                                        Els frens es muntaran obligatoris tant en eix davanter com al darrere en circuits independents.
                                                                    </li>
                                                                    <li>
                                                                        Podran ser hidràulics. Si es fa servir un cable, haurà de tenir un diàmetre mínim d'1,8 mm i estar assegurat mitjançant subjecta cables.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        El sistema de frenada davantera ha d'anar muntat
                                                                        a la part superior de la caixa de canvi o en eix motriu enganxat
                                                                        a roda per evitar que es contamini amb l'oli de la caixa de canvi.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.3) Adreça</h4>
                                                                <ul>
                                                                    <li>
                                                                        És l'articulació entre el cap motriu i el
                                                                        remolc (on van els ocupants) que pivoten sobre
                                                                        un únic eix, podent ser l'enganxament d'unió, doble
                                                                        o simple.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Ha d'estar accionada per un manillar i respectant el
                                                                        sistema original de conducció i/o maneig del
                                                                        motocultor.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Es prohibeix tot comandament flexible per cable o cadena, així com la direccionalitat o la conducció del motocultor mitjançant el gir de les rodes.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Heu de presentar un radi continu en la totalitat del vostre
                                                                        angle de gir, amb un perímetre continu.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Per raons de seguretat el manillar no ha de presentar cap part angular ni punxant.
                                                                        Tot dispositiu muntat al manillar no ha de presentar arestes vives. Tots els elements
                                                                        de la direcció han de comportar un sistema de fixació oferint tota seguretat. S'instal·laran topalls
                                                                        en el mateix i està permès el muntatge dun sistema damortidor de direcció.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.4) Accelerador</h4>
                                                                <ul>
                                                                    <li>
                                                                        Només s'admetrà gallet o puny de gas, sempre que tinguin moll de reculada
                                                                        i el cable que estigui bé subjecte a l'estructura del xassís de la màquina.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.5) Motor</h4>
                                                                <ul>
                                                                    <li>
                                                                        El motor ha de ser original. Es respectarà la unió
                                                                        original motor-cilindre-culata mitjançant espàrrecs
                                                                        passats de mètrica 8, 9 o 10mm. No es pot variar
                                                                        posició ni distribució.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        No es permet instal·lar compressor ni cap sistema de
                                                                        sobrealimentació als motors gasolina, si es
                                                                        permet als motors dièsel.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.6) Embragatge</h4>
                                                                <ul>
                                                                    <li>
                                                                        Original o modificat, estant permesa la
                                                                        substitució de l'embragatge i la seva carcassa envoltant o
                                                                        càrter.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        No es permeten variadors continus de velocitat.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.7)&nbsp; Dipòsit de combustible</h4>
                                                                <ul>
                                                                    <li>
                                                                        Ha d'estar sòlidament fixat, sense que la subjecció
                                                                        tingui un caràcter provisional i no presenti risc de
                                                                        fugida durant la prova.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.8)&nbsp; Caixa de canvis</h4>
                                                                <ul>
                                                                    <li>Només s'admetrà la caixa de canvis original.</li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Es permet la modificació de l'aspecte exterior al
                                                                        tren d'engranatges de la transmissió final a les
                                                                        rodes, permetent-se la modificació de l'alçada
                                                                        total de la caixa.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        El recinte superior de la caixa de canvis ha de
                                                                        conservar les mides i aspecte originals. No
                                                                        no obstant això, es permet la modificació parcial de les
                                                                        parets del recinte superior, sempre que aquesta
                                                                        modificació no suposi un canvi en la sustentació
                                                                        dels eixos motrius.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Es pot modificar internament qualsevol element i
                                                                        mecanisme sempre que les parets de l'habitacle
                                                                        del recinte superior siguin les que sustentin els eixos
                                                                        del canvi. I aquests es mantinguin a la seva posició
                                                                        original.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Es permet el canvi en la relació de les marxes de
                                                                        origen, així com afegir o eliminar pinyons interns que
                                                                        impliquin un major o menor nombre de velocitats. El
                                                                        nombre de velocitats del canvi no és limitat.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        No es permet introduir una caixa reductora.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        El sistema d'accionament del canvi és lliure,
                                                                        podent ser amb el peu o mà, servoassistit o no.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Es permet la utilització de diferencials a l'eix
                                                                        de tracció.
                                                                    </li>
                                                                </ul>

                                                                <h4>2.9) Ventiladors</h4>
                                                                <ul>
                                                                    <li>
                                                                        Els ventiladors han d'anar correctament protegits sense poder tenir accés manual amb reixeta de ventilació i subjectes degudament.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.10) Parafangs</h4>
                                                                <ul>
                                                                    <li>
                                                                        Els parafangs seran obligatoris a les quatre rodes cobrint 180º en forma U invertida havent d'estar subjectes als trapezis.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.11) Bateria </h4>
                                                                <ul>
                                                                    <li>
                                                                        En cas de portar bateria serà obligatori estar ben subjectes amb els borns protegits de qualsevol contacte i portar instal·lat un curt corrent degudament senyalitzat.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.12) Extintors </h4>
                                                                <ul>
                                                                    <li>
                                                                        Els extintors han d'anar amb un suport homologat de fàcil extracció, prohibint les brides, les corretges o qualsevol altre tipus de fixació fixa, han de portar el rellotge de pressió visible.
                                                                    </li>
                                                                </ul>

                                                                <h4>2.13) Home a l'aigua</h4>
                                                                <ul>
                                                                    <li>
                                                                        El sistema de lhome a laigua serà obligatori, no es podrà muntar cap tipus sistema que ho anul·li.
                                                                    </li>
                                                                </ul>
                                                                <h3>3. CATEGORIES</h3>
                                                                <h4>3.1) Categoria Prototip</h4>
                                                                <ul>
                                                                    <li>
                                                                        Aquesta categoria ha de complir el reglament tècnic
                                                                        especificat als punts 1 i 2 d'aquest reglament
                                                                        a més de les següents indicacions:
                                                                    </li>
                                                                </ul>
                                                                <h4>3.1.1) Culata</h4>
                                                                <ul>
                                                                    <li>
                                                                        Es podran utilitzar culates originals o de fosa,
                                                                        sempre que mantinguin i respectin les característiques i
                                                                        aspecte original.
                                                                    </li>
                                                                    <li />
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-355"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico1.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        Es permet la utilització de culatins, devent aquests estar inserits en la culata original. Es permet la modificació de la càmera de combustió en la forma i el volum.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Per a la fixació de la culata al cilindre només es permet utilitzar
                                                                        els orificis originals. Podent afegir-ne dues més per permetre girar-la 90º,
                                                                        respectant sempre la mateixa posició que els originals i les seues mides.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-359"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico2.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-360"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico3.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        Es practicarà un orifici passat a la part davantera
                                                                        a qualsevol zona propera a la tovera d'escapament de 2mm
                                                                        aproximadament, amb la finalitat de servir per a la
                                                                        col·locació del precinte de verificació.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-361"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico4.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h4>3.1.2) Cilindre</h4>
                                                                <ul>
                                                                    <li>
                                                                        Només s'admet l'ús d'un cilindre original.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-363"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico5.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        No es permet afegir elements exteriors o augmentar els ja existents amb la finalitat daugmentar la capacitat de refrigeració
                                                                        (per exemple, soldat o modificació d'aletes del cilindre). Sí que està permès eliminar o retallar aquests elements.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Es permet afegir elements adherits al cilindre amb
                                                                        la finalitat del muntatge d'un sistema d'admissió per
                                                                        làmines al cilindre.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Es permet afegir opcionalment un sistema de
                                                                        ventilació frontal, el qual haurà d'anar ben cobert i
                                                                        protegit per una reixeta per no quedar exposat ni
                                                                        suposar cap perill.
                                                                    </li>
                                                                    <li>
                                                                        Es permet variar el nombre de conductes interns o transfers. També està permesa
                                                                        la modificació dels conductes esmentats, a més dels d'admissió i escapament, eliminant material
                                                                        o emplenant amb qualsevol altre material o
                                                                        tècnica, sempre respectant que el cos principal sigui el cilindre original.
                                                                    </li>
                                                                    <li>
                                                                        Es permet la utilització de camises d'acer o alumini amb tractaments
                                                                        superficials (nicasilat/cromat) de les parets. La mida exterior de la
                                                                        camisa haurà de ser de 83mm (+-2mm). A la part superior s'haurà de respectar
                                                                        l'espessor de l'original (8mm aprox.),
                                                                        podent ampliar la superfície fins a un màxim de 130mm.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-364"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico5.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        Es permet el rectificat i la substitució de camises internes o recobriments.
                                                                    </li>
                                                                    <li>
                                                                        Es permet l'ajust mitjançant la substitució de la
                                                                        junta del cilindre. La junta de culata és admesa.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        No es permet modificar el número, la posició, ni la
                                                                        distribució original dels espàrrecs que fixen el
                                                                        motor, es respectarà la unió original
                                                                        motor-cilindre-culata mitjançant espàrrecs passats de
                                                                        mètrica 8, 9 o 10mm, devent aquests realitzar la mateixa
                                                                        funció que al cilindre original.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-366"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico6.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        Es practicarà un orifici passat a la part davantera
                                                                        a qualsevol zona propera a la tovera d'escapament de 2mm
                                                                        aproximadament, i un altre a qualsevol altre lloc que
                                                                        quedi visible i no molesti, amb la finalitat de servir
                                                                        per a la col·locació del precinte de verificació.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image size-full">
                                                                    <img
                                                                        className="wp-image-638"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico7.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h4>3.1.3) Càrter</h4>
                                                                <ul>
                                                                    <li>Només s'admetrà el càrter original.</li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-370"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico8.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-371"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico9.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        Es permet eliminar material de la part exterior.
                                                                    </li>
                                                                    <li>
                                                                        Només es permet afegir elements adherits al
                                                                        càrter amb la finalitat de muntar un sistema de
                                                                        admissió al càrter.
                                                                    </li>
                                                                    <li>
                                                                        Es permet la modificació interna de l'encare amb els
                                                                        transfers del cilindre.
                                                                    </li>
                                                                    <li>Es permet reparar el càrter.</li>
                                                                    <li>
                                                                        Qualsevol altra modificació externa haurà de ser
                                                                        revisada i aprovada pels CC.DD. de la prova per
                                                                        verificar que el càrter correspon a l'original i no
                                                                        suposa aquesta una millora en el rendiment.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.1.4)&nbsp;&nbsp;Cigonyal, biela i canell</h4>
                                                                <ul>
                                                                    <li>
                                                                        Originals o modificats sempre que es conservi
                                                                        la cursa del cilindre.
                                                                    </li>
                                                                    <li>
                                                                        Es permet la substitució d'aquests elements per
                                                                        altres de millor qualitat sempre que es conservi la
                                                                        carrera original del pistó. La mesura de la biela
                                                                        serà de 116mm.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.1.5) Pistó, segment i buló</h4>
                                                                <ul>
                                                                    <li>
                                                                        Original o modificat, permetent-se modificacions
                                                                        del pistó.
                                                                    </li>
                                                                    <li>
                                                                        Els pistons es podran substituir per altres de major
                                                                        qualitat, sent les mesures admeses: mínim 72mm i
                                                                        màxim 74mm.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.1.6) Admissió</h4>
                                                                <ul>
                                                                    <li>
                                                                        Es permet modificar el sistema d'admissió
                                                                        original.
                                                                    </li>
                                                                    <li>
                                                                        Es permet instal·lar sistemes d'injecció de
                                                                        combustible als motors dièsel.
                                                                    </li>
                                                                    <li>
                                                                        No es poden utilitzar elements de pressurització. La
                                                                        polvorització de productes diferents del carburant
                                                                        està prohibida.
                                                                    </li>
                                                                    <li>
                                                                        L'ancoratge haurà de ser ferm i es respectaran la
                                                                        distribució i la unió amb la tovera d'escapament,
                                                                        podent modificar el diàmetre dels espàrrecs.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-372"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico10.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h4>3.1.7)&nbsp;&nbsp;Carburador</h4>
                                                                <ul>
                                                                    <li>
                                                                        El carburador és lliure sigui quin sigui el seu principi de
                                                                        funcionament.
                                                                    </li>
                                                                    <li>El posicionament del carburador és lliure.</li>
                                                                    <li>El carburat ha d'estar ben fixat al cilindre sense possibilitat de pèrdua del mateix.</li>
                                                                    <li>El filtre d'aire és lliure.</li>

                                                                </ul>
                                                                <h4>3.1.8)&nbsp;&nbsp;Encès</h4>
                                                                <ul>
                                                                    <li>
                                                                        Els sistemes d'encesa variable (sistemes d'avenç
                                                                        i retrocés progressiu) estan permesos, així com
                                                                        tot sistema electrònic que permeti un autocontrol de
                                                                        els paràmetres que utilitza el motor per al vostre
                                                                        funcionament quan el motocultor està en marxa.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.1.9)&nbsp;&nbsp;Electrònica</h4>
                                                                <ul>
                                                                    <li>
                                                                        Es permet qualsevol sistema dadquisició de dades.
                                                                    </li>
                                                                    <li>
                                                                        No es permet la modificació a distància de cap
                                                                        paràmetre del motor, aquestes només es poden fer
                                                                        localment des de la pròpia màquina pel pilot o
                                                                        copilot.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.1.10)&nbsp; Escape</h4>
                                                                <ul>
                                                                    <li>
                                                                        Es permet la modificació parcial o total de la fuita.
                                                                    </li>
                                                                    <li>
                                                                        No es permet la utilització de vàlvules d'escapament.
                                                                    </li>
                                                                    <li>
                                                                        L'ancoratge haurà de ser ferm i es respectaran la
                                                                        distribució i la unió amb la tovera d'escapament,
                                                                        podent modificar el diàmetre dels espàrrecs.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-373"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico11.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h3>3.2)&nbsp; Categoria Sèries</h3>
                                                                <ul>
                                                                    <li>
                                                                        Aquesta categoria ha de complir el reglament tècnic
                                                                        especificat als punts 1 i 2 d'aquest reglament
                                                                        a més de les següents indicacions:
                                                                    </li>
                                                                </ul>
                                                                <h4>3.2.1)&nbsp;&nbsp;&nbsp;Culata</h4>
                                                                <ul>
                                                                    <li>
                                                                        Es podran utilitzar culates originals o de fosa,
                                                                        sempre que mantinguin i respectin les característiques i
                                                                        aspecte original, podent ser la forma de la càmera
                                                                        de combustió lliure.
                                                                        <ul>
                                                                            <li>
                                                                                En cap cas no es permetrà l'ús de culatins.
                                                                            </li>
                                                                            <li>
                                                                                Es permet eliminar material per fer
                                                                                ajustaments, no afegir.
                                                                            </li>
                                                                            <li>
                                                                                Per a la fixació de la culata al cilindre només es
                                                                                permetrà utilitzar els 6 orificis originals.
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-375"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico12.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        Es practicarà un orifici passat a la part davantera
                                                                        a qualsevol zona propera a la tovera d'escapament de 2mm
                                                                        aproximadament, bé a la base o en alguna de les
                                                                        aletes, amb la finalitat de servir per a la col·locació
                                                                        del precinte de verificació.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-377"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico13.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h4>3.2.2)&nbsp;&nbsp;Cilindre</h4>
                                                                <ul>
                                                                    <li>Només s'admetrà el cilindre original.</li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-379"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico14.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-381"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico15.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-382"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico16.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        Es podrà rectificar i substituir la camisa, encara que no
                                                                        podrà ser d'alumini ni portar cap tipus de
                                                                        recobriment. La mida exterior de la camisa haurà de
                                                                        ésser de 83mm (+-2mm).
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-384"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico17.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        En cap cas no es podrà variar el nombre de transfers, devent aquests mantenir la seva posició original, podent variar la seva distribució.
                                                                        No es podrà perforar completament les llumeneres d'admissió, només encara amb el càrter i aprofundir un màxim de 15mm.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-385"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico18.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-386"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico19.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-387"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico20.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-388"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico21.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-389"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico22.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        En cap cas no es podrà afegir material ni nous
                                                                        orificis, tant al cilindre com a la camisa.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        En cap cas no es poden afegir elements exteriors o
                                                                        augmentar els ja existents amb la finalitat de
                                                                        augmentar la capacitat de refrigeració.
                                                                    </li>
                                                                    <li>
                                                                        Es practicarà un orifici passat a la part davantera
                                                                        i un altre a la del darrere, a qualsevol zona propera a la
                                                                        tovera d'escapament ia la d'admissió, de 2mm
                                                                        aproximadament, bé a la base o en alguna de les
                                                                        aletes, i un altre a qualsevol altre lloc que quedi
                                                                        visible i no molesti, amb la finalitat de servir per
                                                                        la col·locació del precinte de verificació.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image size-full">
                                                                    <img
                                                                        className="wp-image-640"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico23.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h4>3.2.3)&nbsp;&nbsp;Càrter</h4>
                                                                <ul>
                                                                    <li>Només s'admetrà el càrter original.</li>
                                                                    <li>
                                                                        No es permet afegir material als conductes del
                                                                        càrter ni modificar l'encare dels transfers amb el
                                                                        cilindre, ni buidar el càrter.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-393"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico24.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-394"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico25.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        Es permet reparar el càrter, i s'han de conservar les
                                                                        mides originals.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.2.4)&nbsp;&nbsp;Cigonyal, biela i canell</h4>
                                                                <ul>
                                                                    <li>
                                                                        Es permetrà la modificació i/o substitució dels dits elements per altres de millor qualitat, sempre que conservin carrera i mesures originals.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.2.5)&nbsp;&nbsp;Pistó, segment i buló</h4>
                                                                <ul>
                                                                    <li>
                                                                        Original o modificat, permetent modificacions en
                                                                        el pistó.
                                                                    </li>
                                                                    <li>
                                                                        Els pistons es podran substituir per altres de major
                                                                        qualitat, sent les mesures admeses: mínim 72mm i
                                                                        màxim 74mm.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.2.6)&nbsp;&nbsp;Admissió</h4>
                                                                <ul>
                                                                    <li>
                                                                        No es permet la modificació del sistema d'admissió
                                                                        original.
                                                                    </li>
                                                                    <li>
                                                                        Es permet ajustar el conducte d'admissió. Sent
                                                                        la presa al carburador amb un diàmetre màxim de 38mm.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-395"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico26.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        No es poden utilitzar elements de pressurització. La
                                                                        polvorització de productes diferents del carburant
                                                                        està prohibida.
                                                                    </li>
                                                                    <li>
                                                                        S'hi afegirà un mitjançant soldadura una peça soldada amb funció d'argolla
                                                                        (similar a l'exemple, també s'admetrà una femella)
                                                                        amb orifici mínim de 2mm aproximadament, a qualsevol zona de la tovera de
                                                                        admissió, amb la finalitat de servir per a la col·locació del precinte de verificació.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-396"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico27.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h4>3.2.7)&nbsp;&nbsp;Carburador</h4>
                                                                <ul>
                                                                    <li>
                                                                        El principi de funcionament és lliure, sent el seu
                                                                        mida màxima 38mm.
                                                                    </li>
                                                                    <li>El posicionament del carburador és lliure.</li>
                                                                    <li>El filtre d'aire és lliure.</li>
                                                                </ul>
                                                                <h4>3.2.8)&nbsp;&nbsp;Encès</h4>
                                                                <ul>
                                                                    <li>
                                                                        Només estan permeses les encesos d'avenç fix.
                                                                        Es comprovarà al final de la prova en cas de
                                                                        classificació.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.2.9) Escape</h4>
                                                                <ul>
                                                                    <li>
                                                                        Es permet la modificació parcial o total de la fuita.
                                                                        No es permet afegir material.
                                                                    </li>
                                                                    <li>
                                                                        La tovera de fuita ha de ser d'un diàmetre màxim
                                                                        de 44m.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-398"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico28.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        No es permet la utilització de vàlvules d'escapament.
                                                                    </li>
                                                                    <li>
                                                                        S'afegirà un mitjançant soldadura una peça amb funció d'argolla (similar a l'exemple, també s'admetrà una rosca) amb orifici mínim de 2mm
                                                                        aproximadament, a qualsevol zona de la tovera d'escapament, amb la finalitat de servir per a la col·locació del precinte de verificació.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-399"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico29.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        L'ancoratge haurà de ser ferm i es respectaran la
                                                                        distribució i la unió amb la tovera d'escapament,
                                                                        podent modificar el diàmetre dels espàrrecs.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-401"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico30.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h3>3.3)&nbsp; Categoria agra</h3>
                                                                <ul>
                                                                    <li>
                                                                        El motocultor haurà de complir el reglament tècnic que
                                                                        marquin les curses alemanyes
                                                                    </li>
                                                                </ul>
                                                                <h3>4.MÈTODES DE VERIFICACIÓ I CONTROL</h3>
                                                                <h3>4.1) Verificació general.</h3>
                                                                <ul>
                                                                    <li>En la concentració després de la pega de dorsals i
                                                                        verificació de cascos es realitzarà una verificació
                                                                        externa dels punts considerats pels CC.DD. per
                                                                        assegurar el compliment del present reglament. Es
                                                                        procedirà a precintar els motors segons categoria. En cas
                                                                        de reparació, durant la competició, s'haurà d'informar
                                                                        als CC.DD. que procediran al reprecintat del motor.
                                                                    </li>
                                                                    <li>
                                                                        Els precintes seran lliurats al control tècnic administratiu perquè els equips participants efectuïn la seva col·locació on correspongui.
                                                                    </li>
                                                                    <li>
                                                                        A
                                                                        cas de ser classificat, en el moment de les
                                                                        verificacions finals, caldrà lliurar les peces
                                                                        substituïdes i utilitzades durant la cursa per a la seva
                                                                        verificació.</li></ul>
                                                                <h3>4.2)&nbsp;&nbsp;Verificació final.</h3>
                                                                Finalitzada la prova la comissió tècnica realitzarà la
                                                                verificació de 5 motocultors, els 3 primers
                                                                classificats i 2 més a criteri de l'organització,
                                                                sent els valors de referència els indicats a la
                                                                taula. Es comprovarà que compleixin les especificacions de
                                                                cada categoria establertes en aquest reglament.
                                                                <figure className="wp-block-table">
                                                                    <table>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td />
                                                                                <td>Diàmetre màxim permès</td>
                                                                                <td>Carrera nominal original</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Hispano Villiers</td>
                                                                                <td>¿?</td>
                                                                                <td>¿?</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Bultaco</td>
                                                                                <td>74±0,2 mm</td>
                                                                                <td>60±0,2 mm</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </figure>
                                                                <h3>4.3) Exemples de precintat</h3>
                                                                <ul>
                                                                    <li>
                                                                        <strong>SÈRIES</strong>
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-404"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico31.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-405"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico32.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        <strong>PROTOTIPS</strong>
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-406"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico33.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h3>4.4)&nbsp;&nbsp;&nbsp;SANCIONS</h3>A càrrec de la
                                                                organització.
                                                                <ul>
                                                                    <li>
                                                                        4.5) L'organització es reserva el dret d'excloure de la carrera qualsevol equip participant que no compleixi aquest reglament.
                                                                    </li>
                                                                </ul>
                                                            </div>


                                                        </article>
                                                    </section>
                                                </div>
                                            </div>
                                        </div>
                                        <footer id="footer">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-3 col-sm-6 col-xs-12">
                                                        &nbsp;<span style={{ fontSize: "1rem" }}>&nbsp;</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </footer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header> */}
                </Grid>

            </Grid>

            <PiePagina></PiePagina>
        </div>
    )
}
