import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HeaderGer } from '../../components/HeaderGer';
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';
import fotoRe from '../../media/DSC_0406-scaled.jpg';

export const ReglamentosGer = () => {

    const Navigate = useNavigate();

    
    return (
        <div className="fade-in" >
            <HeaderGer></HeaderGer>
            <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E' }}>
                <h1>VORSCHRIFTEN</h1>
                <img src={fotoRe} alt="Foto" style={{ maxWidth: '100%', minWidth: '100%' }} />
            </Grid>

            <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
                <Grid container xs={10} md={4} direction="column" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', textAlign: 'center', padding: '1em' }}>
                    <h1>TECHNISCHE VORSCHRIFTEN</h1>
                    <strong>Die technischen Vorschriften beziehen sich auf alle Normen, Spezifikationen und Stufen, die eine Maschine innerhalb ihrer Kategorie erfüllen muss, damit sie nicht bestraft wird.</strong>
                    <div class="sirk2"  onClick={() => Navigate('/reglamento-tecnico')}>
                        Sehen
                    </div>
                </Grid>

                <Grid container xs={10} md={4} direction="column" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', textAlign: 'center', padding: '1em'  }}>
                    <h1>RALLYE-BESTIMMUNGEN</h1>
                    <strong>Liebe Teilnehmer, mit dieser Einleitung möchten wir Sie darauf aufmerksam machen, dass Sie bei der Teilnahme an der TRANSBETXÍ-Rallye neben einigen Rechten und Leistungen, die Sie unterstützen, auch eine Reihe verbindlicher Regeln gelten. Diese Regeln stellen einen Garant für den ordnungsgemäßen Ablauf des Rennens dar und bieten allen teilnehmenden Teams Hilfestellung und die gleiche Behandlung.</strong>
                    <div class="sirk2"  onClick={() => Navigate('/reglamento-de-la-prueba')}>
                        Sehen
                    </div>
                </Grid>

            </Grid>
            <PiePagina></PiePagina>
        </div>
    )
}
