import React, { useEffect, useState } from 'react'
import { redirect, useNavigate, useParams } from 'react-router-dom';
import { Header } from '../../Header';
import { Grid } from '@mui/material';
import axios from 'axios';
import casco from '../../../media/casco.png';
import dorsal from '../../../media/dorsal.png';
import PlaceIcon from '@mui/icons-material/Place';
import EditIcon from '@mui/icons-material/Edit';
import { PiePagina } from '../../PiePagina';
import { CrearEquipo } from './CrearEquipo';
import { DeleteForever } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { EditarEquipo } from './EditarEquipo';

export const Equipos = () => {

    const [equipos, setEquipos] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [ediciones, setEdiciones] = useState([]);
    const [edicion, setEdicion] = useState({});
    const [anadirEquipo, setAnadirEquipo] = useState(false);
    const [eliminar, setEliminar] = useState(false);
    const [editar, setEditar] = useState(false);
    const [editarEquipo, setEditarEquipo] = useState(false);


    const Navigate = useNavigate();
    const param = useParams();

    const firstLoad = () => {
        cargarEquipos();
        cargarEdiciones();
    }

    useEffect(() => {
        firstLoad();
    }, []);

    const cargarEquipos = () => {
        axios.get('https://www.transbetxi.com/WS/edicion.php')
            .then(function (response) {
                let tmp = response.data;
                tmp = tmp.find((tm) => tm.codigoedicion == param.edicion);
                if (tmp) {
                    //console.log(tmp)
                    setEdicion(tmp);
                } else {
                    Navigate('/');
                }

            })
            .catch(function (error) {
                console.error(error)
            })
    }



    const cargarEdiciones = () => {
        //resvisar que en un futuro entre solo la edicion pasada por param en el ws
        axios.get('https://www.transbetxi.com/WS/equiposinfo.php', {
            headers: {
                'code': 'pass23sd2aASED6',

            }
        })
            .then(function (response) {
                // manejar respuesta exitosa
                //console.log("a", response);
                let temp = response.data;
                temp = temp.find((tmp) => tmp.edicion == param.edicion);

                if (temp !== undefined) {
                    setEquipos(temp.equipos);
                }
                setLoaded(true);

            })
            .catch(function (error) {
                // manejar error
                //console.log(error);
            })
            .finally(function () {
                // siempre sera executado
            });
    }

    const eliminarEquipo = (id) => {
        //console.log(id)
        Swal.fire({
            title: "Estas seguro/a?",
            text: "Vas a ELIMINAR permanentemente el Equipo " + id + " !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#dab158',
            cancelButtonColor: '#gray',
            confirmButtonText: "SI, Eliminalo!",
            cancelButtonText: "No, no lo Elimines"
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete('https://www.transbetxi.com/WS/equipoinfo.php', {
                    data: {
                        code: 'pass23sd2aASED/6&',
                        codigoequipo: id
                    }
                })
                    .then(function (response) {
                        //console.log(response.data.success);
                        if (response.data.success === true) {
                            let temp = [...equipos];
                            //console.log(response.data)
                            setEquipos(temp.filter((tmp) => tmp.codigoequipo != response.data.id))
                            Swal.fire({
                                icon: "success",
                                title: "El Equipo ha sido eliminado correctamente",
                                showConfirmButton: false,
                                timer: 1500
                            });



                        } else {
                            Swal.fire({
                                icon: "error",
                                title: "Error inesperado al intentar eliminar ",
                                showConfirmButton: false,
                                timer: 1500
                            });
                        }
                    })
                    .catch(function (response) {
                        console.error(response);
                    })
            }
        });



    }


    return (
        <>
            <Header />
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="left"
                spacing={0}
            >
                <Grid container xs={2} md={2} lg={2} >
                    <div className="button-adm" onClick={() => Navigate('/edicion/' + param.edicion)}>
                        Volver
                    </div>
                </Grid>
            </Grid>
            <div className='flex-center'>
                <h1>Equipos de la edición {edicion.nombre}</h1>
            </div>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ gap: '1em' }}
                xs={12}
            >
                <Grid container xs={2} className="button-aux" onClick={() => setAnadirEquipo(true)}>
                    Nuevo Equipo
                </Grid>
                <Grid container xs={2} className="button-aux" onClick={eliminar ? () => setEliminar(false) : () => setEliminar(true)} >
                    {eliminar === true ? "CANCELAR" : "ELIMINAR"}
                </Grid>
                <Grid container xs={2} className="button-aux" onClick={editarEquipo ? () => setEditarEquipo(false) : () => setEditarEquipo(true)} >
                    {editarEquipo === true ? "CANCELAR" : "EDITAR"}
                </Grid>
            </Grid >

            <Grid
                container
                direction="column"
                justifyContent="space-evenly"
                alignItems="center"
                spacing={0}
            >
                {loaded === true && (
                    <>
                        <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center">
                            {console.log(equipos)}
                            {equipos.map((equi, innerIndex) => (
                                <Grid container xs={12} sm={8} md={6} xl={4} direction="row" justifyContent="center" alignItems="center" key={innerIndex}>
                                    <div className='equipo-card'>
                                        <div className='equipo-content'>
                                            <div className='flex-column'>
                                                <div className="dorsal-img" style={{
                                                    // transform: 'skew(10deg)',
                                                    backgroundSize: 'contain',
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundImage: `url(${dorsal})`,
                                                    width: '2em',
                                                    height: '2.5em',
                                                    color: equi.codigoCategoria == 1 ? 'blue' : equi.codigoCategoria == 2 ? 'red' : equi.codigoCategoria == 3 ? 'black' : ''
                                                }}>
                                                    <div> {equi.dorsal}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='equipo-desc'>
                                            <div className='equipo-element'>{equi.nombreEquipo}</div>
                                            <div className='equipo-element'>Categoria: {equi.categoria} </div>
                                            <div className='equipo-element'>{equi.nombrePiloto.toUpperCase()}</div>
                                            <div className='equipo-element'>{equi.nombreCop.toUpperCase()}</div>
                                            <div className='equipo-element'>{equi.descalificado ? 'Descalificado' : ''}</div>
                                            <div className='flex-even'>
                                                {equi.ubicacion ? <div className='boton-equipo' onClick={() => redirect(equi.ubicacion)}><PlaceIcon className='sm-icon' /> Ubicación</div> : null}
                                                {eliminar && (
                                                    <DeleteForever onClick={() => eliminarEquipo(equi.codigoequipo)} />
                                                )}
                                                {editarEquipo === true && (
                                                    <EditarEquipo innerIndex={innerIndex} infoEquipo={equi} edicion={param.edicion} editarEquipo={editarEquipo} setEditarEquipo={setEditarEquipo} equipos={equipos} setEquipos={setEquipos} />

                                                )}
                                            </div>
                                        </div>

                                        {equi.imagen ? <img className="avatar" src={equi.imagen} /> : <img src={casco} />}
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                        {equipos.length <= 0 && (
                            <h1>
                                UPS... No hay equipos de esta edicion todavia
                            </h1>)}
                    </>

                )}
                {loaded === false && (
                    <div className='flex-container spinner'>
                        <div className='loading-spinner'>
                        </div>
                    </div>
                )}
                {/* crear equipo */}
                {anadirEquipo === true && (
                    <CrearEquipo setAnadirEquipo={setAnadirEquipo} edicion={param.edicion} setEquipos={setEquipos} equipos={equipos} />
                )}

            </Grid >
            <PiePagina></PiePagina>
        </>
    )
}
