import React from 'react'
import { HeaderEn } from '../../components/HeaderEn';
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';
import { TablaActividadesEn } from '../tablaActividades/TablaActividadesEn';


export const ActividadesEn = () => {
  return (
    <div className="fade-in">
        <HeaderEn></HeaderEn>

        <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
          <h1>ACTIVITIES</h1>
        </Grid>

        <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
        <Grid container xs={10} md={7} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E' }}>
         <TablaActividadesEn></TablaActividadesEn>
        </Grid>
      </Grid>

        


        <PiePagina></PiePagina>
    </div>
  )
}
