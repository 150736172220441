import React, { useEffect } from 'react';
import { Header } from '../../components/Header';
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';
import { TablaActividadesVal } from '../tablaActividades/TablaActividadesVal';

export const ReglamentoPruebaVal = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div className="fade-in" >
            <Header></Header>
            <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', textAlign: 'center' }}>
                <h1>REGLAMENT DE LA PROBA</h1>
            </Grid>

            <Grid container xs={12} md={12} direction="column" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', overflow: 'auto' }}>
                <Grid container xs={10} md={8} direction="column" justifyContent="center" alignItems="center">
                    <div style={{ maxWidth: '100%', color: '#EAC474', textAlign: 'left' }}>
                        <h3>PRÒLEG - DEFINICIÓ DEL CONCEPTE D’ACTITUD ANTIESPORTIVA.</h3>
                        <p>Benvolguts participants, amb aquesta introducció, volem fer-vos conscients  que, a més de tindre uns drets i serveis que us assisteixen, en participar en el ral.li TRANSBETXÍ, hi teniu també una sèrie de normes d’obligat compliment. </p>
                        <p>Aquestes normes constitueixen un garant per al correcte esdevenir de la cursa i us ofereixen l’assistència i el mateix tractament a tots els equips que hi preneu part.</p>
                        <p>L’organització, mitjançant les facultats que li atribueixen els continguts dels articles 11, 22 i 23 del present reglament, en contra de qualsevol fet o actitud que atempte contra la seguretat, integritat i assistència als altres participants, organitzadors i públic en general, per part d’un equip o d’alguna persona relacionada amb ell, i per aquest “principi d’actitud antiesportiva”, volem fer pública la reserva del dret a prendre totes les mesures que crega convenients, per tal de corregir o eliminar aquestos actes i comportaments esmentats.</p>
                        <p>En benefici de tots i agraint la vostra col·laboració, rebeu la nostra salutació.</p>

                        <h3>01.- PROGRAMA Y HORARI OFICIAL D’ACTIVITATS</h3>
                        <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
                            <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E' }}>
                               <TablaActividadesVal></TablaActividadesVal>
                            </Grid>
                        </Grid>

                        <h3>02.- ORGANITZACIÓ, DISPOSICIONS GENERALS</h3>

                        <h4>Art.02-01 – GENERALITATS</h4>
                        <p>L’entitat organitzadora prepara una prova esportiva de motocultors que es celebrarà els dies 5,6 i 7 d’abril de 2024, anomenada XXXIV RAL·LI TRANSBETXÍ 2024</p>

                        <h4>Art.02-02 - GENERALITATS NORMATIVA D’APLICACIÓ</h4>
                        <p>Aquesta prova s’organitza d’acord amb els segons reglaments</p>
                        <ul>
                            <li>Reglament Esportiu de la FMCV</li>
                            <li>Reglament General d’Enduro FMCV o RALLI TT</li>
                            <li>El present Reglament Particular.</li>
                        </ul>

                        <h4>Art.02- 03.- SEU DEL COMITÈ D’ORGANITZACIÓ</h4>
                        <p>Entitat organitzadora:</p>
                        <ul>
                            <li>L’il·lustríssim AJUNTAMENT DE BETXÍ.</li>
                            <li>La secretaria del comitè organitzador estarà ubicada a l’Ajuntament de Betxí. Tel. 964 620 002.</li>
                        </ul>

                        <h4>Art. 02-04.- CÀRRECS OFICIALS</h4>
                        <p>Jutge / àrbitre:	Fco. Javier Ferrandis Bordería /	Lic. 500547-RF</p>
                        <p>Comissari Tècnic:	Josep Manel Adsuara Vicent	/ Lic. En trámit</p>
                        <p>Director de Cursa:	David Brisach Miravet	/ Lic. En trámit</p>
                        <p>Cronometrador:	Agustín Ferreres Beltrán	/ Lic. 622497-CS</p>

                        <h4>Art. 02-05.- OFICIALS</h4>
                        <p>El organitzador disposa de dos tipus d’oficials a la proba.</p>

                        <h4>Art. 02-05-01 OFICIALS ESPORTIUS</h4>
                        <p>Son aquelles persones que tenen potestat de prendre decisions sobre el funcionament de la cursa.
                            Estos oficials estaran distingits de la resta de alguna manera per facilitar als participants la resolució de qualsevols problema o dubte.
                        </p>

                        <h4>Art. 02-05-02 OFICIALS D’ORGANITZACIÓ</h4>
                        <p>Son aquelles persones responsables de totes les tasques extra esportives i necessàries per a poder realitzar la prova. (Banderes, controls de pas, personal de ajuda, etc...)
                            Cap oficial d’organització te potestat per a decidir sobre cap tema esportiu.
                            Si que es poden aprofitar el seus mitjans de comunicació interna per a que algun Oficial Esportiu puga informar a algun participant sobre alguna qüestió o dubte que siga necessari per a la proba.
                        </p>

                        <h4>Art. 02-06.- TAULER D’ANUNCIS OFICIALS – MODIFICACIONS</h4>
                        <p>Totes les modificacions o disposicions complementàries es posaran en coneixement dels participants el més prompte possible, mitjançant complements datats i numerats que formaran part d’aquest Reglament Particular.
                            Aquests complements, així com altres resolucions, citacions, penalitzacions, i altres documents, seran exposats al Tauler Oficial d’Avisos a sala de premsa habilitada per a la prova.
                        </p>
                        <p>En totes les modificacions figurarà l’hora de publicació. </p>

                        <h3>03.- RECORREGUT</h3>
                        <h4>Art. 03-01.- RECORREGUT</h4>
                        <p>El XXXIVé RAL·LI TRANSBETXÍ 2024, consta d’un recorregut comú per a tots els participants, amb huit (6) trams cronometrats, distribuïts de la següent manera:</p>
                        <table>
                            <thead>
                                <tr>
                                    <th>DISSABTE 15 D’ABRIL</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>09:30 H.</td>
                                    <td>TC1</td>
                                    <td>TRAM CRONOMETRAT</td>
                                </tr>
                                <tr>
                                    <td>10:30 H.</td>
                                    <td>TC2</td>
                                    <td>TRAM CRONOMETRAT</td>
                                </tr>
                                <tr>
                                    <td>15:30 H.</td>
                                    <td>TC3</td>
                                    <td>TRAM CRONOMETRAT</td>
                                </tr>
                                <tr>
                                    <td>16:30 H.</td>
                                    <td>TC4</td>
                                    <td>TRAM CRONOMETRAT</td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <table>
                            <thead>
                                <tr>
                                    <th>DIUMENGE 16 D’ABRIL</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>09:30 H.</td>
                                    <td>TC5</td>
                                    <td>TRAM CRONOMETRAT</td>
                                </tr>
                                <tr>
                                    <td>10:30 H.</td>
                                    <td>TC6</td>
                                    <td>TRAM CRONOMETRAT</td>
                                </tr>
                            </tbody>
                        </table>

                        <p>La classificació de la edició del 2023 per categories servirà per a establir l’ordre d’eixida a l’etapa del dissabte dia 6 d’abril de 2024.
                            Queda totalment prohibit realitzar marques sobre senyalitzacions de trànsit, hits, pavimentacions, edificacions o marges de la carretera i camins per part dels concursants o altres persones relacionades amb ells.
                        </p>
                        <p>D’altra banda, també els entrenaments del recorregut efectuats al marge de la prova pels equips a partir de diumenge 31 Març de 2024, estan PROHIBITS. S’estableix per aquest suposat el mateix procediment de reclamació i actuacions d’ofici establerts a l’article 7 d’aquest reglament, el qual, a més de  tota infracció denunciada per la Policia Local o qualsevol altre organisme governatiu, implica l’incompliment d’aquest article, considerat com a FALTA MOLT GREU, contemplada en el règim disciplinari d’aquest reglament.</p>

                        <h3>04.- INSCRIPCIÓ DELS PARTICIPANTS</h3>
                        <h4>Art. 04-01.- VEHICLES ADMESOS</h4>
                        <p>Són admesos per a participar en aquesta prova els motocultors sense excepció, però s’ha de tenir en compte que han d’estar compresos dins les següents limitacions i/o característiques tècniques</p>
                        <ul>
                            <li>Ser motocultors de sèrie o tradicionals, evolucionats o no, que conserven motorització (sense canvis externs ni refrigeració líquida), embragament i caixa de canvis originals de llurs marques.</li>
                            <li>No són admeses altres fórmules de tracció (excepte invitació de l’organització) i, amb caràcter general, és obligat que tots els vehicles participants conserven la resta de les característiques del vehicle tradicional, com poden ser  la direcció, el sistema d’unió motriu-carro, etc.</li>
                        </ul>

                        <h4>Art. 04-02.- MESURES DE SEGURETAT</h4>
                        <p>Cada equip participant, per tal d’estar localitzable, rebre notificacions, a més de poder comunicar accidents i altres incidències, deurà portar un telèfon mòbil amb WhatsApp.
                            Tots els participants s’han d’equipar obligatòriament amb els següents elements de protecció:
                        </p>
                        <ol>
                            <li>Casc homologat d’enduro, cros o integral (son aquells que porten una numeració especifica, (que comença per 05, P ó J) el casc correctament col·locat i ajustat amb la corretja ben passada). La numeració (etiquetat del casc) deura de estar visible clarament.
                                S’enviarà un document amb la normativa a tots els socis per comprovar si estan homologats.
                                <ul>a.	Es revisaran tots i no s’acceptarà cap que estiga en mal estat, no complisca la normativa o tinga el etiquetat en mal estat o il·legible Collarí de seguretat, es obligatori collarí rígids o ortopèdic de motocròs.</ul>
                            </li>
                            <li>Protector de pit, espatlla i muscles amb colzeres.</li>
                            <li>Serà obligatori per al peto integral o cuirassa, amb colzeres i muscleres integrades. </li>
                            <li>Es recomana tindre en conter les característiques que deuen de complir i mirar si son compatibles amb els collarins rígids, abans de comprar o actualitzar les seves proteccions.</li>
                            <li>Guants de protecció (mínim exigible: EPI laboral per riscos mecànics NP3). </li>
                            <li>Genolleres</li>
                            <li>Botes d’enduro o cros (mínim exigible: Mitja canya).</li>
                            <li>Serà obligatori un extintor de 1kg de “polvo químico ABC” correctament fixat al vehicle.</li>
                            <li><strong>Es OBLIGATORI el sistema de “hombre al agua” per a aturar el motoret en cas de accident </strong></li>
                            <li>Durant tot el trajecte del XXXIV ral·li TRANSBETXÍ 2024 els motocultors han d’estar protegits amb la barra antibolcada. </li>
                            <li>Han de protegir totes les palanques i puntes amb boles o elements protectors. </li>
                            <li>S’han de recobrir les planxes o xapes amb cantons vius amb perfils de goma o plàstics.</li>
                            <li><strong>13.	En cas de portar una batería per al funcionament de la màquina, el borne positiu deurà de anar tapat per a evitar entrar en contacte amb les parts metàl·liques del motoret i poder causar una xispa que provoque un incendi.</strong></li>
                        </ol>

                        <h4>Art. 04-03.- INSCRIPCIONS</h4>
                        <p>Es permet la inscripció al XXXIV RAL·LI TRANSBETXÍ 2024 a tota persona major de 18 anys, posseïdora de llicència esportiva i que haja complit la sol·licitut d’inscripció a la prova, degudament signada a l’imprès que facilitarà l’organització i que haja satisfet els drets d’inscripció.
                            Les inscripcions s’inicien el 18 de Febrer i conclouen el 16 de Març de 2024 a les 21:00 h., i s’hi pot realitzar cada dia a la pàgina <strong>www.transbetxi.com</strong> .
                        </p>
                        <p>El procediment correcte d’una inscripció consta de les següents parts i cal seguir-les en el mateix ordre:</p>
                        <ol>
                            <li>Inscripció per part de l’equip del pilot, copilot i màquina a l’imprès que la pàgina www.transbetxi.com disposa per tal de poder efectuar-la.</li>
                            <li>Comprovació de la inscripció per al llistat oficial d’inscrits.</li>
                            <p>Es farà, dins del termini d’inscripció, remetent al correu transbetxi89@gmail.com, degudament signada i escanejada, la següent documentació:</p>
                            <strong>PILOT:</strong>
                            <ol>
                                <li>CÒPIA IMPRESA DE LA INSCRIPCIÓ REALITZADA A LA PÀGINA WEB.</li>
                                <li>1 FOTOCÒPIA I ORIGINAL DE LA LLICENCIA FEDERATIVA (envers i revers).</li>
                                <li>1 FOTOCÒPIA I ORIGINAL DEL DOCUMENT NACIONAL D’IDENTITAT (envers i revers).</li>
                                <li>1 FOTOCÒPIA I ORIGINAL DEL PERMÍS DE CONDUIR: B1 (envers i revers).</li>
                            </ol>
                            <strong>PILOT:</strong>
                            <ol>
                                <li>CÒPIA IMPRESA DE LA INSCRIPCIÓ REALITZADA A LA PÀGINA WEB.</li>
                                <li>1 FOTOCÒPIA I ORIGINAL DE LA LLICENCIA FEDERATIVA (envers i revers).</li>
                                <li>1 FOTOCÒPIA I ORIGINAL DEL DOCUMENT NACIONAL D’IDENTITAT (envers i revers).</li>
                                <li>1 FOTOCÒPIA I ORIGINAL DEL PERMÍS DE CONDUIR: B1 (envers i revers) O RECONEIXEMENT MÈDIC EN VIGOR DE L’ANY EN CURS.</li>
                            </ol>
                        </ol>
                        <p>L’organització facilitarà als participants correctament inscrits els següents documents:</p>
                        <ol>
                            <li>FITXA D’INSCRIPCIÓ DE L’EQUIP. </li>
                            <li>CÒPIA DEL REGLAMENT.</li>
                        </ol>
                        <p>L’import, contemplat en l´article 10, haurà de ser abonat en el moment de la inscripció oficial.
                            En cas d’haver-hi modificacions en la inscripció, l’equip haurà de comunicar-ho amb data límit de 24 Març de 2024 i adjuntarà totes les dades que l’organització crega convenient.
                        </p>
                        <ul>
                            <li>Tf. de contacte 696598253. Ref.: En Juanma Moreno.</li>
                            <li>Tf. de contacte 666177845. Ref.: En Xavi Cabedo.</li>
                        </ul>
                        <p>Estan prohibides les dobles inscripcions de vehicles i de participants.</p>

                        <h4>Art. 04-05.- DRETS D’INSCRIPCIÓ</h4>
                        <p>Els drets d’inscripció es fixen en <strong>420.-Euros </strong>per vehicle (s’hi inclou el pagament de les llicències federatives).
                            La inscripció no podrà ser acceptada si no va acompanyada del drets d’inscripció dins del termini fixat en aquest reglament.
                        </p>

                        <h4>Art. 04-06.- ASSEGURANCES</h4>
                        <p>L’organització es reserva el dret de completar el reglament particular i/o emetre disposicions o instruccions complementàries, que hi seran part integrant. Es reserva també el dret d’anul·lar o de parar la prova en cas de força major o succés imprevisible, sense cap obligació d’indemnització.
                            Totes les modificacions i disposicions complementàries seran conegudes pels participants amb la major brevetat possible, mitjançant annexos datats i numerats i a través del medi que s’estime oportú.
                        </p>
                        <p>Mínimament totes les modificacions i disposicions complementàries seran publicades al Tauler Oficial d’Avisos.</p>
                        <strong>(Veure: Art. 02-05.- TAULER D’ANUNCIS OFICIALS – MODIFICACIONS)</strong>
                        <p>L’organització es reserva el dret de rebutjar la inscripció d’un equip; per defectes de forma i terminis, falta de documentació aportada, suposats contemplats al present reglament i d’altres qüestions rellevants, que puguen atemptar contra la integritat, l’image publica i la seguretat de la cursa.
                            El Jurat està autoritzat a decidir sobre qualsevol cas no previst en el Reglament Particular de la prova
                        </p>

                        <h3>05.- OBLIGACIONS DELS PARTICIPANTS</h3>

                        <h4>Art. 05-01.- ASSISTÈNCIA AL BRIEFING</h4>
                        <p>L’assistència al Briefing inicial de dimecres, dia 03 d’abril  de 2024 a les 22:00 h. al Auditori Municipal de Betxí es obligatòria per a tots els equips.
                            A excepció dels casos degudament justificats i comunicats a l’organització, al menys un membre de l’equip (pilot, copilot o responsable de l’equip acreditat) ha d’assistir-hi, recollir-ne tota la documentació amanida i quedar-se a escoltar la descripció i les advertències del recorregut de la cursa també se farà entrega de les acreditacions.
                        </p>

                        <h4>Art. 05-02.- NÚMEROS</h4>
                        <p>Per a cada motocultor participant, l’organització facilitarà, un joc de números doble, que es col·locarà de forma visible als laterals del vehicle, durant tota la prova.
                            Els motocultors que no porten els números, dels quals no siguen correctes o no estiguen correctament col·locats, no seran autoritzats a prendre la eixida.
                            Els números han de tenir, en tot moment, la forma, mida i composició original.
                            No es podran retallar ni afegir cap adhesiu ni publicitat en ells
                            La concessió dels números a cada vehicle és competència de l’organització.
                        </p>

                        <h4>Art. 05-03.- PUBLICITAT</h4>
                        <p>Els vehicles podran anar publicitats a elecció dels seus pilots.
                            No obstant això, és obligatori dur els dorsals proporcionats per l’organització.
                            Així es reservaran dos espais de 20x20 Cm (un a cada costat), que permetran una perfecta visió en els laterals del vehicle.
                            La col·locació haurà de ser correcta, és a dir, adherits sobre una superfície plana, no mai corba, de suport metàl·lic llis, totalment pegat al lateral i sense cap angle sortint.
                            L’incompliment d’aquest article per part d’un equip participant és considerat com a FALTA GREU, contemplada en el règim disciplinari d’aquest reglament.
                        </p>

                        <h4>Art. 05-04.- PRESENTACIÓ A L´EIXIDA </h4>
                        <p>Els participants i llurs vehicles s’han de presentar en el Parc Tancat Pre-cursa i han d’estar a disposició de l’organització a l’hora establerta en l’Horari Oficial.
                            Els participants assumiran les conseqüències d’ignorància de disposicions o de canvis d’horari, que pogueren decidir-se durant l’hora anterior a l’eixida de la prova.
                            Els participants s’hi col·locaran en la fila d’eixida, almenys, 10 minuts abans de la seua hora d’eixida assignada.
                            El motocultor que no s’hi presente a hora perd el dret a l’ordre d’eixida en l’etapa.
                            Per qüestions de seguretat, aquest dret és derogat quan la fila d’eixida queda constituïda, i fins i tot, l’equip infractor podria ser exclòs de la competició, si així ho considera l’organització.
                            L’incompliment d’aquest article per part d’un equip participant és considerat com a FALTA LLEU, contemplada en el règim disciplinari d’aquest reglament.
                        </p>

                        <h4>Art. 05-06.- SENYALITZACIÓ. COMPORTAMENT EN LA CURSA.</h4>
                        <p>Des de la seua presentació al Parc Pre-eixida fins a la finalització de la prova, els participants hauran d’atendre rigorosament a les indicacions de l’organització.
                            Està totalment prohibit circular en sentit invers al de la cursa.
                            Tota infracció a aquesta norma implicarà l’expulsió immediata de la prova. L’incompliment d’aquest article per part d’un equip (participants o assistències) és considerat com a FALTA MOLT GREU, contemplada en el règim disciplinari d’aquest reglament.
                        </p>
                        <p>En cas que un pilot haja d’interrompre el seu recorregut, en un tram cronometrat o en qualsevol altre lloc, per problemes mecànics o d’altre tipus, aparcarà d’immediat el vehicle fora de la carretera o pista, perquè no suposen cap problema per a la resta de vehicles participants i efectuarà les comprovacions i les reparacions pertinents.
                        </p>
                        <p>En cas de no poder reparar el motoret en el tram o en el lloc on es trobe: </p>
                        <ol>
                            <li>Informar al delegat de seguretat, David Brisach, del estat en que es troben i rebre indicacions del que deuen fer. </li>
                            <li>En cap moment deuen de abandonar el motoret i el lloc on estiguin, fins que no els ho autoritzen el delegat de seguretat. </li>
                        </ol>
                        <strong><small>Cadascun dels vehicles agafats per darrere per un mes ràpid en un tram cronometrat ha de amainar la velocitat. I deixar pas lliure lo mes aviat possible, sempre sense crear cap situació perillosa per a ningú i en cap moment del recorregut podran desdoblar-se recuperant així la seua posició inicial.</small></strong>
                        <p>L’incompliment d’aquest article per part d’un equip participant és considerat com a FALTA LLEU, contemplada en el règim disciplinari d’aquest reglament.
                            No és permès que cap vehicle participant siga arrossegat i/o empentat per un altre, siga quina siga la seua naturalesa i el sistema d’arrossegament.
                            L’incompliment d’aquest article per part d’un equip participant és considerat com a FALTA GREU, contemplada en el règim disciplinari d’aquest reglament.
                        </p>

                        <h3>06.- CONTROL TÈCNIC I ADMINISTRATIU</h3>
                        <h4>Art. 06-01.- CONTROL TÈCNIC I ADMINISTRATIU</h4>
                        <p>El control tècnic i administratiu tindrà lloc en el parc tancat PTP, divendres 5 d’abril de 2024 des de les 18:00 hores, fins les 20:00 h., en compareixença gradual dels vehicles, pilot o copilot, segons la programació que l’organització farà pública en forma de disposició complementària en el present reglament.
                            L’incompliment d’aquest article per part d’un equip participant es considerat com a FALTA LLEU, contemplada en el règim disciplinari d’aquest reglament.
                            Aquest control es realitzarà al vehicle i als cascs; tots ells quedaran validats amb uns indicatius oficials, que hauran de romandre íntegres al llarg de tota la cursa. L’incompliment d’aquest article per part d’un equip participant és considerat com a FALTA GREU, contemplada en el règim disciplinari d’aquest reglament.
                            En aquest control, es lliura a tots els equips participants el Llibre de Ruta Oficial de la Cursa (Road Book). On tindran un full per a poder presentar una reclamació; si ho creuen oportú, seguint el procediment que marca el reglament.
                        </p>

                        <h3>CONTROL TÈCNIC I ADMINISTRATIU  /  TORN DE COMPAREIXENCES</h3>
                        <big>
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ paddingRight: '10em' }}>HORA</th>
                                        <th>EQUIPS-DORSAL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>18:00</td>
                                        <td>53 52 51 50 49 48</td>
                                    </tr>

                                    <tr>
                                        <td>18:15</td>
                                        <td>47	46	45	44	43	42</td>
                                    </tr>

                                    <tr>
                                        <td>18:30</td>
                                        <td>41	40	39	38	37	36</td>
                                    </tr>

                                    <tr>
                                        <td>18:45</td>
                                        <td>35	34	33	32	31	30</td>
                                    </tr>

                                    <tr>
                                        <td>19:00</td>
                                        <td>29	28	27	26	25	24</td>
                                    </tr>

                                    <tr>
                                        <td>19:15</td>
                                        <td>23	22	21	20	19	18</td>
                                    </tr>

                                    <tr>
                                        <td>19:30</td>
                                        <td>17	16	15	14	13	12</td>
                                    </tr>

                                    <tr>
                                        <td>19:45</td>
                                        <td>11	10	9	8	7	6</td>
                                    </tr>
                                    <tr>
                                        <td>20:00</td>
                                        <td>5	4	3	2	1	</td>
                                    </tr>
                                </tbody>
                            </table>
                        </big>

                        <h3>07.- DESENVOLUPAMENT DE LA PROVA</h3>
                        <h4>Art. 07-01.- EIXIDA, ARRIBADA, CRONOMETRATGE</h4>
                        <p>L’eixida es donarà individualment amb pilot i copilot en posició (en contacte amb el motocultor), amb el vehicle parat i el motor en marxa.
                            l’ordre d’eixida es dóna de forma ascendent a la numeració (segons la seva classificació, per categories, en el  XXXIII TRANSBETXÍ 2023).
                            En cas de no haver acabat el passat ral·li o ser nou per a aquest, se li assignarà el següent número al adjudicat al últim classificat del anterior ral·li. Així eixirà el primer participant de series , i a continuació la resta de vehicles de minut en minut.
                        </p>
                        <p>Per a l’etapa de dissabte 06 d’abril de 2024 el primer classificat eixirà a les 09:30 hores, i a continuació la resta de classificats de minut en minut.
                            Els participants estan obligats a seguir el recorregut indicat en el Llibre de Cursa sense passar-se cap control.
                            Cal respectar els temps de descans previstos per l’organització.
                            L’organització declina tota responsabilitat en cas d’equivocació en el seguiment de la ruta prevista.
                            El cronometratge es realitzarà al segon, amb cronòmetres que accionen un sistema d´impressió.
                            Cap reclamació en contra d’aquesta serà acceptada.
                            Les fitxes de temps que es faciliten als equips sols són d’informació i sota responsabilitat dels participants.
                            Aquell vehicle que se li haja donat l’eixida i no la realitze de forma immediata no te, en cap cas, dret a una segona eixida i es comptabilitza a partir del primer temps donat.
                            La negació o el retard superior a 15 segons en prendre l’eixida, a qualsevol Control Horari o Tram Cronometrat, significarà l’exclusió del tram.
                            Cal prendre al peu de la lletra les instruccions de l’Organització.
                            L’incompliment d’aquest article per part d’un equip participant és considerat com a FALTA LLEU, contemplada en el règim disciplinari d’aquest reglament.
                            L’arribada en els trams cronometrats es registrarà al pas del vehicle, amb el pilot i el copilot.
                        </p>
                        <strong>El tram cronometrat acaba en passar la línia d’arribada i la velocitat s’ha de reduir immediatament, a més de no obstaculitzar zona de frenada. </strong>
                        <p>L’incompliment d’aquest article per part d’un equip participant és considerat com a FALTA GREU, contemplada en el règim disciplinari d’aquest reglament.
                            En cas d’interrupció forçada de la cursa per qualsevol membre de l’organització, als equips participants afectats per aquesta mesura se‘ls donarà el temps del millor participant arribat a meta (atenent-nos al Codi Esportiu Internacional i al Concepte d’Actitud Antiesportiva).
                            Cada tram cronometrat es tanca després de tants minuts transcorreguts com equips hi haja inscrits a la cursa, més cinc minuts suplementaris, després de l’hora d’eixida del primer participant.
                            Qualsevol interrupció autoritzada per la direcció de la cursa implica un temps afegit igual al de la parada.
                        </p>

                        <h4>Art. 07-02.- CURSA</h4>
                        La cursa tindrà lloc segons l’horari detallat per l’organització en el punt 1 d’aquest reglament.
                        Hi haurà 6 trams cronometrats <strong>(detallats a l’article 03-01.- RECORREGUT) </strong>amb llurs etapes d’enllaç.
                        Cada infracció de les regles establertes en el present reglament serà comunicada al Jurat, i per decisió d’aquest, podrà ser motiu de sanció i pot arribar fins i tot a l’exclusió de l’equip de la prova.
                        Tota infracció denunciada per la Guàrdia Civil, Policia Autonòmica, Policia Local, o qualsevol altre organisme governatiu implica l’exclusió immediata de la prova de l’equip denunciat.
                        Aquest suposat inclou els controls d’alcoholèmia i d’altres, que es realitzaran en cas d’accident o quan s’estime oportú.
                        <strong>De la mateixa manera es traslladarà al Comitè de Disciplina de la FMCV per si tingueren algun tipus de sanció per part de la FMCV.</strong>

                        <h3>08.-CLASSIFICACIONS, RECLAMACIONS, APEL·LACIONS</h3>
                        <h4>Art. 08-01.- CLASSIFICACIONS </h4>
                        <p>Seran expedides per l’organització com a màxim dues hores després de la cursa indicant en ella la hora de publicació o en el cas de una reclamació tècnica al estar esta resolta.
                            Mitja hora desprès de publicada esta classificació ja queda automàticament aprovada.
                            En el cas de haver en tràmit una reclamació de qualsevol tipus, no quedarà aprovada fins que no estiguen tots el casos resolts y publicada la nova classificació.
                        </p>
                        <p>El primer classificat en un Tram Cronometrat serà l’equip que invertisca menys temps i així successivament.
                            Els temps seran acumulables i es considerarà guanyador de la prova l’equip que haja començat i acabat tots els trams cronometrats invertint-hi menys temps.
                            En cas d’empat, el criteri a seguir serà el major nombre de trams guanyats.
                        </p>

                        <h4>Art. 08-02.- RECLAMACIONS</h4>
                        <h4>Art.- 08.02.01 Normes generals</h4>
                        <p>Cada reclamació nomes podrà tractar sobre un punt en concret.
                            Les reclamacions seran públiques i personals (el reclamant ho farà en nom propi)
                            Les reclamacions col·lectives, així com les efectuades contra les comprovacions de cronometratge o contra les decisions dels jutges de fet, no són admeses.
                            Cal recordar que el dret de reclamació pertany sols als participants.
                        </p>
                        <p>Tant la notificació de la reclamació, com la resolució serà comunicada per escrit a les parts interessades i de seguit publicada al Tauler Oficial d’Avisos.
                            Si la reclamació està fonamentada i es comprova l’anomalia, la fiança i el dipòsit seran tornats al reclamant i el reclamat pagarà les despeses de la inspecció, en cas d’haver-ne.
                            Si la reclamació no està fonamentada i no es troba cap anomalia, la fiança quedarà en poder de l’organització i el reclamant pagarà les despeses de la inspecció, en cas d’haver-ne.
                            El Jurat pot actuar d’ofici i iniciar qualsevol acció esportiva o de verificació tècnica a tants vehicles i/o participants com crega convinent i sempre que hi trobe indicis d’una irregularitat.
                        </p>
                        <h4>Art.- 08.02.02 Reclamacions esportives</h4>
                        <p>Les reclamacions per temes esportius hauran de ser formulades i presentades per escrit al Jurat ser acompanyades d’una fiança de 100.-Eur.
                            El temps màxim per a presentar uns reclamació es de 30 minuts desprès de acabada la ultima prova del dia.
                            El Jurat, en considerar fundada i legítima la reclamació, iniciarà l’obertura d’un procediment, que finalitzarà en la redacció d’una resolució final, amb les conclusions i les possibles sancions.
                        </p>
                        <h4>Art.- 08.02.03 Reclamacions contra la classificació</h4>
                        <p>El termini de reclamació en contra dels resultats, de la classificació esportiu és de 30 minuts, després de la publicació dels resultats del dia.
                            Hauran de ser formulades i presentades per escrit al Jurat i ser acompanyades d’una fiança de <strong>100.-Eur</strong> .
                        </p>
                        <h4>Art.- 08.02.04 Reclamacions tècniques</h4>
                        <p>Las possibles reclamacions contra una màquina que poguera presentar components que incompliren alguna de les especificacions tècniques, haurà de ser formulada i presentada per escrit a l’Organització abans de acabar la ultima prova del dia, per escrits i acompanyada d’una fiança de 100.-Eur., a més d’un dipòsit per despeses mecàniques de 100,00.-Eur., pagada per l’equip que formule reclamació. </p>
                        <h4>Art. 08-03.- CONTROL DÒPING</h4>
                        <p>Al discorre la prova per camins i carreteres publiques les forces d’odre públic tenen potestat per a fer proves que creguen convenient.
                            El fet de fer els desplaçament neutralitzat entre trams, no elimina la obligació de complir en esta norma esportiva.
                            Davant de un possible accident en cas de donar positiu en alcohol o algun tipus de droga es pot complicar el pagament de l’assistència sanitària per part de la asseguradora.
                        </p>
                        <h4>Art. 08-04.-PENALITZACIONS</h4>
                        <p>Es castiga amb la penalització els equips participants en els casos i les quantitats que es detallen a continuació.
                            El Jurat es reserva el dret de penalitzar altres supòsits, no contemplats en aquest article, amb altres càrregues
                        </p>
                        <small><strong>ELIMINACIÓ DE LA CLASSIFICACIÓ:</strong></small>
                        <ol>
                            <li>Quan un equip no inicia un tram cronometrat.</li>
                            <li>Per als canvis de la màquina participant per una altra no inscrita</li>
                        </ol>
                        <small><strong>EXCLUSIÓ DE LA CURSA:</strong></small>
                        <ol>
                            <li>En els casos d’accés per part de les assistències d’un equip a trams no permesos per l’organització, a més de ser castigats amb una falta greu.</li>
                            <li>En el canvis de pilot o de copilot per altres no inscrits en la present edició del XXXIV ral·li TRASBETXÍ 2024, a més de ser castigats amb una falta molt greu.</li>
                        </ol>
                        <small><strong>MÉS VINT SEGONS PER SEGON ANTICIPAT:</strong></small>
                        <ol>
                            <li>Per a aquells equips que, desobeint els cronometradors oficials, prenen l’eixida abans que els la donen.</li>
                        </ol>
                        <small><strong>MÉS CINC SEGONS:</strong></small>
                        <ol>
                            <li>En els trams cronometrats TCP (divendres) per als equips que traspassen o abaten, amb una o més rodes, les senyalitzacions que marquen el circuit.</li>
                        </ol>
                        <p>En els trams TCP i TCD, quan un vehicle se n’ix fortuïtament del circuit i no s’hi incorpora pel mateix punt d’eixida, en els casos involuntaris es penalitzarà amb MÉS QUINZE SEGONS i en els casos intencionats es penalitzarà amb MÉS UN MINUT, a més de ser castigats amb una falta lleu.</p>
                        <small><strong>MÉS DOS MINUTS: </strong></small>
                        <ol>
                            <li>Per a aquells participants que quan van a ser doblats no s’aparten i hi faciliten el pas, a més de ser castigats amb una falta lleu.</li>
                        </ol>
                        <small><strong>MÉS UN MINUT I EL TEMPS MÍNIM ESTABLERT PER L’ORGANITZACIÓ:</strong></small>
                        <ol>
                            <li>Per aquells equips que per diverses raons (mecàniques, físiques, etc.), en un tram cronometrat, se’ls done l’eixida i no puguen aconseguir arribar a meta, siga quina siga la distància recorreguda. No obstant això, igualment tenen dret a continuar en competició per a les millors classificacions absolutes.</li>
                        </ol>
                        <p>En els casos on no arriben a meta en el mateix temps pilot i copilot, serà anotat, com a temps realitzat, el marcat per l’últim component de l’equip que arribe a meta; en els casos en què un dels dos no acabe el tram cronometrat, s’aplicarà el punt anterior</p>
                        <small><strong>MES DEU MINUTS:</strong></small>
                        <ol>
                            <li>El canvi de cilindre per avaria. </li>
                            <li>El cilindre de recanvi deurà de haver estat verificat i precintat per l´organització, en el control administratiu</li>
                        </ol>
                        <small><strong>MES VINT MINUTS:</strong></small>
                        <ol>
                            <li>Per pèrdua del precinte oficial i no comunicar-ho a l´organització.</li>
                        </ol>
                        <h4>Art. 08-04 RÈGIM DISCIPLINARI </h4>
                        <p>Es castiga amb l’aplicació d’aquest règim disciplinari els equips participants en els casos i les conseqüències que es detallen a continuació.
                            El Jurat es reserva el dret a penalitzar altres supòsits no contemplats en aquest article amb altres càrregues.
                        </p>
                        <p>Una <strong>FALTA MOLT GREU</strong>  suposa l’exclusió de la cursa, la pèrdua de la fiança pagada i la impossibilitat de ser inscrit per l’edició del ral·li Transbetxí de l’any següent a qualsevol dels membres de l’equip.</p>
                        <p>Una <strong>FALTA GREU</strong>  suposa l’exclusió de la cursa i la pèrdua de la fiança pagada. </p>
                        <p>L’acumulació de cinc <strong>FALTES LLEUS</strong>  implica la comissió d’una falta greu.</p>
                        <p>Altres supòsits no contemplats al present reglament particular, queden sotmesos al règim disciplinari de la FMCV</p>

                        <h3>09.-PREMIS I TROFEUS, LLIURAMENT DE PREMIS</h3>
                        <h4>Art. 09-01.-PREMIS</h4>
                        <p>Els premis seran:</p>
                        <ol>
                            <li>Al primer Classificat Absolut (menys temps invertit al final de totes les etapes cronometrades).</li>
                            <li>Al segon Classificat Absolut (id.).</li>
                            <li>Al tercer Classificat Absolut (id.).</li>
                            <li>Als 3 primers classificats de cada categoria</li>
                        </ol>
                        <p>El lliurament de premis es farà Diumenge 07 d’Abril a les 18:30 h. al FRONTÓ MUNICIPAL.</p>
                    </div>
                </Grid>
            </Grid>

            <PiePagina></PiePagina>
        </div>
    )
}
