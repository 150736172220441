import { Grid, TextField } from '@mui/material';
import React, { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Select from '@mui/material/Select';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios';
import Swal from 'sweetalert2';


export const AnadirComponente = ({ setNuevoComponente }) => {


    const [editar, setEditar] = useState(false);
    const [detalles, setDetalles] = useState(false);
    const [detallesComponente, setDetallesComponente] = useState(
        {
            apellidos: null,
            cod_postal: null,
            direccion: null,
            dni: null,
            nacimiento: null,
            nombre: null,
            poblacion: null,
            seg_social: null,
            telefono: null
        });



    const crearComponente = () => {
        console.log(detallesComponente)

        axios.post('https://www.transbetxi.com/WS/componente.php', detallesComponente)
        .then(function (response) {
            console.log(response.data)
            if(response.data.success === true){
                Swal.fire({
                    icon: "success",
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 1500
                });
                setNuevoComponente(false);
            } else if(response.data.success === false){
                Swal.fire({
                    icon: "error",
                    title: response.data.error,
                    showConfirmButton: false,
                    timer: 1500
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Error inesperado",
                    showConfirmButton: false,
                    timer: 1500
                });
            }
        })
        .catch(function (error) {
            // manejar error
            console.log(error);
        })
    }

    const handleOnChange = event => {
        const { name, value } = event.target;

        setDetallesComponente(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (

        <div className='overlay '>
            <div style={{ borderRadius: '15px', backgroundColor: '#C9C9C9', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                <div className='detalles-componente-container'>
                    <div className='tramo-container'>
                        <div className='edit-equipo-element'>
                            <div>DNI:</div>
                            <TextField
                                onChange={handleOnChange}
                                name='dni'
                                value={detallesComponente.dni}
                                id="outlined-basic"
                                variant="outlined"
                                className='field'
                                style={{ backgroundColor: '#FFFFFF', borderRadius: '10px' }}
                            />
                        </div>
                        <div className='edit-equipo-element'>
                            Nombre:
                            <TextField
                                onChange={handleOnChange}
                                name='nombre'
                                value={detallesComponente.nombre}
                                id="outlined-basic"
                                variant="outlined"
                                className='field'
                                style={{ backgroundColor: '#FFFFFF', borderRadius: '10px' }}
                            />
                        </div>
                        <div className='edit-equipo-element'>
                            Apellidos:
                            <TextField
                                onChange={handleOnChange}
                                name='apellidos'
                                value={detallesComponente.apellidos}
                                id="outlined-basic"
                                variant="outlined"
                                className='field'
                                style={{ backgroundColor: '#FFFFFF', borderRadius: '10px' }}
                            />
                        </div>
                        <div className='edit-equipo-element'>
                            Direccion:
                            <TextField
                                onChange={handleOnChange}
                                name='direccion'
                                value={detallesComponente.direccion}
                                id="outlined-basic"
                                variant="outlined"
                                className='field'
                                style={{ backgroundColor: '#FFFFFF', borderRadius: '10px' }}
                            />
                        </div>

                        <div className='edit-equipo-element'>
                            Telefono:
                            <TextField
                                onChange={handleOnChange}
                                name='telefono'
                                value={detallesComponente.telefono}
                                id="outlined-basic"
                                variant="outlined"
                                className='field'
                                style={{ backgroundColor: '#FFFFFF', borderRadius: '10px' }}
                            />
                        </div>
                    </div>
                    <div className='tramo-container'>
                        <div className='tramo-container'>
                            <div className='edit-equipo-element'>
                                Poblacion:
                                <TextField
                                    onChange={handleOnChange}
                                    name='poblacion'
                                    value={detallesComponente.poblacion}
                                    id="outlined-basic"
                                    variant="outlined"
                                    className='field'
                                    style={{ backgroundColor: '#FFFFFF', borderRadius: '10px' }}
                                />
                            </div>
                            <div className='edit-equipo-element'>
                                Seguridad social:
                                <TextField
                                    onChange={handleOnChange}
                                    name='seg_social'
                                    value={detallesComponente.seg_social}
                                    id="outlined-basic"
                                    variant="outlined"
                                    className='field'
                                    style={{ backgroundColor: '#FFFFFF', borderRadius: '10px' }}
                                />
                            </div>
                            <div className='edit-equipo-element'>
                                Fecha Nacimiento:
                                <TextField
                                    onChange={handleOnChange}
                                    name='nacimiento'
                                    value={detallesComponente.nacimiento}
                                    id="outlined-basic"
                                    variant="outlined"
                                    className='field'
                                    style={{ backgroundColor: '#FFFFFF', borderRadius: '10px' }}
                                />
                            </div>
                            <div className='edit-equipo-element'>
                                Codigo Postal:
                                <TextField
                                    onChange={handleOnChange}
                                    name='cod_postal'
                                    value={detallesComponente.cod_postal}
                                    id="outlined-basic"
                                    variant="outlined"
                                    className='field'
                                    style={{ backgroundColor: '#FFFFFF', borderRadius: '10px' }}
                                />
                            </div>

                        </div>
                    </div>
                </div>
                <div className='buttons-container'>
                    {!editar && (
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignContent: 'center', alignItems: 'center', justifyContent: 'center', gap: '1em', marginBottom: '1em' }}>
                            <div className="button-adm" onClick={() => crearComponente()}>
                                Añadir
                            </div>
                            <div className="button-adm" onClick={() => { setNuevoComponente(false) }}>
                                Cancelar
                            </div>
                        </div>
                    )}
                    {editar && (
                        <div className='button-component-dissabled'>
                            Guardar Componente
                        </div>
                    )}

                </div>
            </div>

        </div>
    )
}
