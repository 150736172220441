import React, { useState } from 'react'
import logo from '../media/logo.png';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';

export const HeaderGer = () => {

	const [menu, setMenu] = useState(false);

	const Navigate = useNavigate();

	const mostrarMenu = () => {
		if (menu == true) {
			setMenu(false);
		} else {
			setMenu(true);
		}

	}

	return (
		<div className='header'>
			<div className='logo' onClick={() => Navigate("/")}>
				<img src={logo} className='logo-header' />
			</div>
			<Grid container direction="column" justifyContent="center" alignItems="center">
				{menu ? <CloseIcon onClick={() => mostrarMenu()} className="fade-in-2" style={{ color: '#EAC474' }} fontSize="large" />
					: <MenuIcon onClick={() => mostrarMenu()} className="fade-in-2" style={{ color: '#EAC474' }} fontSize="large" />
				}
			</Grid>
			{menu === true && (
				<Grid container direction="column" justifyContent="center" alignItems="center" xs={12} md={12}>
					<Grid container
						direction="row"
						justifyContent="center"
						alignItems="center"
						// spacing={1}
						xs={10} md={7}
						style={{ marginTop: '1em' }}
						className='fade-in'
					>
						<Grid container xs={1} md={1} direction="column" justifyContent="center" alignItems="center" className="sirk-2" onClick={() => Navigate('/home')}>
							<a className="element-custom-2">
								Heim
							</a>
						</Grid>

						<Grid container xs={1} md={1} direction="column" justifyContent="center" alignItems="center" className="sirk-2" onClick={() => Navigate('/actividades')}>
							<a className="element-custom-2">
								Aktivitäten
							</a>
						</Grid>

						<Grid container xs={1} md={1} direction="column" justifyContent="center" alignItems="center" className="sirk-2" onClick={() => Navigate('/equipos')}>
							<a className="element-custom-2">
								Mannschaften / Menschen
							</a>
						</Grid>

						<Grid container xs={1} md={1} direction="column" justifyContent="center" alignItems="center" className="sirk-2" onClick={() => Navigate('/tiempos')}>
							<a className="element-custom-2">
								Einstufung
							</a>
						</Grid>

						<Grid container xs={1} md={1} direction="column" justifyContent="center" alignItems="center" className="sirk-2" onClick={() => Navigate('/mapas')}>
							<a className="element-custom-2">
								Abschnittskarte
							</a>
						</Grid>

						<Grid container xs={1} md={1} direction="column" justifyContent="center" alignItems="center" className="sirk-2" onClick={() => Navigate('/inscripciones')}>
							<a className="element-custom-2">
								Beschriftet
							</a>
						</Grid>

						<Grid container xs={1} md={1} direction="column" justifyContent="center" alignItems="center" className="sirk-2" onClick={() => Navigate('/reglamentos')}>
							<a className="element-custom-2">
								Vorschriften
							</a>
						</Grid>

						<Grid container xs={1} md={1} direction="column" justifyContent="center" alignItems="center" className="sirk-2" onClick={() => Navigate('/historia')}>
							<a className="element-custom-2">
								Geschichte
							</a>
						</Grid>

						<Grid container xs={1} md={1} direction="column" justifyContent="center" alignItems="center" className="sirk-2" onClick={() => Navigate('/donde-comer')}>
							<a className="element-custom-2">
								Wo sollen wir essen
							</a>
						</Grid>

						<Grid container xs={1} md={1} direction="column" justifyContent="center" alignItems="center" className="sirk-2" onClick={() => Navigate('/patrocinadores')}>
							<a className="element-custom-2">
								Sponsors
							</a>
						</Grid>
					</Grid>
				</Grid>
			)}

			<div className='spacer'>
			</div>
		</div>
	)
}
