import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Header } from '../../Header';
import { Grid } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import AletartaCard from './AletartaCard';



export const Advertencias = () => {

    const Navigate = useNavigate();
    const param = useParams();

    const [contenidoNoti, setContenidoNoti] = useState([]);
    const [eliminar, setEliminar] = useState(false);
    const [editar, setEditar] = useState(false);
    const [tempAlerta, setTempAlerta] = useState("");
    const [anadirAlerta, setAnadirAlerta] = useState(false);
    const [alerta, setAlerta] = useState({
        edicion: param.edicion,
        descripcion: ""
    })


    const getNotificaciones = () => {
        axios.get('https://www.transbetxi.com/WS/notificaciones.php?edicion=' + param.edicion)
            .then(function (response) {
                // manejar respuesta exitosa
                // console.log(response.data)
                setContenidoNoti(response.data);
            })
            .catch(function (error) {
                // manejar error
                // console.log(error);

            })
            .finally(function () {
                // siempre sera executado
            });
    }

    const postNotificaciones = () => {
        // console.log(alerta);
        axios.post('https://www.transbetxi.com/WS/notificaciones.php', alerta)
            .then(function (response) {
                // console.log(response);
                if (response.data.success === true) {
                    let temp = [...contenidoNoti];
                    alerta.activa = 1;
                    alerta.id = response.data.id;
                    temp.push(alerta);
                    setContenidoNoti(temp);
                }

            })
            .catch(function (error) {
                console.error('Error al enviar la solicitud:', error);
            });

    }

    // const borrarNotificaciones = (id) => {
    //     console.log(id);
    //     axios.delete('https://www.transbetxi.com/WS/notificaciones.php?id=' + id)
    //         .then(function (response) {
    //             // manejar respuesta exitosa
    //             // console.log(response.data);
    //             if (response.data.success == true) {
    //                 let temp = [...contenidoNoti];
    //                 console.log("Antes" + contenidoNoti);
    //                 setContenidoNoti(temp.filter((tmp) => tmp.id != response.data.id));
    //                 console.log("Despues" + temp);
    //                 // temp = temp.filter((tmp) => tmp.id != id );
    //                 // setContenidoNoti(temp);
    //             }
    //         })
    //         .catch(function (error) {
    //             // manejar error
    //             // console.log(error);

    //         })
    //         .finally(function () {
    //             // siempre sera executado
    //         });
    // }
    // const handleClickOpen = () => {
    //     setAnadirAlerta(true);
    // };

    const handleClose = () => {
        setAnadirAlerta(false);
    };

    const handleOnChange = event => {

        switch (event.target.name) {
            case 'descripcion':
                setAlerta({ ...alerta, descripcion: event.target.value });
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        getNotificaciones();
    }, [])


    return (
        <div>
            <Header></Header>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={12}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                >
                    <h1>Advertencias</h1>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={8}
                    style={{ gap: '1em' }}
                >
                    <Grid container xs={2} className="button-aux" onClick={() => Navigate('/edicion/' + param.edicion)}>
                        Volver
                    </Grid>
                    <Grid container xs={2} className="button-aux" onClick={() => setAnadirAlerta(true)}>
                        Nueva Alerta
                    </Grid>
                    <Grid container xs={2} className="button-aux" onClick={eliminar ? () => setEliminar(false) : () => setEliminar(true)} >
                        {eliminar === true ? "CANCELAR" : "ELIMINAR"}
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={8}
                    style={{ gap: '1em', marginTop: '1em' }}
                >
                    {contenidoNoti.map((contenido, index) => (
                        <AletartaCard contenido={contenido} eliminar={eliminar} arrayAlertas={contenidoNoti} setContenidoNoti={setContenidoNoti}></AletartaCard>

                        // <div key={index} className='flex-vertical' style={{ alignItems: 'flex-start', backgroundColor: (contenido.activa == 1 ? '#e30505' : '#C9C9C9'), borderRadius: '15px', padding: '0em 1em' }}>
                        //     <h3 style={{ color: '#2E2E2E', marginBottom: '-1em' }}>Alerta Nº {contenido.id}</h3>
                        //     <p>{contenido.descripcion}</p>
                        //     <div className="flex-horizontal" style={{ gap: '1em' }}>
                        //         <div className='flex-horizontal pointer'
                        //             onClick={editar ? () => setEditar(false) : () => { setEditar(true); setTempAlerta(contenido) }}
                        //         >
                        //             <AutoFixHighIcon />
                        //         </div>
                        //         {eliminar === true ? (
                        //             <div className='element-aux pointer'
                        //                 onClick={() => borrarNotificaciones(contenido.id)}
                        //             >
                        //                 <DeleteForeverIcon />
                        //             </div>
                        //         ) : (
                        //             <div className='element-aux'>
                        //             </div>
                        //         )}
                        //     </div>
                        // </div>
                    ))}
                </Grid>
                <Dialog
                    open={anadirAlerta}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ backdropFilter: 'blur(5px)', minWidth: '50em' }}
                >
                    <DialogContent style={{ backgroundColor: '#C9C9C9' }}>
                        <DialogContentText id="alert-dialog-description">
                            <TextField
                                id="outlined-multiline-static"
                                label="Descripcion"
                                multiline
                                rows={4}
                                defaultValue=""
                                name='descripcion'
                                onChange={handleOnChange}
                                style={{ backgroundColor: '#FFF', borderRadius: '15px' }}
                            />
                        </DialogContentText>
                        <Button onClick={handleClose} >Cancelar</Button>
                        <Button onClick={() => { handleClose(); postNotificaciones(); }}>Añadir</Button>
                    </DialogContent>
                </Dialog>



            </Grid>

        </div>
    )
}
