import React from 'react'
import { DondeComerEs } from '../landing_page/dondeComer/DondeComerEs';
import { DondeComerVal } from '../landing_page/dondeComer/DondeComerVal';
import { DondeComerEn } from '../landing_page/dondeComer/DondeComerEn';
import { DondeComerGer } from '../landing_page/dondeComer/DondeComerGer';
import { SelectorIdioma } from '../components/SelectorIdioma';

import { useMyContext } from '../MyProvider '; //Variable global del idioma

export const DondeComer = () => {

    const { miVariableGlobal, setMiVariableGlobal } = useMyContext();

    return (
        <div className="fade-in">

            <SelectorIdioma idioma={miVariableGlobal} ></SelectorIdioma>

            {(miVariableGlobal == 1) && (
                <DondeComerEs></DondeComerEs>
            )}

            {(miVariableGlobal == 2) && (
                <DondeComerVal></DondeComerVal>
            )}

            {(miVariableGlobal == 3) && (
                <DondeComerEn></DondeComerEn>
            )}

            {(miVariableGlobal == 4) && (
                <DondeComerGer></DondeComerGer>
            )}
        </div>
    )
}
