import React from 'react';
import { Grid } from '@mui/material';
import MapaGenerico from './MapaGenerico'; // Asegúrate de que el path es correcto

export const SegundoTramo = () => {
    const polylineCoordinates = [
        { lat: 39.9249792, lng: -0.2076609 },
        { lat: 39.9264883, lng: -0.2189767 }
    ];

    return (
        <Grid container direction="column" xs={10} md={6} lg={10} style={{ margin: 'auto', marginTop: '1em', textAlign: 'center' }}>
            <h2>Mapa En Tiempo Real TC2</h2>
            <MapaGenerico polylineCoordinates={polylineCoordinates} />
        </Grid>
    );
}

export default SegundoTramo;