import React, { useEffect } from 'react';
import { HeaderGer } from '../../components/HeaderGer';
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';

export const PoliticaCookiesGer = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div className='fade-in'>
            <HeaderGer></HeaderGer>

            <Grid container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={12}
            >
                <Grid container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    xs={10} sm={10} md={6}
                    style={{ color: '#EAC474' }}
                >
                    <h1>Was sind Cookies?</h1>
                    <p>Im Englischen bedeutet der Begriff „Cookie“ Cookie, aber im Bereich des Surfens im Internet ist ein „Cookie“ etwas völlig anderes. Wenn Sie auf unsere Website zugreifen, wird eine kleine Textmenge, ein sogenanntes „Cookie“, im Browser Ihres Geräts gespeichert. Dieser Text enthält verschiedene Informationen über Ihr Surfverhalten, Ihre Gewohnheiten, Vorlieben, Inhaltsanpassungen usw.</p>
                    <p>Es gibt andere Technologien, die auf ähnliche Weise funktionieren und ebenfalls zum Sammeln von Daten über Ihre Surfaktivitäten verwendet werden. Wir werden alle diese Technologien zusammen „Cookies“ nennen.</p>
                    <p>Die konkreten Verwendungszwecke dieser Technologien werden in diesem Dokument beschrieben.</p>

                    <h1>Wofür werden Cookies auf dieser Website verwendet?</h1>
                    <p>Cookies sind ein wesentlicher Bestandteil der Funktionsweise der Website. Das Hauptziel unserer Cookies besteht darin, Ihr Surferlebnis zu verbessern. Zum Beispiel, um Ihre Präferenzen (Sprache, Land usw.) beim Surfen und bei zukünftigen Besuchen zu speichern. Die in Cookies gesammelten Informationen ermöglichen es uns auch, die Website zu verbessern, sie an Ihre Interessen als Benutzer anzupassen, die von Ihnen durchgeführten Suchvorgänge zu beschleunigen usw.</p>
                    <p>In bestimmten Fällen können wir Cookies für andere Zwecke verwenden, wenn wir zuvor Ihre Einwilligung nach Aufklärung eingeholt haben, z. B. um Informationen zu erhalten, die es uns ermöglichen, Ihnen Werbung auf der Grundlage der Analyse Ihrer Surfgewohnheiten anzuzeigen.</p>

                    <h1>Wofür werden Cookies auf dieser Website NICHT verwendet?</h1>
                    <p>In den von uns verwendeten Cookies werden keine sensiblen persönlichen Identifikationsdaten wie Ihr Name, Ihre Adresse, Ihr Passwort usw. gespeichert.</p>

                    <h1>Wer nutzt die in Cookies gespeicherten Informationen?</h1>
                    <p>Die in den Cookies auf unserer Website gespeicherten Informationen werden ausschließlich von uns verwendet, mit Ausnahme der im Folgenden als „Cookies von Drittanbietern“ bezeichneten Cookies, die von externen Unternehmen verwendet und verwaltet werden, die uns Dienste zur Verbesserung des Benutzers bereitstellen Erfahrung. Benutzer. Zum Beispiel die Statistiken, die über die Anzahl der Besuche, den Inhalt, der am meisten geliked wird, usw. gesammelt werden. </p>

                    <h1>Wie können Sie die Verwendung von Cookies auf dieser Website verhindern?</h1>
                    <p>Wenn Sie die Verwendung von Cookies lieber vermeiden möchten, können Sie deren Verwendung ABLEHNEN oder diejenigen KONFIGURIEREN, die Sie vermeiden möchten und deren Verwendung Sie zulassen (in diesem Dokument geben wir Ihnen ausführliche Informationen zu jeder Art von Cookies, sein Zweck, Empfänger, Zeitlichkeit usw.).</p>
                    <p>Wenn Sie diese akzeptiert haben, werden wir Sie nicht erneut fragen, es sei denn, Sie löschen die Cookies auf Ihrem Gerät, wie im folgenden Abschnitt beschrieben. Wenn Sie Ihre Einwilligung widerrufen möchten, müssen Sie die Cookies löschen und erneut konfigurieren.</p>

                    <h1>Wie kann ich die Verwendung von Cookies deaktivieren und beseitigen?</h1>
                    <p>Um Cookies auf dieser Website (und den von Dritten verwendeten) einzuschränken, zu blockieren oder zu löschen, können Sie dies jederzeit tun, indem Sie Ihre Browsereinstellungen ändern. Bitte beachten Sie, dass diese Einstellungen in jedem Browser unterschiedlich sind.</p>
                    <p>Unter den folgenden Links finden Sie Anweisungen zum Aktivieren oder Deaktivieren von Cookies in den gängigsten Browsern.</p>
                    <ul>
                        <li><a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we" target="_blank" style={{ color: '#EAC474' }}>Firefox</a></li>
                        <li><a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank" style={{ color: '#EAC474' }}>Google Chrome</a></li>
                        <li><a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies#ie=%22ie-10" target="_blank" style={{ color: '#EAC474' }}>Internet Explorer</a></li>
                        <li><a href="https://support.microsoft.com/es-es/help/4468242/microsoft-edge-browsing-data-and-privacy" target="_blank" style={{ color: '#EAC474' }}>Microsoft Edge</a></li>
                        <li><a href="http://support.apple.com/kb/HT1677?viewlocale=es_ES" target="_blank" style={{ color: '#EAC474' }}>Safari</a></li>
                    </ul>

                    <h1>Welche Arten von Cookies werden auf dieser Website verwendet?</h1>
                    <p>Jede Website verwendet ihre eigenen Cookies. Auf unserer Website verwenden wir Folgendes:</p>

                    <h2>Gemäß der Entität, die es verwaltet</h2>
                    <p>Dabei handelt es sich um solche, die von einem Computer oder einer Domäne, die vom Herausgeber selbst verwaltet wird und von dem aus der vom Benutzer angeforderte Dienst bereitgestellt wird, an das Endgerät des Benutzers gesendet werden.</p>

                    <strong>Cookies von Drittanbietern:</strong>
                    <p>Dabei handelt es sich um solche, die von einem Computer oder einer Domäne an das Endgerät des Benutzers gesendet werden, die nicht vom Herausgeber verwaltet wird, sondern von einer anderen Stelle, die die durch Cookies erhaltenen Daten verarbeitet.</p>
                    <p>Für den Fall, dass die Cookies von einem Computer oder einer Domäne bereitgestellt werden, die vom Herausgeber selbst verwaltet wird, die über sie gesammelten Informationen jedoch von einem Dritten verwaltet werden, können sie nicht als eigene Cookies betrachtet werden, wenn der Dritte sie für sich verwendet eigene Zwecke (z. B. die Verbesserung der von ihm bereitgestellten Dienste oder die Bereitstellung von Werbediensten zugunsten anderer Unternehmen).</p>

                    <h2>Entsprechend seinem Zweck</h2>
                    <strong>Technische Cookies:</strong>
                    <p>Dabei handelt es sich um solche, die für die Navigation und das ordnungsgemäße Funktionieren unserer Website erforderlich sind, wie zum Beispiel die Steuerung des Datenverkehrs und der Datenkommunikation, die Identifizierung der Sitzung, den Zugriff auf Teile mit eingeschränktem Zugang, die Einreichung einer Registrierungs- oder Teilnahmeanfrage für eine Veranstaltung Besuche zum Zwecke der Abrechnung von Lizenzen für die Software, mit der der Website-Dienst funktioniert, Verwendung von Sicherheitselementen während der Navigation, Speicherung von Inhalten für die Verbreitung von Videos oder Ton, Ermöglichung dynamischer Inhalte (z. B. Animation beim Laden eines Textes oder Bildes).</ p>

                    <strong>Analyse-Cookies:</strong>
                    <p>Sie ermöglichen die Quantifizierung der Anzahl der Nutzer und führen so die Messung und statistische Analyse der Nutzung der Website durch die Nutzer durch.</p>

                    <strong>Präferenzen oder Personalisierungs-Cookies:</strong>
                    <p>Dabei handelt es sich um solche, die es dem Benutzer ermöglichen, sich Informationen zu merken, damit er auf den Dienst mit bestimmten Merkmalen zugreifen kann, die seine Erfahrung von der anderer Benutzer unterscheiden können, wie zum Beispiel die Sprache, die Anzahl der anzuzeigenden Ergebnisse wenn der Benutzer eine Suche durchführt. , das Erscheinungsbild oder der Inhalt des Dienstes, abhängig von der Art des Browsers, über den der Benutzer auf den Dienst zugreift, oder der Region, von der aus der Benutzer auf den Dienst zugreift usw.</p>

                    <h2>Je nachdem, wie lange sie aktiviert bleiben</h2>

                    <strong>Sitzungscookies:</strong>
                    <p>Sie dienen dazu, Daten zu sammeln und zu speichern, während der Benutzer auf eine Webseite zugreift.</p>
                    <p>Sie werden in der Regel zum Speichern von Informationen verwendet, deren Aufbewahrung nur für die Erbringung der vom Benutzer einmalig angeforderten Dienstleistung von Interesse ist (z. B. eine Liste der gekauften Produkte) und verschwinden, wenn die Sitzung endet.</p >

                    <strong>Persistente Cookies:</strong>
                    <p>Hierbei handelt es sich um solche, bei denen die Daten noch im Endgerät gespeichert sind und während eines vom Cookie-Verantwortlichen festgelegten Zeitraums, der von wenigen Minuten bis zu mehreren Jahren reichen kann, abgerufen und verarbeitet werden können. Dabei ist konkret zu prüfen, ob der Einsatz persistenter Cookies erforderlich ist, da durch den Einsatz von Session-Cookies Risiken für die Privatsphäre reduziert werden könnten. In jedem Fall wird bei der Installation dauerhafter Cookies empfohlen, deren vorübergehende Dauer unter Berücksichtigung des Verwendungszwecks auf das erforderliche Minimum zu reduzieren. Zu diesem Zweck wurde in der Stellungnahme 4/2012 der WG29 darauf hingewiesen, dass ein Cookie nur dann von der Pflicht zur Einwilligung nach Aufklärung ausgenommen ist, wenn sein Ablaufdatum mit seinem Zweck in Zusammenhang steht. Aus diesem Grund werden Sitzungscookies viel eher als ausgenommen betrachtet als dauerhafte Cookies.</p>

                    <strong>Details zu den auf dieser Website verwendeten Cookies:</strong>
                    <strong><p>Daten der besuchten Website</p></strong>
                    <small>Die Website speichert möglicherweise die von Ihnen bevorzugte Sprache oder die Artikel, die Sie kaufen möchten. Diese Informationen sind für die Website und ihre Subdomains verfügbar</small>
                </Grid>
            </Grid>


            <PiePagina></PiePagina>
        </div>
    )
}
