import React, { useEffect, useState } from 'react'
import axios from 'axios'; import logo from '../../media/logo.png';
import { HeaderVal } from '../../components/HeaderVal';
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Galeria } from '../../components/Galeria';

export const HistoriaVal = () => {

    const [ganadores, setGanadores] = useState([])

    const getGanadores = () => {
        axios.get('https://www.transbetxi.com/WS/ganadores.php')
            .then(function (response) {
                // manejar respuesta exitosa
                // console.log(response.data)
                setGanadores(response.data);

            })
            .catch(function (error) {
                // manejar error
                console.log(error);
            })
            .finally(function () {
                // siempre sera executado
            });
    }

    useEffect(() => {
        getGanadores();
    }, [])


    return (
        <div>
            <HeaderVal></HeaderVal>
            <Grid container xs={12} md={12} direction="column" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
                <Grid container direction="column" xs={10} md={7} style={{ alignItems: 'flex-start', justifyContent: 'flex-start', textAlign: 'left', marginTop: '1em', color: '#EAC474' }}>
                    <h2>Què és el TransBetxí?</h2>

                    <p>El “TransBetxí” és una carrera que es ve realitzant des de 1988 per camins i pistes del terme de Betxí (Castelló) i dels pobles limítrofs a aquest. Els equips estan formats per un pilot i un copilot, la màquina que utilitzen per a participar és un motocultor (normalment de gasolina).</p>
                    <p>No es tracta únicament d'una competició esportiva com les altres, sinó que té un component cultural que es manifesta a través del que significava la màquina per als agricultors antigament i perquè el poble ho pren com un acte festiu en el qual pràcticament tota la població està pendents d'aquest esdeveniment any rere any.</p>
                    <p>Evidentment aquesta carrera va néixer per casualitat, de la imaginació d'un grup d'amics que volien fer el seu Dakar particular amb un vehicle tan peculiar.</p>
                    <p>La carrera va començar amb 9 equips participants en els quals l'objectiu principal era passar-ho bé i en els quals al dia següent de la carrera aquests mateixos motocultors (aleshores sense cap millora tècnica) passaven de ser un vehicle per a ús esportiu a una màquina per a llaurar la terra de tarongers. Això ha anat evolucionant fins al punt que ara es corre per guanyar, ja que les màquines han anat evolucionant fins a convertir-se en autèntics fórmules un del camp. Normalment estan fetes pels propis membres de l'equip així com els seus familiars i amics.</p>
                    <p>El XXXIV TransBetxí consta d'un pròleg que s'inicia el dissabte 6 i diumenge 7 d'abril de 2024 a les 09.00 hores, en el qual es determina l'ordre de sortida dels posteriors trams que es realitzen aquest mateix dissabte (dos trams al matí i dos a la tarda) i el diumenge (dos trams al matí).</p>

                    <br />
                    <br />

                    <strong>Figura 1.- Logo del TransBetxí</strong>
                    <br />
                    <Grid container direction="column" xs={12} md={12} justifyContent="center" alignItems="center">
                        <img src={logo} alt="" />
                    </Grid>
                    <br />

                    <p>El TransBetxí és una prova reconeguda per la Federació de Motociclisme de La Comunitat Valenciana (FMCV) en la qual tots els participants i els seus vehicles d'assistència posseïxen la llicència federativa per a poder participar en esta mena de carreres. Això suposa que els participants estan coberts amb una assegurança d'assistència sanitària il·limitada, i que la carrera dispose d'una assegurança de responsabilitat civil d'acord amb esta mena de proves esportives. El control i direcció de la mateixa esta regulat per persones que disposen de la llicència federativa corresponent.</p>

                    <br />
                    <br />
                    <strong>Figura 2.- Foto d'un motocultor en carrera.</strong>
                    <br />
                    <Grid container direction="column" xs={12} md={12} justifyContent="center" alignItems="center">
                        <img src="https://www.transbetxi.com/img/historia/historia3.jpg" alt="" />
                    </Grid>
                    <p>L'Ajuntament de Betxí és l'organitzador del XXXIV TransBetxí 2024 i responsable de coordinar a tots els col·laboradors i voluntaris que participen en l'esdeveniment esportiu.</p>
                    <p>L'Ajuntament de Betxí és l'encarregat de sol·licitar els permisos corresponents per a poder accedir als trams pels quals circulen les màquines, tambien s'encarrega de la coordinació dels servicis mèdics, policia local i altres membres de seguretat i orde públic.</p>
                    <p>La Federació de Motociclisme de la Comunitat Valenciana a través dels seus comissaris esportius vetlen per l'aplicació del reglament. Tambien sota l'empara de FMCV està la figura del Director de Carrera, el qual és el responsable durant el transcurs de la competició.</p>
                    <p>Una altra figura important i que actua sota la supervisió del Director de Carrera és el Responsable de Comunicació, el qual dissenya tota la xarxa amb doble canal de comunicació mitjançant repetidors per a walkies i telèfons moviles que permeten poder tindre en contacte al Director de Carrera amb els responsables de cada tram i amb els membres de seguretat i orde públic. El Responsable de Comunicació té repartits en tots els trams que es realitzen durant la competició als seus ajudants. Normalment se situan en els inicis i finals de trams i en les zones que per especial complexitat puguen ser causa d'origen d'accidents, per a així poder parar la carrera en cas de perill per un accident.</p>
                    <p>I finalment tenim als Caps de Tram que solen col·locar-se en els inicis i finals de tram, per a així poder decidir en cas de perill interrompre l'eixida en el moment que se l'informe a traves del Responsable de Comunicació i amb la confirmació del Director de Carrera.</p>
                    <br />
                    <p>Per tant l'organigrama durant la celebració del TransBetxí és el següent:</p>
                    <small><strong>S'utilitza una doble canal de comunicació per a assegurar la comunicació en trams que la telefonia mòbil no disposa de cobertura, ja que hi ha diversos trams que discorren per pistes forestals.</strong></small>
                    <br />
                    <br />
                    <Grid container direction="column" xs={12} md={12} justifyContent="center" alignItems="center">
                        <Grid container direction="column" xs={12} sm={10} md={8} justifyContent="center" alignItems="center">
                            <img src="https://www.transbetxi.com/img/historia/historia4_val.png" alt="" />
                        </Grid>

                    </Grid>
                    <br />
                    <br />
                    <Grid container direction="column" xs={12} md={12} justifyContent="center" alignItems="center">
                        <h2>Palmars</h2>
                    </Grid>

                    <TableContainer component={Paper} style={{ backgroundColor: '#C9C9C9', borderRadius: '15px' }} elevation={12}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow style={{ backgroundColor: '#EAC474' }}>
                                    <TableCell><strong style={{ color: '#2E2E2E' }}>ANY</strong></TableCell>
                                    <TableCell><strong style={{ color: '#2E2E2E' }}>EQUIP</strong></TableCell>
                                    <TableCell><strong style={{ color: '#2E2E2E' }}>PILOT-COPILOT</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {ganadores.map((ganador, index) => (
                                    <TableRow>
                                        <TableCell align="left">{ganador.ano} </TableCell>
                                        <TableCell align="left">{ganador.equipo}</TableCell>
                                        <TableCell align="left">{ganador.pilotos}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br />
                    <br />

                    <Grid container direction="column" xs={12} md={12} justifyContent="center" alignItems="center">
                        <h2>Imatges historiques</h2>
                    </Grid>

                </Grid>
                <Galeria></Galeria>
            </Grid>


            <PiePagina></PiePagina>
        </div>
    )
}
