import React from 'react';
import { SelectorIdioma } from '../components/SelectorIdioma';
import { ReglamentosEs } from '../landing_page/reglamentos/ReglamentosEs';
import { ReglamentosVal } from '../landing_page/reglamentos/ReglamentosVal';
import { ReglamentosEn } from '../landing_page/reglamentos/ReglamentosEn';
import { ReglamentosGer } from '../landing_page/reglamentos/ReglamentosGer';
import { useMyContext } from '../MyProvider '; //Variable global del idioma


export const Reglamentos = () => {

    const { miVariableGlobal, setMiVariableGlobal } = useMyContext();

    return (
        <div className="fade-in">

            <SelectorIdioma idioma={miVariableGlobal} ></SelectorIdioma>

            {(miVariableGlobal == 1) && (
                <ReglamentosEs></ReglamentosEs>
            )}

            {(miVariableGlobal == 2) && (
                <ReglamentosVal></ReglamentosVal>
            )}

            {(miVariableGlobal == 3) && (
                <ReglamentosEn></ReglamentosEn>
            )}

            {(miVariableGlobal == 4) && (
                <ReglamentosGer></ReglamentosGer>
            )}
        </div>
    )
}
