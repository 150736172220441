import React from 'react';
import { PoliticaCookiesEs } from '../components/cookies/PoliticaCookiesEs';
import { PoliticaCookiesVal } from '../components/cookies/PoliticaCookiesVal';
import { PoliticaCookiesEn } from '../components/cookies/PoliticaCookiesEn';
import { PoliticaCookiesGer } from '../components/cookies/PoliticaCookiesGer';
import { SelectorIdioma } from '../components/SelectorIdioma';
import { useMyContext } from '../MyProvider '; //Variable global del idioma

export const PoliticaCookies = () => {

    const { miVariableGlobal, setMiVariableGlobal } = useMyContext();

    return (
        <div className="fade-in">

            <SelectorIdioma idioma={miVariableGlobal} ></SelectorIdioma>

            {(miVariableGlobal == 1) && (
                <PoliticaCookiesEs></PoliticaCookiesEs>
            )}

            {(miVariableGlobal == 2) && (
                <PoliticaCookiesVal></PoliticaCookiesVal>
            )}

            {(miVariableGlobal == 3) && (
                <PoliticaCookiesEn></PoliticaCookiesEn>
            )}

            {(miVariableGlobal == 4) && (
                <PoliticaCookiesGer></PoliticaCookiesGer>
            )}
        </div>
    )
}
