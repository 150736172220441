import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';

export const Galeria = () => {

    const [open, setOpen] = React.useState(false);
    const [imgTemp, setImgTemp] = useState("");

    const handleClickOpen = (param) => {
        setOpen(true);
        setImgTemp(param);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const itemData = [
        {
            img: 'https://www.transbetxi.com/img/ghistoria/1.jpg',
            title: 'historia',
        },
        {
            img: 'https://www.transbetxi.com/img/ghistoria/2.jpg',
            title: 'historia',
        },
        {
            img: 'https://www.transbetxi.com/img/ghistoria/3.jpg',
            title: 'historia',
        },
        {
            img: 'https://www.transbetxi.com/img/ghistoria/4.jpg',
            title: 'historia',
        },
        {
            img: 'https://www.transbetxi.com/img/ghistoria/5.jpg',
            title: 'historia',
        },
        {
            img: 'https://www.transbetxi.com/img/ghistoria/6.jpg',
            title: 'historia',
        },
        {
            img: 'https://www.transbetxi.com/img/ghistoria/7.jpg',
            title: 'historia',
        },
        {
            img: 'https://www.transbetxi.com/img/ghistoria/8.jpg',
            title: 'historia',
        },
        {
            img: 'https://www.transbetxi.com/img/ghistoria/9.jpg',
            title: 'historia',
        },
        {
            img: 'https://www.transbetxi.com/img/ghistoria/10.jpg',
            title: 'historia',
        },
        {
            img: 'https://www.transbetxi.com/img/ghistoria/11.jpg',
            title: 'historia',
        },
        {
            img: 'https://www.transbetxi.com/img/ghistoria/12.jpg',
            title: 'historia',
        },
        {
            img: 'https://www.transbetxi.com/img/ghistoria/13.jpg',
            title: 'historia',
        },
        {
            img: 'https://www.transbetxi.com/img/ghistoria/14.jpg',
            title: 'historia',
        },
        {
            img: 'https://www.transbetxi.com/img/ghistoria/15.jpg',
            title: 'historia',
        },
        {
            img: 'https://www.transbetxi.com/img/ghistoria/16.jpg',
            title: 'historia',
        },
        {
            img: 'https://www.transbetxi.com/img/ghistoria/17.jpg',
            title: 'historia',
        },
        {
            img: 'https://www.transbetxi.com/img/ghistoria/20.jpg',
            title: 'historia',
        }
    ];



    return (
        <Grid container direction="comlumn" justifyContent="center" alignItems="center" xs={10} sm={10} md={10} className="fade-in" style={{ overflow: 'auto', marginTop: '1em'}}>
            {/* <Grid container direction="comlumn" justifyContent="center" alignItems="center" xs={12} sm={12} md={12} style={{ margin: '3px', border: '22px', borderColor: '#FFF', minHeight: '10vh' }}>
                <h2>Imagenes historicas</h2>

            </Grid> */}

            {itemData.map((item) => (
                <Grid container direction="row" justifyContent="center" alignItems="center" xs={12} sm={5} md={3} style={{ margin: '3px' }}>
                    <img
                        srcSet={`${item.img}`}
                        src={`${item.img}`}
                        alt={item.title}
                        loading="lazy"
                        style={{ maxWidth: '100%' }}
                        onClick={() => handleClickOpen(item.img)}
                    />
                </Grid>

            ))}
            <Dialog
                className='fade-in-2'
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="md"
                PaperProps={{
                    style: {
                        backgroundColor: '#2E2E2E00',
                        width: '90%',
                        margin: 0,
                        padding: 0,
                    },
                }}
            >
                <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" xs={12} sm={12} md={12}>
                    <Button onClick={handleClose}>
                    <CloseIcon className="fade-in-2" style={{ color: '#EAC474' }} fontSize="large" />
                    </Button>
                </Grid>
                <DialogContent style={{ backgroundColor: '#FFF', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '0em' }}>
                    <img
                        src={imgTemp}
                        alt=""
                        loading="lazy"
                        style={{ maxWidth: '100%', maxHeight: '100%' }}
                        onClick={handleClose}
                    />

                </DialogContent>
            </Dialog>
        </Grid>
    )
}
