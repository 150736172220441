import axios from 'axios';
import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, IconButton, InputAdornment } from '@mui/material';
import { PersonaCard } from './PersonaCard';

export const ComponenteWp = () => {
    const [opcionesComp, setOpcionesComp] = useState([]);
    const [browser, setBrowser] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [componentes, setComponentes] = useState([]);



    const handleSearch = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        setSearchTerm(searchTerm);

        const filteredData = browser.filter((item) =>
            searchTerm
                .split(/\s+/)
                .every((word) => item.lista.toLowerCase().includes(word))
        );
        setComponentes(filteredData)
    }
    const listar = (list) => {
        const newList = list.map((element) => ({
            ...element,
            lista: `${element.nombre} ${element.apellidos}`,
        }));

        setBrowser(newList);
        setOpcionesComp(newList);
        setLoaded(true);
    };

    const componentesLoad = () => {
        axios.get('https://www.transbetxi.com/WS/componente.php', {
            headers: {
                'code': 'pass23sd2aASED6',
            }
        })
            .then(function (response) {
                setComponentes(response.data)
                listar(response.data);
            })
            .catch(function (error) {
                console.error(error)
            })
    }
    useEffect(() => {
        componentesLoad();
    }, [])
    return (
        <>
            {(loaded === false) && (
                <div className='flex-container spinner'>
                    <div className='loading-spinner'>
                    </div>
                </div>
            )}

            {(loaded === true) && (
                <>
                    <div className='flex-container'>
                        <div className='browser'>
                            <div className='flex-center'>
                                <TextField
                                    className="field"
                                    value={searchTerm}
                                    placeholder='Buscar'
                                    onChange={handleSearch}
                                    fullWidth
                                    id="fullWidth"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    edge="end"
                                                >
                                                    <SearchIcon sx={{ fontSize: 40 }} className='icon' />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <Grid container xs={12} direction="column" justifyContent="center" alignItems="center">
                        <Grid container xs={11} direction="row" justifyContent="center" alignItems="center">
                            {componentes.map((componente, index) => (
                                <PersonaCard persona={componente} index={index} />
                            ))
                            }
                        </Grid>
                    </Grid>
                </>

            )
            }

        </>
    )
}
