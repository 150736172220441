import React, { useEffect, useState } from 'react'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { CrearEquipo } from './equipos/CrearEquipo';
import { CrearTramo } from './tramos/CrearTramo';
import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Header } from '../Header';
import axios from 'axios';
import InfoIcon from '@mui/icons-material/Info';


export const Edicion = () => {

    const [ediciones, setEdiciones] = useState([]);
    const [edicion, setEdicion] = useState({});
    const [loaded, setLoaded] = useState(false);

    const Navigate = useNavigate();
    const param = useParams();

    useEffect(() => {
        cargarEdiciones();

    }, [])


    const cargarEdiciones = () => {
        axios.get('https://www.transbetxi.com/WS/edicion.php')
            .then(function (response) {
                if (response.data.length > 0) {
                    let temp = response.data;
                    temp = temp.reverse();
                    setEdiciones(temp);
                    setEdicion(temp.filter(tmp => (tmp.codigoedicion == param.edicion)))
                    setLoaded(true);
                    
                }
            })
            .catch(function (error) {
                console.error(error)
            })
    }


    return (
        <div >
            <Header></Header>
            {loaded && (
                <div>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="left"
                        spacing={0}
                    >
                        <Grid container xs={2} md={2} lg={2} >
                            <div className="button-adm" onClick={() => Navigate('/panel-admin')}>
                                Volver
                            </div>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={0}
                    >
                        <Grid container xs={11}>
                            <div className="header-gest-tiemp">
                                Edicion {edicion[0].nombre}
                            </div>
                        </Grid>
                        <Grid container xs={2} md={2} lg={2} >
                            <div className="button-adm" onClick={() => Navigate('/gestion-tiempo/'+param.edicion)}>
                                TIEMPOS
                            </div>
                        </Grid> <Grid container xs={2} md={2} lg={2} >
                            <div className="button-adm" onClick={() => Navigate('/equipos/'+param.edicion)}>
                                EQUIPOS
                            </div>
                        </Grid>
                        <Grid container xs={2} md={2} lg={2} >
                            <div className="button-adm" onClick={() => Navigate('/tramos/'+param.edicion+'/'+edicion[0].nombre)}>
                                TRAMOS
                            </div>
                        </Grid>
                        <Grid container xs={2} md={2} lg={2} >
                            <div className="button-adm" onClick={() => Navigate('/patrocinadoresadm/'+param.edicion)}>
                                PATROCINADORES
                            </div>
                        </Grid>
                        <Grid container xs={2} md={2} lg={2} >
                            <div className="button-adm" onClick={() => Navigate('/advertencias/'+param.edicion)}>
                                ADVERTENCIAS
                            </div>
                        </Grid>
                        <Grid container xs={2} md={2} lg={2} >
                            <div className="button-adm" onClick={() => Navigate('/mapa/' + param.edicion)}>
                                Mapas
                            </div>
                        </Grid>
                    </Grid>

                </div >
            )
            }

        </div >
    )

}
