import { Grid } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

export const DetallesEquipo = ({ setMInfo, equi, dni, nombre }) => {
    const [loaded, setLoaded] = useState(false);
    const [infoEquipo, setInfoEquipo] = useState([]);
    const [ediciones, setEdiciones] = useState([]);

    useEffect(() => {
        cargarDetallesEquipo();
        cargarEdiciones();
    }, [])

    function decodeHTMLEntities(text) {
        var textArea = document.createElement('textarea');
        textArea.innerHTML = text;
        return textArea.value;
    }
    const cargarEdiciones = () => {
        axios.get('https://www.transbetxi.com/WS/edicion.php')
            .then(function (response) {
                if (response.status === 200) {
                    setEdiciones(response.data);
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const cargarDetallesEquipo = () => {
        axios.get('https://www.transbetxi.com/WS/componenteInfo.php?dni=' + dni)
            .then(function (response) {
                // manejar respuesta exitosa

                if (response.data.success == true) {
                    setInfoEquipo(response.data.data);

                    setLoaded(true);
                } else if (response.data.succes == false) {

                }


            })
            .catch(function (error) {
                // manejar error
                console.log(error);
            })
            .finally(function () {
                // siempre sera executado
            });
    }

    return (
        <>


            <div className="overlay" style={{ zIndex: 9999, position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}>

                {loaded == false && (
                    <div className='flex-container spinner'>
                        <div className='loading-spinner'>
                        </div>
                    </div>
                )}
                {loaded == true && (

                    <Grid container xs={12} sm={8} md={6} xl={4} direction="row" justifyContent="center" alignItems="center" style={{ margin: 'auto' }}>
                        <Grid container xs={6} md={10} direction="row" justifyContent="center" alignItems="center" className='equipo-card-info'>
                            <div className="spacer"></div>

                            <div className='equipo-card-info'>
                                <CloseIcon onClick={() => setMInfo(false)} className="fade-in-2" style={{ color: '#EAC474' }} fontSize="large" />
                                <div className='flex-center'>
                                    {infoEquipo.equipoNombre}
                                </div>
                                <div className='equipo-element'>
                                    <div className="flex-center">
                                        <h2>{decodeHTMLEntities(nombre)}</h2>
                                    </div>
                                </div>
                                <div className='component-card-info' style={{ backgroundColor: '#C9C9C9', color: '#2E2E2E'}}>
                                    <div className='component-card-row-type2'>
                                        <div className='component-card-row'>{'Año'}</div>
                                        <div className='component-card-row'>{'Posicion'}</div>
                                        <div className='component-card-row'>{'Categoria'}</div>
                                        <div className='component-card-row'>{'Pos. Categoria'}</div>
                                    </div>
                                </div>
                                {infoEquipo.map((histor, index) => (
                                    <div className={ histor.descalificado == 'yes' ? 'component-container descalif' : 'component-container' }>
                                        <div className={ histor.descalificado == 'yes'  ? 'component-card-info descalif' : 'component-card-info' } key={index}>
                                            <div className='component-card-row'>
                                                {decodeHTMLEntities(histor.equipoNombre)}
                                            </div>
                                        </div>
                                        <div className= { histor.descalificado == 'yes' ? 'component-card-info descalif' : 'component-card-info'} key={index}>

                                            <div className='component-card-row-type2'>
                                                <div className='component-card-row'>
                                                    {decodeHTMLEntities(histor.nombre_edicion)} <br />{histor.rol}
                                                </div>
                                                <div className='component-card-row'>
                                                    {histor.posicion_categoria ? histor.posicion_categoria + "º" : ''}
                                                </div>
                                                <div className='component-card-row'>
                                                    {histor.categoria == 1 ? "PRO" : histor.categoria == 2 ? "CLA" : histor.categoria == 3 ? 'AGR' : ''}
                                                </div>
                                                <div className='component-card-row'>
                                                    {histor.posicion_global ? histor.posicion_global + "º" : ''}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Grid>

                    </Grid>
                )}
            </div>


        </>

    )
}
