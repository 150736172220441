import React from 'react';
import { SelectorIdioma } from '../components/SelectorIdioma';
import { HomeEs } from '../landing_page/home/HomeEs';
import { HomeEn } from '../landing_page/home/HomeEn';
import { HomeVal } from '../landing_page/home/HomeVal';
import { HomeGer } from '../landing_page/home/HomeGer';
import { useMyContext } from '../MyProvider '; //Variable global del idioma

export const Home = () => {

    const { miVariableGlobal, setMiVariableGlobal } = useMyContext();

    return (
        <div className="fade-in">
           <SelectorIdioma idioma={miVariableGlobal} ></SelectorIdioma>

            {(miVariableGlobal == 1) && (
                <HomeEs></HomeEs>
            )}

            {(miVariableGlobal == 2) && (
                <HomeVal></HomeVal>
            )}

            {(miVariableGlobal == 3) && (
                <HomeEn></HomeEn>
            )}

            {(miVariableGlobal == 4) && (
                <HomeGer></HomeGer>
            )}
        </div>

    )
}
