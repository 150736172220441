import { MenuItem, Select, TextField } from '@mui/material';
import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import Swal from 'sweetalert2';


export const CrearTramo = ({ setAnadirTramo, edicion, codigoEdicion, setTramos, tramos }) => {
    const [editar, setEditar] = useState(false);
    const [loaded, setLoaded] = useState(true);

    const [tramo, setTramo] = useState({
        codigotramo: null,
        orden_tramo: null,
        nombre: null,
        descripcion: null,
        edicion: codigoEdicion,
        finalizado: null
    })

    const handleOnChange = event => {
        const { name, value } = event.target;

        setTramo(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const crearTramo = () => {
        console.log(tramo);
        axios.post('https://www.transbetxi.com/WS/tramos.php', tramo)
            .then(function (response) {
                console.log(response);
                if (response.data.success == true) {

                    let tmp = [...tramos];
                    tramo.codigotramo = response.data.codigotramo;
                    tmp.push(tramo);
                    Swal.fire({
                        icon: "success",
                        title: "El tramo ha sido creado correctamente",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    setTramos(tmp);
                    setAnadirTramo(false);
                }
            })
            .catch(function (error) {
                console.error('Error al enviar la solicitud:', error);
            });

    }
    

    return (
        <div className='detalles-equipo'>
            {loaded === false && (
                <div className='flex-center'>
                    <div className='loading-spinner'></div>
                </div>
            )}
            {loaded === true && (
                <div className='overlay fade-in-2 '>
                    <div className='detalles-componente-container'>
                        <div className='tramo-container'>
                            <div className='edit-equipo-element'>
                                Nombre:
                                <TextField
                                    onChange={handleOnChange}
                                    name='nombre'
                                    value={tramo.nombre}
                                    disabled={editar}
                                    id="outlined-basic"
                                    variant="outlined"
                                    className='field'
                                />
                            </div>
                            <div className='edit-equipo-element'>
                                Orden Tramo:
                                <TextField
                                    onChange={handleOnChange}
                                    name='orden_tramo'
                                    value={tramo.orden_tramo}
                                    disabled={editar}
                                    id="outlined-basic"
                                    variant="outlined"
                                    className='field'
                                    type='number'
                                />
                            </div>



                        </div>
                        <div className='tramo-container'>
                            <div className='edit-equipo-element'>
                                Descripcion:
                                <TextField
                                    onChange={handleOnChange}
                                    name='descripcion'
                                    value={tramo.descripcion}
                                    disabled={editar}
                                    id="outlined-basic"
                                    variant="outlined"
                                    className='field'
                                />
                            </div>
                            <div className='edit-equipo-element'>
                                Edición:
                                <TextField
                                    onChange={handleOnChange}
                                    name='edicion'
                                    value={edicion}
                                    disabled={true}
                                    id="outlined-basic"
                                    variant="outlined"
                                    className='field'
                                />
                            </div>
                        </div>
                        <div className="buttons-container">
                            {(editar === false) && (
                                <div className='edit-equipo-element'>
                                    <div className='button-aux' onClick={() => crearTramo()}>
                                        Guardar Tramo
                                    </div>
                                    {(editar === true) && (
                                        <div className='button-aux' onClick={() => setEditar(false)}>
                                            <EditIcon />    Editar
                                        </div>
                                    )}
                                    <div className='button-aux' onClick={() => setAnadirTramo(false)}>
                                        Cancelar
                                    </div>
                                </div>
                            )
                            }
                        </div>

                    </div>
                </div>

            )}
        </div>
    );
};