import { Grid } from '@mui/material'
import React, { useState } from 'react'
import { DetallesEquipo } from './DetallesEquipo';
import casco from '../media/casco.png';

export const PersonaCard = ({ persona, index }) => {
    const [minfo, setMInfo] = useState(false);

    return (
        <Grid container xs={10} sm={5} md={4} direction="row" justifyContent="center" alignItems="center" key={index}>
            <div className='equipo-card'>
                <div className="persona-container">
                    {<img className="avatar" img src={casco} />}

                </div>
                <div className="persona-container">
                    <div className='persona-content'>
                        {persona.nombre}
                        <div>{persona.apellidos}</div>

                    </div>

                </div>
                <div className="persona-container">
                    <div className='boton-equipo-info' onClick={() => setMInfo(true)}>+ info</div>
                </div>
            </div>


            {minfo === true && (
                <DetallesEquipo equi={persona} setMInfo={setMInfo} dni={persona.dni} nombre={persona.nombre + " " + persona.apellidos} />

            )}

        </Grid >

    )
}
