import React, { useEffect } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Es from '../media/banderas/bandera.png';
import Val from '../media/banderas/valaencia.png';
import En from '../media/banderas/reino-unido.png';
import Ger from '../media/banderas/alemania.png';
import { useMyContext } from '../MyProvider '; //Variable global del idioma
import './Marcos.css';



export const SelectorIdioma = () => {

    const { miVariableGlobal, setMiVariableGlobal } = useMyContext();

    const theme = createTheme({
        palette: {
            primary: {
                light: '#757ce800',
                main: '#3f50b500',
                dark: '#00288400',
                contrastText: '#fffFFF00',
            },
            secondary: {
                light: '#ff796100',
                main: '#f4433600',
                dark: '#ba000d00',
                contrastText: '#0000000',
            },
        },
    });

    const handleChange = (event) => {
        setMiVariableGlobal(event.target.value);
    };

    return (
        <div className="fade-in">

            <div className="flex-horizontal" style={{ justifyContent: 'right' }}>
                <div className='selec-idioma'>
                    <ThemeProvider theme={theme}>
                        <Select
                            value={miVariableGlobal}
                            onChange={handleChange}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                                getContentAnchorEl: null,
                                style: { marginTop: 'auto' }, // Para evitar que el menú se superponga con el select
                            }}
                        >
                            <MenuItem value={1}><img src={Es} alt="Es" style={{ maxWidth: '35px' }} /></MenuItem>
                            <MenuItem value={2}><img src={Val} alt="Val" style={{ maxWidth: '35px' }} /></MenuItem>
                            <MenuItem value={3}><img src={En} alt="En" style={{ maxWidth: '35px' }} /></MenuItem>
                            <MenuItem value={4}><img src={Ger} alt="Ger" style={{ maxWidth: '35px' }} /></MenuItem>
                        </Select>
                    </ThemeProvider>
                </div>
            </div>
        </div>
    )
}
