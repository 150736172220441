import React from 'react';
import { Grid } from '@mui/material';
import MapaGenerico from './MapaGenerico'; // Asegúrate de que el path es correcto

export const PrimerTramo = () => {
    const polylineCoordinates = [
        { lat: 39.925534, lng: -0.197960 },
        { lat: 39.924678, lng: -0.197600 },
        { lat: 39.924768, lng: -0.198641 },
        { lat: 39.924843, lng: -0.199623 },
        { lat: 39.925694, lng: -0.199097 },
        { lat: 39.925604, lng: -0.197965 }

    ];

    return (
        <Grid container direction="column" xs={10} md={6} lg={10} style={{ margin: 'auto', marginTop: '1em', textAlign: 'center' }}>
            <h2>Mapa En Tiempo Real TC1</h2>
            <MapaGenerico polylineCoordinates={polylineCoordinates} />
        </Grid>
    );
}

export default PrimerTramo;