import React, { useEffect, useState } from 'react';
import { Header } from '../Header';
import axios from 'axios';
import { Edicion } from './Edicion';
import { CrearEdicion } from './CrearEdicion';
import { CrearTramo } from './tramos/CrearTramo';
import { CompnenteCard } from './componentes/CompnenteCard';
import { Grid } from '@mui/material';
import { AnadirComponente } from './componentes/AnadirComponente';
import { useNavigate } from 'react-router-dom';
import { Componente } from './componentes/Componente';

export const PanelPrincipal = () => {

    const [edicion, setEdicion] = useState();
    const [ediciones, setEdiciones] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [desplegable, setDesplegable] = useState(false);
    const [nuevaEdicion, setNuevaEdicion] = useState(false);
    const [nuevoTramo, setNuevoTramo] = useState(false);
    const [nuevoComponente, setNuevoComponente] = useState(false);

    const Navigate = useNavigate();


    //estados para ediciones
    const [mostrarEdiciones, setMostrarEdiciones] = useState(false);

    //estados para componentes
    const [componentes, setComponentes] = useState([]);
    const [mostrarComponentes, setMostrarComponentes] = useState(false);
    //ficticio respuesta WS
    const [tiempos, setTiempos] = useState([]);

    useEffect(() => {
        firtsLoad();
    }, [])


    const cargarComponentes = () => {
        axios.get('https://www.transbetxi.com/WS/componente.php', {
            headers: {
                'code': 'pass23sd2aASED6',

            }
        })
            .then(function (response) {
                setComponentes(response.data);
                console.log("waa", response.data);
                setLoaded(true);
            })
            .catch(function (error) {
                // manejar error
                console.log(error);
            })
            .finally(function () {
                // siempre sera executado
            });
    }

    const cargarEdiciones = () => {
        axios.get('https://www.transbetxi.com/WS/edicion.php')
            .then(function (response) {
                if (response.data.length > 0) {
                    let temp = response.data;
                    temp = temp.reverse();
                    setEdiciones(temp);
                }
            })
            .catch(function (error) {
                console.error(error)
            })
    }
    const firtsLoad = () => {
        cargarEdiciones();
        cargarComponentes();

    }
    return (
        <div className='fade-in'>
            <Header />
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1}
            >

                {(mostrarEdiciones === false) && (mostrarComponentes === false) && (
                    <>
                        <Grid container xs={6} md={4} lg={2} >
                            <div className="button-adm" onClick={() => setMostrarEdiciones(true)}>
                                Edición
                            </div>
                        </Grid>
                        <Grid container xs={6} md={4} lg={2} >
                            <div className="button-adm" onClick={() => setMostrarComponentes(true)}>
                                Componentes
                            </div>
                        </Grid>

                    </>
                )}
                {mostrarEdiciones === true && (
                    <>
                        <h1>Selecciona la edicion a la que quieres acceder</h1>

                        <Grid container xs={6} md={4} lg={2} >
                            <div className="button-adm" onClick={() => setMostrarEdiciones(false)}>
                                Cancelar
                            </div>
                        </Grid>
                        <Grid container xs={6} md={4} lg={2} >
                            <div className="button-adm" onClick={() => setNuevaEdicion(true)}>
                                Nueva Edicion
                            </div>
                        </Grid>
                        {ediciones.map((edicion, index) => (
                            <Grid container xs={6} md={4} lg={2} >
                                <div className="button-adm" onClick={() => Navigate('/edicion/' + edicion.codigoedicion)}>
                                    {edicion.nombre}
                                </div>
                            </Grid>
                        ))}
                    </>
                )}
                {mostrarComponentes === true && (
                    <>
                        <Grid container xs={6} md={4} lg={2} >
                            <div className="button-adm" onClick={() => setMostrarComponentes(false)}>
                                Cancelar
                            </div>
                        </Grid>
                        <Componente />
                    </>
                )}
                {nuevaEdicion && (
                    <CrearEdicion setNuevaEdicion={setNuevaEdicion} />
                )}

            </Grid>


        </div>
    )
}
