import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export const TablaActividadesGer = () => {
    return (
        <div style={{ overflow: 'auto'}}>
            <TableContainer component={Paper} style={{ backgroundColor: '#C9C9C9', borderRadius: '15px' }} elevation={12}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow style={{ backgroundColor: '#EAC474' }}>
                            <TableCell><strong style={{ color: '#2E2E2E' }}>Datum</strong></TableCell>
                            <TableCell><strong style={{ color: '#2E2E2E' }}>Stunde</strong></TableCell>
                            <TableCell><strong style={{ color: '#2E2E2E' }}>Schlüssel</strong></TableCell>
                            <TableCell><strong style={{ color: '#2E2E2E' }}>Aktivität</strong></TableCell>
                            <TableCell><strong style={{ color: '#2E2E2E' }}>Ort</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="left">18.02.24</TableCell>
                            <TableCell align="left"> </TableCell>
                            <TableCell align="left"> </TableCell>
                            <TableCell align="left">Öffnung der Anmeldungen</TableCell>
                            <TableCell align="left">www.transbetxi.com</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">01.03.24</TableCell>
                            <TableCell align="left">22:00H</TableCell>
                            <TableCell align="left"> </TableCell>
                            <TableCell align="left">Vorbesprechung der Teilnehmer</TableCell>
                            <TableCell align="left">Auditori Municipal de Betxí</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">15.03.24</TableCell>
                            <TableCell align="left">19-21:00</TableCell>
                            <TableCell align="left"> </TableCell>
                            <TableCell align="left">Anmeldung und Bezahlung</TableCell>
                            <TableCell align="left">MONKEY</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">16.03.24</TableCell>
                            <TableCell align="left">19-21:00</TableCell>
                            <TableCell align="left"> </TableCell>
                            <TableCell align="left">Bezahlung und Abschluss der Anmeldungen</TableCell>
                            <TableCell align="left">AGORA</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">18.03.24</TableCell>
                            <TableCell align="left">22:00 H.</TableCell>
                            <TableCell align="left"> </TableCell>
                            <TableCell align="left">Veröffentlichung der Teilnehmerliste</TableCell>
                            <TableCell align="left">www.transbetxi.com</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">03.04.24</TableCell>
                            <TableCell align="left">22:00 H.</TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left">Erstes Briefing der Teilnehmer</TableCell>
                            <TableCell align="left">Auditori Municipal de Betxí</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">05.04.24</TableCell>
                            <TableCell align="left">18:00 H.</TableCell>
                            <TableCell align="left">PTP</TableCell>
                            <TableCell align="left">Technische und administrative Kontrolle, erstes Fahrzeug</TableCell>
                            <TableCell align="left">Avinguda Sant Josep Obrer</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">05.04.24</TableCell>
                            <TableCell align="left">20:00 H.</TableCell>
                            <TableCell align="left">PTP</TableCell>
                            <TableCell align="left">Zeitlimit für die Präsentation der teilnehmenden Fahrzeuge</TableCell>
                            <TableCell align="left">Avinguda Sant Josep Obrer</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">06.04.24</TableCell>
                            <TableCell align="left">08:30 H.</TableCell>
                            <TableCell align="left">PT1</TableCell>
                            <TableCell align="left">Zeitlimit für die Präsentation der Teilnehmer</TableCell>
                            <TableCell align="left">FRONTÓ MUNICIPAL</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">06.04.24</TableCell>
                            <TableCell align="left">09:00 H.</TableCell>
                            <TableCell align="left">PT1</TableCell>
                            <TableCell align="left">Überführung neutralisierter Fahrzeuge von PT1 nach TC1</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">06.04.24</TableCell>
                            <TableCell align="left">09:30 H.</TableCell>
                            <TableCell align="left">TC1</TableCell>
                            <TableCell align="left">Zeitabschnitt, erstes Fahrzeug</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">06.04.24</TableCell>
                            <TableCell align="left">10:30 H.</TableCell>
                            <TableCell align="left">TC2</TableCell>
                            <TableCell align="left">Zeitabschnitt, erstes Fahrzeug</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">06.04.24</TableCell>
                            <TableCell align="left">11:30 H.</TableCell>
                            <TableCell align="left">PT2</TableCell>
                            <TableCell align="left">Überführung neutralisierter Fahrzeuge von TC2 nach PT2</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">06.04.24</TableCell>
                            <TableCell align="left">12:00 H.</TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"><strong style={{ color: '#2E2E2E' }}>LETZTE 1. ETAPPE</strong></TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">06.04.24</TableCell>
                            <TableCell align="left">15:00 H.</TableCell>
                            <TableCell align="left">PT2</TableCell>
                            <TableCell align="left">Frist für die Präsentation der Teilnehmer, um neutralisiert von PT2 auf TC3 übertragen zu können</TableCell>
                            <TableCell align="left">FRONTÓ MUNICIPAL</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">06.04.24</TableCell>
                            <TableCell align="left">15:30 H.</TableCell>
                            <TableCell align="left">TC3</TableCell>
                            <TableCell align="left">Zeitabschnitt, erstes Fahrzeug</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">06.04.24</TableCell>
                            <TableCell align="left">16:30 H.</TableCell>
                            <TableCell align="left">TC4</TableCell>
                            <TableCell align="left">Zeitabschnitt, erstes Fahrzeug</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">06.04.24</TableCell>
                            <TableCell align="left">17:30 H.</TableCell>
                            <TableCell align="left">PT3</TableCell>
                            <TableCell align="left">Überführung neutralisierter Fahrzeuge von TC4 nach PT3</TableCell>
                            <TableCell align="left">FRONTÓ MUNICIPAL</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">06.04.24</TableCell>
                            <TableCell align="left">18:00 H.</TableCell>
                            <TableCell align="left">PT3</TableCell>
                            <TableCell align="left"><strong style={{ color: '#2E2E2E' }}>LETZTE 2. ETAPPE</strong></TableCell>
                            <TableCell align="left">FRONTÓ MUNICIPAL</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">07.04.24</TableCell>
                            <TableCell align="left">08:30 H.</TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left">Präsentationszeit der Teilnehmer</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">07.04.24</TableCell>
                            <TableCell align="left">09:00 H.</TableCell>
                            <TableCell align="left">PT4</TableCell>
                            <TableCell align="left">Zeitlimit für die Präsentation der Teilnehmer</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">07.04.24</TableCell>
                            <TableCell align="left">09:30 H.</TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left">Überführung neutralisierter Fahrzeuge von PT4 nach TC5</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">07.04.24</TableCell>
                            <TableCell align="left">10:30 H.</TableCell>
                            <TableCell align="left">TC5</TableCell>
                            <TableCell align="left">Zeitabschnitt, erstes Fahrzeug</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">07.04.24</TableCell>
                            <TableCell align="left">10:30 H.</TableCell>
                            <TableCell align="left">TC5</TableCell>
                            <TableCell align="left">Zeitabschnitt, erstes Fahrzeug</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">07.04.24</TableCell>
                            <TableCell align="left">11:30 H.</TableCell>
                            <TableCell align="left">TC6</TableCell>
                            <TableCell align="left">Zeitabschnitt, erstes Fahrzeug</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">07.04.24</TableCell>
                            <TableCell align="left">12:30 H.</TableCell>
                            <TableCell align="left">PT5</TableCell>
                            <TableCell align="left">Überführung neutralisierter Fahrzeuge von TC6 nach PT5</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">07.04.24</TableCell>
                            <TableCell align="left">13:00 H.</TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"><strong style={{ color: '#2E2E2E' }}>LETZTE 3. STUFE</strong></TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">07.04.24</TableCell>
                            <TableCell align="left">13:30 H.</TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left">Frist für die Einreichung von Reklamationen und technischen Prüfungen</TableCell>
                            <TableCell align="left">FRONTÓ MUNICIPAL</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">07.04.24</TableCell>
                            <TableCell align="left">18:00 H.</TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left">Veröffentlichung der Ergebnisse</TableCell>
                            <TableCell align="left">www.transbetxi.com</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">07.04.24</TableCell>
                            <TableCell align="left">18:30 H.</TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left">Übergabe der Trophäen</TableCell>
                            <TableCell align="left">FRONTÓ MUNICIPAL</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
