import React from 'react';
import { Grid, Button, Typography, Paper, Switch, FormControlLabel } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Swal from 'sweetalert2';
import { Header } from '../../Header';
import { useMaps } from '../../../MapContext';

export const Mapas = () => {
    const { mapas, toggleHabilitado, eliminarMapa, añadirMapa, editarMapa } = useMaps();

    const handleEliminarMapa = (codigotramo) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: `Vas a eliminar permanentemente el mapa ${codigotramo}!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, elimínalo!',
            cancelButtonText: 'No, cancelar!'
        }).then((result) => {
            if (result.isConfirmed) {
                eliminarMapa(codigotramo);
                Swal.fire('Eliminado!', 'El mapa ha sido eliminado.', 'success');
            }
        });
    };

    const handleNuevoMapa = () => {
        // Aquí podrías abrir un formulario modal para añadir un nuevo mapa
        añadirMapa({ nombre: "Nuevo Mapa", descripcion: "Descripción del mapa", habilitado: false });
    };

    const handleEditarMapa = (mapa) => {
        // editarMapa(mapa.codigotramo, { nombre: "Mapa Editado", descripcion: "Nueva descripción" });
    };

    return (
        <>
            <Header />
            <Typography variant="h4" style={{ margin: '20px', textAlign: 'center' }}>Gestión de Mapas</Typography>
            <Grid container spacing={2} justifyContent="center" style={{ marginBottom: 20 }}>
                <Button variant="contained" onClick={handleNuevoMapa}>Nuevo Mapa</Button>
            </Grid>
            <Grid container direction="column" alignItems="center">
                {mapas.map((mapa) => (
                    <Paper key={mapa.codigotramo} style={{ padding: '16px', margin: '10px', width: '80%' }}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Typography variant="h6">{mapa.nombre}</Typography>
                            <Typography color="textSecondary">{mapa.descripcion}</Typography>
                            <FormControlLabel
                                control={<Switch checked={mapa.habilitado} onChange={() => toggleHabilitado(mapa.codigotramo)} />}
                                label={mapa.habilitado ? "Habilitado" : "Deshabilitado"}
                            />
                            <Button onClick={() => handleEditarMapa(mapa)}>Editar</Button>
                            <DeleteForeverIcon onClick={() => handleEliminarMapa(mapa.codigotramo)} style={{ cursor: 'pointer' }} />
                        </Grid>
                    </Paper>
                ))}
            </Grid>
        </>
    );
};

export default Mapas;
