import React from 'react'
import { SelectorIdioma } from '../components/SelectorIdioma';
import { ReglamentoPruebaEs } from '../landing_page/reglamentoPrueba/ReglamentoPruebaEs'
import { ReglamentoPruebaVal } from '../landing_page/reglamentoPrueba/ReglamentoPruebaVal'
import { ReglamentoPruebaEn } from '../landing_page/reglamentoPrueba/ReglamentoPruebaEn'
import { ReglamentoPruebaGer } from '../landing_page/reglamentoPrueba/ReglamentoPruebaGer'
import { useMyContext } from '../MyProvider '; //Variable global del idioma

export const ReglamentoPrueba = () => {

  const { miVariableGlobal, setMiVariableGlobal } = useMyContext();

  return (
    <div className="fade-in">
      <SelectorIdioma idioma={miVariableGlobal} ></SelectorIdioma>
      
      {(miVariableGlobal == 1) && (
        <ReglamentoPruebaEs></ReglamentoPruebaEs>
      )}

      {(miVariableGlobal == 2) && (
        <ReglamentoPruebaVal></ReglamentoPruebaVal>
      )}

      {(miVariableGlobal == 3) && (
        <ReglamentoPruebaEn></ReglamentoPruebaEn>
      )}

      {(miVariableGlobal == 4) && (
        <ReglamentoPruebaGer></ReglamentoPruebaGer>
      )}
    </div>
  )
}
