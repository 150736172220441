import React, { useEffect, useState } from 'react'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';



export const Notificaciones = () => {

    const [colorNoti, setColorNoti] = useState("primary");
    const [notificaciones, setNotificaciones] = useState(false);
    const [contenidoNoti, setContenidoNoti] = useState([]);
    const [notificacionesVacio, setNotificacionesVacio] = useState(true);

    const getNotificaciones = () => {
        axios.get('https://www.transbetxi.com/WS/notificaciones.php?activa=' + 1 + '&edicion=' + 21)
            .then(function (response) {
                // manejar respuesta exitosa
                // console.log(response.data)
                setContenidoNoti(response.data);
                setNotificaciones(true);
                setColorNoti("secondary");
                setNotificacionesVacio(false);
                if (response.data.length == 0) {
                    setNotificaciones(false);
                    setColorNoti("primary");
                    setNotificacionesVacio(true);
                }
            })
            .catch(function (error) {
                // manejar error
                // console.log(error);

            })
            .finally(function () {
                // siempre sera executado
            });
    }

    const handleClickOpen = () => {
        setNotificaciones(true);
    };

    const handleClose = () => {
        setNotificaciones(false);
    };

    const theme = createTheme({
        palette: {
            primary: {
                light: '#C9C9C9',
                main: '#C9C9C9',
                dark: '#2E2E2E',
                contrastText: '#C9C9C9',
            },
            secondary: {
                light: '#e30505',
                main: '#e30505',
                dark: '#2E2E2E',
                contrastText: '#e30505',
            },
        },
    });


    useEffect(() => {
        getNotificaciones();
    }, [])

    return (
        <div className='fade-in'>
            <ThemeProvider theme={theme}>
                <div className='top-2 fade-in' style={{ padding: '1em' }}>
                    <InfoRoundedIcon fontSize="large" color={colorNoti} onClick={handleClickOpen}></InfoRoundedIcon>
                </div>
                <Dialog
                    open={notificaciones}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ backdropFilter: 'blur(2px)'}}
                >
                    <DialogContentText id="alert-dialog-description" className='flex-vertical' style={{ alignItems: 'flex-start', gap: '1em', padding: '0em 2em', paddingBottom: '1em', backgroundColor: '#C9C9C9'}}>
                        {contenidoNoti.map((contenido, index) => (
                            <div className='flex-vertical' style={{ alignItems: 'flex-start' }}>
                                <h3 style={{ color: '#2E2E2E', marginBottom: '0em' }}>Alerta de la organizacion</h3>
                                <samll>{contenido.fechaHora}</samll>
                                <p>{contenido.descripcion}</p>
                            </div>
                        ))}
                        {notificacionesVacio ? <h3 style={{ color: '#2E2E2E' }}>No hay notificaciones</h3> : ''}
                        <div className="flex-vertical" style={{ alignItems: 'flex-end', minWidth: '100%', textAlign: 'right', alignContent: 'flex-end' }}>
                            <Button onClick={handleClose} style={{ color: '#2E2E2E', backgroundColor: '#EAC474' }} variant="contained">
                                Aceptar
                            </Button>

                        </div>
                    </DialogContentText>
                </Dialog>
            </ThemeProvider>
        </div >
    )
}
