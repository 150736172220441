import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import EditIcon from '@mui/icons-material/Edit';
import Autocomplete from '@mui/material/Autocomplete';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { maxHeight } from '@mui/system';
import Swal from 'sweetalert2';


export const CrearEquipo = ({ edicion, setAnadirEquipo, setEquipos, equipos }) => {

    const [detallesEquipo, setDetallesEquipo] = useState({
        codigoequipo: null,
        nombre: null,
        dni_piloto: null,
        confirmado: null,
        dni_copiloto: null,
        edicion: edicion,
        dorsal: null,
        codigocarrera: 1,
        codigocategoria: null
    })

    const [loaded, setLoaded] = useState(true);
    const [editar, setEditar] = useState(false);
    const [componentes, setComponentes] = useState([]);
    const [opcionesComp, setOpcionesComp] = useState([]);
    const [piloto, setPiloto] = useState(null);
    const [copiloto, setCopiloto] = useState(null);
    const [camara, setCamara] = useState(false);
    const [imagenSeleccionada, setImagenSeleccionada] = useState(null);

    const fileInputRef = useRef(null);


    useEffect(() => {
        cargarComponentes();
    }, [])


    const handleOnChange = event => {
        const { name, value } = event.target;

        setDetallesEquipo(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const crearEquipo = () => {
        if (!piloto || !copiloto || !detallesEquipo.nombre) {
            Swal.fire({
                icon: "error",
                title: "Hay campos obligatorios sin rellenar",
                showConfirmButton: false,
                timer: 1500
            });
            return; // Si piloto o copiloto están vacíos, salir de la función
        }

        if (imagenSeleccionada) detallesEquipo.imagen = (imagenSeleccionada);

        let pilotoTemp;
        let copilotoTemp;

        if (piloto.id) {
            pilotoTemp = componentes.find((componente) => componente.dni == piloto.id);
        }
        if (copiloto.id) {
            copilotoTemp = componentes.find((componente) => componente.dni == copiloto.id);
        }

        detallesEquipo.dni_piloto = pilotoTemp ? pilotoTemp.dni : '';
        detallesEquipo.dni_copiloto = copilotoTemp ? copilotoTemp.dni : '';
        console.log(detallesEquipo)
        axios.post('https://www.transbetxi.com/WS/equipoinfo.php', detallesEquipo)
            .then(function (response) {
                console.log(response)
                if (response.data.success == true) {
                    detallesEquipo.codigoequipo = response.data.idCompra;
                    let temp = [...equipos];
                    temp.push(response.data.equipo);
                    Swal.fire({
                        icon: "success",
                        title: "Equipo creado correctamente",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    setEquipos(temp);
                    setAnadirEquipo(false);

                }
            })
            .catch(function (error) {
                console.error(error)
            });
    }

    //formatear lista de componentes para el select

    const formarListaComponentes = (componentes) => {
        let arrayFormateado = [];
        componentes.map((componente, index) => {
            let temp = {
                label: componente.dni + " " + componente.nombre + " " + componente.apellidos,
                id: componente.dni
            }
            arrayFormateado.push(temp);
        })
        setOpcionesComp(arrayFormateado);
    }

    const cargarComponentes = () => {
        axios.get('https://www.transbetxi.com/WS/componente.php', {
            headers: {
                'code': 'pass23sd2aASED6',

            }
        })
            .then(function (response) {
                if (response.status == 200) {
                    formarListaComponentes(response.data);
                    setComponentes(response.data);
                }
            })
            .catch(function (response) {
                console.error(response)
            })
    }

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            const image = new Image();
            image.src = reader.result;
            image.onload = () => {
                const canvas = document.createElement('canvas');
                const maxDimension = 400;
                let width = image.width;
                let height = image.height;

                if (width > height) {
                    if (width > maxDimension) {
                        height *= maxDimension / width;
                        width = maxDimension;
                    }
                } else {
                    if (height > maxDimension) {
                        width *= maxDimension / height;
                        height = maxDimension;
                    }
                }

                canvas.width = width;
                canvas.height = height;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(image, 0, 0, width, height);

                canvas.toBlob((blob) => {
                    if (blob.size > 500 * 1024) {
                        // Si el tamaño de la imagen comprimida es aún mayor que 500 KB, 
                        // puedes realizar más compresión o reducir la calidad aquí si es necesario
                        console.log("La imagen aún es demasiado grande después de la compresión.");
                    } else {
                        setImagenSeleccionada(canvas.toDataURL('image/jpeg'));
                    }
                }, 'image/jpeg', 0.7); // 0.7 es la calidad de compresión

                setCamara(true);
            };
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleLoopIconClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <div className='detalles-equipo'>
            {loaded === false && (
                <div className='flex-center'>
                    <div className='loading-spinner'></div>
                </div>
            )}
            {loaded === true && (
                <div className='overlay-crear-equipo' >
                    <div style={{ borderRadius: '15px', backgroundColor: '#C9C9C9', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                        <div className='detalles-componente-container'>
                            <div className='tramo-container'>
                                <div className='edit-equipo-element'>
                                    Nombre:
                                    <TextField
                                        onChange={handleOnChange}
                                        name='nombre'
                                        value={detallesEquipo.nombre}
                                        disabled={editar}
                                        id="outlined-basic"
                                        variant="outlined"
                                        className='field'
                                    />
                                </div>
                                <div className="edit-equipo-element">
                                    Piloto:&nbsp;
                                    <Autocomplete
                                        disablePortal
                                        name='dni_piloto'
                                        options={opcionesComp}
                                        className='field'
                                        sx={{ width: 300 }}
                                        value={piloto}
                                        onChange={(event, newValue) => {
                                            setPiloto(newValue)
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Piloto" />}
                                    />
                                </div>
                                <div className="edit-equipo-element">
                                    Copiloto: &nbsp;
                                    <Autocomplete
                                        disablePortal
                                        name='dni_copiloto'
                                        id="combo-box-demo"
                                        options={opcionesComp}
                                        className='field'
                                        sx={{ width: 300 }}
                                        value={copiloto}
                                        onChange={(event, newValue) => {
                                            setCopiloto(newValue)
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Copiloto" />}
                                    />
                                </div>
                                <div className='edit-equipo-element column'>
                                    Foto equipo:
                                    {camara === false && (
                                        <div className='button-aux' onClick={handleLoopIconClick}>
                                            <CameraAltIcon className='flex-center' />
                                        </div>
                                    )}
                                    {camara === true && (
                                        <>
                                            <div className='button-aux' onClick={() => { setImagenSeleccionada(null); setCamara(false); }}>
                                                <DeleteForeverIcon className='flex-center' />
                                            </div>
                                            <div className='flex-center' onClick={() => setCamara(true)}>
                                                <img src={imagenSeleccionada} alt="Imagen seleccionada" className='miniatura' />
                                            </div>
                                        </>
                                    )}
                                    <div className='edit-equipo-element'>
                                        <input
                                            ref={fileInputRef}
                                            type='file'
                                            style={{ display: 'none' }}
                                            accept='image/*'
                                            onChange={handleFileInputChange}
                                        />

                                    </div>

                                </div>
                                <div className='edit-equipo-element'>
                                    {(editar === true) && (
                                        <div className='button' onClick={() => setEditar(false)}>
                                            <EditIcon />    Editar
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='tramo-container'>
                                <div className='edit-equipo-element'>
                                    ID edicion:
                                    <TextField
                                        onChange={handleOnChange}
                                        name='edicion'
                                        value={detallesEquipo.edicion}
                                        disabled={true}
                                        id="outlined-basic"
                                        variant="outlined"
                                        className='field'
                                    />
                                </div>
                                <div className='edit-equipo-element'>
                                    Dorsal:
                                    <TextField
                                        onChange={handleOnChange}
                                        name='dorsal'
                                        value={detallesEquipo.dorsal}
                                        disabled={editar}
                                        id="outlined-basic"
                                        variant="outlined"
                                        className='field'
                                    />
                                </div>
                                <div className='edit-equipo-element'>
                                    Categoría:
                                    <Select
                                        value={detallesEquipo.codigocategoria}
                                        name="codigocategoria"
                                        onChange={handleOnChange}
                                        className='field'
                                        disabled={editar}
                                    >
                                        <MenuItem value={1}>PROTOTIPO</MenuItem>
                                        <MenuItem value={2}>CLASICA</MenuItem>
                                        <MenuItem value={3}>AGRIA</MenuItem>
                                    </Select>
                                </div>
                                {/* <div className='edit-equipo-element'>
                                    URL Ubicacion:
                                    <TextField
                                        onChange={handleOnChange}
                                        name='ubicacion'
                                        value={detallesEquipo.ubicacion}
                                        disabled={editar}
                                        id="outlined-basic"
                                        variant="outlined"
                                        className='field'
                                    />
                                </div> */}
                            </div>
                            <div className='buttons-container'>
                                {!editar && (
                                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignContent: 'center', alignItems: 'center', justifyContent: 'center', gap: '1em', marginBottom: '1em' }}>
                                        <div className="button-adm" onClick={() => crearEquipo()}>
                                            Añadir
                                        </div>
                                        <div className="button-adm" onClick={() => setAnadirEquipo(false)}>
                                            Cancelar
                                        </div>
                                    </div>
                                )}
                                {editar && (
                                    <div className='button-component-dissabled'>
                                        Guardar Componente
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>

    );
};