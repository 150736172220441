import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const MapContext = createContext();

export const useMaps = () => useContext(MapContext);

export const MapProvider = ({ children }) => {
    const [mapas, setMapas] = useState(() => {
        const localData = localStorage.getItem('mapas');
        return localData ? JSON.parse(localData) : [];
    });

    useEffect(() => {
        if (!mapas.length) {
            cargarMapas();
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('mapas', JSON.stringify(mapas));
    }, [mapas]);

    const cargarMapas = () => {
        axios.get('https://www.transbetxi.com/WS/tramos.php?edicion=21')
            .then(response => {
                if (response.data.length > 0) {
                    const mapasIniciales = response.data.map(mapa => ({
                        ...mapa,
                        habilitado: false
                    }));
                    setMapas(mapasIniciales);
                }
            })
            .catch(error => {
                console.error('Error al cargar los mapas:', error);
            });
    };

    const toggleHabilitado = codigotramo => {
        setMapas(currentMapas => {
            return currentMapas.map(mapa => mapa.codigotramo === codigotramo ? { ...mapa, habilitado: !mapa.habilitado } : { ...mapa, habilitado: false });
        });
    };

    const añadirMapa = mapa => {
        setMapas([...mapas, { ...mapa, codigotramo: Date.now(), habilitado: false }]);
    };

    const editarMapa = (codigotramo, updates) => {
        setMapas(currentMapas => {
            return currentMapas.map(mapa => mapa.codigotramo === codigotramo ? { ...mapa, ...updates } : mapa);
        });
    };

    const eliminarMapa = codigotramo => {
        setMapas(currentMapas => currentMapas.filter(mapa => mapa.codigotramo !== codigotramo));
    };

    return (
        <MapContext.Provider value={{ mapas, toggleHabilitado, añadirMapa, editarMapa, eliminarMapa }}>
            {children}
        </MapContext.Provider>
    );
};

export default MapProvider;
