import React, { useEffect } from 'react';
import { Header } from '../../components/Header';
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';
import { TablaActividadesGer } from '../tablaActividades/TablaActividadesGer';

export const ReglamentoPruebaGer = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div className="fade-in" >
            <Header></Header>
            <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', textAlign: 'center' }}>
                <h1>PRÜFORDNUNG</h1>
            </Grid>

            <Grid container xs={12} md={12} direction="column" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', overflow: 'auto' }}>
                <Grid container xs={10} md={8} direction="column" justifyContent="center" alignItems="center">
                    <div style={{ maxWidth: '100%', color: '#EAC474', textAlign: 'left' }}>
                        <h3>PROLOG – DEFINITION DES KONZEPTS DER ANTI-SPORT-HALTUNG.</h3>
                        <p>Liebe Teilnehmer, mit dieser Einführung möchten wir Sie darauf aufmerksam machen, dass Sie bei der Teilnahme an der TRANSBETXÍ-Rallye neben den Rechten und Dienstleistungen, die Sie unterstützen, auch eine Reihe von Regeln haben, die Sie einhalten müssen. </p>
                        <p>Diese Regeln stellen einen Garant für die ordnungsgemäße Durchführung des Rennens dar und bieten Ihnen Unterstützung und die gleiche Behandlung aller teilnehmenden Teams.</p>
                        <p>Die Organisation ist aufgrund der ihr durch den Inhalt der Artikel 11, 22 und 23 dieses Reglements zuerkannten Befugnisse gegen jede Handlung oder Haltung geschützt, die die Sicherheit, Integrität und Unterstützung anderer Teilnehmer, Organisatoren und der Öffentlichkeit im Allgemeinen gefährdet , durch ein Team oder eine damit verbundene Person, und für diesen „Grundsatz des unsportlichen Verhaltens“ möchten wir den Vorbehalt des Rechts veröffentlichen, alle von ihm als angemessen erachteten Maßnahmen zu ergreifen, um diese Handlungen und Verhaltensweisen zu korrigieren oder zu beseitigen erwähnt.</p>
                        <p>Zum Wohle aller und als Dank für Ihre Zusammenarbeit erhalten Sie unsere Grüße.</p>

                        <h3>01.- FAHRPLAN PROGRAMM OFFIZIELLER ZEITPLAN DER AKTIVITÄTEN</h3>
                        <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
                            <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E' }}>
                                <TablaActividadesGer></TablaActividadesGer>
                            </Grid>
                        </Grid>

                        <h3>02.- ORGANISATION, ALLGEMEINE BESTIMMUNGEN</h3>

                        <h4>Art.02-01 – ALLGEMEINES</h4>
                        <p>Der Veranstalter bereitet eine Motorradsportveranstaltung vor, die am 5., 6. und 7. April 2024 stattfinden wird und den Namen XXXIV TRANSBETXÍ RALLY 2024 trägt.</p>

                        <h4>Art.02-02 – ALLGEMEINE ANWENDBARE VORSCHRIFTEN</h4>
                        <p>Dieser Test wird gemäß der zweiten Verordnung organisiert</p>
                        <ul>
                            <li>Sportliche Bestimmungen des FMCV</li>
                            <li>Allgemeine Bestimmungen für Enduro FMCV oder RALLI TT</li>
                            <li>Diese Sonderregelung.</li>
                        </ul>

                        <h4>Art.02- 03.- SITZ DES ORGANISATIONSKOMITEES</h4>
                        <p>Organisationseinheit:</p>
                        <ul>
                            <li>Der berühmte BETXÍ-RAT.</li>
                            <li>Das Sekretariat des Organisationskomitees befindet sich im Rathaus von Betxí. Tel. 964 620 002.</li>
                        </ul>

                        <h4>Kunst. 02-04.- OFFIZIELLE GEBÜHREN</h4>
                        <p>Richter/Schiedsrichter: Fco. Javier Ferrandis Bordería / Lic. 500547-RF</p>
                        <p>Technischer Kommissar: Josep Manel Adsuara Vicent / Lic. In Bearbeitung</p>
                        <p>Rennleiter: David Brisach Miravet / Lic. In Bearbeitung</p>
                        <p>Zeitnehmer: Agustín Ferreres Beltrán / Lic. 622497-CS</p>

                        <h4>Kunst. 02-05.- OFFIZIERE</h4>
                        <p>Der Veranstalter setzt beim Test zwei Arten von Offiziellen ein.</p>

                        <h4>Kunst. 02-05-01 SPORTOFFIZIELLE</h4>
                        <p>Das sind die Menschen, die die Macht haben, Entscheidungen über den Verlauf des Rennens zu treffen.
                            Diese Beamten werden sich in irgendeiner Weise von den anderen unterscheiden, um den Teilnehmern die Lösung etwaiger Probleme oder Zweifel zu erleichtern.
                        </p>

                        <h4>Kunst. 02-05-02 ORGANISATIONSOFFIZIERE</h4>
                        <p>Dies sind die Personen, die für alle zusätzlichen sportlichen Aufgaben verantwortlich sind und zur Durchführung des Tests erforderlich sind. (Flaggen, Grenzkontrollen, Hilfspersonal usw.)
                            Kein Funktionär der Organisation hat die Befugnis, über sportliche Fragen zu entscheiden.
                            Ja, Sie können Ihre internen Kommunikationsmittel nutzen, damit ein Sportfunktionär einen Teilnehmer über alle Fragen oder Zweifel informieren kann, die für die Prüfung erforderlich sind.
                        </p>

                        <h4>Kunst. 02-06.- OFFIZIELLES MITTEILUNGSBRETT – ÄNDERUNGEN</h4>
                        <p>Alle Änderungen oder zusätzlichen Bestimmungen werden den Teilnehmern so schnell wie möglich durch datierte und nummerierte Ergänzungen bekannt gegeben, die Teil dieser Sonderbestimmungen sind.
                            Diese Ergänzungen sowie andere Beschlüsse, Vorladungen, Strafen und andere Dokumente werden an der offiziellen Anschlagtafel im für den Test ausgestatteten Presseraum ausgehängt.
                        </p>
                        <p>Alle Änderungen enthalten den Zeitpunkt der Veröffentlichung. </p>

                        <h3>03.- ROUTE</h3>
                        <h4>Kunst. 03-01.- ROUTE</h4>
                        <p>Die XXXIV. TRANSBETXÍ RALLY 2024 besteht aus einer gemeinsamen Route für alle Teilnehmer mit acht (6) zeitgesteuerten Abschnitten, die wie folgt verteilt sind:</p>
                        <table>
                            <thead>
                                <tr>
                                    <th>SAMSTAG, 15. APRIL</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>09:30 H.</td>
                                    <td>TC1</td>
                                    <td>ZEITBEDINGTER ABSCHNITT</td>
                                </tr>
                                <tr>
                                    <td>10:30 H.</td>
                                    <td>TC2</td>
                                    <td>ZEITBEDINGTER ABSCHNITT</td>
                                </tr>
                                <tr>
                                    <td>15:30 H.</td>
                                    <td>TC3</td>
                                    <td>ZEITBEDINGTER ABSCHNITT</td>
                                </tr>
                                <tr>
                                    <td>16:30 H.</td>
                                    <td>TC4</td>
                                    <td>ZEITBEDINGTER ABSCHNITT</td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <table>
                            <thead>
                                <tr>
                                    <th>SONNTAG, 16. APRIL</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>09:30 H.</td>
                                    <td>TC5</td>
                                    <td>CHRONOMETER-SPUR CHRONOMETER-SPUR</td>
                                </tr>
                                <tr>
                                    <td>10:30 H.</td>
                                    <td>TC6</td>
                                    <td>ZEITBEDINGTER ABSCHNITT</td>
                                </tr>
                            </tbody>
                        </table>

                        <p>Die Klassifizierung der Ausgabe 2023 nach Kategorien wird zur Festlegung der Startreihenfolge für die Etappe am Samstag, 6. April 2024, herangezogen.
                            Es ist strengstens verboten, von Teilnehmern oder anderen ihnen nahestehenden Personen Markierungen auf Verkehrsschildern, Treffern, Gehwegen, Gebäuden oder Straßenrändern anzubringen.
                        </p>
                        <p>Andererseits ist auch das Training der Strecke, das am Rande des Tests von den Teams ab Sonntag, 31. März 2024, durchgeführt wird, VERBOTEN. Hiermit werden das gleiche Beschwerdeverfahren und die gleichen Maßnahmen von Amts wegen wie in Artikel 7 dieser Verordnung festgelegt, was zusätzlich zu jedem von der örtlichen Polizei oder einer anderen Regierungsbehörde gemeldeten Verstoß die Nichteinhaltung dieses Artikels impliziert und als SEHR gilt SCHWERE VERLETZUNG, die in der Disziplinarregelung dieser Verordnung vorgesehen ist.</p>

                        <h3>04.- ANMELDUNG DER TEILNEHMER</h3>
                        <h4>Kunst. 04-01.- ZUGELASSENE FAHRZEUGE</h4>
                        <p>Motorgrubber sind ausnahmslos zur Teilnahme an dieser Prüfung zugelassen, es ist jedoch zu berücksichtigen, dass sie den folgenden Einschränkungen und/oder technischen Merkmalen unterliegen müssen</p>
                        <ul>
                            <li>Seien Sie Serien- oder traditionelle Kultivatoren, weiterentwickelt oder nicht, die den Originalmotor (ohne externe Änderungen oder Flüssigkeitskühlung), die Kupplung und das Getriebe ihrer Marke beibehalten.</li>
                            <li>Andere Traktionsformeln werden nicht akzeptiert (außer auf Einladung der Organisation) und im Allgemeinen ist es erforderlich, dass alle teilnehmenden Fahrzeuge die übrigen Eigenschaften des traditionellen Fahrzeugs beibehalten, wie z. B. die Lenkung und das Motorsystem -Wagenverbindung usw.</li>
                        </ul>

                        <h4>Kunst. 04-02.- SICHERHEITSMASSNAHMEN</h4>
                        <p>Jedes teilnehmende Team muss ein Mobiltelefon mit WhatsApp dabei haben, um geortet zu werden, Benachrichtigungen zu erhalten und Unfälle und andere Vorfälle melden zu können.
                            Alle Teilnehmer müssen mit folgender Schutzausrüstung ausgestattet sein:
                        </p>
                        <ol>
                            <li>Zugelassener Enduro-, Cross- oder Integralhelm (das sind Helme, die eine bestimmte Nummer tragen (beginnend mit 05, P oder J); der Helm muss korrekt platziert und eingestellt sein, wobei der Riemen gut durchgezogen ist). Die Nummerierung (Beschriftung des Helmes) muss deutlich sichtbar sein.
                                Ein Dokument mit den Bestimmungen wird allen Mitgliedern zugesandt, um zu prüfen, ob diese genehmigt sind.
                                <ul>A. Alle werden überprüft und es werden keine akzeptiert, die in schlechtem Zustand sind, nicht den Vorschriften entsprechen oder deren Etikett in schlechtem Zustand oder unleserlich ist. Sicherheitshalsband, starres oder orthopädisches Motocross-Halsband sind obligatorisch.</ul>
                            </li>
                            <li>Brust-, Schulter- und Schulterschutz mit Ellenbogenpolstern.</li>
                            <li>Der integrierte Brustpanzer oder Kürass mit integrierten Ellbogen- und Schulterpolstern wird obligatorisch sein. </li>
                            <li>Es wird empfohlen, sich vor dem Kauf oder der Aktualisierung ihrer Schutzvorrichtungen über die erforderlichen Eigenschaften zu informieren und zu prüfen, ob sie mit den starren Halsbändern kompatibel sind.</li>
                            <li>Schutzhandschuhe (mindestens erforderlich: berufliche PSA für mechanische Risiken NP3). </li>
                            <li>Knieschoner</li>
                            <li>Enduro- und Crossstiefel (erforderliches Minimum: Halbstock).</li>
                            <li>Ein ordnungsgemäß am Fahrzeug befestigter 1-kg-Feuerlöscher „ABC-Chemiepulver“ ist obligatorisch.</li>
                            <li><strong>Das „Mann-über-Bord“-System ist ZWINGEND, um im Falle eines Unfalls den Motor abzustellen</strong></li>
                            <li>Während der gesamten Strecke der XXXIV TRANSBETXÍ-Rallye 2024 müssen die Deichseln mit dem Überrollbügel geschützt werden. </li>
                            <li>Sie müssen alle Hebel und Spitzen mit Kugeln oder Schutzelementen schützen. </li>
                            <li>Platten oder Bleche mit scharfen Ecken müssen mit Gummi- oder Kunststoffprofilen abgedeckt werden.</li>
                            <li><strong>13. Wenn Sie für den Betrieb der Maschine eine Batterie mitführen, muss der Pluspol abgedeckt werden, um zu vermeiden, dass er mit den Metallteilen des Motors in Berührung kommt und einen Funken verursachen kann, der einen Brand verursacht.</strong></ li>
                        </ol>

                        <h4>Kunst. 04-03.- ANMELDUNGEN</h4>
                        <p>Die Anmeldung zur XXXIV TRANSBETXÍ RALLY 2024 ist jedem gestattet, der über 18 Jahre alt ist, Inhaber einer Sportlizenz ist und den Anmeldeantrag für den Test ausgefüllt und ordnungsgemäß auf dem Formular unterschrieben hat, das die Organisation und die Anmeldung erleichtert Rechte wurden erfüllt.
                            Die Anmeldung beginnt am 18. Februar und endet am 16. März 2024 um 21:00 Uhr und kann täglich auf der Seite <strong>www.transbetxi.com</strong> erfolgen.
                        </p>
                        <p>Das korrekte Registrierungsverfahren besteht aus den folgenden Teilen und muss in derselben Reihenfolge befolgt werden:</p>
                        <ol>
                            <li>Registrierung durch das Team des Piloten, Copiloten und der Maschine auf dem Formular, das auf der Seite www.transbetxi.com verfügbar ist, um dies tun zu können.</li>
                            <li>Registrierungsprüfung für die offizielle Liste der Registranten.</li>
                            <p>Dies erfolgt innerhalb der Anmeldefrist durch Senden der folgenden ordnungsgemäß unterschriebenen und gescannten Unterlagen an transbetxi89@gmail.com:</p>
                            <strong>PILOT:</strong>
                            <ol>
                                <li>GEDRUCKTE KOPIE DER AUF DER WEBSITE VORGENOMMENEN REGISTRIERUNG.</li>
                                <li>1 FOTOKOPIE UND ORIGINAL DER BUNDESLIZENZ (Vorder- und Rückseite).</li>
                                <li>1 FOTOKOPIE UND ORIGINAL DES NATIONALEN PERSONALDOKUMENTS (Vorder- und Rückseite).</li>
                                <li>1 Fotokopie und Original des Führerscheins: B1 (Vorder- und Rückseite).</li>
                            </ol>
                            <strong>PILOT:</strong>
                            <ol>
                                <li>GEDRUCKTE KOPIE DER AUF DER WEBSITE VORGENOMMENEN REGISTRIERUNG.</li>
                                <li>1 FOTOKOPIE UND ORIGINAL DER BUNDESLIZENZ (Vorder- und Rückseite).</li>
                                <li>1 FOTOKOPIE UND ORIGINAL DES NATIONALEN PERSONALDOKUMENTS (Vorder- und Rückseite).</li>
                                <li>1 Fotokopie und Original des Führerscheins: B1 (Vorder- und Rückseite) oder eine für das aktuelle Jahr geltende medizinische Anerkennung.</li>
                            </ol>
                        </ol>
                        <p>Die Organisation stellt korrekt registrierten Teilnehmern die folgenden Dokumente zur Verfügung:</p>
                        <ol>
                            <li>TEAM-REGISTRIERUNGSBLATT. </li>
                            <li>KOPIE DER VORSCHRIFTEN.</li>
                        </ol>
                        <p>Der in Artikel 10 vorgesehene Betrag muss zum Zeitpunkt der offiziellen Registrierung gezahlt werden.
                            Sollten sich Änderungen an der Anmeldung ergeben, muss das Team dies bis zum 24. März 2024 mitteilen und alle von der Organisation als angemessen erachteten Daten beifügen.
                        </p>
                        <ul>
                            <li>Tf. Kontaktnummer 696598253. Ref.: Juanma Moreno.</li>
                            <li>Tf. Kontaktnummer 666177845. Ref.: En Xavi Cabedo.</li>
                        </ul>
                        <p>Doppelanmeldungen von Fahrzeugen und Teilnehmern sind untersagt.</p>

                        <h4>Kunst. 04-05.- REGISTRIERUNGSRECHTE</h4>
                        <p>Die Zulassungsgebühr beträgt <strong>420,-Euro</strong> pro Fahrzeug (Gebühren für Bundeslizenzen sind inbegriffen).
                            Die Anmeldung kann nicht angenommen werden, wenn ihr nicht innerhalb der in dieser Ordnung festgelegten Frist die Anmelderechte beiliegen.
                        </p>

                        <h4>Kunst. 04-06.- VERSICHERUNG</h4>
                        <p>Die Organisation behält sich das Recht vor, die jeweilige Verordnung zu ergänzen und/oder zusätzliche Bestimmungen oder Anweisungen zu erlassen, die ein integraler Bestandteil davon sein werden. Es bleibt außerdem das Recht vorbehalten, den Test im Falle höherer Gewalt oder eines unvorhersehbaren Ereignisses abzusagen oder abzubrechen, ohne dass eine Schadensersatzpflicht besteht.
                            Alle Änderungen und zusätzlichen Bestimmungen werden den Teilnehmern so bald wie möglich durch datierte und nummerierte Anhänge und durch das als geeignet erachtete Medium bekannt gegeben.
                        </p>
                        <p>Zumindest alle Änderungen und zusätzlichen Bestimmungen werden auf dem offiziellen Schwarzen Brett veröffentlicht.</p>
                        <strong>(Siehe: Art. 02-05.- OFFIZIELLES MITTEILUNGSBRETT – ÄNDERUNGEN)</strong>
                        <p>Die Organisation behält sich das Recht vor, die Registrierung eines Teams abzulehnen; aufgrund von Form- und Fristmängeln, fehlender bereitgestellter Dokumentation, die angeblich von diesem Reglement abgedeckt werden soll, und anderen relevanten Problemen, die die Integrität, das öffentliche Image und die Sicherheit des Rennens gefährden können.
                            Die Jury ist berechtigt, in allen Fällen zu entscheiden, die nicht in den Sonderbestimmungen der Prüfung vorgesehen sind
                        </p>

                        <h3>05.- PFLICHTEN DER TEILNEHMER</h3>

                        <h4>Kunst. 05-01.- TEILNAHME AM BRIEFING</h4>
                        <p>Teilnahme am ersten Briefing am Mittwoch, 3. April 2024 um 22:00 Uhr. Der Besuch im Betxí Municipal Auditorium ist für alle Teams obligatorisch.
                            Mit Ausnahme ordnungsgemäß begründeter und der Organisation mitgeteilter Fälle muss mindestens ein Mitglied des Teams (Pilot, Co-Pilot oder akkreditierter Teamleiter) anwesend sein, alle vorbereiteten Unterlagen einsammeln und sich die Beschreibung und Warnungen des Teams anhören Auf der Rennstrecke werden auch die Akkreditierungen vergeben.
                        </p>

                        <h4>Kunst. 05-02.- ZAHLEN</h4>
                        <p>Für jedes teilnehmende Motorrad stellt die Organisation einen doppelten Nummernsatz zur Verfügung, der während des gesamten Tests gut sichtbar an den Seiten des Fahrzeugs angebracht wird.
                            Motorgrubber, denen die Nummern fehlen, die nicht korrekt sind oder nicht richtig platziert sind, erhalten keine Berechtigung zur Ausfahrt.
                            Die Zahlen müssen jederzeit die ursprüngliche Form, Größe und Zusammensetzung haben.
                            Es dürfen keine Aufkleber oder Werbung darauf ausgeschnitten oder angebracht werden
                            Die Vergabe von Nummern für jedes Fahrzeug liegt in der Verantwortung der Organisation.
                        </p>

                        <h4>Kunst. 05-03.- WERBUNG</h4>
                        <p>Fahrzeuge können nach Wahl ihrer Fahrer beworben werden.
                            Es ist jedoch Pflicht, die von der Organisation bereitgestellten Nummern zu tragen.
                            Somit werden zwei Räume von 20 x 20 cm reserviert (einer auf jeder Seite), die eine perfekte Sicht auf die Seiten des Fahrzeugs ermöglichen.
                            Die Platzierung muss korrekt sein, d. h. auf einer ebenen, niemals gekrümmten Fläche, mit einer glatten Metallunterlage, vollständig an der Seite befestigt und ohne hervorstehende Ecken.
                            Die Nichteinhaltung dieses Artikels durch ein teilnehmendes Team gilt als SCHWERER FEHLER und ist in der Disziplinarregelung dieser Verordnung vorgesehen.
                        </p>

                        <h4>Kunst. 05-04.- PRÄSENTATION AM AUSGANG </h4>
                        <p>Teilnehmer und ihre Fahrzeuge müssen sich im geschlossenen Park vor dem Rennen einfinden und der Organisation zu dem im offiziellen Zeitplan festgelegten Zeitpunkt zur Verfügung stehen.
                            Die Teilnehmer tragen die Folgen der Unkenntnis der Bestimmungen oder Zeitplanänderungen, die in der Stunde vor Testbeginn beschlossen werden können.
                            Die Teilnehmer werden mindestens 10 Minuten vor der ihnen zugewiesenen Ausstiegszeit in der Ausgangsschlange platziert.
                            Der Motorkultivator, der nicht rechtzeitig erscheint, verliert das Recht auf die Ausgangsverfügung in der Etappe.
                            Aus Sicherheitsgründen wird dieses Recht bei der Bildung der Ausgangsreihe widerrufen, und selbst die fehlbare Mannschaft könnte vom Wettbewerb ausgeschlossen werden, wenn die Organisation dies so erwägt.
                            Die Nichteinhaltung dieses Artikels durch ein teilnehmendes Team gilt als KLEINER FEHLER und ist in der Disziplinarregelung dieser Verordnung vorgesehen.
                        </p>

                        <h4>Kunst. 05-06.- BESCHILDERUNG. VERHALTEN IM RENNEN.</h4>
                        <p>Von der Präsentation im Pre-Exit Park bis zum Ende des Tests müssen sich die Teilnehmer strikt an die Anweisungen der Organisation halten.
                            Es ist absolut verboten, entgegen der Rennrichtung zu fahren.
                            Jeder Verstoß gegen diese Regel führt zum sofortigen Ausschluss von der Prüfung. Die Nichteinhaltung dieses Artikels durch ein Team (Teilnehmer oder Assistenten) gilt als SEHR SCHWERES VERSTÖSSEN und ist in der Disziplinarregelung dieser Verordnung vorgesehen.
                        </p>
                        <p>Für den Fall, dass ein Pilot seine Route aufgrund mechanischer oder anderer Probleme in einem Zeitabschnitt oder anderswo unterbrechen muss, parkt er das Fahrzeug sofort außerhalb der Straße oder Strecke, damit er kein Problem darstellt für die übrigen teilnehmenden Fahrzeuge und führt die entsprechenden Kontrollen und Reparaturen durch.
                        </p>
                        <p>Falls es nicht möglich ist, den Motor auf dem Abschnitt oder an der Stelle, an der er sich befindet, zu reparieren: </p>
                        <ol>
                            <li>Informieren Sie den Sicherheitsdelegierten David Brisach über den Zustand, in dem sie sich befinden, und erhalten Sie Anweisungen, was zu tun ist. </li>
                            <li>Sie dürfen das Motorrad und den Ort, an dem sie sich befinden, zu keinem Zeitpunkt verlassen, es sei denn, sie wurden vom Sicherheitsbeauftragten dazu autorisiert. </li>
                        </ol>
                        <strong><small>Jedes der Fahrzeuge, die in einem Zeitabschnitt von einem schnellen Monat zurückgeholt werden, muss langsamer werden. Und hinterlassen Sie so schnell wie möglich einen freien Weg, immer ohne eine gefährliche Situation für irgendjemanden zu schaffen und zu keinem Zeitpunkt während der Route können sie sich teilen und so ihre ursprüngliche Position wiedererlangen.</small></strong>
                        <p>Ein Verstoß gegen diesen Artikel durch ein teilnehmendes Team gilt als KLEINER VERTRAG und ist in der Disziplinarregelung dieser Regelung vorgesehen.
                            Das Ziehen und/oder Schieben eines teilnehmenden Fahrzeugs durch ein anderes Fahrzeug, unabhängig von seiner Beschaffenheit und dem Abschleppsystem, ist nicht gestattet.
                            Die Nichteinhaltung dieses Artikels durch ein teilnehmendes Team gilt als SCHWERER FEHLER und ist in der Disziplinarregelung dieser Verordnung vorgesehen.
                        </p>

                        <h3>06.- TECHNISCHE UND ADMINISTRATIVE KONTROLLE</h3>
                        <h4>Kunst. 06-01.- TECHNISCHE UND ADMINISTRATIVE KONTROLLE</h4>
                        <p>Die technische und administrative Kontrolle findet am Freitag, den 5. April 2024, von 18:00 bis 20:00 Uhr im geschlossenen PTP-Park statt, mit dem schrittweisen Erscheinen der Fahrzeuge, Fahrer oder Beifahrer, je nach Bedarf den Zeitplan, den die Organisation in Form einer ergänzenden Bestimmung in dieser Verordnung veröffentlicht.
                            Die Nichteinhaltung dieses Artikels durch ein teilnehmendes Team gilt als KLEINER FEHLER und ist in der Disziplinarregelung dieser Verordnung vorgesehen.
                            Diese Kontrolle wird am Fahrzeug und an den Helmen durchgeführt; Alle werden mit offiziellen Schildern validiert, die während des gesamten Rennens intakt bleiben müssen. Die Nichteinhaltung dieses Artikels durch ein teilnehmendes Team gilt als SCHWERER FEHLER und ist in der Disziplinarregelung dieser Verordnung vorgesehen.
                            Bei dieser Kontrolle wird allen teilnehmenden Teams das offizielle Renn-Roadbook (Roadbook) ausgehändigt. Wo sie über ein Blatt verfügen, um einen Anspruch geltend machen zu können; wenn sie es für angemessen halten, befolgen sie das in den Vorschriften festgelegte Verfahren.
                        </p>

                        <h3>TECHNISCHE UND ADMINISTRATIVE KONTROLLE / ERSCHEINUNGSVERSCHIEBUNG</h3>
                        <big>
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ paddingRight: '10em' }}>ZEIT</th>
                                        <th>AUSRÜSTUNG-ZURÜCK</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>18:00</td>
                                        <td>53 52 51 50 49 48</td>
                                    </tr>

                                    <tr>
                                        <td>18:15</td>
                                        <td>47	46	45	44	43	42</td>
                                    </tr>

                                    <tr>
                                        <td>18:30</td>
                                        <td>41	40	39	38	37	36</td>
                                    </tr>

                                    <tr>
                                        <td>18:45</td>
                                        <td>35	34	33	32	31	30</td>
                                    </tr>

                                    <tr>
                                        <td>19:00</td>
                                        <td>29	28	27	26	25	24</td>
                                    </tr>

                                    <tr>
                                        <td>19:15</td>
                                        <td>23	22	21	20	19	18</td>
                                    </tr>

                                    <tr>
                                        <td>19:30</td>
                                        <td>17	16	15	14	13	12</td>
                                    </tr>

                                    <tr>
                                        <td>19:45</td>
                                        <td>11	10	9	8	7	6</td>
                                    </tr>
                                    <tr>
                                        <td>20:00</td>
                                        <td>5	4	3	2	1	</td>
                                    </tr>
                                </tbody>
                            </table>
                        </big>

                        <h3>07.- TESTENTWICKLUNG</h3>
                        <h4>Kunst. 07-01.- ABFAHRT, ANKUNFT, ZEITPLAN</h4>
                        <p>Der Ausstieg erfolgt einzeln mit Pilot und Co-Pilot in Position (in Kontakt mit der Pinne), bei stehendem Fahrzeug und laufendem Motor.
                            Die Ausgangsreihenfolge wird in aufsteigender Reihenfolge der Nummerierung angegeben (entsprechend ihrer Klassifizierung nach Kategorien im XXXIII TRANSBETXÍ 2023).
                            Wenn Sie die vorherige Rallye noch nicht beendet haben oder neu dabei sind, wird die nächste Nummer dem Gewinner der letzten Wertung der vorherigen Rallye zugewiesen. So wird der erste Serienteilnehmer losfahren, Minute für Minute folgen die restlichen Fahrzeuge.
                        </p>
                        <p>Für die Etappe am Samstag, den 6. April 2024, startet die erste Wertung um 09:30 Uhr, gefolgt von den restlichen Wertungen Minute für Minute.
                            Die Teilnehmer sind verpflichtet, der im Rennbuch angegebenen Route zu folgen, ohne an einer Kontrolle vorbeizukommen.
                            Die von der Organisation vorgegebenen Ruhezeiten sind einzuhalten.
                            Die Organisation lehnt jegliche Verantwortung für den Fall eines Fehlers bei der Befolgung der geplanten Route ab.
                            Die Zeitmessung erfolgt im Sekundentakt, mit Timern, die ein Drucksystem aktivieren.
                            Ein Anspruch hiergegen besteht nicht.
                            Die den Teams zur Verfügung gestellten Stundennachweise dienen ausschließlich Informationszwecken und unterliegen der Verantwortung der Teilnehmer.
                            Ein Fahrzeug, dem die Ausfahrt gewährt wurde und diese nicht sofort in Anspruch nimmt, hat unter keinen Umständen das Recht auf eine zweite Ausfahrt und wird ab der ersten Ausfahrt gezählt.
                            Die Weigerung oder eine Verzögerung von mehr als 15 Sekunden bei der Ausfahrt in einer Zeitkontrolle oder einem gezeiteten Abschnitt führt zum Ausschluss aus dem Abschnitt.
                            Den Weisungen der Organisation ist strikt Folge zu leisten.
                            Die Nichteinhaltung dieses Artikels durch ein teilnehmendes Team gilt als KLEINER FEHLER und ist in der Disziplinarregelung dieser Verordnung vorgesehen.
                            Die Ankunft in den gezeiteten Abschnitten wird beim Vorbeifahren des Fahrzeugs mit dem Piloten und dem Copiloten aufgezeichnet.
                        </p>
                        <strong>Der gezeitete Abschnitt endet mit dem Überqueren der Ziellinie und die Geschwindigkeit muss sofort reduziert werden, außerdem darf die Bremszone nicht behindert werden. </strong>
                        <p>Ein Verstoß gegen diesen Artikel durch ein teilnehmendes Team gilt als SCHWERER FEHLER und ist in der Disziplinarregelung dieser Regelung vorgesehen.
                            Im Falle einer erzwungenen Unterbrechung des Rennens durch ein Mitglied der Organisation erhalten die von dieser Maßnahme betroffenen teilnehmenden Teams die Zeit des besten Teilnehmers, der die Ziellinie erreicht hat (unter Einhaltung des Internationalen Sportgesetzes und des Konzepts der unsportlichen Haltung). .
                            Jeder gezeitete Abschnitt endet, nachdem so viele Minuten verstrichen sind, wie Teams für das Rennen angemeldet sind, plus fünf zusätzliche Minuten nach der Abfahrtszeit des ersten Teilnehmers.
                            Jede von der Rennleitung genehmigte Unterbrechung erfordert eine zusätzliche Zeit, die der des Stopps entspricht.
                        </p>

                        <h4>Kunst. 07-02.- RENNEN</h4>
                        Das Rennen findet nach dem von der Organisation unter Punkt 1 dieser Ausschreibung festgelegten Zeitplan statt.
                        Es wird 6 zeitgesteuerte Abschnitte <strong>(detailliert in Artikel 03-01.- ROUTE)</strong> mit ihren Verbindungsetappen geben.
                        Jeder Verstoß gegen die in diesem Reglement festgelegten Regeln wird der Jury gemeldet und kann durch deren Entscheidung zu einer Sanktion führen und sogar zum Ausschluss des Teams von der Veranstaltung führen.
                        Jeder Verstoß, der von der Guardia Civil, der Regionalpolizei, der örtlichen Polizei oder einer anderen Regierungsbehörde gemeldet wird, führt zum sofortigen Ausschluss des gemeldeten Teams vom Test.
                        Diese Annahme umfasst Blutalkoholtests und andere, die im Falle eines Unfalls oder wenn dies als angemessen erachtet wird, durchgeführt werden.
                        <strong>Auf die gleiche Weise wird es an den Disziplinarausschuss des FMCV weitergeleitet, falls eine Sanktion vom FMCV vorliegt.</strong>

                        <h3>08.-KLASSIFIKATIONEN, ANSPRÜCHE, BESCHWERDE</h3>
                        <h4>Kunst. 08-01.- KLASSIFIKATIONEN </h4>
                        <p>Sie werden von der Organisation spätestens zwei Stunden nach dem Rennen herausgegeben und geben den Zeitpunkt der Veröffentlichung bzw. im Falle einer technischen Beschwerde an, wann diese behoben wurde.
                            Eine halbe Stunde nach Veröffentlichung dieser Klassifizierung wird sie automatisch genehmigt.
                            Sollte ein Anspruch jeglicher Art anhängig sein, wird dieser erst genehmigt, wenn alle Fälle geklärt sind und die neue Einstufung veröffentlicht wurde.
                        </p>
                        <p>Der erste Platz in einem zeitgesteuerten Abschnitt wird das Team sein, das am wenigsten Zeit investiert und so weiter.
                            Die Zeiten werden addiert und das Team, das alle gezeiteten Abschnitte mit der geringsten Zeit gestartet und beendet hat, gilt als Sieger des Tests.
                            Im Falle eines Unentschiedens ist das Kriterium die höhere Anzahl gewonnener Abschnitte.
                        </p>

                        <h4>Kunst. 08-02.- ANSPRÜCHE</h4>
                        <h4>Art.- 08.02.01 Allgemeine Regeln</h4>
                        <p>Jeder Anspruch kann sich nur auf einen bestimmten Punkt beziehen.
                            Ansprüche sind öffentlich und persönlich (der Antragsteller tut dies in seinem eigenen Namen)
                            Kollektivklagen sowie Klagen gegen Zeitkontrollen oder gegen Entscheidungen von Sachrichtern sind ausgeschlossen.
                            Es ist zu beachten, dass das Anspruchsrecht ausschließlich den Teilnehmern zusteht.
                        </p>
                        <p>Sowohl die Meldung des Anspruchs als auch die Lösung werden den interessierten Parteien schriftlich mitgeteilt und dann auf der offiziellen Bekanntmachungstafel veröffentlicht.
                            Wenn der Anspruch begründet ist und die Anomalie nachgewiesen ist, werden die Kaution und die Kaution an den Antragsteller zurückerstattet und der Antragsteller übernimmt gegebenenfalls die Kosten der Inspektion.
                            Wenn der Anspruch unbegründet ist und keine Anomalie festgestellt wird, wird die Kaution von der Organisation einbehalten und der Antragsteller wird gegebenenfalls die Kosten der Inspektion tragen.
                            Die Jury kann von Amts wegen eine sportliche oder technische Überprüfung an so vielen Fahrzeugen und/oder Teilnehmern einleiten, wie sie es für angemessen hält und wann immer sie Anzeichen einer Unregelmäßigkeit feststellt.
                        </p>
                        <h4>Art.- 08.02.02 Sportliche Ansprüche</h4>
                        <p>Reklamationen aus sportlichen Angelegenheiten müssen schriftlich formuliert und der Jury vorgelegt werden und mit einer Anzahlung von 100,-Eur verbunden sein.
                            Die maximale Frist zur Einreichung einer Reklamation beträgt 30 Minuten nach Ende des letzten Tests des Tages.
                            Wenn die Jury den Anspruch als begründet und berechtigt erachtet, leitet sie ein Verfahren ein, das mit der Ausarbeitung eines endgültigen Beschlusses mit den Schlussfolgerungen und möglichen Sanktionen endet.
                        </p>
                        <h4>Art.- 08.02.03 Ansprüche gegen die Klassifizierung</h4>
                        <p>Die Einspruchsfrist gegen die Ergebnisse der Sportwertung beträgt 30 Minuten nach Veröffentlichung der Tagesergebnisse.
                            Sie müssen schriftlich formuliert und der Jury vorgelegt werden und mit einer Kaution in Höhe von <strong>100,-Eur</strong> versehen sein.
                        </p>
                        <h4>Art.- 08.02.04 Technische Ansprüche</h4>
                        <p>Mögliche Ansprüche gegen eine Maschine, die Komponenten aufweisen könnte, die gegen die technischen Spezifikationen verstoßen, müssen schriftlich formuliert und der Organisation vor dem Ende des letzten Tests des Tages vorgelegt werden, begleitet von einer Kaution von 100. -Eur., zuzüglich einer Kaution für mechanische Kosten in Höhe von 100,00.-Eur., die von der anspruchsberechtigten Mannschaft zu zahlen ist. </p>
                        <h4>Kunst. 08-03.- DOPINGKONTROLLE</h4>
                        <p>Wenn der Test auf öffentlichen Straßen und Straßen durchgeführt wird, sind die öffentlichen Vollzugsbehörden befugt, Tests durchzuführen, die sie für angemessen halten.
                            Die Tatsache, dass die Fahrt zwischen den Abschnitten neutralisiert wird, entbindet nicht von der Verpflichtung, diese Sportregel einzuhalten.
                            Wenn Sie bei einem möglichen Unfall positiv auf Alkohol oder eine andere Droge getestet werden, kann die Zahlung der Krankenversicherung durch den Versicherer kompliziert sein.
                        </p>
                        <h4>Kunst. 08-04.-STRAFEN</h4>
                        <p>Teilnehmende Teams werden in den unten aufgeführten Fällen und Beträgen bestraft.
                            Die Jury behält sich das Recht vor, andere Fälle, die in diesem Artikel nicht behandelt werden, mit anderen Anklagen zu bestrafen
                        </p>
                        <small><strong>ENTFERNUNG DER KLASSIFIZIERUNG:</strong></small>
                        <ol>
                            <li>Wenn ein Team einen zeitgesteuerten Abschnitt nicht startet.</li>
                            <li>Für Änderungen von der teilnehmenden Maschine zu einer anderen, nicht registrierten Maschine</li>
                        </ol>
                        <small><strong>AUSSCHLUSS VOM RENNEN:</strong></small>
                        <ol>
                            <li>Im Falle des Zutritts der Assistenten einer Mannschaft zu Bereichen, die von der Organisation nicht zugelassen sind, zusätzlich zur Bestrafung mit einem schweren Foul.</li>
                            <li>Beim Wechsel des Fahrers oder Beifahrers für andere, die nicht an der vorliegenden Ausgabe der XXXIV. Rallye TRASBETXÍ 2024 teilgenommen haben, wird zusätzlich eine sehr schwere Straftat verhängt.</li>
                        </ol>
                        <small><strong>ZWANZIG SEKUNDEN PRO SEKUNDE ERWEITERT:</strong></small>
                        <ol>
                            <li>Für die Teams, die den offiziellen Zeitnehmern nicht gehorchen und den Ausgang nehmen, bevor er ihnen gegeben wird.</li>
                        </ol>
                        <small><strong>FÜNF SEKUNDEN NOCH:</strong></small>
                        <ol>
                            <li>In den TCP-Zeitabschnitten (Freitag) für Teams, die mit einem oder mehreren Rädern die Markierungen, die die Rennstrecke markieren, überqueren oder niederschlagen.</li>
                        </ol>
                        <p>Wenn ein Fahrzeug in den TCP- und TCD-Abschnitten versehentlich die Rennstrecke verlässt und nicht über denselben Ausstiegspunkt wieder in die Rennstrecke einfährt, wird es in unfreiwilligen Fällen mit MEHR FÜNFZEHN SEKUNDEN und in absichtlichen Fällen mit EINER MEHR SEKUNDE bestraft MINUTE, zusätzlich zur Bestrafung mit einem kleinen Foul.</p>
                        <small><strong>ZWEI MEHR MINUTEN: </strong></small>
                        <ol>
                            <li>Für diejenigen Teilnehmer, die bei der Verdoppelung nicht weggehen und den Durchgang erleichtern, zusätzlich zur Bestrafung mit einem kleinen Foul.</li>
                        </ol>
                        <small><strong>NOCH EINE MINUTE UND DIE VON DER ORGANISATION FESTGELEGTE MINDESTZEIT:</strong></small>
                        <ol>
                            <li>Für jene Teams, die aus verschiedenen Gründen (mechanisch, physisch usw.) in einem gezeiteten Abschnitt den Ausgang erhalten und die Ziellinie nicht erreichen können, unabhängig von der zurückgelegten Distanz. Sie haben jedoch auch das Recht, weiterhin um die besten absoluten Platzierungen zu konkurrieren.</li>
                        </ol>
                        <p>In Fällen, in denen Pilot und Co-Pilot die Ziellinie nicht gleichzeitig erreichen, wird die Zeit, die das letzte Teammitglied, das die Ziellinie erreicht, markiert hat, als erreichte Zeit erfasst. In Fällen, in denen einer der beiden den gezeiteten Abschnitt nicht beendet, gilt der vorherige Punkt.</p>
                        <small><strong>MONAT ZEHN MINUTEN:</strong></small>
                        <ol>
                             <li>Zylinderwechsel aufgrund einer Panne. </li>
                             <li>Die Ersatzflasche muss von der Organisation im Rahmen der Verwaltungskontrolle überprüft und versiegelt worden sein</li>
                         </ol>
                         <small><strong>MONAT ZWANZIG MINUTEN:</strong></small>
                         <ol>
                             <li>Für den Verlust des offiziellen Siegels und dessen Nichtmitteilung an die Organisation.</li>
                         </ol>
                         <h4>Kunst. 08-04 DISZIPLINARREGELUNG </h4>
                         <p>Teilnehmende Teams werden mit der Anwendung dieser Disziplinarregelung in den unten aufgeführten Fällen und Konsequenzen bestraft.
                             Die Jury behält sich das Recht vor, andere Fälle, die in diesem Artikel nicht behandelt werden, mit anderen Anklagen zu bestrafen.
                         </p>
                         <p>Ein <strong>SEHR SCHWERER FEHLER</strong> bedeutet den Ausschluss vom Rennen, den Verlust der geleisteten Anzahlung und die Unmöglichkeit, sich für die Ausgabe der Transbetxí-Rallye des Folgejahres für eines der Teammitglieder zu registrieren.</p>
                         <p>Ein <strong>SCHWERER FEHLER</strong> bedeutet den Ausschluss vom Rennen und den Verlust der geleisteten Anzahlung. </p>
                         <p>Die Häufung von fünf <strong>KLEINEREN FEHLER</strong> deutet auf die Begehung eines schwerwiegenden Fehlers hin.</p>
                         <p>Andere Fälle, die in dieser besonderen Regelung nicht berücksichtigt sind, unterliegen der Disziplinarregelung des FMCV</p>

                         <h3>09.-AUSZEICHNUNGEN UND TROPHÄEN, ÜBERGABE DER AUSZEICHNUNGEN</h3>
                         <h4>Kunst. 01.09.-AUSZEICHNUNGEN</h4>
                         <p>Die Preise sind:</p>
                         <ol>
                             <li>Zum ersten Absolute Classified (weniger Zeitaufwand am Ende aller gezeiteten Etappen).</li>
                             <li>Im zweiten Absolute Classified (id.).</li>
                             <li>Zum dritten Absolute Classified (id.).</li>
                             <li>Zu den Top 3 in jeder Kategorie</li>
                         </ol>
                         <p>Die Preisverleihung findet am Sonntag, 7. April, um 18:30 Uhr statt. an der STÄDTISCHEN FRONTÓ.</p>
                     </div>
                </Grid>
            </Grid>

            <PiePagina></PiePagina>
        </div>
    )
}
