import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';


export const TiemposCard = ({ resultado, index }) => {

	const [info, setInfo] = useState(false);
	const [mostrarPenalizaciones, setMostrarPenalizaciones] = useState(false);

	let penalizacionesArray = [];


	// Intentar analizar la cadena JSON
	if (resultado.penalizaciones) {
		penalizacionesArray = JSON.parse(resultado.penalizaciones);

	}


	const convertirSegundosAHorasMinutosSegundos = (segundos) => {
		const horas = Math.floor(segundos / 3600);
		const minutos = Math.floor((segundos % 3600) / 60);
		const segundosRestantes = segundos % 60;
		// Formatear cada parte a dos dígitos
		const horasFormateadas = String(horas).padStart(2, '0');
		const minutosFormateados = String(minutos).padStart(2, '0');
		const segundosFormateados = String(segundosRestantes).padStart(2, '0');


		return `${horasFormateadas}:${minutosFormateados}:${segundosFormateados}`;
	};

	return (
		<div>
			{/* <div className={index % 2 === 0 ? 'tiempos-card-pair' : 'tiempos-card-odd'} onClick={() => info ? setInfo(false) : setInfo(true)}> */}
			<div className={resultado.descalificado ? 'descalificado' : 'no-descalf'}>
				<div className={index % 2 === 0 ? 'tiempos-card-pair ' : 'tiempos-card-odd'} onClick={() => info ? setInfo(false) : setInfo(true)} >
					<div className='posicion '>{index + 1}</div>
					<div className='dorsal bx' style={{
						fontWeight: 800,
						color: resultado.codigocategoria == 1 ? 'red' : resultado.codigocategoria == 2 ? 'black' : resultado.codigocategoria == 3 ? 'blue' : ''
					}}>{resultado.dorsal}</div>				&nbsp;
					<div className='nombre-equipo '>{resultado.nombreEquipo}</div>
					&nbsp;
					{(penalizacionesArray.length > 0) && (
						<>
							<div className='penalizado '>
								P
							</div>
							<div className={resultado.confirmado == 1 ? 'tiempo confirmado' : 'tiempo provisional'} onClick={() => setMostrarPenalizaciones(true)}>
								{convertirSegundosAHorasMinutosSegundos(resultado.tiempo)},{resultado.decimas ? resultado.decimas : '00'}
							</div>
						</>
					)}
					{(penalizacionesArray.length <= 0) && (
						<div className={resultado.confirmado == 1 ? 'tiempo confirmado' : 'tiempo provisional'}>
							{convertirSegundosAHorasMinutosSegundos(resultado.tiempo)},{resultado.decimas ? resultado.decimas : '00'}
						</div>
					)}
					{(mostrarPenalizaciones === true && (
						<div className="overlay" onClick={() => setMostrarPenalizaciones(false)} style={{ zIndex: 9999, position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}>
							<div className='equipo-card-info'>
								<CloseIcon onClick={() => setMostrarPenalizaciones(false)} className="fade-in-2" style={{ color: '#EAC474' }} fontSize="large" />
								<div className='flex-center'>
									<h3>PENALIZACIONES APLICADAS</h3>
								</div>
								{console.log(penalizacionesArray)}
								{(penalizacionesArray).map((penalizacion) => (
										<h4>{penalizacion.label}</h4>
								))} 
								<div className="spacer"></div>
							</div>

						</div>
					))}

				</div>
			</div>
		</div>
	);
};
