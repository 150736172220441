import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Header } from '../../Header';
import { Grid } from '@mui/material';
import { TiempoTramoCard } from './TiempoTramoCard';

export const TiempoTramo = () => {
	const Navigate = useNavigate();
	const param = useParams();

	const [loaded, setLoaded] = useState(false);
	const [equipos, setEquipos] = useState([]);


	useEffect(() => {
		cargarTiemposTramo();

	}, [])



	const cargarTiemposTramo = () => {
		console.log(param.id)
		axios.get('https://www.transbetxi.com/WS/tiempoTramo.php?codigoTramo=' + param.id + "&idEdicion=" + param.edicion)
			.then(function (response) {
				// manejar respuesta exitosa
				if (response.status == 200) {
					setEquipos(response.data);
					console.log(response.data)

				}
			})
			.catch(function (error) {
				// manejar error
				console.log(error);
			})
			.finally(function () {
				// siempre sera executado
			});
	}




	return (
		<div>
			<Header></Header>
			<Grid container xs={6} md={4} lg={2} >
				<div className="button-adm" onClick={() => Navigate('/gestion-tiempo/' + param.edicion)}>
					Volver
				</div>

			</Grid>
			<div className="flex-center">
				<h1>{param.nombretramo}</h1>
			</div>
			{equipos.map((equipo) => (
				<>
					<TiempoTramoCard equipo={equipo} key={equipo.codigoequipo} />
				</>
			))}
		</div>

	)
}
