import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

export const TiemposTramos = () => {

    const [loaded, setLoaded] = useState(false);
    const [tramos, setTramos] = useState([
        {
            nombre: "tc1 Pantano",
            kms: 6,
            idTramo: "01",
            finalizado: false,
            inicializado: true,
        }, {
            nombre: "tc2 Mortera",
            kms: 8.4,
            idTramo: "02",
            finalizado: false,
            inicializado: true,
        }]
    )
    const Navigate = useNavigate();


    useEffect(() => {
        setLoaded(true);
    }, [])

    return (
        <div>
            <div className='return-button' onClick={() => Navigate("/")}>
                &nbsp;Volver
            </div>
            {(loaded === true) && (
                <div className='flex-center'>
                    <div className='tramo-container'>
                        {tramos.map((tramo, index) => (
                            <div className='tramo-card' key={index}>
                                <div className='card-title'>
                                    {tramo.nombre}
                                </div>
                                <div className='card-element'>
                                   Kilometros: {tramo.kms}
                                </div>
                                <div className='card-element'>
                                    {(tramo.finalizado ? "Finalizado" : "En curso")}
                                    {(tramo.inicializado ? "Iniciado" : "En espera")}
                                </div>
                            </div>
                        ))}
                    </div>

                </div>

            )}

        </div>)
}
