import React, { useEffect, useState } from 'react';
import { TiemposCard } from './TiemposCard';

export const Tiempos = ({ tiempos, edicion, tramo, categoria }) => {

    const [first, setFirst] = useState([]);
    const [iterado, setIterado] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const tiemposPorEdicion = tiempos.filter((tiempo) => tiempo.nombreEdicion === edicion);
        let tramosDeEdicion = null;
        if (tiemposPorEdicion[0]) {
            tramosDeEdicion = tiemposPorEdicion[0].tramos;

            tramosDeEdicion.forEach(tram => {
                tram.resultados.sort((a, b) => a.tiempo - b.tiempo);
            });

            const tramoBuscado = tramosDeEdicion.find(tram => tram.codigotramo === tramo.codigotramo);

            if (tramoBuscado) {
                setFirst(tramoBuscado.resultados);
                setIterado(tramoBuscado.resultados);
            } else {
                //si no hay resultado se setea a 0 para que no colapse
                setFirst([]);
                setIterado([]);
            }

        } else {
            //en caso de no encontrar resultados seteara a 0 para que no colapse
            setIterado([]);
            setFirst([]);


        }



        setLoaded(true);
    }, [tramo, tiempos, edicion]);

    useEffect(() => {
        if (first.length > 0 && first != undefined) {
            let temp = [...first];

            if (categoria === 5) {
                temp = temp.filter(tmp => tmp.codigocategoria === 2 || tmp.codigocategoria === 1);
            } else if (categoria === 4) {
                temp = temp.filter(tmp => tmp.codigocategoria === 2 || tmp.codigocategoria === 1 || tmp.codigocategoria === 3);
            } else {
                temp = temp.filter(tmp => tmp.codigocategoria === categoria);
            }
            setIterado(temp);
        }
    }, [categoria, first]);

    return (
        <div>
            {(loaded === false) && (
                <div className='flex-container spinner'>
                    <div className='loading-spinner'></div>
                </div>
            )}
            <div className="spacer"></div>
            <div className='tiempos-card-pair '>
                <div className='posicion '>x</div>
                <div className='dorsal bx blue'>x</div>
                &nbsp;
                <div className='nombre-equipo '>TIEMPO CONFIRMADO</div>
                &nbsp;
                <div className={'tiempo confirmado'}>
                    HH:MM:SS
                </div>
            </div>
            <div className="spacer"></div>
            <div className='tiempos-card-pair '>
                <div className='posicion '>x</div>
                <div className='dorsal bx blue'>x</div>
                &nbsp;
                <div className='nombre-equipo '>TIEMPO PROVISIONAL</div>
                &nbsp;
                <div className={'tiempo provisional'}>
                    HH:MM:SS
                </div>
            </div>
            <div className="spacer"></div>
            <div className="descalificado">
                <div className='tiempos-card-pair '>
                    <div className='posicion '>x</div>
                    <div className='dorsal bx blue'>x</div>
                    &nbsp;
                    <div className='nombre-equipo '>DESCALIFICADO</div>
                    &nbsp;
                    <div className={'tiempo provisional'}>
                        HH:MM:SS
                    </div>
                </div>
            </div>
            {(loaded === true) && (
                <div>

                    {/* Tiempos de la edicion {first[0].nombreEdicion} */}
                    <div className='tiempos-container'>
                        <div className='tramo-title'>{tramo.nombre} {tramo.descripcion}</div>
                        {((iterado).length > 0) && (
                            iterado.map((resultado, index) => (
                                <TiemposCard resultado={resultado} index={index} key={index} />
                            ))
                        )}
                        {((iterado).length <= 0) && (
                            <div className='flex-center'>
                                <h3> Aun no hay Resultados disponibles</h3>
                                <br></br>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};
