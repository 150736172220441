import React, { useEffect, useState } from 'react'
import axios from 'axios';
import logo from '../../media/logo.png';
import { Header } from '../../components/Header';
import { PiePagina } from '../../components/PiePagina';
import { Galeria } from '../../components/Galeria';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export const HistoriaEs = () => {

    const [ganadores, setGanadores] = useState([])

    const getGanadores = () => {
        axios.get('https://www.transbetxi.com/WS/ganadores.php')
            .then(function (response) {
                // manejar respuesta exitosa
                // console.log(response.data)
                setGanadores(response.data);

            })
            .catch(function (error) {
                // manejar error
                console.log(error);
            })
            .finally(function () {
                // siempre sera executado
            });
    }

    useEffect(() => {
        getGanadores();
    }, [])


    return (
        <div className="fade-in">
            <Header></Header>
            <Grid container xs={12} md={12} direction="column" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
                <Grid container direction="column" xs={10} md={7} style={{ alignItems: 'flex-start', justifyContent: 'flex-start', textAlign: 'left', marginTop: '1em', color: '#EAC474' }}>
                    <h2>¿Qué es el TransBetxí?</h2>

                    <p>El “TransBetxí” es una carrera que se viene realizando desde 1988 por caminos y pistas del término de Betxí (Castellón) y de los pueblos limítrofes a este. Los equipos están formados por un piloto y un copiloto, la máquina que utilizan para participar es un motocultor (normalmente de gasolina).</p>
                    <p>No se trata solamente de una competición deportiva al uso, sino que tiene un componente cultural que se manifiesta a través de lo que significaba la máquina para los agricultores antiguamente y porque el pueblo se lo toma como un acto festivo en el que prácticamente toda la población está pendiente de este evento año tras año.</p>
                    <p>Evidentemente esta carrera nació por casualidad, de la imaginación de un grupo de amigos que querían hacer su Dakar particular con un vehículo tan peculiar.</p>
                    <p>La carrera empezó con 9 equipos participantes en el que el objetivo primordial era pasárselo bien y en los que al día siguiente de la carrera esos mismos motocultores (entonces sin ninguna mejora técnica) pasaban de ser un vehículo para uso deportivo a una máquina para labrar la tierra de naranjos. Esto ha ido evolucionando hasta el nivel que ahora se corre para ganar, dado que las máquinas han ido evolucionando hasta convertirse en auténticos fórmulas uno del campo. Normalmente están hechas por los propios componentes del equipo así como sus familiares y amigos.</p>
                    <p>El XXXIV TransBetxí consta de un prólogo que se inicia el sábado 6 y domingo 7 de abril de 2024 a las 09:00 horas, en el cual se determina el orden de salida de los posteriores tramos que se realizan ese mismo sábado (dos tramos por la mañana y dos por la tarde) y el domingo (dos tramos por la mañana).</p>

                    <br />
                    <br />

                    <strong>Figura 1.- Logo del TransBetxí</strong>

                    <br />
                    <Grid container direction="column" xs={12} md={12} justifyContent="center" alignItems="center">
                        <img src={logo} alt="" />
                    </Grid>
                    <br />

                    <p>El TransBetxí es una prueba reconocida por la Federación de Motociclismo de la Comunidad Valenciana (FMCV), en la que todos los participantes y sus vehículos de asistencia poseen la licencia federativa para poder participar en este tipo de carreras. Esto supone que los participantes están cubiertos con un seguro de asistencia sanitaria ilimitada y que la carrera dispone de un seguro de responsabilidad civil acorde con este tipo de pruebas deportivas. El control y dirección de la misma está regulado por personas que disponen de la licencia federativa correspondiente.</p>

                    <br />
                    <br />
                    <strong>Figura 2.- Foto de un motocultor en carrera.</strong>
                    <br />
                    <Grid container direction="column" xs={12} md={12} justifyContent="center" alignItems="center">
                        <img src="https://www.transbetxi.com/img/historia/historia3.jpg" alt="" />
                    </Grid>
                    <p>El Ayuntamiento de Betxí es el organizador del XXXIV TransBetxí 2024 y responsable de coordinar a todos los colaboradores y voluntarios que participan en el evento deportivo.</p>
                    <p>El Ayuntamiento de Betxí es el encargado de solicitar los permisos correspondientes para poder acceder a los tramos por los que circulan las máquinas. También se encarga de coordinar los servicios médicos, la policía local y otros miembros de seguridad y orden público.</p>
                    <p>La Federación de Motociclismo de la Comunidad Valenciana, a través de sus comisarios deportivos, vela por la aplicación del reglamento. También bajo el amparo de la FMCV está la figura del Director de Carrera, quien es el responsable durante el transcurso de la competición.</p>
                    <p>Otra figura importante y que actúa bajo la supervisión del Director de Carrera es el Responsable de Comunicación, quien diseña toda la red con doble canal de comunicación mediante repetidores para walkies y teléfonos móviles, lo que permite tener en contacto al Director de Carrera con los responsables de cada tramo y con los miembros de seguridad y orden público. El Responsable de Comunicación tiene distribuidos en todos los tramos que se realizan durante la competición a sus ayudantes. Normalmente se sitúan en los inicios y finales de tramos, así como en las zonas que, por especial complejidad, puedan ser causa de origen de accidentes, para así poder parar la carrera en caso de peligro por un accidente.</p>
                    <p>Y por último, tenemos a los Jefes de Tramo, que suelen colocarse en los inicios y finales de tramo, para así poder decidir, en caso de peligro, interrumpir la salida en el momento que se les informe a través del Responsable de Comunicación y con la confirmación del Director de Carrera.</p>
                    <br />
                    <p>Por lo tanto, el organigrama durante la celebración del TransBetxí es el siguiente:</p>
                    <small><strong>Se utiliza un doble canal de comunicación para asegurar la comunicación en tramos donde la telefonía móvil no dispone de cobertura, ya que hay varios tramos que discurren por pistas forestales.</strong></small>
                    <br />

                    <br />
                    <Grid container direction="column" xs={12} md={12} justifyContent="center" alignItems="center">
                        <Grid container direction="column" xs={12} sm={10} md={8} justifyContent="center" alignItems="center">
                            <img src="https://www.transbetxi.com/img/historia/historia4.jpg" alt="" />
                        </Grid>

                    </Grid>
                    <br />
                    <br />
                    <Grid container direction="column" xs={12} md={12} justifyContent="center" alignItems="center">
                        <h2>Palmarés</h2>
                    </Grid>
                    <TableContainer component={Paper} style={{ backgroundColor: '#C9C9C9', borderRadius: '15px' }} elevation={12}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow style={{ backgroundColor: '#EAC474' }}>
                                    <TableCell><strong style={{ color: '#2E2E2E' }}>AÑO</strong></TableCell>
                                    <TableCell><strong style={{ color: '#2E2E2E' }}>EQUIPO</strong></TableCell>
                                    <TableCell><strong style={{ color: '#2E2E2E' }}>PILOTO-COPILOTO</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {ganadores.map((ganador, index) => (
                                    <TableRow>
                                        <TableCell align="left">{ganador.ano} </TableCell>
                                        <TableCell align="left">{ganador.equipo}</TableCell>
                                        <TableCell align="left">{ganador.pilotos}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <br />
                    <br />

                    <Grid container direction="column" xs={12} md={12} justifyContent="center" alignItems="center">
                        <h2>Imagenes historicas</h2>
                    </Grid>


                </Grid>

                <Galeria></Galeria>
            </Grid>

            <PiePagina></PiePagina>
        </div >
    )
}
