import React, { useEffect } from 'react';
import { HeaderEn } from '../../components/HeaderEn';
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';

export const ReglamentoTecnicoEn = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div className="fade-in" >
            <HeaderEn></HeaderEn>
            <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E' }}>
                <h1>TECHNICAL REGULATION</h1>
            </Grid>

            <Grid container xs={12} md={12} direction="column" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E' }}>
                <Grid container xs={10} md={10} direction="row" justifyContent="center" alignItems="center">

                    {/* <object data="https://transbetxi.com//REGLAMENTO_TÉCNICO_en.pdf" type="application/pdf" style={{ minHeight: '100vh', minWidth: '100%' }}>
                        <p><a href="https://transbetxi.com//REGLAMENTO_TÉCNICO_en.pdf"></a></p>
                    </object> */}


                    <iframe src="https://drive.google.com/viewerng/viewer?embedded=true&url=https://transbetxi.com//REGLAMENTO_TÉCNICO_en.pdf" style={{ minHeight: '100vh', minWidth: '100%' }}></iframe>







                    {/* <header id="header" className="header-blog" style={{ maxWidth: '100%', color: '#EAC474' }}>
                        <div className="top-header">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-4 col-xs-8">
                                        <header id="header" className="header-blog">
                                            <div className="top-header">
                                                <div className="container">
                                                    <div className="row" />
                                                </div>
                                            </div>
                                            <div className="bottom-header blog">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <h1 />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </header>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm-8 col-sm-offset-2">
                                                    <section id="blog">
                                                        <article
                                                            id="post-233"
                                                            className="blog-post post-233 page type-page status-publish hentry"
                                                        >
                                                            <div className="blog-post-entry markup-format">

                                                                <h3>
                                                                    1.&nbsp;&nbsp;&nbsp;&nbsp; GENERAL PRESCRIPTIONS
                                                                </h3>
                                                                <h4>1.1) Vehicles admitted:</h4>
                                                                <p>Vehicles marketed under the name of motocultor and that fit the definition in point 1 are admitted to participate in this test. It is important to keep in mind that they must comply with the following limitations and/or technical characteristics:</p>
                                                                <ul>
                                                                    <li>
                                                                        Be series or traditional motocultors and that have been marketed under the name of motocultor for agricultural use, evolved or not, that retain the original engine and gearbox.
                                                                    </li>
                                                                    <li>
                                                                        The engine cannot present external changes not included in the regulations or liquid cooling of any type.
                                                                    </li>
                                                                    <li>
                                                                        Its manufacturing date must be before the year 2000.
                                                                    </li>
                                                                    <li>
                                                                        Motorized cultivators with a single drive axle are admitted. This drive axle must be the front one. Other forms of traction are not allowed (except for guests of the organization).
                                                                    </li>
                                                                </ul>

                                                                <ul>
                                                                    <li>
                                                                        The maximum measurements allowed are:
                                                                        <ul>
                                                                            <li>
                                                                                1200 millimeters for the front tread width counting from the outside of the tire.
                                                                            </li>
                                                                            <li>
                                                                                1500 millimeters for the rear track width counting from the outside of the tire.
                                                                            </li>
                                                                            <li>
                                                                                2200 millimeters of battle, between front and rear axle centers.
                                                                            </li>
                                                                            <li>
                                                                                14 inch rim diameter. Only tires with inner tubes, “tubeless” and “musse” will be admitted, never being solid. </li>
                                                                        </ul>
                                                                    </li>
                                                                    <li>
                                                                        No type of electrical or mechanical aid to increase driving force is permitted. For example, electric motors supported by batteries charged by regenerative braking or other means (KERS style).
                                                                    </li>
                                                                    <li>
                                                                        No type of electrical or mechanical steering assistance is permitted. For example, hydraulic power steering.
                                                                    </li>
                                                                </ul>

                                                                <ul>
                                                                    <li>
                                                                        In general, it is mandatory that all participating vehicles retain the rest of their traditional vehicle characteristics, such as the steering, the car drive system, etc.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        The maximum cylinder capacity is limited to the following
                                                                        values:
                                                                        <ul>
                                                                            <li>2-stroke gasoline engines: maximum 260cc.</li>
                                                                            <li>4-stroke gasoline engines: maximum 260cc.</li>
                                                                            <li>4-stroke diesel engines: maximum 500c.c.</li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                                Note: The original stroke and diameters will be respected.
                                                                <ul>
                                                                    <li>
                                                                        It is the duty of the contestants to prove to the
                                                                        Technical Stewards and the Sports Stewards the
                                                                        total compliance of your Motocultor with the regulations
                                                                        at all times of the test. The contestants must
                                                                        ensure that your Motocultors meet the conditions
                                                                        compliance and security throughout the entire duration of
                                                                        the proof.
                                                                    </li>
                                                                </ul>
                                                                <h3>2.&nbsp;&nbsp;&nbsp;&nbsp; MODIFICATIONS</h3>
                                                                <ul>
                                                                    <li>
                                                                        Modification is understood as any operation
                                                                        tending to change the initial appearance, dimensions,
                                                                        plans, composition or function of an original part.
                                                                    </li>
                                                                    <li>
                                                                        A prohibited modification is understood to be one that is not authorized in these regulations.
                                                                        fails to comply or is so determined by the criteria of the CC.DD. Of the test. Being penalized
                                                                        according to the test regulations.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.1) Suspension</h4>
                                                                <ul>
                                                                    <li>
                                                                        Mechanical, hydraulic or suspension elements
                                                                        tires are allowed on all Motocultor,
                                                                        They must be correctly welded and/or anchored.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.2) Brakes</h4>
                                                                <ul>
                                                                    <li>
                                                                        The brakes will be mandatory to be mounted on both the front and rear axles in independent circuits.
                                                                    </li>
                                                                    <li>
                                                                        They may be hydraulic. If a cable is used, it must have a minimum diameter of 1.8 mm and be secured with cable ties.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        The front braking system must be mounted
                                                                        on top of gearbox or stuck drive shaft
                                                                        to the wheel to avoid contamination with gearbox oil.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.3) Address</h4>
                                                                <ul>
                                                                    <li>
                                                                        It is the joint between the motor head and the
                                                                        trailer (where the occupants go) that pivot on
                                                                        a single axle, which may be the connecting hitch, double
                                                                        or simple.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        It must be operated by a handlebar and respecting the
                                                                        original driving and/or handling system
                                                                        motocultor.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Any flexible control by cable or chain is prohibited, as is the direction or driving of the tiller by turning the wheels.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        It must present a continuous radius throughout its entire
                                                                        angle of rotation, with a continuous perimeter.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        For safety reasons, the handlebar must not have any angular or sharp parts.
                                                                        Any device mounted on the handlebar must not have sharp edges. All the elements
                                                                        of the steering must include a fixing system offering complete security. Stops will be installed
                                                                        in it and the mounting of a steering damper system is permitted.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.4) Accelerator</h4>
                                                                <ul>
                                                                    <li>
                                                                        Only gas trigger or grip will be admitted, as long as they have a recoil spring.
                                                                        and the cable that is well attached to the structure of the machine chassis.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.5) Engine</h4>
                                                                <ul>
                                                                    <li>
                                                                        The engine must be original. The union will be respected
                                                                        original engine-cylinder-head using studs
                                                                        past metric 8, 9 or 10mm. cannot be varied
                                                                        its position or distribution.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        It is not allowed to install a compressor or any system of
                                                                        supercharging in gasoline engines, if
                                                                        allowed in diesel engines.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.6) Clutch</h4>
                                                                <ul>
                                                                    <li>
                                                                        Original or modified, the
                                                                        replacement of the clutch and its surrounding casing or
                                                                        sump.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Continuous speed drives are not permitted.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.7)&nbsp; Fuel tank</h4>
                                                                <ul>
                                                                    <li>
                                                                        It must be solidly fixed, without the fastening
                                                                        is of a provisional nature and does not present a risk of
                                                                        leak during the test.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.8)&nbsp; Gearbox</h4>
                                                                <ul>
                                                                    <li>Only the original gearbox will be accepted.</li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Modification of the exterior appearance is allowed in the
                                                                        final drive gear train at
                                                                        wheels, allowing modification of the height
                                                                        total of the box.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        The upper compartment of the gearbox must
                                                                        preserve the original measurements and appearance. No
                                                                        However, partial modification of the
                                                                        walls of the upper enclosure, whenever it is
                                                                        modification does not imply a change in lift
                                                                        of the drive axles.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Any element can be modified internally and
                                                                        mechanism as long as the walls of the passenger compartment
                                                                        of the upper enclosure are those that support the axes
                                                                        change. And these stay in position
                                                                        original.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        The change in the gear ratio is allowed.
                                                                        origin, as well as adding or eliminating internal pins that
                                                                        involve a greater or lesser number of speeds. He
                                                                        number of gear speeds is not limited.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        It is not allowed to insert a reduction box.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        The gearbox drive system is free,
                                                                        It can be with the foot or hand, servo-assisted or not.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        The use of axle differentials is allowed
                                                                        traction.
                                                                    </li>
                                                                </ul>

                                                                <h4>2.9) Fans</h4>
                                                                <ul>
                                                                    <li>
                                                                        The fans must be properly protected without being able to have manual access with a ventilation grill and properly secured.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.10) Mudguards</h4>
                                                                <ul>
                                                                    <li>
                                                                        Mudguards will be mandatory on all four wheels, covering 180º in an inverted U shape, having to be attached to the trapezoids.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.11) Battery </h4>
                                                                <ul>
                                                                    <li>
                                                                        If you carry a battery, it will be mandatory to be securely fastened with the terminals protected from any contact and to have a properly marked short circuit installed.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.12) Fire extinguishers </h4>
                                                                <ul>
                                                                    <li>
                                                                        Fire extinguishers must have an approved support that is easy to remove, prohibiting ties, straps or any other type of fixed fixation, and must have a visible pressure gauge.
                                                                    </li>
                                                                </ul>

                                                                <h4>2.13) Man overboard</h4>
                                                                <ul>
                                                                    <li>
                                                                        The man overboard system will be mandatory, no system can be set up to cancel it.
                                                                    </li>
                                                                </ul>
                                                                <h3>3. CATEGORIES</h3>
                                                                <h4>3.1) Prototype Category</h4>
                                                                <ul>
                                                                    <li>
                                                                        This category must comply with the technical regulations
                                                                        specified in points 1 and 2 of this regulation
                                                                        in addition to the following indications:
                                                                    </li>
                                                                </ul>
                                                                <h4>3.1.1) Cylinder head</h4>
                                                                <ul>
                                                                    <li>
                                                                        Original or cast cylinder heads may be used,
                                                                        as long as they maintain and respect the characteristics and
                                                                        original appearance.
                                                                    </li>
                                                                    <li />
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-355"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico1.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        The use of nocks is allowed, and these must be inserted in the original stock. Modification of the combustion chamber in its shape and volume is allowed.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        To fix the cylinder head to the cylinder, it is only permitted to use
                                                                        the original holes. Being able to add two more to allow it to be rotated 90º,
                                                                        always respecting the same position as the originals and their measurements.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-359"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico2.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-360"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico3.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        A through hole will be drilled in the front part
                                                                        in any area close to the 2mm exhaust nozzle
                                                                        approximately, with the purpose of serving for the
                                                                        placement of the verification seal.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-361"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico4.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h4>3.1.2) Cylinder</h4>
                                                                <ul>
                                                                    <li>
                                                                        Only the use of an original cylinder will be permitted.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-363"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico5.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        It is not permitted to add exterior elements or increase existing ones in order to increase the cooling capacity.
                                                                        (e.g. welding or modification of cylinder fins). Yes, it is allowed to eliminate or cut these elements.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        It is allowed to add elements attached to the cylinder with
                                                                        The purpose of mounting an intake system by
                                                                        sheets to the cylinder.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        It is possible to optionally add a system of
                                                                        front ventilation, which must be well covered and
                                                                        protected by a grille so as not to be exposed or
                                                                        pose no danger.
                                                                    </li>
                                                                    <li>
                                                                        It is allowed to vary the number of internal conduits or transfers. It is also allowed
                                                                        the modification of said ducts, in addition to the intake and exhaust, eliminating material
                                                                        or filling with any other material or
                                                                        technique, always respecting that the main body is the original cylinder.
                                                                    </li>
                                                                    <li>
                                                                        The use of steel or aluminum jackets with treatments is allowed.
                                                                        surface (nicasilated/chrome) of the walls. The outer measurement of the
                                                                        sleeve must be 83mm (+-2mm). At the top you must respect
                                                                        the thickness of the original (8mm approx.),
                                                                        being able to extend the surface up to a maximum of 130mm.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-364"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico5.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        Grinding and replacement of internal liners or coatings is permitted.
                                                                    </li>
                                                                    <li>
                                                                        Adjustment is allowed by replacing the
                                                                        cylinder gasket. The head gasket is allowed.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        It is not allowed to modify the number, position, or
                                                                        original distribution of the studs that fix the
                                                                        engine, the original union will be respected
                                                                        engine-cylinder-cylinder head by means of studs passed through
                                                                        metric 8, 9 or 10mm, these must perform the same
                                                                        function than in the original cylinder.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-366"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico6.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        A through hole will be drilled in the front part
                                                                        in any area close to the 2mm exhaust nozzle
                                                                        approximately, and another in any other place that
                                                                        remains visible and does not disturb, with the purpose of serving
                                                                        for placing the verification seal.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image size-full">
                                                                    <img
                                                                        className="wp-image-638"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico7.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h4>3.1.3) Crankcase</h4>
                                                                <ul>
                                                                    <li>Only the original crankcase will be accepted.</li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-370"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico8.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-371"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico9.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        It is allowed to remove material from its outer part.
                                                                    </li>
                                                                    <li>
                                                                        It is only allowed to add elements attached to the
                                                                        crankcase in order to assemble a
                                                                        admission to the crankcase.
                                                                    </li>
                                                                    <li>
                                                                        Internal modification of the face is allowed with the
                                                                        cylinder transfers.
                                                                    </li>
                                                                    <li>Repair of the crankcase is allowed.</li>
                                                                    <li>
                                                                        Any other external modification must be
                                                                        reviewed and approved by the CC.DD. of the test for
                                                                        verify that the crankcase corresponds to the original and does not
                                                                        This represents an improvement in performance.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.1.4)&nbsp;&nbsp;Crankshaft, connecting rod and crankpin</h4>
                                                                <ul>
                                                                    <li>
                                                                        Original or modified as long as it is preserved
                                                                        the cylinder stroke.
                                                                    </li>
                                                                    <li>
                                                                        The replacement of these elements by
                                                                        others of better quality as long as the
                                                                        original piston stroke. The measurement of the connecting rod
                                                                        It will be 116mm.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.1.5) Piston, ring and pin</h4>
                                                                <ul>
                                                                    <li>
                                                                        Original or modified, modifications allowed
                                                                        of the piston.
                                                                    </li>
                                                                    <li>
                                                                        The pistons can be replaced with others of greater size.
                                                                        quality, the accepted measurements being: minimum 72mm and
                                                                        maximum 74mm.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.1.6) Admission</h4>
                                                                <ul>
                                                                    <li>
                                                                        Modification of the intake system is allowed
                                                                        original.
                                                                    </li>
                                                                    <li>
                                                                        It is allowed to install injection systems
                                                                        fuel for diesel engines.
                                                                    </li>
                                                                    <li>
                                                                        Pressurization elements may not be used. The
                                                                        spraying of products other than fuel
                                                                        It is forbidden.
                                                                    </li>
                                                                    <li>
                                                                        The anchorage must be firm and the
                                                                        distribution and the connection with the exhaust nozzle,
                                                                        being able to modify the diameter of the studs.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-372"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico10.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h4>3.1.7)&nbsp;&nbsp;Carburetor</h4>
                                                                <ul>
                                                                    <li>
                                                                        The carburetor is free whatever its operating principle.
                                                                        functioning.
                                                                    </li>
                                                                    <li>The positioning of the carburetor is free.</li>
                                                                    <li>The carburetor must be well fixed to the cylinder without the possibility of losing it.</li>
                                                                    <li>The air filter is free.</li>

                                                                </ul>
                                                                <h4>3.1.8)&nbsp;&nbsp;Power on</h4>
                                                                <ul>
                                                                    <li>
                                                                        Variable ignition systems (advance systems
                                                                        and progressive recoil) are allowed, as well as
                                                                        any electronic system that allows self-control of
                                                                        the parameters that the motor uses for its
                                                                        operation when the tiller is running.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.1.9)&nbsp;&nbsp;Electronics</h4>
                                                                <ul>
                                                                    <li>
                                                                        Any data acquisition system is allowed.
                                                                    </li>
                                                                    <li>
                                                                        Remote modification of any
                                                                        engine parameter, these can only be done
                                                                        locally from the machine itself by the pilot or
                                                                        co-pilot.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.1.10)&nbsp; Escape</h4>
                                                                <ul>
                                                                    <li>
                                                                        Partial or total modification of the exhaust is allowed.
                                                                    </li>
                                                                    <li>
                                                                        The use of exhaust valves is not permitted.
                                                                    </li>
                                                                    <li>
                                                                        The anchorage must be firm and the
                                                                        distribution and the connection with the exhaust nozzle,
                                                                        being able to modify the diameter of the studs.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-373"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico11.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h3>3.2)&nbsp; Category Series</h3>
                                                                <ul>
                                                                    <li>
                                                                        This category must comply with the technical regulations
                                                                        specified in points 1 and 2 of this regulation
                                                                        in addition to the following indications:
                                                                    </li>
                                                                </ul>
                                                                <h4>3.2.1)&nbsp;&nbsp;&nbsp;Cylinder head</h4>
                                                                <ul>
                                                                    <li>
                                                                        Original or cast cylinder heads may be used,
                                                                        as long as they maintain and respect the characteristics and
                                                                        original appearance, which may be the shape of the camera
                                                                        free combustion.
                                                                        <ul>
                                                                            <li>
                                                                                Under no circumstances will the use of nocks be permitted.
                                                                            </li>
                                                                            <li>
                                                                                It is allowed to eliminate material to make
                                                                                adjustments, not add.
                                                                            </li>
                                                                            <li>
                                                                                To fix the cylinder head to the cylinder, only
                                                                                will allow you to use the 6 original holes.
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-375"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico12.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        A through hole will be drilled in the front part
                                                                        in any area close to the 2mm exhaust nozzle
                                                                        approximately, either at the base or in one of the
                                                                        fins, with the purpose of serving for placement
                                                                        of the verification seal.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-377"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico13.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h4>3.2.2)&nbsp;&nbsp;Cylinder</h4>
                                                                <ul>
                                                                    <li>Only the original cylinder will be accepted.</li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-379"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico14.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-381"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico15.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-382"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico16.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        The liner can be rectified and replaced, although not
                                                                        It may not be made of aluminum or carry any type of
                                                                        covering. The outside measurement of the shirt must
                                                                        be 83mm (+-2mm).
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-384"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico17.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        In no case may the number of transfers be varied, these must maintain their original position, and their distribution may vary.
                                                                        It is not possible to completely drill the intake ports, only face the crankcase and deepen a maximum of 15mm.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-385"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico18.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-386"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico19.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-387"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico20.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-388"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico21.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-389"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico22.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        In no case may material or new material be added.
                                                                        holes, both in the cylinder and in the sleeve.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        In no case may exterior elements be added or
                                                                        increase the existing ones with the purpose of
                                                                        increase cooling capacity.
                                                                    </li>
                                                                    <li>
                                                                        A through hole will be drilled in the front part
                                                                        and another in the rear, in any area close to the
                                                                        exhaust nozzle and intake nozzle, 2mm
                                                                        approximately, either at the base or in one of the
                                                                        fins, and another in any other place that is left
                                                                        visible and do not disturb, with the purpose of serving to
                                                                        the placement of the verification seal.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image size-full">
                                                                    <img
                                                                        className="wp-image-640"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico23.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h4>3.2.3)&nbsp;&nbsp;Crankcase</h4>
                                                                <ul>
                                                                    <li>Only the original crankcase will be accepted.</li>
                                                                    <li>
                                                                        It is not permitted to add material to the ducts of the
                                                                        crankcase or modify the transfer rate with the
                                                                        cylinder, nor empty the crankcase.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-393"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico24.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-394"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico25.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        Repair of the crankcase is permitted, and the
                                                                        original measurements.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.2.4)&nbsp;&nbsp;Crankshaft, connecting rod and crankpin</h4>
                                                                <ul>
                                                                    <li>
                                                                        Modification and/or replacement of said elements with others of better quality will be permitted, as long as they retain their original stroke and measurements.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.2.5)&nbsp;&nbsp;Piston, ring and pin</h4>
                                                                <ul>
                                                                    <li>
                                                                        Original or modified, modifications allowed in
                                                                        the piston.
                                                                    </li>
                                                                    <li>
                                                                        The pistons can be replaced with others of greater size.
                                                                        quality, the accepted measurements being: minimum 72mm and
                                                                        maximum 74mm.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.2.6)&nbsp;&nbsp;Admission</h4>
                                                                <ul>
                                                                    <li>
                                                                        Modification of the intake system is not allowed
                                                                        original.
                                                                    </li>
                                                                    <li>
                                                                        Adjustment of the intake duct is allowed. Being
                                                                        the intake to the carburetor with a maximum diameter of 38mm.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-395"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico26.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        Pressurization elements may not be used. The
                                                                        spraying of products other than fuel
                                                                        It is forbidden.
                                                                    </li>
                                                                    <li>
                                                                        A welded piece with a ring function will be added by welding
                                                                        (similar to the example, a nut will also be allowed)
                                                                        with a minimum hole of approximately 2mm, in any area of the nozzle.
                                                                        admission, with the purpose of serving for the placement of the verification seal.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-396"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico27.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h4>3.2.7)&nbsp;&nbsp;Carburetor</h4>
                                                                <ul>
                                                                    <li>
                                                                        The principle of operation is free, its
                                                                        maximum measurement 38mm.
                                                                    </li>
                                                                    <li>The positioning of the carburetor is free.</li>
                                                                    <li>The air filter is free.</li>
                                                                </ul>
                                                                <h4>3.2.8)&nbsp;&nbsp;Power on</h4>
                                                                <ul>
                                                                    <li>
                                                                        Only fixed advance ignitions are allowed.
                                                                        It will be checked at the end of the test in case of
                                                                        classification.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.2.9) Escape</h4>
                                                                <ul>
                                                                    <li>
                                                                        Partial or total modification of the exhaust is allowed.
                                                                        Adding material is not allowed.
                                                                    </li>
                                                                    <li>
                                                                        The exhaust nozzle must have a maximum diameter
                                                                        of 44m.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-398"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico28.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        The use of exhaust valves is not permitted.
                                                                    </li>
                                                                    <li>
                                                                        A piece with a ring function will be added by welding (similar to the example, a nut will also be accepted) with a minimum hole of 2mm
                                                                        approximately, in any area of the exhaust nozzle, with the purpose of serving to place the verification seal.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-399"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico29.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        The anchorage must be firm and the
                                                                        distribution and the connection with the exhaust nozzle,
                                                                        being able to modify the diameter of the studs.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-401"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico30.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h3>3.3)&nbsp; Sour Category</h3>
                                                                <ul>
                                                                    <li>
                                                                        The motocultor must comply with the technical regulations that
                                                                        mark the german races
                                                                    </li>
                                                                </ul>
                                                                <h3>4. VERIFICATION AND CONTROL METHODS</h3>
                                                                <h3>4.1) General verification.</h3>
                                                                <ul>
                                                                    <li>In the concentration after the hit of dorsals and
                                                                        helmet verification a verification will be carried out
                                                                        external of the points considered by the CC.DD. for
                                                                        ensure compliance with this regulation. HE
                                                                        will proceed to seal the engines according to category. in case
                                                                        of repair, during the competition, you must inform
                                                                        to the CC.DD. that will proceed to re-seal the engine.
                                                                    </li>
                                                                    <li>
                                                                        The seals will be delivered to the administrative technical control so that the participating teams can place them where appropriate.
                                                                    </li>
                                                                    <li>
                                                                        In
                                                                        If classified, at the time of the
                                                                        final verifications, the parts must be delivered
                                                                        replaced and used during the race for their
                                                                        verification. </li></ul>
                                                                <h3>4.2)&nbsp;&nbsp;Final verification.</h3>
                                                                Once the test is finished, the technical commission will carry out the
                                                                verification of 5 motocultors, the first 3
                                                                classified and 2 others at the discretion of the organization,
                                                                The reference values being those indicated in the
                                                                board. It will be checked that they meet the specifications of
                                                                each category established in this regulation.
                                                                <figure className="wp-block-table">
                                                                    <table>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td />
                                                                                <td>Maximum diameter allowed</td>
                                                                                <td>Original nominal stroke</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Hispanic Villiers</td>
                                                                                <td>?</td>
                                                                                <td>?</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Bultaco</td>
                                                                                <td>74±0.2 mm</td>
                                                                                <td>60±0.2mm</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </figure>
                                                                <h3>4.3) Examples of sealing</h3>
                                                                <ul>
                                                                    <li>
                                                                        <strong>SERIES</strong>
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-404"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico31.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-405"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico32.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        <strong>PROTOTYPES</strong>
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-406"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico33.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h3>4.4)&nbsp;&nbsp;&nbsp;SANCTIONS</h3>In charge of the
                                                                organization.
                                                                <ul>
                                                                    <li>
                                                                        4.5) The organization reserves the right to exclude from the race any participating team that does not comply with these regulations.
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                        </article>
                                                    </section>
                                                </div>
                                            </div>
                                        </div>
                                        <footer id="footer">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-3 col-sm-6 col-xs-12">
                                                        &nbsp;<span style={{ fontSize: "1rem" }}>&nbsp;</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </footer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header> */}

                </Grid>
            </Grid>

            <PiePagina></PiePagina>
        </div>
    )
}
