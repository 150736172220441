import React from 'react';
import './App.css';
import Router from './router/Router';
import { MapProvider } from './MapContext';

function App() {
	return (
		<MapProvider>
			<div className="main-background-dark">
				<Router />
			</div>
		</MapProvider>
	);
}

export default App;
