import { Grid } from '@mui/material'
import React, { useState } from 'react'
import { redirect } from 'react-router-dom'
import PlaceIcon from '@mui/icons-material/Place';
import { DetallesEquipo } from './DetallesEquipo';
import CloseIcon from '@mui/icons-material/Close';



export const EquipoCard = ({ equi, innerIndex, edicion, casco, dorsal }) => {


    const [minfo, setMInfo] = useState(false);
    const [minfoCop, setInfoCop] = useState(false);
    const [zoom, setZoom] = useState(false);
    const [dni, setDni] = useState('ss');
    function decodeHTMLEntities(text) {
        var textArea = document.createElement('textarea');
        textArea.innerHTML = text;
        return textArea.value;
    }

    function redirect(location) {
        // Redirige a la ubicación proporcionada
        window.location.href = location;
    }

    return (
        <Grid container xs={12} sm={8} md={6} xl={4} direction="row" justifyContent="center" alignItems="center" key={innerIndex} fontWeight={700}>
            <div className='equipo-card'>
                <div className='equipo-content'>
                    <div className='flex-column'>
                        <div className="dorsal-img" style={{
                            // transform: 'skew(10deg)',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundImage: `url(${dorsal})`,
                            width: '2em',
                            height: '2.5em',
                            color: equi.codigoCategoria == 1 ? 'red' : equi.codigoCategoria == 2 ? 'balck' : equi.codigoCategoria == 3 ? 'blue' : ''
                        }}>
                            <div> {equi.dorsal}</div>

                        </div>
                    </div>
                </div>
                <Grid container xs={12} sm={8} md={6} xl={4} direction="row" justifyContent="center" alignItems="center" key={innerIndex}>
                    <div className='equipo-element'>{decodeHTMLEntities(equi.nombreEquipo)}</div>
                    <div className='equipo-element'>Categoria: {(equi.categoria)} </div>
                    <div className='equipo-element'>{decodeHTMLEntities(equi.nombrePiloto.toUpperCase())}</div>
                    <div className='equipo-element'>
                        <div className='boton-equipo-info' onClick={() => setMInfo(true)}>+ info</div>
                    </div>

                    <div className='equipo-element'>{decodeHTMLEntities(equi.nombreCop.toUpperCase())}</div>
                    <div className='equipo-element'>
                        <div className='boton-equipo-info' onClick={() => { setInfoCop(true); }}>+ info</div>
                    </div>
                    <div className='equipo-element'>{equi.descalificado ? 'Descalificado' : ''}</div>
                    <div className='flex-even'>
                        {equi.ubicacion ? <div className='boton-equipo' onClick={() => redirect(equi.ubicacion)}><PlaceIcon className='sm-icon' /> Ubicación</div> : null}
                    </div>
                    {minfo === true && (
                        <DetallesEquipo equi={equi} setMInfo={setMInfo} dni={equi.dniPiloto} nombre={equi.nombrePiloto} />
                    )}
                    {minfoCop === true && (
                        <DetallesEquipo equi={equi} setMInfo={setInfoCop} dni={equi.dniCopi} nombre={equi.nombreCop} />
                    )}

                </Grid>
                {equi.imagen ? <img className="avatar" onClick={() => setZoom(true)} src={equi.imagen} /> : <img src={casco} />}
            </div>
            {zoom === true && (
                <div className='overlay' onClick={() => setZoom(false)}>
                    <div className="cloumn">
                        <div className="flex-center"><CloseIcon className="fade-in-2" style={{ color: '#EAC474' }} fontSize="large" /></div>
                        <img src={equi.imagen} />
                    </div>
                </div>
            )}
        </Grid>
    )
}
