import React, { useEffect, useState } from 'react';
import logo from '../media/logo.png';
import { useNavigate } from 'react-router-dom';
import { SelectorIdioma } from '../components/SelectorIdioma';
import { Header } from '../components/Header';
import { HeaderEn } from '../components/HeaderEn';
import { HeaderVal } from '../components/HeaderVal';
import { HeaderGer } from '../components/HeaderGer';
import { PiePagina } from '../components/PiePagina';
import { useMyContext } from '../MyProvider '; //Variable global del idioma
import Grid from '@mui/material/Grid';
import axios from 'axios';

export const Patrocinadores = () => {

	const Navigate = useNavigate();

	const { miVariableGlobal, setMiVariableGlobal } = useMyContext();

	const [patrosPeques, setPatrosPeques] = useState([]);
	const [patrosGrandes, setPatrosGrandes] = useState([]);
	const [colaboradores, setcolaboradores] = useState([]);
	const [loaded, setLoaded] = useState(false);

	const shuffleArray = (array) => {
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[array[i], array[j]] = [array[j], array[i]];
		}
		return array;
	}

	useEffect(() => {
		cargarPatros();
	}, [])


	const cargarPatros = () => {
		//resvisar que en un futuro entre solo la edicion pasada por param en el ws
		//id de la edicion manual , revisar para automatizar
		axios.get('https://www.transbetxi.com/WS/patrocinador.php/?edicion=' + 21)
			.then(function (response) {
				// console.log(response);
				// manejar respuesta exitosa
				if (response.status === 200) {
					// console.log(response.data[2].tarifa);
					if (response.data[0].tarifa == 10) {
						let barajado = shuffleArray(response.data[0].patrocinadores);
						setPatrosPeques(barajado);
						// console.log(barajado);
						// console.log(response.data);
					} else if (response.data[0].tarifa == 20) {
						let barajado = shuffleArray(response.data[0].patrocinadores);
						setPatrosGrandes(barajado);
						console.log(barajado);
					}
					if (response.data[1].tarifa == 20) {
						let barajado = shuffleArray(response.data[1].patrocinadores);
						setPatrosGrandes(barajado);
						// console.log(barajado);
					} else if (response.data[1].tarifa == 10) {
						let barajado = shuffleArray(response.data[1].patrocinadores);
						setPatrosPeques(barajado);
						console.log(barajado);
					}
					if (response.data[2].tarifa == 30) {
						let barajado = shuffleArray(response.data[2].patrocinadores);
						setcolaboradores(barajado);
						// console.log(barajado);
					}

				}
				// let patrosBarajados = shuffleArray(response.data)
				//  setPatros(patrosBarajados);
				setLoaded(true);
			})
			.catch(function (error) {
				// manejar error
				console.log(error);
			})
			.finally(function () {
				// siempre sera executado
			});
	}

	return (
		<div className="fade-in">
			<SelectorIdioma idioma={miVariableGlobal} ></SelectorIdioma>


			{(miVariableGlobal == 1) && (
				<Header></Header>
			)}

			{(miVariableGlobal == 2) && (
				<HeaderVal></HeaderVal>
			)}

			{(miVariableGlobal == 3) && (
				<HeaderEn></HeaderEn>
			)}

			{(miVariableGlobal == 4) && (
				<HeaderGer></HeaderGer>
			)}

			{(miVariableGlobal == 1) && (
				<Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
					<h1>COLABORADORES</h1>
				</Grid>
			)}

			{(miVariableGlobal == 2) && (
				<Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
					<h1>COL·LABORADORS</h1>
				</Grid>
			)}

			{(miVariableGlobal == 3) && (
				<Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
					<h1>COLLABORATORS</h1>
				</Grid>
			)}

			{(miVariableGlobal == 4) && (
				<Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
					<h1>MITARBEITER</h1>
				</Grid>
			)}
			<Grid container xs={12} direction="column" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
				<Grid container xs={12} sm={12} md={12} direction="row" justifyContent="center" alignItems="center">
					{colaboradores.map((colaborador, index) => (
						<Grid container xs={12} sm={6} md={4} direction="row" justifyContent="center" alignItems="center" className='logo' key={index}>
							<a href={colaborador.enlace} target='_blank'><img src={colaborador.imagen} className='logo-header' style={{ maxWidth: '300px' }} /></a>
						</Grid>
					))}
				</Grid>
			</Grid>


			{
				(miVariableGlobal == 1) && (
					<Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
						<h1>PATROCINADORES</h1>
					</Grid>
				)
			}

			{
				(miVariableGlobal == 2) && (
					<Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
						<h1>PATROCINADORS</h1>
					</Grid>
				)
			}

			{
				(miVariableGlobal == 3) && (
					<Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
						<h1>SPONSORS</h1>
					</Grid>
				)
			}

			{
				(miVariableGlobal == 4) && (
					<Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
						<h1>SPONSOREN</h1>
					</Grid>
				)
			}

			<Grid container xs={12} md={12} direction="column" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
				{patrosGrandes.length > 0 && (
					<Grid container direction="row" xs={12} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
						{patrosGrandes.map((patrociG, index) => (
							<Grid container xs={12} sm={6} md={4} direction="row" justifyContent="center" alignItems="center" className='logo' key={index}>
								<a href={patrociG.enlace} target='_blank'><img src={patrociG.imagen} className='logo-header' style={{ maxWidth: '300px' }} /></a>
							</Grid>
						))}
						{/* {console.log(patrosGrandes)} */}
					</Grid>)
				}

				<Grid container direction="row" xs={12} md={12} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
					{patrosPeques.map((patrociP, index) => (

						<Grid container xs={6} sm={6} md={4} direction="row" justifyContent="center" alignItems="center" className='logo'>
							<a href={patrociP.enlace} target='_blank'><img src={patrociP.imagen} className='logo-header' style={{ maxWidth: '50%' }} /></a>
						</Grid>


					))}
				</Grid>
			</Grid>

			<PiePagina></PiePagina>
		</div >
	)
}
