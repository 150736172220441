import React from 'react';
import { SelectorIdioma } from '../components/SelectorIdioma';
import { ReglamentoTecnicoEs } from '../landing_page/reglamentoTecnico/ReglamentoTecnicoEs';
import { ReglamentoTecnicoVal } from '../landing_page/reglamentoTecnico/ReglamentoTecnicoVal';
import { ReglamentoTecnicoEn } from '../landing_page/reglamentoTecnico/ReglamentoTecnicoEn';
import { ReglamentoTecnicoGer } from '../landing_page/reglamentoTecnico/ReglamentoTecnicoGer';
import { useMyContext } from '../MyProvider '; //Variable global del idioma



export const ReglamentoTecnico = () => {

  const { miVariableGlobal, setMiVariableGlobal } = useMyContext();

  return (

    <div className="fade-in">
      <SelectorIdioma idioma={miVariableGlobal} ></SelectorIdioma>

      {(miVariableGlobal == 1) && (
        <ReglamentoTecnicoEs></ReglamentoTecnicoEs>
      )}

      {(miVariableGlobal == 2) && (
        <ReglamentoTecnicoVal></ReglamentoTecnicoVal>
      )}

      {(miVariableGlobal == 3) && (
        <ReglamentoTecnicoEn></ReglamentoTecnicoEn>
      )}

      {(miVariableGlobal == 4) && (
        <ReglamentoTecnicoGer></ReglamentoTecnicoGer>
      )}



    </div>

  )
}
