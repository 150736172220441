import { Grid } from '@mui/material'
import React, { useState } from 'react'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';

export const TramoCard = ({ tramo, index, edicion }) => {
    const Navigate = useNavigate();
    const [tramoTemp, setTramoTemp] = useState(tramo)
    const iniciarTramo = (id) => {
        console.log(id)
        Swal.fire({
            title: "¿Seguro que quieres iniciar " + tramoTemp.nombre + "?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si inicialo!",
            cancelButtonText: "Cancelar"

        }).then((result) => {
            if (result.isConfirmed) {
                axios.put("https://www.transbetxi.com/WS/tramos.php", { id: id, finalizado: 'start' }, {
                    headers: {
                        'code': 'pass23sd2aASED6',
                    }


                })
                    .then(function (response) {
                        console.log(response.data);
                        if (response.data.success === true) {
                            Swal.fire({
                                title: "¡Iniciado!",
                                text: "El tramo ha sido iniciado.",
                                icon: "success"
                            });
                            let temp = { ...tramoTemp }
                            temp.finalizado = 'start';
                            setTramoTemp(temp);
                        }
                    })
                    .catch(function (error) {
                        console.error(error);
                        Swal.fire({
                            title: "Error",
                            text: "Hubo un error al iniciar el tramo.",
                            icon: "error"
                        });
                    });
            }
        });
    }
    
    
    const finalizarTramo = (id) => {
        console.log(id)
        Swal.fire({
            title: "¿Seguro que quieres finalizar " + tramoTemp.nombre + "?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si Finalizalo!",
            cancelButtonText: "Cancelar"

        }).then((result) => {
            if (result.isConfirmed) {
                axios.put("https://www.transbetxi.com/WS/tramos.php", { id: id, finalizado: 'yes' }, {
                    headers: {
                        'code': 'pass23sd2aASED6',
                    }


                })
                    .then(function (response) {
                        console.log(response.data);
                        if (response.data.success === true) {
                            Swal.fire({
                                title: "Finalizado!",
                                text: "El tramo ha sido finalizado.",
                                icon: "success"
                            });
                            let temp = { ...tramoTemp }
                            temp.finalizado = 'yes';
                            setTramoTemp(temp);
                        }
                    })
                    .catch(function (error) {
                        console.error(error);
                        Swal.fire({
                            title: "Error",
                            text: "Hubo un error al iniciar el tramo.",
                            icon: "error"
                        });
                    });
            }
        });
    }

    
    return (
        <Grid container xs={11}>
            <div className={tramoTemp.finalizado == "yes" ? 'tramo-card-end fade-in' : tramoTemp.finalizado == "start" ? 'tramo-card-on fade-in' : 'tramo-card-null fade-in'} key={index}>
                {console.log(tramoTemp)}
                <Grid container xs={1}>
                    {tramoTemp.nombre}
                </Grid>
                <Grid container xs={6}>
                    {tramoTemp.descripcion}
                </Grid>
                <Grid container xs={2}>
                    <div className='tiempo-buton ' onClick={() => Navigate('/tiempo-tramo/' + tramoTemp.codigotramo + '/' + edicion +'/' + tramoTemp.nombre) }>
                        Tiempos <MoreTimeIcon />
                    </div>
                </Grid>
                <Grid container xs={2}>
                    {tramoTemp.finalizado == null ? (
                        <div className='tiempo-buton on' onClick={() => iniciarTramo(tramoTemp.codigotramo)}>
                            Iniciar <PlayCircleOutlineIcon />
                        </div>
                    ) : tramoTemp.finalizado == "start" ? (
                        <div className='tiempo-buton end' onClick={() => finalizarTramo(tramoTemp.codigotramo)}>
                            Finalizar <StopCircleIcon />
                        </div>
                    ) : (
                        <div className='tiempo-buton'>
                            Finalizado <SportsScoreIcon />
                        </div>
                    )}
                </Grid>
            </div>
        </Grid >
    )
}
