import React from 'react'
import { SelectorIdioma } from '../components/SelectorIdioma';
import { Header } from '../components/Header';
import { HeaderEn } from '../components/HeaderEn';
import { HeaderVal } from '../components/HeaderVal';
import { HeaderGer } from '../components/HeaderGer';
import { PiePagina } from '../components/PiePagina';
import { useMyContext } from '../MyProvider '; //Variable global del idioma
import Grid from '@mui/material/Grid';
import { MapasTramosEs } from '../landing_page/mapasTramos/MapasTramosEs';
import { MapasTramosEn } from '../landing_page/mapasTramos/MapasTramosEn';
import { MapasTramosGer } from '../landing_page/mapasTramos/MapasTramosGer';
import { MapasTramosVal } from '../landing_page/mapasTramos/MapasTramosVal';

export const MapasTramos = () => {

  const { miVariableGlobal, setMiVariableGlobal } = useMyContext();


  return (
    <div className="fade-in">
      <SelectorIdioma idioma={miVariableGlobal} ></SelectorIdioma>

      {(miVariableGlobal == 1) && (
        <MapasTramosEs></MapasTramosEs>
      )}

      {(miVariableGlobal == 2) && (
       <MapasTramosVal></MapasTramosVal>
      )}

      {(miVariableGlobal == 3) && (
        <MapasTramosEn></MapasTramosEn>
      )}

      {(miVariableGlobal == 4) && (
        <MapasTramosGer></MapasTramosGer>
      )} 

      

    </div>
  )
}
