import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Header } from '../../Header';
import { Grid } from '@mui/material';
import { AnadirComponente } from '../componentes/AnadirComponente';
import { CrearTramo } from './CrearTramo';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Swal from 'sweetalert2';

export const Tramos = () => {

    const [tramos, setTramos] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [anadirTramo, setAnadirTramo] = useState(false);
    const [eliminar, setEliminar] = useState(false);

    const Navigate = useNavigate();
    const param = useParams();

    useEffect(() => {
        cargarTramos();
    }, [])


    const cargarTramos = () => {
        axios.get('https://www.transbetxi.com/WS/tramos.php?edicion=' + param.edicion)
            .then(function (response) {
                if (response.data.length > 0) {
                    setTramos(response.data);
                    setLoaded(true);
                }
            })
            .catch(function (error) {
                console.error(error)
            })
    }

    const eliminarTramo = (codigotramo) => {

        Swal.fire({
            title: "Estas seguro/a?",
            text: "Vas a ELIMINAR permanentemente el tramo " + codigotramo + " !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#dab158',
            cancelButtonColor: '#gray',
            confirmButtonText: "SI, Eliminalo!",
            cancelButtonText: "No, no lo Elimines"
        }).then((result) => {
            if (result.isConfirmed) {

                axios.delete('https://www.transbetxi.com/WS/tramos.php', {
                    data: {
                        code: 'pass23sd2aASED/6&',
                        codigotramo: codigotramo
                    }
                })
                    .then(function (response) {
                        console.log(response.data.success);
                        if (response.data.success === true) {
                            let temp = [...tramos];
                            console.log(temp)
                            setTramos(temp.filter((tmp) => tmp.codigotramo != response.data.id))
                            Swal.fire({
                                icon: "success",
                                title: "El Tramo ha sido eliminado correctamente",
                                showConfirmButton: false,
                                timer: 1500
                            });



                        } else {
                            Swal.fire({
                                icon: "error",
                                title: "Error inesperado al intentar eliminar ",
                                showConfirmButton: false,
                                timer: 1500
                            });
                        }
                    })
                    .catch(function (response) {
                        console.error(response);
                    })
            }
        });
    }

    return (
        <>
            <Header></Header>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="left"
                spacing={0}
            >
                <Grid container xs={2} md={2} lg={2} >
                    <div className="button-adm" onClick={() => Navigate('/edicion/' + param.edicion)}>
                        Volver
                    </div>
                </Grid>
            </Grid>
            <div className='flex-center'>
                <h1>Tramos de la edicion {param.nombredicion}</h1>
            </div>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ gap: '1em' }}
                xs={12}
            >

                <Grid container xs={2} className="button-aux" onClick={() => setAnadirTramo(true)}>
                    Nuevo Tramo
                </Grid>
                <Grid container xs={2} className="button-aux" onClick={eliminar ? () => setEliminar(false) : () => setEliminar(true)} >
                    {eliminar === true ? "CANCELAR" : "ELIMINAR"}
                </Grid>
                <Grid container xs={2} className="button-aux" onClick={() => setAnadirTramo(true)} >
                    Editar
                </Grid>
            </Grid>

            <Grid
                container
                direction="column"
                justifyContent="space-evenly"
                alignItems="center"
                spacing={0}
            >
                {loaded === true && (
                    <>
                        {tramos.map((tramo, index) => (
                            <Grid container xs={6} md={4} lg={4} >
                                <div className="button-admf" >
                                    {console.log(tramo)}
                                    <div className='element-aux'>
                                        {tramo.nombre}&nbsp;
                                    </div>
                                    <div className='element-aux'>
                                        {tramo.descripcion}
                                    </div>
                                    {eliminar === true ? (
                                        <div className='element-aux' onClick={() => eliminarTramo(tramo.codigotramo)}>
                                            <DeleteForeverIcon />
                                        </div>
                                    ) : (
                                        <div className='element-aux'>
                                        </div>
                                    )}



                                </div>
                            </Grid>
                        ))}
                    </>
                )}
                {anadirTramo && (
                    <CrearTramo setAnadirTramo={setAnadirTramo} tramos={tramos} setTramos={setTramos} codigoEdicion={param.edicion} edicion={param.nombredicion} />
                )}
            </Grid>
        </>
    )
}
