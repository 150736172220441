import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HeaderVal } from '../../components/HeaderVal';
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';
import fotoRe from '../../media/DSC_0406-scaled.jpg';

export const ReglamentosVal = () => {

    const Navigate = useNavigate();

    
    return (
        <div className="fade-in" >
            <HeaderVal></HeaderVal>
            <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E' }}>
                <h1>REGLAMENTS</h1>
                <img src={fotoRe} alt="Foto" style={{ maxWidth: '100%', minWidth: '100%' }} />
            </Grid>

            <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
                <Grid container xs={10} md={4} direction="column" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', textAlign: 'center', padding: '1em' }}>
                    <h1>REGLAMENT TÈCNIC</h1>
                    <strong>El reglament tècnic es refereix a totes les normes, especificacions i cotes que una màquina ha de complir dins de la seva categoria perquè no sigui penalitzada</strong>
                    <div class="sirk2"  onClick={() => Navigate('/reglamento-tecnico')}>
                        Veure
                    </div>
                </Grid>

                <Grid container xs={10} md={4} direction="column" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', textAlign: 'center', padding: '1em'  }}>
                    <h1>REGLAMENT DE LA PROVA</h1>
                    <strong>Estimats participants, amb aquesta introducció, volem fer-vos conscients que, a més de tenir uns drets i serveis que us assisteixen, en participar al ral·li TRANSBETXÍ, teniu també una sèrie de normes de compliment obligat. Aquestes normes constitueixen un garant per al correcte esdevenir de la cursa i us ofereixen l'assistència i el mateix tractament a tots els equips que en preneu part</strong>
                    <div class="sirk2"  onClick={() => Navigate('/reglamento-de-la-prueba')}>
                        Veure
                    </div>
                </Grid>

            </Grid>
            <PiePagina></PiePagina>
        </div>
    )
}
