import React from 'react'
import { useNavigate } from 'react-router-dom';
import { HeaderHomeEn } from '../../components/HeaderHomeEn'
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';
import portapapeles from '../../media/icons/portapapeles1.png';
import mapa from '../../media/icons/mapa1.png';
import casco from '../../media/icons/casco.png';
import crono from '../../media/icons/cronografo-1.png';
import libro from '../../media/icons/libro-de-normas.png';
import inscripcion from '../../media/icons/inscribirse.png';
import bar from '../../media/icons/tenedor.png';
import historia from '../../media/icons/libro.png';


export const HomeEn = () => {

    const Navigate = useNavigate();

    return (
        <Grid className="fade-in">
            <HeaderHomeEn></HeaderHomeEn>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}
                direction="column"
                justifyContent="center"
                alignItems="center">

                <Grid container
                    direction="column"
                    // spacing={{ xs: 2, md: 3 }} 
                    // columns={{ xs: 4, sm: 8, md: 12 }}
                    style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: '#2E2E2E', paddingTop: '5em' }}>
                    <Grid container
                        xs={10} md={8}
                        style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                        <h1>WELCOME TO RALLY OF BETXI!</h1>
                        <h1>COMPETITION, FUN, MOTOR, AND ABOVE ALL, FRIENDSHIP AND COMPANIONSHIP</h1>
                    </Grid>
                    <Grid container xs={10} md={4} direction="column" justifyContent="center" alignItems="center" style={{ marginTop: '2em', marginBottom: '1em', marginLeft: '1em', textAlign: 'center' }}>
                        <h2>THANK YOU TO ALL THE SPONSORS AND COLLABORATORS FOR THE SUPPORT AND WORK THESE DAYS.</h2>
                    </Grid>
                    <Grid container xs={10} md={4} direction="column" justifyContent="center" alignItems="center" style={{ marginTop: '2em', marginBottom: '1em', marginLeft: '1em' }}>
                        <img src="https://www.transbetxi.com/img/patros2024.png" alt="" />
                    </Grid>
                    <Grid container
                        xs={10} md={8}
                        style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                        <h2>ORGANIZED BY</h2>
                    </Grid>
                    <Grid container xs={10} md={4} direction="column" justifyContent="center" alignItems="center" style={{ marginTop: '2em', marginBottom: '1em', marginLeft: '1em' }}>
                        <img src="https://www.transbetxi.com/img/betxi-logo.png" alt="" />
                    </Grid>

                    <Grid container xs={10} md={4} direction="column" justifyContent="center" alignItems="center" style={{ marginTop: '2em', marginBottom: '1em', marginLeft: '1em' }}>
                        <img src="https://www.transbetxi.com/img/cartel_transbetxi_2024.jpeg" alt="" />
                    </Grid>

                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    // spacing={{ xs: 2, sm: 2, md: 3, ln: 4 }}
                    // columns={{ xs: 2, sm: 2, md: 1, ln: 4 }}
                    xs={12} md={12}
                    style={{ backgroundColor: '#2E2E2E', paddingTop: '5em' }}
                >
                    <Grid container direction="column" xs={8} md={4} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                        <img src={mapa} alt="Icon" style={{ minWidth: '1em', maxWidth: '5em' }} />
                        <h1>SECTIONS MAP</h1>
                        <strong>Check the location and route of the sections, in addition to being able to see your favorite team in real time.</strong>
                        <div class="sirk2" onClick={() => Navigate('/mapas')}>
                            <a class="element-custom">Maps</a>
                        </div>
                    </Grid>
                    <Grid container direction="column" xs={8} md={4} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                        <img src={portapapeles} alt="Icon" style={{ minWidth: '1em', maxWidth: '5em' }} />
                        <h1>ACTIVITY PROGRAM</h1>
                        <strong>We will soon post the Transbetxi 2024 Activities Program.</strong>
                        <div class="sirk2" onClick={() => Navigate('/actividades')}>
                            <a class="element-custom">Activities</a>
                        </div>
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    // spacing={{ xs: 2, sm: 2, md: 3, ln: 4 }}
                    // columns={{ xs: 2, sm: 2, md: 1, ln: 4 }}
                    style={{ backgroundColor: '#2E2E2E', paddingTop: '1em', paddingBottom: '1em', margin: '-1px' }}
                >
                    <Grid container direction="column" xs={8} md={4} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                        <img src={bar} alt="Icon" style={{ minWidth: '1em', maxWidth: '5em' }} />
                        <h1>WHERE TO EAT</h1>
                        <strong>Bars and restaurants to eat during the days of the rally.</strong>
                        <div class="sirk2" onClick={() => Navigate('/donde-comer')}>
                            <a class="element-custom">Where to eat</a>
                        </div>
                    </Grid>
                    <Grid container direction="column" xs={8} md={4} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                        <img src={historia} alt="Icon" style={{ minWidth: '1em', maxWidth: '5em' }} />
                        <h1>HISTORY</h1>
                        <strong>Learn where the Transbetxi rally comes from and its roots.</strong>
                        <div class="sirk2" onClick={() => Navigate('/historia')}>
                            <a class="element-custom">History</a>
                        </div>
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    // spacing={{ xs: 2, sm: 2, md: 3, ln: 4 }}
                    // columns={{ xs: 2, sm: 2, md: 1, ln: 4 }}
                    xs={12} md={12}
                    style={{ backgroundColor: '#2E2E2E', marginTop: '0em', paddingTop: '1em', paddingBottom: '1em', margin: '-1px' }}
                >
                    <Grid container direction="column" xs={8} md={4} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                        <img src={casco} alt="Icon" style={{ minWidth: '1em', maxWidth: '5em' }} />
                        <h1>TEAMS</h1>
                        <strong>Check all the information about your favorite team.</strong>
                        <div class="sirk2" onClick={() => Navigate('/equipos')}>
                            <a class="element-custom">Teams</a>
                        </div>
                    </Grid>
                    <Grid container direction="column" xs={8} md={4} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                        <img src={crono} alt="Icon" style={{ minWidth: '1em', maxWidth: '5em' }} />
                        <h1>CLASSIFICATION</h1>
                        <strong>This year we launched a classification web application, where you can consult both the times of this rally and previous ones.</strong>
                        <br />
                        <strong>Do not forget that some times of this rally are provisional and are uploaded as a guide until they are confirmed.</strong>
                        <div class="sirk2" onClick={() => Navigate('/tiempos')}>
                            <a class="element-custom">Clasificacón</a>
                        </div>
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    // spacing={{ xs: 2, sm: 2, md: 3, ln: 4 }}
                    // columns={{ xs: 2, sm: 2, md: 1, ln: 4 }}
                    xs={12} md={12}
                    style={{ backgroundColor: '#2E2E2E', marginTop: '0em', paddingTop: '1em', paddingBottom: '1em', margin: '-1px' }}
                >
                    <Grid container direction="column" xs={8} md={4} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                        <img src={inscripcion} alt="Icon" style={{ minWidth: '1em', maxWidth: '5em' }} />
                        <h1>REGISTRATIONS</h1>
                        <strong>The necessary steps to register for the rally will be indicated soon.</strong>
                        <div class="sirk2" onClick={() => Navigate('/inscripciones')}>
                            <a class="element-custom">Registrations</a>
                        </div>
                    </Grid>
                    <Grid container direction="column" xs={8} md={4} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                        <img src={libro} alt="Icon" style={{ minWidth: '1em', maxWidth: '5em' }} />
                        <h1>REGULATIONS</h1>
                        <strong>In this section you can find both the technical regulations and the test regulations.</strong>
                        <div class="sirk2" onClick={() => Navigate('/reglamentos')}>
                            <a class="element-custom">Regulations</a>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <PiePagina></PiePagina>
        </Grid>

    )
}
