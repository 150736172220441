import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Checkbox from '@mui/material/Checkbox';


const AletartaCard = ({contenido, arrayAlertas, setContenidoNoti}) => {

    const [open, setOpen] = useState(false);
    const [activa, setActiva] = React.useState(true);
    const [tempContenido, setTempContenido] = useState(arrayAlertas);
    const [editar, setEditar] = useState(false);

    const param = useParams();
    const Navigate = useNavigate();

    console.log(contenido, arrayAlertas)

    const [alerta, setAlerta] = useState({
        edicion: param.edicion,
        id: contenido.id,
        activa: contenido.activa,
        descripcion: contenido.descripcion
    })

    const editarNotificaciones = () => {
        // console.log(alerta)
        axios.put('https://www.transbetxi.com/WS/notificaciones.php', alerta)
            .then(function (response) {
                // manejar respuesta exitosa
                 console.log(response.data)
                 if(response.data.success == true){
                    let temp = [...arrayAlertas]
                    let idArr = (temp.findIndex((tmp) => tmp.id == alerta.id));
                     temp[idArr] = alerta;
                    setContenidoNoti(temp);
                 }
                setOpen(false);
                // window.location.reload();
            })
            .catch(function (error) {
                // manejar error
                console.log(error);

            })
            .finally(function () {
                // siempre sera executado
            });
    }

    const borrarNotificaciones = (id) => {
        console.log(id);
        axios.delete('https://www.transbetxi.com/WS/notificaciones.php?id=' + id)
            .then(function (response) {
                // manejar respuesta exitosa
                console.log("w",response.data);
                if(response.data.success == true){
                    console.log("hish", arrayAlertas);
                    let temp = [...arrayAlertas];
                    console.log("Antes" + temp);
                    console.log(temp.filter((tmp) => tmp.id != response.data.id));
                    console.log("Despues" + temp);
                    temp = temp.filter((tmp) => tmp.id != id );
                    setContenidoNoti(temp);
                }
             
            })
            .catch(function (error) {
                // manejar error
                // console.log(error);

            })
            .finally(function () {
                // siempre sera executado
            });
    }



    const handleClose = () => {
        setOpen(false);
    }

    const handleOnChange = event => {

        switch (event.target.name) {
            case 'descripcion':
                setAlerta({ ...alerta, descripcion: event.target.value });
                break;
            default:
                break;
        }
    };

    const handleChange = (event) => {
        if (event.target.checked) {
            setAlerta({ ...alerta, activa: 1 });
        }
        if (!event.target.checked) {
            setAlerta({ ...alerta, activa: 0 });
        }

        setActiva(event.target.checked);
    };

    useEffect(() => {
        if (contenido.activa == 1) {
            setActiva(true);
        } else {
            setActiva(false);
        }
    }, [])

    return (
        <div>
            <div className='flex-vertical' style={{ alignItems: 'flex-start', backgroundColor: (contenido.activa == 1 ? '#e30505' : '#C9C9C9'), borderRadius: '15px', padding: '0em 1em' }}>
                <h2 style={{ color: '#2E2E2E'}}>Alerta Nº {contenido.id}</h2>
                <samll>{contenido.fechaHora}</samll>
                <p>{contenido.descripcion}</p>
                <div className="flex-horizontal" style={{ gap: '1em' }}>
                    <div className='flex-horizontal pointer' style={{ marginBottom: '1em' }}
                        onClick={editar ? () => setOpen(false) : () => { setOpen(true); }}
                    >
                        <strong style={{color:'#2E2E2E'}}><small>Editar</small></strong>
                    </div>
                    <div className='flex-horizontal pointer' style={{ marginBottom: '1em' }}
                        onClick={() => borrarNotificaciones(contenido.id)}
                    >
                        <strong style={{color:'#2E2E2E'}}><small>Eliminar</small></strong>
                    </div>
                </div>
            </div>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{ backdropFilter: 'blur(5px)', minWidth: '50em' }}
            >
                <DialogContent style={{ backgroundColor: '#C9C9C9' }}>
                    <DialogContentText id="alert-dialog-description">
                        <div className="flex-vertical">
                            <TextField
                                id="outlined-multiline-static"
                                label="Descripcion"
                                multiline
                                rows={4}
                                defaultValue={contenido.descripcion}
                                name='descripcion'
                                onChange={handleOnChange}
                                style={{ backgroundColor: '#FFF', borderRadius: '15px' }}
                            />
                            <div className="flex-horizontal">
                                <p>Activa</p>
                                <Checkbox
                                    checked={activa}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </div>

                        </div>
                    </DialogContentText>
                    <Button onClick={handleClose} >Cancelar</Button>
                    <Button onClick={() => {
                        editarNotificaciones();
                        // handleClose(); 
                    }}>Editar</Button>
                </DialogContent>
            </Dialog>
        </div >
    )
}

export default AletartaCard; // No olvides exportar el componente
