import React, { useEffect } from 'react';
import { Header } from '../../components/Header';
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';


export const ReglamentoTecnicoEs = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div className="fade-in" >
            <Header></Header>
            <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E' }}>
                <h1>REGLAMENTO TÉCNICO</h1>
            </Grid>


            <Grid container xs={12} md={12} direction="column" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E' }}>
                <Grid container xs={10} md={10} direction="row" justifyContent="center" alignItems="center">

                    {/* <object data="https://transbetxi.com//REGLAMENTO_TÉCNICO.pdf" type="application/pdf" style={{ minHeight: '100vh', minWidth: '100%' }}>
                        <p><a href="https://transbetxi.com//REGLAMENTO_TÉCNICO.pdf"></a></p>
                    </object>

                    <embed src="https://transbetxi.com/REGLAMENTO_TÉCNICO.pdf" type="application/pdf" style={{ minHeight: '100vh', minWidth: '100%' }} /> */}

                    <iframe src="https://drive.google.com/viewerng/viewer?embedded=true&url=https://transbetxi.com/REGLAMENTO_TÉCNICO.pdf" style={{ minHeight: '100vh', minWidth: '100%' }}></iframe>

                    {/* <header id="header" className="header-blog" style={{ maxWidth: '100%', color: '#EAC474' }}>
                        <div className="top-header">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-4 col-xs-8">
                                        <header id="header" className="header-blog">
                                            <div className="top-header">
                                                <div className="container">
                                                    <div className="row" />
                                                </div>
                                            </div>
                                            <div className="bottom-header blog">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <h1 />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </header>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm-8 col-sm-offset-2">
                                                    <section id="blog">
                                                        <article
                                                            id="post-233"
                                                            className="blog-post post-233 page type-page status-publish hentry"
                                                        >
                                                            <div className="blog-post-entry markup-format">
                                                                <h3>
                                                                    1.&nbsp;&nbsp;&nbsp;&nbsp; PRESCRIPCIONES GENERALES
                                                                </h3>
                                                                <h4>1.1) Vehículos admitidos:</h4>
                                                                <p>Son admitidos a participar en esta prueba los vehículos comercializados bajo la denominación de motocultor y que encajen con la definición del punto 1. Es importante tener en cuenta que deben cumplir con las siguientes limitaciones y/o características técnicas:</p>
                                                                <ul>
                                                                    <li>
                                                                        Ser motocultores de serie o tradicionales y que hayan sido comercializados con la denominación de motocultor para uso agrícola, evolucionados o no, que conserven la motorización y la caja de cambios originales.
                                                                    </li>
                                                                    <li>
                                                                        El motor no puede presentar cambios externos no recogidos en el reglamento ni refrigeración líquida de ningún tipo.
                                                                    </li>
                                                                    <li>
                                                                        Su fecha de fabricación debe ser anterior al año 2000.
                                                                    </li>
                                                                    <li>
                                                                        Son admitidos los motocultores con un solo eje motriz. Este eje motriz debe ser el delantero. No se admiten otras fórmulas de tracción (excepto los invitados de la organización).
                                                                    </li>
                                                                </ul>

                                                                <ul>
                                                                    <li>
                                                                        Las medidas máximas admitidas son:
                                                                        <ul>
                                                                            <li>
                                                                                1200 milímetros para el ancho de vías delantero contando desde el exterior del neumático.
                                                                            </li>
                                                                            <li>
                                                                                1500 milímetros para el ancho de vías trasero contando desde el exterior del neumático.
                                                                            </li>
                                                                            <li>
                                                                                2200 milímetros de batalla, entre centros de eje delantero y trasero.
                                                                            </li>
                                                                            <li>
                                                                                14 pulgadas de diámetro de llanta. Solo serán admitidos neumáticos con cámara de aire, “tubeless” y “musse” no siendo nunca macizados.                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                    <li>
                                                                        No se permite ningún tipo de ayuda eléctrica o mecánica para aumentar la fuerza motriz. Por ejemplo, motores eléctricos apoyados por baterías cargadas por frenada regenerativa u otros medios (estilo KERS).
                                                                    </li>
                                                                    <li>
                                                                        No se permite ningún tipo de ayuda eléctrica o mecánica a la dirección. Por ejemplo, servodirección hidráulica.
                                                                    </li>
                                                                </ul>

                                                                <ul>
                                                                    <li>
                                                                        Con carácter general, es obligatorio que todos los vehículos participantes conserven el resto de sus características de vehículo tradicional, como pueden la dirección, el sistema de unión motriz carro, etc.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Queda limitada la cilindrada máxima a los siguientes
                                                                        valores:
                                                                        <ul>
                                                                            <li>Motores 2 tiempos gasolina: máximo 260c.c.</li>
                                                                            <li>Motores 4 tiempos gasolina: máximo 260c.c.</li>
                                                                            <li>Motores 4 tiempos diesel: máximo 500c.c.</li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                                Nota: Se respetarán la carrera y los diámetros originales.
                                                                <ul>
                                                                    <li>
                                                                        Es deber de los concursantes probar ante los
                                                                        Comisarios Técnicos y los Comisarios Deportivos la
                                                                        conformidad total de su Motocultor con los reglamentos
                                                                        en todo momento de la prueba. Los concursantes deben
                                                                        asegurar que sus Motocultores cumplen las condiciones
                                                                        de conformidad y seguridad durante toda la duración de
                                                                        la prueba.
                                                                    </li>
                                                                </ul>
                                                                <h3>2.&nbsp;&nbsp;&nbsp;&nbsp; MODIFICACIONES</h3>
                                                                <ul>
                                                                    <li>
                                                                        Se entiende por modificación cualquier operación
                                                                        tendente a cambiar el aspecto inicial, dimensiones,
                                                                        planos, composición o función de una parte original.
                                                                    </li>
                                                                    <li>
                                                                        Se entiende como modificación prohibida aquella que no esté autorizada en el presente reglamento,
                                                                        lo incumpla o sea determinada así por criterio de los CC.DD. de la prueba. Siendo penalizable
                                                                        según el reglamento de la prueba.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.1) Suspensión</h4>
                                                                <ul>
                                                                    <li>
                                                                        Los elementos de suspensión mecánicos, hidráulicos o
                                                                        neumáticos están permitidos en todo el Motocultor,
                                                                        debiendo estar correctamente soldados y/o anclados.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.2) Frenos</h4>
                                                                <ul>
                                                                    <li>
                                                                        Los frenos se montarán obligatorios tanto en eje delantero como en el trasero en circuitos independientes.
                                                                    </li>
                                                                    <li>
                                                                        Podrán ser hidráulicos. Si se usa un cable, deberá tener un diámetro mínimo de 1,8 mm y estar asegurado mediante sujeta cables.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        El sistema de frenado delantero tiene que ir montado
                                                                        en la parte superior de la caja de cambio o en eje motriz pegado
                                                                        a rueda para evitar que se contamine con el aceite de la caja de cambio.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.3) Dirección</h4>
                                                                <ul>
                                                                    <li>
                                                                        Es la articulación entre la cabeza motriz y el
                                                                        remolque (donde van los ocupantes) que pivotan sobre
                                                                        un único eje, pudiendo ser el enganche de unión, doble
                                                                        o simple.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Debe estar accionada por un manillar y respetando el
                                                                        sistema original de conducción y/o manejo del
                                                                        motocultor.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Se prohíbe todo mando flexible por cable o cadena, así como la direccionalidad o conducción del motocultor mediante el giro de las ruedas.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Debe presentar un radio continúo en la totalidad de su
                                                                        ángulo de giro, con un perímetro continuo.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Por razones de seguridad el manillar no debe presentar ninguna parte angular ni punzante.
                                                                        Todo dispositivo montado en el manillar no debe presentar aristas vivas. Todos los elementos
                                                                        de la dirección deben comportar un sistema de fijación ofreciendo toda seguridad. Se instalarán topes
                                                                        en el mismo y está permitido el montaje de un sistema de amortiguador de dirección.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.4) Acelerador</h4>
                                                                <ul>
                                                                    <li>
                                                                        Solo se admitirá gatillo o puño de gas, siempre y cuando tengan muelle de retroceso
                                                                        y el cable que este bien sujeto a la estructura del chasis de la máquina.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.5) Motor</h4>
                                                                <ul>
                                                                    <li>
                                                                        El motor debe ser original. Se respetará la unión
                                                                        original motor-cilindro-culata mediante espárragos
                                                                        pasados de métrica 8, 9 o 10mm. No pudiéndose variar
                                                                        su posición ni distribución.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        No se permite instalar compresor ni sistema alguno de
                                                                        sobrealimentación en los motores gasolina, si se
                                                                        permite en los motores diesel.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.6) Embrague</h4>
                                                                <ul>
                                                                    <li>
                                                                        Original o modificado, estando permitida la
                                                                        sustitución del embrague y su carcasa envolvente ó
                                                                        cárter.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        No se permiten variadores continuos de velocidad.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.7)&nbsp; Depósito de combustible</h4>
                                                                <ul>
                                                                    <li>
                                                                        Debe estar sólidamente fijado, sin que la sujeción
                                                                        tenga un carácter provisional y no presente riesgo de
                                                                        fuga durante la prueba.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.8)&nbsp; Caja de cambios</h4>
                                                                <ul>
                                                                    <li>Sólo se admitirá la caja de cambios original.</li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Se permite la modificación del aspecto exterior en el
                                                                        tren de engranajes de la transmisión final a las
                                                                        ruedas, permitiéndose la modificación de la altura
                                                                        total de la caja.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        El recinto superior de la caja de cambios debe
                                                                        conservar las medidas y aspecto originales. No
                                                                        obstante, se permite la modificación parcial de las
                                                                        paredes del recinto superior, siempre que esta
                                                                        modificación no suponga un cambio en la sustentación
                                                                        de los ejes motrices.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Se puede modificar internamente cualquier elemento y
                                                                        mecanismo siempre y cuando las paredes del habitáculo
                                                                        del recinto superior sean las que sustenten los ejes
                                                                        del cambio. Y estos se mantengan en su posición
                                                                        original.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Se permite el cambio en la relación de las marchas de
                                                                        origen, así como añadir o eliminar piños internos que
                                                                        impliquen un mayor o menor número de velocidades. El
                                                                        número de velocidades del cambio no está limitado.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        No se permite introducir una caja reductora.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        El sistema de accionamiento del cambio es libre,
                                                                        pudiendo ser con el pie o mano, servoasistido o no.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Se permite la utilización de diferenciales en el eje
                                                                        de tracción.
                                                                    </li>
                                                                </ul>

                                                                <h4>2.9) Ventiladores</h4>
                                                                <ul>
                                                                    <li>
                                                                        Los ventiladores deben ir correctamente protegidos sin poder tener acceso manual con rejilla de ventilación y sujetos debidamente.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.10) Guardabarros</h4>
                                                                <ul>
                                                                    <li>
                                                                        Los guardabarros serán obligatorios en las cuatro ruedas cubriendo 180º en forma U invertida teniendo que estar sujetos en los trapecios.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.11) Bateria </h4>
                                                                <ul>
                                                                    <li>
                                                                        En caso de llevar batería será obligatorio estar bien sujetas con los bornes protegidos de cualquier contacto y llevar instalado una corta corriente debidamente señalizado.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.12) Extintores </h4>
                                                                <ul>
                                                                    <li>
                                                                        Los extintores deberán ir con un soporte homologado de fácil extracción, prohibiendo las bridas, las correas o cualquier otro tipo de fijación fija, deberán llevar el reloj de presión visible.
                                                                    </li>
                                                                </ul>

                                                                <h4>2.13) Hombre al agua</h4>
                                                                <ul>
                                                                    <li>
                                                                        El sistema del hombre al agua será obligatorio, no se podrá montar ningún tipo sistema que lo anule.
                                                                    </li>
                                                                </ul>
                                                                <h3>3. CATEGORÍAS</h3>
                                                                <h4>3.1) Categoría Prototipo</h4>
                                                                <ul>
                                                                    <li>
                                                                        Esta categoría debe cumplir el reglamento técnico
                                                                        especificado en los puntos 1 y 2 de este reglamento
                                                                        además de las siguientes indicaciones:
                                                                    </li>
                                                                </ul>
                                                                <h4>3.1.1) Culata</h4>
                                                                <ul>
                                                                    <li>
                                                                        Se podrán utilizar culatas originales o de fundición,
                                                                        siempre que mantengan y respeten las características y
                                                                        aspecto original.
                                                                    </li>
                                                                    <li />
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-355"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico1.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        Se permite la utilización de culatines, debiendo estos estar insertados en la culata original. Se permite la modificación de la cámara de combustión en su forma y volumen.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Para la fijación de la culata al cilindro solo está permitido utilizar
                                                                        los orificios originales. Pudiendo añadir dos más para permitir girarla 90º,
                                                                        respetando siempre la misma posición que los originales y sus medidas.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-359"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico2.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-360"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico3.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        Se practicará un orificio pasado en la parte delantera
                                                                        en cualquier zona próxima a la tobera de escape de 2mm
                                                                        aproximadamente, con la finalidad de servir para la
                                                                        colocación del precinto de verificación.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-361"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico4.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h4>3.1.2) Cilindro</h4>
                                                                <ul>
                                                                    <li>
                                                                        Solo se admitirá el uso de un cilindro original.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-363"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico5.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        No se permite añadir elementos exteriores o aumentar los ya existentes con la finalidad de aumentar la capacidad de refrigeración
                                                                        (por ejemplo, soldado o modificación de aletas del cilindro). Sí está permitido eliminar o recortar estos elementos.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Se permite añadir elementos adheridos al cilindro con
                                                                        la finalidad del montaje de un sistema de admisión por
                                                                        láminas al cilindro.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Se permite añadir opcionalmente un sistema de
                                                                        ventilación frontal, el cual deberá ir bien cubierto y
                                                                        protegido por una rejilla para no quedar expuesto ni
                                                                        suponer ningún peligro.
                                                                    </li>
                                                                    <li>
                                                                        Se permite variar el número de conductos internos o transferes. También está permitida
                                                                        la modificación de dichos conductos, además de los de admisión y escape, eliminando material
                                                                        o rellenando con cualquier otro material o
                                                                        técnica, siempre respetando que el cuerpo principal sea el cilindro original.
                                                                    </li>
                                                                    <li>
                                                                        Se permite utilización de camisas de acero o aluminio con tratamientos
                                                                        superficiales (nicasilado/cromado) de las paredes. La medida exterior de la
                                                                        camisa deberá ser de 83mm (+-2mm). En la parte superior se deberá respetar
                                                                        el espesor de la original (8mm aprox.),
                                                                        pudiendo ampliar la superficie hasta un máximo de 130mm.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-364"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico5.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        Se permite el rectificado y sustitución de camisas internas o recubrimientos.
                                                                    </li>
                                                                    <li>
                                                                        Se permite el ajuste mediante la sustitución de la
                                                                        junta del cilindro. La junta de culata es admitida.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        No se permite modificar el número, la posición, ni la
                                                                        distribución original de los espárragos que fijan el
                                                                        motor, se respetará la unión original
                                                                        motor-cilindro-culata mediante espárragos pasados de
                                                                        métrica 8, 9 o 10mm, debiendo estos realizar la misma
                                                                        función que en el cilindro original.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-366"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico6.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        Se practicará un orificio pasado en la parte delantera
                                                                        en cualquier zona próxima a la tobera de escape de 2mm
                                                                        aproximadamente, y otro en cualquier otro lugar que
                                                                        quede visible y no moleste, con la finalidad de servir
                                                                        para la colocación del precinto de verificación.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image size-full">
                                                                    <img
                                                                        className="wp-image-638"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico7.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h4>3.1.3) Cárter</h4>
                                                                <ul>
                                                                    <li>Sólo se admitirá el cárter original.</li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-370"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico8.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-371"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico9.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        Se permite eliminar material de su parte exterior.
                                                                    </li>
                                                                    <li>
                                                                        Solamente se permite añadir elementos adheridos al
                                                                        cárter con la finalidad de montar un sistema de
                                                                        admisión al cárter.
                                                                    </li>
                                                                    <li>
                                                                        Se permite la modificación interna del encare con los
                                                                        transfers del cilindro.
                                                                    </li>
                                                                    <li>Se permite reparar el cárter.</li>
                                                                    <li>
                                                                        Cualquier otra modificación externa deberá ser
                                                                        revisada y aprobada por los CC.DD. de la prueba para
                                                                        verificar que el cárter corresponde al original y no
                                                                        supone esta una mejora en el rendimiento.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.1.4)&nbsp;&nbsp;Cigüeñal, biela y muñequilla</h4>
                                                                <ul>
                                                                    <li>
                                                                        Originales o modificados siempre y cuando se conserve
                                                                        la carrera del cilindro.
                                                                    </li>
                                                                    <li>
                                                                        Se permite la sustitución de dichos elementos por
                                                                        otros de mejor calidad siempre y cuando se conserve la
                                                                        carrera original del pistón. La medida de la biela
                                                                        será de 116mm.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.1.5) Pistón, segmento y bulón</h4>
                                                                <ul>
                                                                    <li>
                                                                        Original o modificado, permitiéndose modificaciones
                                                                        del pistón.
                                                                    </li>
                                                                    <li>
                                                                        Los pistones se podrán sustituir por otros de mayor
                                                                        calidad, siendo las medidas admitidas: mínimo 72mm y
                                                                        máximo 74mm.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.1.6) Admisión</h4>
                                                                <ul>
                                                                    <li>
                                                                        Se permite la modificación del sistema de admisión
                                                                        original.
                                                                    </li>
                                                                    <li>
                                                                        Se permite instalar sistemas de inyección de
                                                                        combustible a los motores diesel.
                                                                    </li>
                                                                    <li>
                                                                        No se podrán utilizar elementos de presurización. La
                                                                        pulverización de productos distintos del carburante
                                                                        está prohibida.
                                                                    </li>
                                                                    <li>
                                                                        El anclaje deberá ser firme y se respetarán la
                                                                        distribución y la unión con la tobera de escape,
                                                                        pudiendo modificar el diámetro de los espárragos.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-372"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico10.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h4>3.1.7)&nbsp;&nbsp;Carburador</h4>
                                                                <ul>
                                                                    <li>
                                                                        El carburador es libre sea cual sea su principio de
                                                                        funcionamiento.
                                                                    </li>
                                                                    <li>El posicionamiento del carburador es libre.</li>
                                                                    <li>El carburado tiene que estar bien fijado al cilindro sin posibilidad de perdida del mismo.</li>
                                                                    <li>El filtro de aire es libre.</li>

                                                                </ul>
                                                                <h4>3.1.8)&nbsp;&nbsp;Encendido</h4>
                                                                <ul>
                                                                    <li>
                                                                        Los sistemas de encendido variable (sistemas de avance
                                                                        y retroceso progresivo) están permitidos, así como
                                                                        todo sistema electrónico que permita un autocontrol de
                                                                        los parámetros que utiliza el motor para su
                                                                        funcionamiento cuando el motocultor está en marcha.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.1.9)&nbsp;&nbsp;Electrónica</h4>
                                                                <ul>
                                                                    <li>
                                                                        Se permite cualquier sistema de adquisición de datos.
                                                                    </li>
                                                                    <li>
                                                                        No se permite la modificación a distancia de ningún
                                                                        parámetro del motor, estas solo pueden realizarse
                                                                        localmente desde la propia maquina por el piloto o
                                                                        copiloto.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.1.10)&nbsp; Escape</h4>
                                                                <ul>
                                                                    <li>
                                                                        Se permite la modificación parcial o total del escape.
                                                                    </li>
                                                                    <li>
                                                                        No se permite la utilización de válvulas de escape.
                                                                    </li>
                                                                    <li>
                                                                        El anclaje deberá ser firme y se respetarán la
                                                                        distribución y la unión con la tobera de escape,
                                                                        pudiendo modificar el diámetro de los espárragos.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-373"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico11.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h3>3.2)&nbsp; Categoría Series</h3>
                                                                <ul>
                                                                    <li>
                                                                        Esta categoría debe cumplir el reglamento técnico
                                                                        especificado en los puntos 1 y 2 de este reglamento
                                                                        además de las siguientes indicaciones:
                                                                    </li>
                                                                </ul>
                                                                <h4>3.2.1)&nbsp;&nbsp;&nbsp;Culata</h4>
                                                                <ul>
                                                                    <li>
                                                                        Se podrán utilizar culatas originales o de fundición,
                                                                        siempre que mantengan y respeten las características y
                                                                        aspecto original, pudiendo ser la forma de la cámara
                                                                        de combustión libre.
                                                                        <ul>
                                                                            <li>
                                                                                En ningún caso se permitirá el uso de culatines.
                                                                            </li>
                                                                            <li>
                                                                                Se permite eliminar material para realizar
                                                                                ajustes, no añadir.
                                                                            </li>
                                                                            <li>
                                                                                Para la fijación de la culata al cilindro solo se
                                                                                permitirá utilizar los 6 orificios originales.
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-375"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico12.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        Se practicará un orificio pasado en la parte delantera
                                                                        en cualquier zona próxima a la tobera de escape de 2mm
                                                                        aproximadamente, bien en la base o en alguna de las
                                                                        aletas, con la finalidad de servir para la colocación
                                                                        del precinto de verificación.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-377"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico13.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h4>3.2.2)&nbsp;&nbsp;Cilindro</h4>
                                                                <ul>
                                                                    <li>Solo la se admitirá el cilindro original.</li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-379"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico14.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-381"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico15.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-382"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico16.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        Se podrá rectificar y sustituir la camisa, aunque no
                                                                        podrá ser de aluminio ni llevar ningún tipo de
                                                                        recubrimiento. La medida exterior de la camisa deberá
                                                                        ser de 83mm (+-2mm).
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-384"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico17.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        En ningún caso se podrá variar el número de transferes, debiendo estos mantener su posición original, pudiéndose variar su distribución.
                                                                        No se podrá perforar por completo las lumbreras de admisión, solo encara con el cárter y profundizar un máximo de 15mm.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-385"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico18.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-386"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico19.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-387"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico20.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-388"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico21.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-389"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico22.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        En ningún caso se podrá añadir material ni nuevos
                                                                        orificios, tanto en el cilindro como en la camisa.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        En ningún caso se podrá añadir elementos exteriores o
                                                                        aumentar los ya existentes con la finalidad de
                                                                        aumentar la capacidad de refrigeración.
                                                                    </li>
                                                                    <li>
                                                                        Se practicará un orificio pasado en la parte delantera
                                                                        y otro en la trasera, en cualquier zona próxima a la
                                                                        tobera de escape y en la de admisión, de 2mm
                                                                        aproximadamente, bien en la base o en alguna de las
                                                                        aletas, y otro en cualquier otro lugar que quede
                                                                        visible y no moleste, con la finalidad de servir para
                                                                        la colocación del precinto de verificación.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image size-full">
                                                                    <img
                                                                        className="wp-image-640"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico23.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h4>3.2.3)&nbsp;&nbsp;Cárter</h4>
                                                                <ul>
                                                                    <li>Solo se admitirá el cárter original.</li>
                                                                    <li>
                                                                        No se permite añadir material a los conductos del
                                                                        cárter ni modificar el encare de los transfers con el
                                                                        cilindro, ni vaciar el cárter.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-393"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico24.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-394"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico25.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        Se permite reparar el cárter, debiéndose conservar las
                                                                        medidas originales.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.2.4)&nbsp;&nbsp;Cigüeñal, biela y muñequilla</h4>
                                                                <ul>
                                                                    <li>
                                                                        Se permitirá la modificación y/o sustitución de dichos elementos por otros de mejor calidad, siempre y cuando conserven carrera y medidas originales.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.2.5)&nbsp;&nbsp;Pistón, segmento y bulón</h4>
                                                                <ul>
                                                                    <li>
                                                                        Original o modificado, permitiéndose modificaciones en
                                                                        el pistón.
                                                                    </li>
                                                                    <li>
                                                                        Los pistones se podrán sustituir por otros de mayor
                                                                        calidad, siendo las medidas admitidas: mínimo 72mm y
                                                                        máximo 74mm.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.2.6)&nbsp;&nbsp;Admisión</h4>
                                                                <ul>
                                                                    <li>
                                                                        No se permite la modificación del sistema de admisión
                                                                        original.
                                                                    </li>
                                                                    <li>
                                                                        Se permite el ajuste del conducto de admisión. Siendo
                                                                        la toma al carburador de un diámetro máximo de 38mm.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-395"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico26.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        No se podrán utilizar elementos de presurización. La
                                                                        pulverización de productos distintos del carburante
                                                                        está prohibida.
                                                                    </li>
                                                                    <li>
                                                                        Se añadirá un mediante soldadura una pieza soldada con función de argolla
                                                                        (similar al ejemplo, también se admitirá una tuerca)
                                                                        con orificio mínimo de 2mm aproximadamente, en cualquier zona de la tobera de
                                                                        admisión, con la finalidad de servir para la colocación del precinto de verificación.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-396"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico27.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h4>3.2.7)&nbsp;&nbsp;Carburador</h4>
                                                                <ul>
                                                                    <li>
                                                                        El principio de funcionamiento es libre, siendo su
                                                                        medida máxima 38mm.
                                                                    </li>
                                                                    <li>El posicionamiento del carburador es libre.</li>
                                                                    <li>El filtro de aire es libre.</li>
                                                                </ul>
                                                                <h4>3.2.8)&nbsp;&nbsp;Encendido</h4>
                                                                <ul>
                                                                    <li>
                                                                        Solo están permitidos los encendidos de avance fijo.
                                                                        Se comprobará al final de la prueba en caso de
                                                                        clasificación.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.2.9) Escape</h4>
                                                                <ul>
                                                                    <li>
                                                                        Se permite la modificación parcial o total del escape.
                                                                        No se permite añadir material.
                                                                    </li>
                                                                    <li>
                                                                        La tobera de escape deberá ser de un diámetro máximo
                                                                        de 44m.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-398"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico28.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        No se permite la utilización de válvulas de escape.
                                                                    </li>
                                                                    <li>
                                                                        Se añadirá un mediante soldadura una pieza con función de argolla (similar al ejemplo, también se admitirá una tuerca) con orificio mínimo de 2mm
                                                                        aproximadamente, en cualquier zona de la tobera de escape, con la finalidad de servir para la colocación del precinto de verificación.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-399"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico29.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        El anclaje deberá ser firme y se respetarán la
                                                                        distribución y la unión con la tobera de escape,
                                                                        pudiendo modificar el diámetro de los espárragos.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-401"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico30.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h3>3.3)&nbsp; Categoría Agria</h3>
                                                                <ul>
                                                                    <li>
                                                                        El motocultor deberá cumplir el reglamento técnico que
                                                                        marquen las carreras alemanas
                                                                    </li>
                                                                </ul>
                                                                <h3>4.MÉTODOS DE VERIFICACIÓN Y CONTROL</h3>
                                                                <h3>4.1) Verificación general.</h3>
                                                                <ul>
                                                                    <li>En la concentración tras la pega de dorsales y
                                                                        verificación de cascos se realizará una verificación
                                                                        externa de los puntos considerados por los CC.DD. para
                                                                        asegurar el cumplimiento del presente reglamento. Se
                                                                        procederá a precintar los motores según categoría. En caso
                                                                        de reparación, durante la competición, se deberá informar
                                                                        a los CC.DD. que procederán al re-precintado del motor.
                                                                    </li>
                                                                    <li>
                                                                        Los precintos serán entregados en el control técnico administrativo para que los equipos participantes efectúen su colocación donde corresponda.
                                                                    </li>
                                                                    <li>
                                                                        En
                                                                        caso de ser clasificado, en el momento de las
                                                                        verificaciones finales, se deberá entregar las piezas
                                                                        sustituidas y utilizadas durante la carrera para su
                                                                        verificación.</li></ul>
                                                                <h3>4.2)&nbsp;&nbsp;Verificación final.</h3>
                                                                Finalizada la prueba la comisión técnica realizará la
                                                                verificación de a 5 motocultores, los 3 primeros
                                                                clasificados y otros 2 a criterio de la organización,
                                                                siendo los valores de referencia los indicados en la
                                                                tabla. Se comprobará que cumplan las especificaciones de
                                                                cada categoría establecidas en este reglamento.
                                                                <figure className="wp-block-table">
                                                                    <table>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td />
                                                                                <td>Diámetro máximo permitido</td>
                                                                                <td>Carrera nominal original</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Hispano Villiers</td>
                                                                                <td>¿?</td>
                                                                                <td>¿?</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Bultaco</td>
                                                                                <td>74±0,2 mm</td>
                                                                                <td>60±0,2 mm</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </figure>
                                                                <h3>4.3) Ejemplos de precintado</h3>
                                                                <ul>
                                                                    <li>
                                                                        <strong>SERIES</strong>
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-404"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico31.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-405"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico32.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        <strong>PROTOTIPOS</strong>
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-406"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico33.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h3>4.4)&nbsp;&nbsp;&nbsp;SANCIONES</h3>A cargo de la
                                                                organización.
                                                                <ul>
                                                                    <li>
                                                                        4.5) La organización se reserva el derecho de excluir de la carrera a cualquier equipo participante que no cumpla con este reglamento.
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </article>
                                                    </section>
                                                </div>
                                            </div>
                                        </div>
                                        <footer id="footer">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-3 col-sm-6 col-xs-12">
                                                        &nbsp;<span style={{ fontSize: "1rem" }}>&nbsp;</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </footer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header> */}

                </Grid>
            </Grid>

            <PiePagina></PiePagina>
        </div>
    )
}
