import React, { createContext, useContext, useState } from 'react';

// Creamos el contexto
const MyContext = createContext();

// Creamos un componente proveedor que contendrá el estado compartido
const MyProvider = ({ children }) => {
  const [miVariableGlobal, setMiVariableGlobal] = useState(1);

  return (
    <MyContext.Provider value={{ miVariableGlobal, setMiVariableGlobal }}>
      {children}
    </MyContext.Provider>
  );
};

// Un hook personalizado para acceder al contexto
const useMyContext = () => useContext(MyContext);

export { MyProvider, useMyContext };