import { MenuItem, Select, TextField } from '@mui/material';
import axios from 'axios';
import React, { useRef, useState } from 'react'
import Swal from 'sweetalert2';
import EditIcon from '@mui/icons-material/Edit';
import CameraAltIcon from '@mui/icons-material/CameraAlt';



export const CrearPatro = ({ setAnadirPatro, edicion, setPatros,  patros , setLoadedList}) => {
    const [editar, setEditar] = useState(false);
    const [loaded, setLoaded] = useState(true);
    const [camara, setCamara] = useState(false);
    const [imagenSeleccionada, setImagenSeleccionada] = useState(null);

    const [patrocinador, setPatrocinador] = useState({
        id: null,
        telefono: null,
        nombre: null,
        url: null,
        edicion: edicion.codigoedicion,
        tarifa: 10,
        imagen: null,
    })

    const handleOnChange = event => {
        const { name, value } = event.target;

        setPatrocinador(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    console.log(edicion);

    const crearPatro = () => {
        let temp = {...patrocinador};
        temp.imagen = imagenSeleccionada;

        console.log(temp);
        axios.post('https://www.transbetxi.com/WS/patrocinador.php', temp)
            .then(function (response) {
                console.log(response.data);
                if (response.data.success == true) {
                    Swal.fire({
                        icon: "success",
                        title: "El Patrocinador ha sido creado correctamente",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    let tmp = [...patros];
                    temp.id = response.data.codigotramo;
                    tmp.push(temp);

                    setPatros(tmp);
                    setAnadirPatro(false);
                    setLoadedList(true);
                } else {
                    Swal.fire({
                        icon: "error",
                        title: response.data.error,
                        showConfirmButton: false,
                        timer: 2500
                    });
                }
            })
            .catch(function (error) {
                console.error('Error al enviar la solicitud:', error);
            });

    }


    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            const image = new Image();
            image.src = reader.result;
            image.onload = () => {
                const canvas = document.createElement('canvas');
                const maxDimension = 400;
                let width = image.width;
                let height = image.height;

                if (width > height) {
                    if (width > maxDimension) {
                        height *= maxDimension / width;
                        width = maxDimension;
                    }
                } else {
                    if (height > maxDimension) {
                        width *= maxDimension / height;
                        height = maxDimension;
                    }
                }

                canvas.width = width;
                canvas.height = height;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(image, 0, 0, width, height);

                canvas.toBlob((blob) => {
                    if (blob.size > 500 * 1024) {
                        // Si el tamaño de la imagen comprimida es aún mayor que 500 KB, 
                        // puedes realizar más compresión o reducir la calidad aquí si es necesario
                        console.log("La imagen aún es demasiado grande después de la compresión.");
                    } else {
                        setImagenSeleccionada(canvas.toDataURL('image/jpeg'));
                    }
                }, 'image/jpeg', 0.7); // 0.7 es la calidad de compresión

                setCamara(true);
            };
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };
    const fileInputRef = useRef(null);

    const handleLoopIconClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    return (
        <div className='detalles-equipo'>
            {loaded === false && (
                <div className='flex-center'>
                    <div className='loading-spinner'></div>
                </div>
            )}
            {loaded === true && (
                <div className='overlay fade-in-2 '>
                    <div className='detalles-componente-container'>
                        <div className='tramo-container'>
                            <div className='edit-equipo-element'>
                                Nombre:
                                <TextField
                                    onChange={handleOnChange}
                                    name='nombre'
                                    value={patrocinador.nombre}
                                    disabled={editar}
                                    id="outlined-basic"
                                    variant="outlined"
                                    className='field'
                                />
                            </div>
                            <div className='edit-equipo-element'>
                                Telefono:
                                <TextField
                                    onChange={handleOnChange}
                                    name='telefono'
                                    value={patrocinador.telefono}
                                    disabled={editar}
                                    id="outlined-basic"
                                    variant="outlined"
                                    className='field'
                                />
                            </div>
                            <div className='edit-equipo-element'>
                                tarifa:
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={patrocinador.tarifa}
                                    label="Age"
                                    name='tarifa'
                                    className='field'
                                    onChange={handleOnChange}
                                >
                                    <MenuItem value={10}>NORMAL</MenuItem>
                                    <MenuItem value={20}>GRANDE</MenuItem>
                                    <MenuItem value={30}>COLABORADOR</MenuItem>
                                </Select>
                            </div>
                        </div>
                        <div className='tramo-container'>
                            <div className='edit-equipo-element'>
                                Enlace web:
                                <TextField
                                    onChange={handleOnChange}
                                    name='url'
                                    value={patrocinador.url}
                                    disabled={editar}
                                    id="outlined-basic"
                                    variant="outlined"
                                    className='field'
                                />
                            </div>
                            <div className='edit-equipo-element'>
                                Edición:
                                <TextField
                                    onChange={handleOnChange}
                                    name='edicion'
                                    value={edicion.nombre}
                                    disabled={true}
                                    id="outlined-basic"
                                    variant="outlined"
                                    className='field'
                                />
                            </div>
                            <div className='edit-equipo-element column'>
                                Imagen:
                                <div className='button-aux' onClick={handleLoopIconClick}>
                                    <CameraAltIcon className='flex-center' /> &nbsp;Imagen
                                </div>
                                <input
                                    ref={fileInputRef}
                                    type='file'
                                    style={{ display: 'none' }}
                                    accept='image/*'
                                    name='imagen'
                                    onChange={handleFileInputChange}
                                    className='field'
                                />
                                <div className='flex-center' onClick={() => setCamara(true)}>
                                    <img src={imagenSeleccionada} alt="Imagen seleccionada" className='miniatura field' />

                                </div>

                            </div>
                        </div>
                        <div className="buttons-container">
                            {(editar === false) && (
                                <div className='edit-equipo-element'>
                                    <div className='button-aux' onClick={() => crearPatro()}>
                                        Guardar Patrocinador
                                    </div>
                                    {(editar === true) && (
                                        <div className='button-aux' onClick={() => setEditar(false)}>
                                            <EditIcon />    Editar
                                        </div>
                                    )}
                                    <div className='button-aux' onClick={() => setAnadirPatro(false)}>
                                        Cancelar
                                    </div>
                                </div>
                            )
                            }
                        </div>

                    </div>
                </div>

            )}
        </div>
    );
};