import React from 'react'
import { useNavigate } from 'react-router-dom';
import { HeaderHomeGer } from '../../components/HeaderHomeGer'
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';
import portapapeles from '../../media/icons/portapapeles1.png';
import mapa from '../../media/icons/mapa1.png';
import casco from '../../media/icons/casco.png';
import crono from '../../media/icons/cronografo-1.png';
import libro from '../../media/icons/libro-de-normas.png';
import inscripcion from '../../media/icons/inscribirse.png';
import bar from '../../media/icons/tenedor.png';
import historia from '../../media/icons/libro.png';


export const HomeGer = () => {

    const Navigate = useNavigate();


    return (
        <Grid className="fade-in">
            <HeaderHomeGer></HeaderHomeGer>
            <Grid container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                direction="column"
                justifyContent="center"
                alignItems="center">

                <Grid container
                    direction="column"
                    // spacing={{ xs: 2, md: 3 }} 
                    // columns={{ xs: 4, sm: 8, md: 12 }}
                    style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: '#2E2E2E', paddingTop: '5em' }}>
                    <Grid container
                        xs={10} md={8}
                        style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                        <h1>WILLKOMMEN BEI DER BETXÍ-RALLYE!</h1>
                        <h1>WETTBEWERB, SPASS, MOTOR UND VOR ALLEM FREUNDSCHAFT UND BEGLEITUNG</h1>
                    </Grid>
                    <Grid container xs={10} md={4} direction="column" justifyContent="center" alignItems="center" style={{ marginTop: '2em', marginBottom: '1em', marginLeft: '1em', textAlign: 'center' }}>
                        <h2>VIELEN DANK AN ALLE SPONSOREN UND MITARBEITER FÜR DIE UNTERSTÜTZUNG UND ARBEIT IN DIESEN TAGEN</h2>
                    </Grid>
                    <Grid container xs={10} md={4} direction="column" justifyContent="center" alignItems="center" style={{ marginTop: '2em', marginBottom: '1em', marginLeft: '1em' }}>
                        <img src="https://www.transbetxi.com/img/patros2024.png" alt="" />
                    </Grid>
                    <Grid container
                        xs={10} md={8}
                        style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                        <h2>ORGANISIERT VON</h2>
                    </Grid>
                    <Grid container xs={10} md={4} direction="column" justifyContent="center" alignItems="center" style={{ marginTop: '2em', marginBottom: '1em', marginLeft: '1em' }}>
                        <img src="https://www.transbetxi.com/img/betxi-logo.png" alt="" />
                    </Grid>

                    <Grid container xs={10} md={4} direction="column" justifyContent="center" alignItems="center" style={{ marginTop: '2em', marginBottom: '1em', marginLeft: '1em' }}>
                        <img src="https://www.transbetxi.com/img/cartel_transbetxi_2024.jpeg" alt="" />
                    </Grid>

                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    // spacing={{ xs: 2, sm: 2, md: 3, ln: 4 }}
                    // columns={{ xs: 2, sm: 2, md: 1, ln: 4 }}
                    style={{ backgroundColor: '#2E2E2E', paddingTop: '5em', paddingBottom: '1em', margin: '-1px' }}
                >
                    <Grid container direction="column" xs={8} md={4} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                        <img src={mapa} alt="Icon" style={{ minWidth: '1em', maxWidth: '5em' }} />
                        <h1>KARTE DER ABSCHNITTE</h1>
                        <strong>Überprüfen Sie die Lage und Route der Abschnitte und sehen Sie Ihr Lieblingsteam in Echtzeit.</strong>
                        <div class="sirk2" onClick={() => Navigate('/mapas')}>
                            <a class="element-custom">Karten</a>
                        </div>
                    </Grid>
                    <Grid container direction="column" xs={8} md={4} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                        <img src={portapapeles} alt="Icon" style={{ minWidth: '1em', maxWidth: '5em' }} />
                        <h1>AKTIVITÄTEN</h1>
                        <strong>Wir werden in Kürze das Transbetxi 2024-Aktivitätenprogramm veröffentlichen.</strong>
                        <div class="sirk2" onClick={() => Navigate('/actividades')}>
                            <a class="element-custom">Aktivitäten</a>
                        </div>
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    // spacing={{ xs: 2, sm: 2, md: 3, ln: 4 }}
                    // columns={{ xs: 2, sm: 2, md: 1, ln: 4 }}
                    style={{ backgroundColor: '#2E2E2E', paddingTop: '5em', paddingBottom: '1em', margin: '-1px' }}
                >
                    <Grid container direction="column" xs={8} md={4} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                        <img src={bar} alt="Icon" style={{ minWidth: '1em', maxWidth: '5em' }} />
                        <h1>WO SOLLEN WIR ESSEN</h1>
                        <strong>Bars und Restaurants zum Essen während der Rallye-Tage.</strong>
                        <div class="sirk2" onClick={() => Navigate('/donde-comer')}>
                            <a class="element-custom">Wo sollen wir essen</a>
                        </div>
                    </Grid>
                    <Grid container direction="column" xs={8} md={4} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                        <img src={historia} alt="Icon" style={{ minWidth: '1em', maxWidth: '5em' }} />
                        <h1>GESCHICHTE</h1>
                        <strong>Erfahren Sie, woher die Transbetxi-Rallye kommt und welche Wurzeln sie hat.</strong>
                        <div class="sirk2" onClick={() => Navigate('/historia')}>
                            <a class="element-custom">Geschichte</a>
                        </div>
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    // spacing={{ xs: 2, sm: 2, md: 3, ln: 4 }}
                    // columns={{ xs: 2, sm: 2, md: 1, ln: 4 }}
                    style={{ backgroundColor: '#2E2E2E', paddingTop: '5em', paddingBottom: '1em', margin: '-1px' }}
                >
                    <Grid container direction="column" xs={8} md={4} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                        <img src={casco} alt="Icon" style={{ minWidth: '1em', maxWidth: '5em' }} />
                        <h1>MANNSCHAFTEN</h1>
                        <strong>Überprüfen Sie alle Informationen zu Ihrem Lieblingsteam.</strong>
                        <div class="sirk2" onClick={() => Navigate('/equipos')}>
                            <a class="element-custom">Mannschaften</a>
                        </div>
                    </Grid>
                    <Grid container direction="column" xs={8} md={4} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                        <img src={crono} alt="Icon" style={{ minWidth: '1em', maxWidth: '5em' }} />
                        <h1>EINSTUFUNG</h1>
                        <strong>In diesem Jahr haben wir eine Klassifizierungs-Webanwendung gestartet, in der Sie sowohl die Zeiten dieser Rallye als auch früherer Rallyes einsehen können.</strong>
                        <br />
                        <strong>Vergessen Sie nicht, dass einige Zeiten dieser Rallye vorläufig sind und bis zur Bestätigung als Leitfaden hochgeladen werden.</strong>
                        <div class="sirk2" onClick={() => Navigate('/tiempos')}>
                            <a class="element-custom">Einstufung</a>
                        </div>
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    // spacing={{ xs: 2, sm: 2, md: 3, ln: 4 }}
                    columns={{ xs: 2, sm: 2, md: 1, ln: 4 }}
                    style={{ backgroundColor: '#2E2E2E', marginTop: '0em', paddingTop: '1em', paddingBottom: '1em', margin: '-1px' }}
                >
                    <Grid container direction="column" xs={8} md={4} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                        <img src={inscripcion} alt="Icon" style={{ minWidth: '1em', maxWidth: '5em' }} />
                        <h1>ANMELDUNGEN</h1>
                        <strong>Die notwendigen Schritte zur Anmeldung zur Rallye werden in Kürze bekannt gegeben.</strong>
                        <div class="sirk2" onClick={() => Navigate('/inscripciones')}>
                            <a class="element-custom">Anmeldungen</a>
                        </div>
                    </Grid>
                    <Grid container direction="column" xs={8} md={4} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                        <img src={libro} alt="Icon" style={{ minWidth: '1em', maxWidth: '5em' }} />
                        <h1>VORSCHRIFTEN</h1>
                        <strong>In diesem Bereich finden Sie sowohl die technischen Vorschriften als auch die Prüfungsordnung.</strong>
                        <div class="sirk2" onClick={() => Navigate('/reglamentos')}>
                            <a class="element-custom">Vorschriften</a>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <PiePagina></PiePagina>
        </Grid>

    )
}
