import { Grid, TextField } from '@mui/material'
import React, { useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios';

export const CrearEdicion = ({ setNuevaEdicion }) => {

    const [edicion, setEdicion] = useState({
        nombre: null
    })
    const handleOnChange = event => {
        const { name, value } = event.target;

        setEdicion(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const crearEdicion = () => {
        console.log(edicion)
        axios.post('https://www.transbetxi.com/WS/edicion.php', edicion)
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            console.error('Error al enviar la solicitud:', error);
        });
        
    }
    return (
        <div className='overlay fade-in-2 '>
            <div className='detalles-componente-container'>
                <div className='tramo-container'>
                    <div className='edit-equipo-element'>
                        Año de la edicion:&nbsp;
                        <TextField
                            onChange={handleOnChange}
                            name='nombre'
                            value={edicion.nombre}
                            id="outlined-basic"
                            variant="outlined"
                            className='field'
                        />
                    </div>
                    <div className="buttons-container">
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={1}
                        >
                            <Grid container xs={6} md={4} lg={2} >
                                <div className="button-adm" onClick={() => crearEdicion()}>
                                    Añadir
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <div className='edit-equipo-element'>
                    <div className='close' onClick={() => { setNuevaEdicion(false) }}>
                        <CancelIcon />
                    </div>
                </div>
            </div>
        </div>
    )
}
