import React from 'react'
import { Header } from '../../components/Header';
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';

export const DondeComerEs = () => {
    return (
        <div className="fade-in">
            <Header></Header>
            <Grid container xs={12} md={12} direction="column" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
                <Grid container direction="column" xs={10} md={6} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                    <h1> Dónde comer</h1>
                    <p style={{ color: '#EAC474' }}>Bares y restaurantes colaboradores del rally Transbetxi.</p>
                </Grid>
                <Grid container xs={10} sm={8} md={6} direction="column" justifyContent="center" alignItems="center">
                <iframe src="https://www.google.com/maps/d/embed?mid=13coRg-nFDtMpOaAUtESLIJVmGeQliQw&ehbc=2E312F&noprof=1" style={{ minWidth: '100%', minHeight: '50vh' }}></iframe>
                </Grid>
            </Grid>

            <PiePagina></PiePagina>
        </div>
    )
}
