import React, { useEffect, useState } from 'react'
import axios from 'axios'; import logo from '../../media/logo.png';
import { HeaderEn } from '../../components/HeaderEn';
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';
import { Galeria } from '../../components/Galeria';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export const HistoriaEn = () => {

    const [ganadores, setGanadores] = useState([])

    const getGanadores = () => {
        axios.get('https://www.transbetxi.com/WS/ganadores.php')
            .then(function (response) {
                // manejar respuesta exitosa
                // console.log(response.data)
                setGanadores(response.data);

            })
            .catch(function (error) {
                // manejar error
                console.log(error);
            })
            .finally(function () {
                // siempre sera executado
            });
    }

    useEffect(() => {
        getGanadores();
    }, [])


    return (
        <div>
            <HeaderEn></HeaderEn>
            <Grid container xs={12} md={12} direction="column" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
                <Grid container direction="column" xs={10} md={7} style={{ alignItems: 'flex-start', justifyContent: 'flex-start', textAlign: 'left', marginTop: '1em', color: '#EAC474' }}>
                    <h2>What is TransBetxi?</h2>

                    <p>The “Trans-Betxi” is a race that has been taking place since 1988 on roads and tracks in the area of Betxi (Castellón) and the towns bordering it. The teams are made up of a pilot and a co-pilot, the machine they use to participate is a motocultor (normally gasoline-powered).</p>
                    <p>It is not just a typical sports competition, but it has a cultural component that is manifested through what the machine meant to farmers in the past and because the people take it as a festive event in which they practically The entire population is aware of this event year after year.</p>
                    <p>Evidently this race was born by chance, from the imagination of a group of friends who wanted to do their own Dakar with such a peculiar vehicle.</p>
                    <p>The race began with 9 participating teams in which the primary objective was to have a good time and in which the day after the race those same motocultors (then without any technical improvement) went from being a vehicle for sports use to a machine. to till the land of orange trees. This has evolved to the level that is now raced to win, given that the machines have evolved to become authentic formulas in the field. They are normally made by the team members themselves as well as their family and friends.</p>
                    <p>The XXXIV TransBetxí consists of a prologue that begins on Saturday, April 6 and Sunday, April 7, 2024 at 9:00 a.m., in which the starting order of the subsequent sections that take place that same Saturday is determined ( two sections in the morning and two in the afternoon) and on Sunday (two sections in the morning).</p>

                    <br />
                    <br />

                    <strong>Figure 1.- TransBetxí Logo</strong>
                    <br />
                    <Grid container direction="column" xs={12} md={12} justifyContent="center" alignItems="center">
                        <img src={logo} alt="" />
                    </Grid>
                    <br />

                    <p>The TransBetxí is a test recognized by the Motorcycle Federation of the Valencian Community (FMCV) in which all participants and their assistance vehicles have the federative license to participate in this type of races. This means that participants are covered with unlimited healthcare insurance, and that the race has civil liability insurance in line with this type of sporting event. Its control and direction is regulated by people who have the corresponding federative license.</p>

                    <br />
                    <br />
                    <strong>Figure 2.- Photo of a motocultor in the race.</strong>
                    <br />
                    <Grid container direction="column" xs={12} md={12} justifyContent="center" alignItems="center">
                        <img src="https://www.transbetxi.com/img/historia/historia3.jpg" alt="" />
                    </Grid>
                    <p>The Betxí City Council is the organizer of the XXXIV TransBetxí 2024 and is responsible for coordinating all the collaborators and volunteers who participate in the sporting event.</p>
                    <p>The Betxí City Council is in charge of requesting the corresponding permits to access the sections through which the machines circulate, it is also in charge of coordinating the medical services, local police and other members of security and public order. </p>
                    <p>The Motorcycle Federation of the Valencian Community, through its sports commissioners, ensures the application of the regulations. Also under the protection of FMCV is the figure of the Race Director, who is responsible during the course of the competition.</p>
                    <p>Another important figure who acts under the supervision of the Race Director is the Communication Manager, who designs the entire network with a double communication channel through repeaters for walkies and mobile phones that allow the Race Director to be in contact. with those responsible for each section and with members of security and public order. The Communication Manager has his assistants distributed throughout all the sections that take place during the competition. They are normally located at the beginning and end of sections and in areas that, due to special complexity, may be the cause of accidents, in order to be able to stop the race in case of danger due to an accident.</p>
                    <p>And finally we have the Section Leaders who are usually placed at the beginning and end of the section, in order to decide in case of danger to interrupt the start at the moment they are informed through the Communication Manager and with the confirmation from the Race Director.</p>
                    <br />
                    <p>Therefore, the organizational chart during the TransBetxí celebration is as follows:</p>
                    <small><strong>A double communication channel is used to ensure communication in sections where mobile telephony does not have coverage, since there are several sections that run through forest tracks.</strong></small>                    <br />
                    <br />
                    <Grid container direction="column" xs={12} md={12} justifyContent="center" alignItems="center">
                        <Grid container direction="column" xs={12} sm={10} md={8} justifyContent="center" alignItems="center">
                            <img src="https://www.transbetxi.com/img/historia/historia4_en.png" alt="" />
                        </Grid>

                    </Grid>
                    <br />
                    <br />
                    <Grid container direction="column" xs={12} md={12} justifyContent="center" alignItems="center">
                        <h2>Honors</h2>
                    </Grid>

                    <TableContainer component={Paper} style={{ backgroundColor: '#C9C9C9', borderRadius: '15px' }} elevation={12}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow style={{ backgroundColor: '#EAC474' }}>
                                    <TableCell><strong style={{ color: '#2E2E2E' }}>YEAR</strong></TableCell>
                                    <TableCell><strong style={{ color: '#2E2E2E' }}>TEAM</strong></TableCell>
                                    <TableCell><strong style={{ color: '#2E2E2E' }}>PILOT-COPILOT</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {ganadores.map((ganador, index) => (
                                    <TableRow>
                                        <TableCell align="left">{ganador.ano} </TableCell>
                                        <TableCell align="left">{ganador.equipo}</TableCell>
                                        <TableCell align="left">{ganador.pilotos}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br />
                    <br />

                    <Grid container direction="column" xs={12} md={12} justifyContent="center" alignItems="center">
                        <h2>Historical images</h2>
                    </Grid>

                </Grid>

                <Galeria></Galeria>
            </Grid>


            <PiePagina></PiePagina>
        </div>
    )
}
