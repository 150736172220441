import { Alert, Button, FormControlLabel, FormGroup, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CheckIcon from '@mui/icons-material/Check';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useParams } from 'react-router-dom';

export const TiempoTramoCard = ({ equipo }) => {
    const param = useParams();
    const [checkedItems, setCheckedItems] = useState({});
    const [confirmadoCheck, setConfirmadoCheck] = useState(false);
    const [descalificado, setDescalificado] = useState(equipo.descalificado)
    const [estado, setEstado] = useState(null);
    const [provisionalCheck, setProvisionalCheck] = useState(false);
    const [penalizacionesState, setPenalizacionesState] = useState([]);
    const [penalizaciones, setPenalizaciones] = useState([
        { label: "D1 NO INICIAR TRAMO ", id: "D1", sancion: 0, },
        { label: "D2 CAMBIO DE MAQUINA", id: "D2", sancion: 0, },
        { label: "E3  ACCESO DE LA ASISTENCIA A TRAMO NO PERMITIDO...", id: "E3", sancion: 0, },
        { label: "E4 CAMBIAR PILOTO O COPI POR NO INSICRITO", id: "E4", sancion: 0, },
        { label: "A5 SALIDA ANTES DE TIEMPO", id: "A5", sancion: 20, },
        { label: "A6 TRASPASAR O ABATIR SEÑAL", id: "A6", sancion: 5, },
        { label: "A7 SALIRSE Y NO INCORPORARSE TRAMO.. FORMA INVOLUNTARIA", id: "A7", sancion: 15, },
        { label: "A8 SALIRSE Y NO INCORPORARSE TRAMO.. FORMA  INTENCIONADA", id: "A8", sancion: 60, },
        { label: "A9 NO FACILITAR PASO O SER DOBLADO", id: "A9", sancion: 120, },
        { label: "A10 NO LLEGAR A META", id: "A10", sancion: 60, },
        { label: "B11 CAMBIO CILINDRO", id: "B11", sancion: 600, },
        { label: "B12 NO HABERLO REVISADO", id: "B12", sancion: 600, },
        { label: "B13 PERDER PRECINTO", id: "B13", sancion: 1200, },
        { label: "PERSONALIZADA", id: "B14", sancion: 0, }
    ]);
    const [sancionPerson, setSancionPerson] = useState({ label: "PERSONALIZADA", id: "B14", sancion: 0, });

    // const [sancionPersonalizada, setSancionPersonalizada] = useState({ id: 'B14', sancion: 0, label: 'Sancion personalizada' });

    //funcion para formatear segundos a hh:mm:ss
    const convertirSegundosAHorasMinutosSegundos = (segundos, milIni, milFin) => {

        let milesimas = milIni - milFin;
        let segunTmp = segundos;
        if (milesimas < 0) {
            milesimas = 100 + milesimas;
            segunTmp = segunTmp - 1;
        }

        const horas = Math.floor(segunTmp / 3600);
        const minutos = Math.floor((segunTmp % 3600) / 60);
        const segundosRestantes = segunTmp % 60;
        // Formatear cada parte a dos dígitos
        const horasFormateadas = String(horas).padStart(2, '0');
        const minutosFormateados = String(minutos).padStart(2, '0');
        const segundosFormateados = String(segundosRestantes).padStart(2, '0');


        return `${horasFormateadas}:${minutosFormateados}:${segundosFormateados},${milesimas}`;
    };

    function calcularDiferenciaTiempo() {

        const segundosInicio = tiempoInputs.hora_inicio * 3600 + tiempoInputs.minuto_inicio * 60 + tiempoInputs.segundo_inicio * 1;
        const segundosFin = tiempoInputs.hora_fin * 3600 + tiempoInputs.minuto_fin * 60 + tiempoInputs.segundo_fin * 1;

        const diferenciaSegundos = segundosFin - segundosInicio;

        return diferenciaSegundos;
    }



    //selec checkbox
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 350,
            },
        },
    };


    const [personName, setPersonName] = React.useState([]);
    const [open, setOpen] = useState(false);

    // const handleChange = (event) => {
    //     const {
    //         target: { value },
    //     } = event;
    //     setPersonName(
    //         // On autofill we get a stringified value.
    //         typeof value === 'string' ? value.split(',') : value,
    //     );
    // };
    //
    useEffect(() => {
        decodificar();
        if (equipo.tiempo > 0) {
            setProvisionalCheck(true);
        }
        if (equipo.confirmado == 1) {
            setConfirmadoCheck(true);
        }
        if (equipo.penalizaciones !== null) {
            setPenalizacionesState(equipo.penalizaciones)
            let tempPenaliz = [...equipo.penalizaciones];
            let tempChekeds = {};
            tempPenaliz.forEach((penalizacion) => {
                tempChekeds[penalizacion.label] = true; 
                if(penalizacion.id == 'B14'){
                    setSancionPerson(penalizacion);
                }              
            });
            setCheckedItems(tempChekeds);
        }
    }, []);

    const [tiempoInputs, setTiempoInputs] = useState({
        nombre: equipo.nombre,
        dorsal: equipo.dorsal,
        decimas: equipo.decimasTiempo,
        codigoequipo: equipo.codigoequipo,
        confirmado: equipo.confirmado,
        codigotramo: param.id,
        codigoTiempoAux: equipo.codigoTiempoAux,
        codigoTiempoProvisional: equipo.codigoTiempo,
        tiempo_provisional: equipo.tiempo,
        tiempo_confirmado: null,
        sancion: equipo.sancion,
        penalizaciones: equipo.penalizaciones,
        descalificado: equipo.descalificado,
        hora_inicio: equipo.hora,
        minuto_inicio: equipo.minuto,
        segundo_inicio: equipo.segundo,
        milesimas_inicio: equipo.decimasIni,
        hora_fin: 0,
        minuto_fin: 0,
        segundo_fin: 0,
        milesimas_fin: equipo.decimasFin
    });

    useEffect(() => {
        let tiempoEnSegundos = calcularDiferenciaTiempo();
        // let tiempoProvisional = convertirSegundosAHorasMinutosSegundos(tiempoEnSegundos);

        setTiempoInputs(prevState => ({
            ...prevState,
            tiempo_provisional: tiempoEnSegundos
        }));

        if (tiempoInputs.hora_fin > 0 || tiempoInputs.minuto_fin > 0 || tiempoInputs.segundo_fin > 0) {
            setEstado('segundTiempo');
        }
        else if (tiempoInputs.hora_inicio > 0 || tiempoInputs.minuto_inicio > 0 || tiempoInputs.segundo_inicio > 0) {
            setEstado('primerTiempo');

        }
    }, [tiempoInputs.hora_fin, tiempoInputs.minuto_fin, tiempoInputs.segundo_fin, tiempoInputs.milesimas_inicio, tiempoInputs.milesimas_fin, tiempoInputs.hora_inicio, tiempoInputs.minuto_inicio, tiempoInputs.segundo_inicio, tiempoInputs.sancion]);

    useEffect(() => {
        tiempoInputs.descalificado == 'yes' ? tiempoInputs.descalificado = null : tiempoInputs.descalificado = 'yes';
    }, [descalificado])

    const handleOnChange = event => {
        const { name, value } = event.target;
        let temp = value; // Mantén el valor actual
        setProvisionalCheck(false);
        setConfirmadoCheck(false);
        // Verifica si el nombre del campo incluye 'segundo' y si el valor excede 59
        if (name.includes('segundo') && parseInt(value, 10) > 59) {
            temp = '59'; // Ajusta el valor a 59 si excede 59
        }
        if (name.includes('minuto') && parseInt(value, 10) > 59) {
            temp = '59'; // Ajusta el valor a 59 si excede 59
        }
        if (name.includes('hora') && parseInt(value, 10) > 24) {
            temp = '24'; // Ajusta el valor a 59 si excede 59
        }
        if (name.includes('milesima') && parseInt(value, 10) > 99) {
            temp = '99'; // Ajusta el valor a 59 si excede 59
        }

        setTiempoInputs(prevState => ({
            ...prevState,
            [name]: temp // Actualiza solo el campo correspondiente
        }));
    };

    const handleCheckboxChange = (label, value, id) => {
        setConfirmadoCheck(false);
        setCheckedItems({
            ...checkedItems,
            [label]: !checkedItems[label] // Toggle the state of the checkbox
        });
        const isChecked = penalizacionesState.some(pen => pen.id === id);
        switch (id) {
            case id = 'D1':
            case id = 'D2':
            case id = 'E3':
            case id = 'E4':
                descalificado ? setDescalificado(null) : setDescalificado('yes');
                if (isChecked) {
                    // Si ya está presente, restar la penalización
                    setPenalizacionesState(prevState =>
                        prevState.filter(pen => pen.id !== id)
                    );
                    tiempoInputs.sancion = tiempoInputs.sancion - value;

                } else {
                    // Si no está presente, añadir la penalización
                    setPenalizacionesState(prevState => [...prevState, { id, label, value }]);
                    tiempoInputs.sancion = tiempoInputs.sancion + value;

                }
                break;
            case id = 'A5':
            case id = 'A6':
            case id = 'A7':
            case id = 'A8':
            case id = 'A9':
            case id = 'A10':
            case id = 'B11':
            case id = 'B12':
            case id = 'B13':
                if (isChecked) {
                    // Si ya está presente, restar la penalización
                    setPenalizacionesState(prevState =>
                        prevState.filter(pen => pen.id !== id)
                    );
                    tiempoInputs.sancion = tiempoInputs.sancion - value;

                } else {
                    // Si no está presente, añadir la penalización
                    setPenalizacionesState(prevState => [...prevState, { id, label, value }]);
                    tiempoInputs.sancion = tiempoInputs.sancion + value;

                }
                break;
            case id = 'B14':
                if (isChecked) {
                    setPenalizacionesState(prevState =>
                        prevState.filter(pen => pen.id !== id)
                    );
                    tiempoInputs.sancion = tiempoInputs.sancion - sancionPerson.sancion;
                } else {
                    let sancion = sancionPerson.sancion;
                    setPenalizacionesState(prevState => [...prevState, { id, label, sancion }]);
                    tiempoInputs.sancion = tiempoInputs.sancion + sancionPerson.sancion;
                }
                break;
            default:
                break;
        }
    }

    const decodificar = () => {
        let tiempoProvisional = tiempoInputs.tiempo_provisional;
        let temp = convertirSegundosAHorasMinutosSegundos(tiempoProvisional);

        // Extraer horas, minutos y segundos de la cadena de tiempo provisional
        let hora = parseInt(temp.substring(0, 2), 10);
        let minutos = parseInt(temp.substring(3, 5), 10);
        let segundos = parseInt(temp.substring(6, 8), 10);

        // Calcular tiempo final sumando el tiempo inicial
        let horaFin = hora + tiempoInputs.hora_inicio;
        let minutosFin = minutos + tiempoInputs.minuto_inicio;
        let segundosFin = segundos + tiempoInputs.segundo_inicio;

        // Manejar casos donde los valores superan los límites
        if (segundosFin >= 60) {
            segundosFin -= 60;
            minutosFin++;
        }
        if (minutosFin >= 60) {
            minutosFin -= 60;
            horaFin++;
        }

        // Asignar los valores finales al objeto tiempoInputs
        tiempoInputs.hora_fin = horaFin;
        tiempoInputs.minuto_fin = minutosFin;
        tiempoInputs.segundo_fin = segundosFin;

    };
    const handleSancionPersonalizadaChange = (event) => {
        const value = parseInt(event.target.value);
        let temp = { ...sancionPerson };
        temp.sancion = value;
        setSancionPerson(temp);
    };
    //funciones del usuario
    const enviarTiempoProvisional = () => {
        //añadira tiempo en la tabla tiempo y la hora del inicio de la maquina


        if (tiempoInputs.tiempo_provisional > 0) {
            let temp = { ...tiempoInputs }
            let milesimas = temp.milesimas_inicio * 1 - 1 * temp.milesimas_fin;
            if (milesimas < 0) {

                temp.decimas = -milesimas;
            } else if (milesimas > 0) {
                temp.decimas = 100 - milesimas;
            }

            axios.post("https://www.transbetxi.com/WS/tiempoprovisional.php", temp)
                .then(function (response) {
                    console.log("respuestaProvisional",response)
                    if(response.data.success == true){
                        setProvisionalCheck(true);
                        let temp = {...tiempoInputs};
                        temp.codigoTiempoAux = response.data.tiempoAux;
                        temp.codigoTiempoProvisional = response.data.tiempoId;
                        setTiempoInputs(temp);
                    }
                })
                .catch(function (error) {
                    console.error(error);
                })
        } else {
            Swal.fire({
                icon: "error",
                title: "El tiempo provisional no puede ser menor a 0",
                showConfirmButton: false,
                timer: 1500
            });
        }

    }

    const enviarTiempoFinal = () => {
        //añadira sanciones al provisional
        if (tiempoInputs.tiempo_provisional > 0) {

            let tiempoInputsTMP = { ...tiempoInputs };
            tiempoInputsTMP.penalizaciones = penalizacionesState;
            tiempoInputsTMP.descalificado = descalificado;
            tiempoInputsTMP.confirmado = 1;

            axios.post("https://www.transbetxi.com/WS/tiempoconfirmado.php", tiempoInputsTMP)
                .then(function (response) {
                    if(response.data.success == true){
                    console.log("respuesConfirmado",response)
                    setConfirmadoCheck(true);

                    }
                })
                .catch(function (error) {
                    console.error(error);
                })

        } else {
            Swal.fire({
                icon: "error",
                title: "El tiempo provisional no puede ser menor a 0",
                showConfirmButton: false,
                timer: 1500
            });
        }
    }

    return (
        <Grid
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            xs={12} md={12}
            style={{ marginTop: '1em' }}
            className='flex-center fade-in'
        >
            <Grid container xs={11} justifyContent="center" alignItems="center">
                <div className={descalificado == 'yes' ? "tiempo-ind descalif" : estado === 'primerTiempo' ? 'tiempo-ind bl' : estado === 'segundTiempo' ? 'tiempo-ind yw' : 'tiempo-ind'} style={{ textAlign: 'center' }}>
                    <div className='column'>
                        {/* <div>Nombre</div>
                        <div>{tiempoInputs.nombre}</div> */}
                        <div>Dorsal</div>
                        <div>{equipo.dorsal}</div>
                    </div>
                    <div className="tiempo-inicio-container">

                        <div className='column'>

                            <div>H</div>
                            <input type="number"
                                onChange={handleOnChange}
                                name='hora_inicio'
                                value={tiempoInputs.hora_inicio}
                                id="outlined-basic"
                                variant="outlined"
                                className='field-tiempo'
                                onWheel={(e) => e.target.blur()}
                            />
                        </div>
                        <div className='column'>
                            <div>M</div>

                            <input type="number"
                                onChange={handleOnChange}
                                name='minuto_inicio'
                                value={tiempoInputs.minuto_inicio}
                                id="outlined-basic"
                                variant="outlined"
                                className='field-tiempo'
                                onWheel={(e) => e.target.blur()}

                            />
                        </div>
                        <div className='column'>
                            <div>S</div>
                            <input type="number"
                                onChange={handleOnChange}
                                name='segundo_inicio'
                                value={tiempoInputs.segundo_inicio}
                                id="outlined-basic"
                                variant="outlined"
                                className='field-tiempo'
                                max="59"
                                onWheel={(e) => e.target.blur()}

                            />
                        </div>
                        {/* <div className='column'>
                            <div>MS</div>
                            <input type="number"
                                onChange={handleOnChange}
                                name='milesimas_inicio'
                                value={tiempoInputs.milesimas_inicio}
                                id="outlined-basic"
                                variant="outlined"
                                className='field-tiempo'

                            />
                        </div> */}
                    </div>
                    <div className="tiempo-inicio-container">

                        <div className='column'>
                            <div>H</div>

                            <input type="number"
                                onChange={handleOnChange}
                                name='hora_fin'
                                value={tiempoInputs.hora_fin}
                                id="outlined-basic"
                                variant="outlined"
                                className='field-tiempo'
                                onWheel={(e) => e.target.blur()}

                            />
                        </div>
                        <div className='column'>
                            <div>M</div>
                            <input type="number"
                                onChange={handleOnChange}
                                name='minuto_fin'
                                value={tiempoInputs.minuto_fin}
                                id="outlined-basic"
                                variant="outlined"
                                className='field-tiempo'
                                onWheel={(e) => e.target.blur()}

                            />
                        </div>
                        <div className='column'>
                            <div>S</div>
                            <input type="number"
                                onChange={handleOnChange}
                                name='segundo_fin'
                                value={tiempoInputs.segundo_fin}
                                id="outlined-basic"
                                variant="outlined"
                                className='field-tiempo'
                                onWheel={(e) => e.target.blur()}

                            />
                        </div>
                        <div className='column'>
                            <div>MS</div>
                            <input type="number"
                                onChange={handleOnChange}
                                name='milesimas_fin'
                                value={tiempoInputs.milesimas_fin}
                                id="outlined-basic"
                                variant="outlined"
                                className='field-tiempo'
                                onWheel={(e) => e.target.blur()}

                            />
                        </div>
                    </div>

                    <div className='column'>
                        <div>PROVISIONAL</div>
                        <input type="text"
                            onChange={handleOnChange}
                            name='tiempo_provisional'
                            value={convertirSegundosAHorasMinutosSegundos(tiempoInputs.tiempo_provisional, tiempoInputs.milesimas_fin, tiempoInputs.milesimas_inicio)}
                            id="outlined-basic"
                            variant="outlined"
                            className={provisionalCheck ? 'field-tiempo gr' : 'field-tiempo '}
                        />

                    </div>
                    <div className='column'>
                        <button
                            type="number"
                            onChange={handleOnChange}
                            name='tiempo_provisional'
                            id="outlined-basic"
                            className='button-tiempo'
                            onClick={() => enviarTiempoProvisional()}
                        >
                            Confirmar Provisional
                        </button>
                    </div>
                    <div className='column'>
                        <button
                            onClick={() => setOpen(true)}
                            name='abrir'
                            id="outlined-basic"
                            className='button-penalizaciones'
                        >
                            Penalizaciones
                        </button>
                    </div>
                    <Dialog
                        open={open}
                        onClose={() => setOpen(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <div className="penalizaciones-card">
                            <div className='column'>

                                <h3>PENALIZACIONES Dorsal {equipo.dorsal}</h3>

                                <FormGroup>

                                    {penalizaciones.map((penalizacion, index) => (
                                        <FormControlLabel
                                            key={index}
                                            control={
                                                <>
                                                    <Checkbox
                                                        checked={checkedItems[penalizacion.label]}
                                                        onChange={() => handleCheckboxChange(penalizacion.label, parseInt(penalizacion.sancion) || 0, penalizacion.id)} // Pass the label and value to the handler function
                                                    />
                                                </>
                                            }
                                            label={penalizacion.label}
                                        />))}

                                    <div className='column'>
                                        <div>Segundos Personalizado</div>
                                        <input
                                            type="number"
                                            onChange={handleSancionPersonalizadaChange}
                                            name='personalizadaSancion'
                                            value={sancionPerson.sancion}
                                            id="outlined-basic"
                                            variant="outlined"
                                            className='field-tiempo'
                                        />
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
                    </Dialog>
                    {/* <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={personName}
                            onChange={handleChange}
                            input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                            className='field-tiempo'

                        >
                            {names.map((name) => (
                                <MenuItem key={name} value={name}>
                                    <Checkbox checked={personName.indexOf(name) > -1} />
                                    <ListItemText primary={name} />
                                </MenuItem>
                            ))}
                        </Select> */}
                    <div className='column'>
                        <div>CONFIRMADO</div>
                        <input type="text"
                            onChange={handleOnChange}
                            name='tiempo_confirmado'
                            value={convertirSegundosAHorasMinutosSegundos(tiempoInputs.tiempo_provisional + tiempoInputs.sancion + penalizaciones[13].sancion, tiempoInputs.milesimas_fin, tiempoInputs.milesimas_inicio)}
                            id="outlined-basic"
                            variant="outlined"
                            className={confirmadoCheck ? 'field-tiempo gr' : 'field-tiempo '}
                        />
                    </div>
                    <div className='column'>
                        <button
                            type="number"
                            onClick={() => enviarTiempoFinal()}
                            name='tiempo_provisional'
                            id="outlined-basic"
                            className='button-tiempo'
                        >
                            Confirmar tiempo
                        </button>
                    </div>
                </div>
            </Grid>
        </Grid >

    )
}
