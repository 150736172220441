import React, { useEffect } from 'react';
import { HeaderEn } from '../../components/HeaderEn';
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';

export const PoliticaCookiesEn = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='fade-in'>
            <HeaderEn></HeaderEn>

            <Grid container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={12}
            >
                <Grid container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    xs={10} sm={10} md={6}
                    style={{ color: '#EAC474' }}
                >
                    <h1>What are cookies?</h1>
                    <p>In English, the term “cookie” means cookie, but in the field of web browsing, a “cookie” is something completely different. When you access our Website, a small amount of text called a “cookie” is stored in your device's browser. This text contains various information about your browsing, habits, preferences, content customizations, etc...</p>
                    <p>There are other technologies that work in a similar way and are also used to collect data about your browsing activity. We will call all these technologies together “cookies.”</p>
                    <p>The specific uses we make of these technologies are described in this document.</p>

                    <h1>What are cookies used for on this website?</h1>
                    <p>Cookies are an essential part of how the Website works. The main objective of our cookies is to improve your browsing experience. For example, to remember your preferences (language, country, etc.) during browsing and on future visits. The information collected in cookies also allows us to improve the website, adapt it to your interests as a user, speed up the searches you perform, etc.</p>
                    <p>In certain cases, if we have obtained your prior informed consent, we may use cookies for other uses, such as to obtain information that allows us to show you advertising based on the analysis of your browsing habits.</p>
                    <h1>¿Para qué NO se utilizan las cookies en esta web?</h1>
                    <p>En las cookies que utilizamos no se almacena información sensible de identificación personal como su nombre, dirección, tu contraseña, etc…</p>

                    <h1>Who uses the information stored in cookies?</h1>
                    <p>The information stored in the cookies on our Website is used exclusively by us, with the exception of those identified below as “third-party cookies”, which are used and managed by external entities that provide us with services that improve the user experience. user. For example, the statistics that are collected on the number of visits, the content that is most liked, etc... </p>

                    <h1>How can you prevent the use of cookies on this Website?</h1>
                    <p>If you prefer to avoid the use of cookies, you can REJECT their use or you can CONFIGURE the ones you want to avoid and the ones you allow to use (in this document we give you expanded information regarding each type of cookie, its purpose, recipient, temporality , etc… ).</p>
                    <p>If you have accepted them, we will not ask you again unless you delete the cookies on your device as indicated in the following section. If you want to revoke your consent you will have to delete the cookies and configure them again.</p>

                    <h1>How do I disable and eliminate the use of cookies?</h1>
                    <p>To restrict, block or delete cookies on this Website (and those used by third parties) you can do so, at any time, by modifying your browser settings. Please note that these settings are different in each browser.</p>
                    <p>In the following links you will find instructions to enable or disable cookies in the most common browsers.</p>
                    <ul>
                        <li><a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we" target="_blank" style={{ color: '#EAC474' }}>Firefox</a></li>
                        <li><a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank" style={{ color: '#EAC474' }}>Google Chrome</a></li>
                        <li><a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies#ie=%22ie-10" target="_blank" style={{ color: '#EAC474' }}>Internet Explorer</a></li>
                        <li><a href="https://support.microsoft.com/es-es/help/4468242/microsoft-edge-browsing-data-and-privacy" target="_blank" style={{ color: '#EAC474' }}>Microsoft Edge</a></li>
                        <li><a href="http://support.apple.com/kb/HT1677?viewlocale=es_ES" target="_blank" style={{ color: '#EAC474' }}>Safari</a></li>
                    </ul>

                    <h1>What types of cookies are used on this website?</h1>
                    <p>Each website uses its own cookies. On our website we use the following:</p>

                    <h2>ACcording to the entity that manages it</h2>
                    <p>These are those that are sent to the User's terminal equipment from a computer or domain managed by the editor itself and from which the service requested by the User is provided.</p>

                    <strong>Third-party cookies:</strong>
                    <p>These are those that are sent to the User's terminal equipment from a computer or domain that is not managed by the editor, but by another entity that processes the data obtained through cookies.</p>
                    <p>In the event that the cookies are served from a computer or domain managed by the editor itself, but the information collected through them is managed by a third party, they cannot be considered as own cookies if the third party uses them to its own purposes (for example, the improvement of the services it provides or the provision of advertising services in favor of other entities).</p>

                    <h2>ACcording to its purpose</h2>
                    <strong>Technical cookies:</strong>
                    <p>They are those necessary for the navigation and proper functioning of our Website, such as, for example, controlling traffic and data communication, identifying the session, accessing restricted access parts, making the request for registration or participation in an event, count visits for the purposes of billing licenses for the software with which the Website service works, use security elements during navigation, store content for the dissemination of videos or sound, enable dynamic content (for example, animation loading a text or image).</p>

                    <strong>Analysis cookies:</strong>
                    <p>They allow the number of users to be quantified and thus carry out the measurement and statistical analysis of the use made by users of the Website.</p>
                    <strong>Preferences or personalization cookies:</strong>
                    <p>These are those that allow the User to remember information so that they can access the service with certain characteristics that can differentiate their experience from that of other users, such as, for example, the language, the number of results to be displayed when the User performs a search. , the appearance or content of the service depending on the type of browser through which the User accesses the service or the region from which the User accesses the service, etc.</p>

                    <h2>ACcording to the period of time they remain activated</h2>

                    <strong>Session cookies:</strong>
                    <p>They are those designed to collect and store data while the User accesses a web page.</p>
                    <p>They are usually used to store information that is only interesting to keep for the provision of the service requested by the User on a single occasion (for example, a list of products purchased) and disappear when the session ends.</p>

                    <strong>Persistent cookies:</strong>
                    <p>These are those in which the data is still stored in the terminal and can be accessed and processed during a period defined by the person responsible for the cookie, which can range from a few minutes to several years. In this regard, it must be specifically assessed whether the use of persistent cookies is necessary, since the risks to privacy could be reduced by the use of session cookies. In any case, when persistent cookies are installed, it is recommended to reduce their temporary duration to the minimum necessary taking into account the purpose of their use. For these purposes, Opinion 4/2012 of the WG29 indicated that for a cookie to be exempt from the duty of informed consent, its expiration must be related to its purpose. Because of this, session cookies are much more likely to be considered excepted than persistent ones.</p>

                    <strong>Details of cookies used on this website:</strong>
                    <strong><p>Data of the website visited</p></strong>
                    <small>The website may save the language you prefer or the items you want to purchase. This information is available for the website and its subdomains</small>
                </Grid>
            </Grid>


            <PiePagina></PiePagina>
        </div>
    )
}
