import React from 'react'
import { useNavigate } from 'react-router-dom';
import { HeaderHomeVal } from '../../components/HeaderHomeVal'
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';
import portapapeles from '../../media/icons/portapapeles1.png';
import mapa from '../../media/icons/mapa1.png';
import casco from '../../media/icons/casco.png';
import crono from '../../media/icons/cronografo-1.png';
import libro from '../../media/icons/libro-de-normas.png';
import inscripcion from '../../media/icons/inscribirse.png';
import bar from '../../media/icons/tenedor.png';
import historia from '../../media/icons/libro.png';


export const HomeVal = () => {


    const Navigate = useNavigate();

    return (
        <Grid className="fade-in">
            <HeaderHomeVal></HeaderHomeVal>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}
                direction="column"
                justifyContent="center"
                alignItems="center">
                <Grid container
                    direction="column"
                    // spacing={{ xs: 2, md: 3 }} 
                    // columns={{ xs: 4, sm: 8, md: 12 }}
                    style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: '#2E2E2E', paddingTop: '5em' }}>
                    <Grid container
                        xs={10} md={8}
                        style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                        <h1>BENVINGUTS AL RAL·LI DE BETXÍ!</h1>
                        <h1>COMPETICIÓ, DIVERSIÓ, MOTOR, I SOBRETOT, AMISTAT I COMPANYERISME</h1>
                    </Grid>
                    <Grid container xs={10} md={4} direction="column" justifyContent="center" alignItems="center" style={{ marginTop: '2em', marginBottom: '1em', marginLeft: '1em', textAlign: 'center' }}>
                        <h2>GRÀCIES A TOTS ELS PATROCINADORS I COL·LABORADORS PEL SUPORT I TREBALL AQUESTS DIES</h2>
                    </Grid>
                    <Grid container xs={10} md={4} direction="column" justifyContent="center" alignItems="center" style={{ marginTop: '2em', marginBottom: '1em', marginLeft: '1em' }}>
                        <img src="https://www.transbetxi.com/img/patros2024.png" alt="" />
                    </Grid>
                    <Grid container
                        xs={10} md={8}
                        style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                        <h2>ORGANITZAT PER</h2>
                    </Grid>
                    <Grid container xs={10} md={4} direction="column" justifyContent="center" alignItems="center" style={{ marginTop: '2em', marginBottom: '1em', marginLeft: '1em' }}>
                        <img src="https://www.transbetxi.com/img/betxi-logo.png" alt="" />
                    </Grid>
                    <Grid container xs={10} md={4} direction="column" justifyContent="center" alignItems="center" style={{ marginTop: '2em', marginBottom: '1em', marginLeft: '1em' }}>
                        <img src="https://www.transbetxi.com/img/cartel_transbetxi_2024.jpeg" alt="" />
                    </Grid>

                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    // spacing={{ xs: 2, sm: 2, md: 3, ln: 4 }}
                    // columns={{ xs: 2, sm: 2, md: 1, ln: 4 }}
                    style={{ backgroundColor: '#2E2E2E', paddingTop: '5em', paddingBottom: '1em', margin: '-1px' }}
                >
                    <Grid container direction="column" xs={8} md={4} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                        <img src={mapa} alt="Icon" style={{ minWidth: '1em', maxWidth: '5em' }} />
                        <h1>MAPA DELS TRAMS</h1>
                        <strong>Consulta la ubicació i el recorregut dels trams, a més de poder veure en temps real el teu equip favorit.</strong>
                        <div class="sirk2" onClick={() => Navigate('/mapas')}>
                            <a class="element-custom">Mapes</a>
                        </div>
                    </Grid>
                    <Grid container direction="column" xs={8} md={4} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                        <img src={portapapeles} alt="Icon" style={{ minWidth: '1em', maxWidth: '5em' }} />
                        <h1>PROGRAMA ACTIVITATS</h1>
                        <strong>Properament penjarem el Programa d'activitats del transbetxi 2024.</strong>
                        <div class="sirk2" onClick={() => Navigate('/actividades')}>
                            <a class="element-custom">Activitats</a>
                        </div>
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    // spacing={{ xs: 2, sm: 2, md: 3, ln: 4 }}
                    // columns={{ xs: 2, sm: 2, md: 1, ln: 4 }}
                    style={{ backgroundColor: '#2E2E2E', paddingTop: '1em', paddingBottom: '1em', margin: '-1px' }}
                >
                    <Grid container direction="column" xs={8} md={4} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                        <img src={bar} alt="Icon" style={{ minWidth: '1em', maxWidth: '5em' }} />
                        <h1>ON MENJAR</h1>
                        <strong>Bars i restaurants per a menjar durant els dies del ral·li.</strong>
                        <div class="sirk2" onClick={() => Navigate('/donde-comer')}>
                            <a class="element-custom">On menjar</a>
                        </div>
                    </Grid>
                    <Grid container direction="column" xs={8} md={4} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                        <img src={historia} alt="Icon" style={{ minWidth: '1em', maxWidth: '5em' }} />
                        <h1>HISTÒRIA</h1>
                        <strong>Coneix d'on ve el ral·li Transbetxi i les seues arrels.</strong>
                        <div class="sirk2" onClick={() => Navigate('/historia')}>
                            <a class="element-custom">Història</a>
                        </div>
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    // spacing={{ xs: 2, sm: 2, md: 3, ln: 4 }}
                    columns={{ xs: 2, sm: 2, md: 1, ln: 4 }}
                    style={{ backgroundColor: '#2E2E2E', marginTop: '0em', paddingTop: '1em', paddingBottom: '1em', margin: '-1px' }}
                >
                    <Grid container direction="column" xs={8} md={4} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                        <img src={casco} alt="Icon" style={{ minWidth: '1em', maxWidth: '5em' }} />
                        <h1>EQUIPS</h1>
                        <strong>Consulta tota la informació del teu equip preferit.</strong>
                        <div class="sirk2" onClick={() => Navigate('/equipos')}>
                            <a class="element-custom">Equips</a>
                        </div>
                    </Grid>
                    <Grid container direction="column" xs={8} md={4} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                        <img src={crono} alt="Icon" style={{ minWidth: '1em', maxWidth: '5em' }} />
                        <h1>CLASSIFICACIÓ</h1>
                        <strong>Aquest any estrenem aplicació web de classificació, on podreu consultar tant els temps d'aquest ral·li com dels anteriors.</strong>
                        <br />
                        <strong>No oblidis que alguns temps d'aquest ral·li són provisionals i pugen orientativament fins que es confirmin.</strong>
                        <div class="sirk2" onClick={() => Navigate('/tiempos')}>
                            <a class="element-custom">Classificació</a>
                        </div>
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    // spacing={{ xs: 2, sm: 2, md: 3, ln: 4 }}
                    columns={{ xs: 2, sm: 2, md: 1, ln: 4 }}
                    style={{ backgroundColor: '#2E2E2E', marginTop: '0em', paddingTop: '1em', paddingBottom: '1em', margin: '-1px' }}
                >
                    <Grid container direction="column" xs={8} md={4} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                        <img src={inscripcion} alt="Icon" style={{ minWidth: '1em', maxWidth: '5em' }} />
                        <h1>INSCRIPCIONS</h1>
                        <strong>Aviat s'indicaran els passos necessaris per inscriure's al ral·li.</strong>
                        <div class="sirk2" onClick={() => Navigate('/inscripciones')}>
                            <a class="element-custom">Inscripcions</a>
                        </div>
                    </Grid>
                    <Grid container direction="column" xs={8} md={4} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                        <img src={libro} alt="Icon" style={{ minWidth: '1em', maxWidth: '5em' }} />
                        <h1>REGLAMENTS</h1>
                        <strong>En aquest apartat podreu trobar tant el reglament tècnic com el reglament de la prova.</strong>
                        <div class="sirk2" onClick={() => Navigate('/reglamentos')}>
                            <a class="element-custom">Reglaments</a>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <PiePagina></PiePagina>
        </Grid>

    )
}
