import React, { useEffect } from 'react';
import { HeaderVal } from '../../components/HeaderVal';
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';

export const PoliticaCookiesVal = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div className='fade-in'>
            <HeaderVal></HeaderVal>

            <Grid container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={12}
            >
                <Grid container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    xs={10} sm={10} md={6}
                    style={{ color: '#EAC474' }}
                >
                    <h1>Què són les cookies?</h1>
                    <p>En anglès, el terme “cookie” significa galeta, però en l'àmbit de la navegació web, una “cookie” és una cosa completament diferent. Quan accediu al nostre Lloc Web, al navegador del dispositiu s'emmagatzema una petita quantitat de text que s'anomena “cookie”. Aquest text conté informació variada sobre la vostra navegació, hàbits, preferències, personalitzacions de continguts, etc…</p>
                    <p>Hi ha altres tecnologies que funcionen de manera similar i que també es fan servir per recopilar dades sobre la teva activitat de navegació. Anomenarem “cookies” a totes aquestes tecnologies en el seu conjunt.</p>
                    <p>Els usos concrets que fem d'aquestes tecnologies es descriuen en aquest document.</p>

                    <h1>Per què s'utilitzen les galetes en aquesta web?</h1>
                    <p>Les galetes són una part essencial de com funciona el Lloc Web. L'objectiu principal de les nostres galetes és millorar la seva experiència en la navegació. Per exemple, per recordar les vostres preferències (idioma, país, etc.) durant la navegació i en futures visites. La informació recollida a les cookies ens permet a més millorar la web, adaptar-la als seus interessos com a usuari, accelerar les cerques que realitzi, etc.</p>
                    <p>En determinats casos, si hem obtingut el previ consentiment informat, podrem utilitzar cookies per a altres usos, com per exemple per obtenir informació que ens permeti mostrar publicitat basada en l'anàlisi dels seus hàbits de navegació.</p>

                    <h1>Per què NO s'utilitzen les cookies en aquesta web?</h1>
                    <p>A les galetes que utilitzem no s'emmagatzema informació sensible d'identificació personal com el nom, l'adreça, la contrasenya, etc…</p>

                    <h1>Qui utilitza la informació emmagatzemada a les cookies?</h1>
                    <p>La informació emmagatzemada a les cookies del nostre Lloc Web és utilitzada exclusivament per nosaltres, a excepció d'aquelles identificades més endavant com a “cookie de tercers”, que són utilitzades i gestionades per entitats externes que ens proporcionen serveis que milloren l'experiència del usuari. Per exemple, les estadístiques que es recullen sobre el nombre de visites, el contingut que més agrada, etc…</p>

                    <h1>Com podeu evitar l'ús de cookies en aquest Lloc Web?</h1>
                    <p>Si preferiu evitar l'ús de les galetes, podeu REBUIXAR-ne l'ús o podeu CONFIGURA'N les que voleu evitar i les que permeteu utilitzar (en aquest document us donem informació ampliada sobre aquest tipus de galeta, la finalitat, destinatari, temporalitat , etc… ).</p>
                    <p>Si les heu acceptat, no tornarem a preguntar-vos a menys que esborreu les galetes al vostre dispositiu segons s'indica a l'apartat següent. Si voleu revocar el consentiment haureu d'eliminar les cookies i tornar-les a configurar.</p>

                    <h1>Com deshabilito i elimini la utilització de cookies?</h1>
                    <p>Per restringir, bloquejar o esborrar les galetes d'aquest Lloc Web (i les utilitzades per tercers) ho podeu fer, en qualsevol moment, modificant la configuració del vostre navegador. Tingueu en compte que aquesta configuració és diferent a cada navegador.</p>
                    <p>Als següents enllaços trobareu instruccions per habilitar o deshabilitar les cookies en els navegadors més comuns.</p>
                    <ul>
                        <li><a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we" target="_blank" style={{ color: '#EAC474' }}>Firefox</a></li>
                        <li><a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank" style={{ color: '#EAC474' }}>Google Chrome</a></li>
                        <li><a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies#ie=%22ie-10" target="_blank" style={{ color: '#EAC474' }}>Internet Explorer</a></li>
                        <li><a href="https://support.microsoft.com/es-es/help/4468242/microsoft-edge-browsing-data-and-privacy" target="_blank" style={{ color: '#EAC474' }}>Microsoft Edge</a></li>
                        <li><a href="http://support.apple.com/kb/HT1677?viewlocale=es_ES" target="_blank" style={{ color: '#EAC474' }}>Safari</a></li>
                    </ul>

                    <h1>Quins tipus de cookies s'utilitzen en aquesta pàgina web?</h1>
                    <p>Cada pàgina web utilitza les seves pròpies galetes. A la nostra web utilitzem les que s'indiquen a continuació:</p>

                    <h2>SEGUN L'ENTITAT QUE HO GESTIONA</h2>
                    <p>Són aquelles que s'envien a l'equip terminal de l'Usuari des d'un equip o domini gestionat pel mateix editor i des del qual es presta el servei sol·licitat per l'Usuari.</p>

                    <strong>Cookies de tercers:</strong>
                    <p>Són aquelles que s'envien a l'equip terminal de l'Usuari des d'un equip o domini que no és gestionat per l'editor, sinó per una altra entitat que tracta les dades obtingudes a través de les cookies.</p>
                    <p>En el cas que les cookies siguin servides des d'un equip o domini gestionat pel mateix editor, però la informació que es reculli mitjançant aquestes sigui gestionada per un tercer, no poden ser considerades com a cookies pròpies si el tercer les utilitza per a finalitats (per exemple, la millora dels serveis que presta o la prestació de serveis de caràcter publicitari a favor d'altres entitats).</p>

                    <h2>SEGUN LA SEVA FINALITAT</h2>
                    <strong>Cookies tècniques:</strong>
                    <p>Són aquelles necessàries per a la navegació i el bon funcionament del nostre Lloc Web, com per exemple, controlar el trànsit i la comunicació de dades, identificar la sessió, accedir a parts d'accés restringit, fer la sol·licitud d'inscripció o participació a un esdeveniment, comptar visites als efectes de la facturació de llicències del programari amb què funciona el servei del Lloc Web, utilitzar elements de seguretat durant la navegació, emmagatzemar continguts per a la difusió de vídeos o so, habilitar continguts dinàmics (per exemple, animació de càrrega d'un text o imatge).</p>

                    <strong>Cookies d'anàlisi:</strong>
                    <p>Permeten quantificar el nombre d'usuaris i així realitzar el mesurament i l'anàlisi estadística de la utilització que fan els usuaris del Lloc Web.</p>

                    <strong>Cookies de preferències o personalització:</strong>
                    <p>Són aquelles que permeten recordar informació perquè l'Usuari accedeixi al servei amb determinades característiques que poden diferenciar la seva experiència de la d'altres usuaris, com ara l'idioma, el nombre de resultats a mostrar quan l'Usuari fa una cerca , l'aspecte o el contingut del servei en funció del tipus de navegador a través del qual l'Usuari accedeix al servei o de la regió des de la qual accedeix al servei, etc.</p>

                    <h2>SEGUN EL TERMINI DE TEMPS QUE ROMANDEN ACTIVADES</h2>

                    <strong>Cookies de sessió:</strong>
                    <p>Són aquelles dissenyades per demanar i emmagatzemar dades mentre l'Usuari accedeix a una pàgina web.</p>
                    <p>Se solen emprar per emmagatzemar informació que només interessa conservar per a la prestació del servei sol·licitat per l'Usuari en una sola ocasió (per exemple, una llista de productes adquirits) i desapareixen en acabar la sessió.</p>

                    <strong>Cookies persistents:</strong>
                    <p>Són aquelles en què les dades segueixen emmagatzemades al terminal i poden ser accedides i tractades durant un període definit pel responsable de la galeta, i que pot anar d'uns minuts a diversos anys. Pel que fa a això, s'ha de valorar específicament si és necessària la utilització de cookies persistents, ja que els riscos per a la privadesa podrien reduir-se mitjançant la utilització de cookies de sessió. En tot cas, quan s'instal·lin galetes persistents, es recomana reduir al mínim necessari la seva durada temporal atenent a la finalitat del seu ús. A aquests efectes, el Dictamen 4/2012 del GT29 va indicar que perquè una galeta pugui estar exempta del deure de consentiment informat, la seva caducitat ha d'estar relacionada amb la finalitat. A causa d'això, és molt més probable que es considerin exceptuades les galetes de sessió que les persistents.</p>

                    <strong>Detall de cookies utilitzades en aquesta web:</strong>
                    <strong><p>Dades del lloc web visitat</p></strong>
                    <small>És possible que el lloc web guardi l'idioma que preferiu o els articles que voleu comprar. Aquesta informació està disponible per al lloc web i els seus subdominis</small>
                </Grid>
            </Grid>


            <PiePagina></PiePagina>
        </div>
    )
}
