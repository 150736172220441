import React, { useEffect } from 'react';
import { Header } from '../../components/Header';
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';
import { TablaActividadesEn } from '../tablaActividades/TablaActividadesEn';

export const ReglamentoPruebaEn = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div className="fade-in" >
            <Header></Header>
            <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', textAlign: 'center' }}>
                <h1>TEST REGULATIONS</h1>
            </Grid>

            <Grid container xs={12} md={12} direction="column" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', overflow: 'auto' }}>
                <Grid container xs={10} md={8} direction="column" justifyContent="center" alignItems="center">
                    <div style={{ maxWidth: '100%', color: '#EAC474', textAlign: 'left' }}>
                        <h3>PROLOGUE - DEFINITION OF THE CONCEPT OF ANTI-SPORTS ATTITUDE.</h3>
                        <p>Dear participants, with this introduction, are fervently aware that, in addition to having the rights and services that we assist, in participating in the TRANSBETXÍ rally, we also have a series of obligatory compliment rules. </p>
                        <p>These rules constitute a guarantee for the correct development of the course and we offer the assistance and the material treatment for all the teams that you take part in.</p>
                        <p>The organization, through the faculties that are attributed to the continguts of articles 11, 22 and 23 of the present regulation, against any fet or attitude that threatens security, integrity and assistance to the other participants, organizers and public in In general, on the part of a team or any person related to it, and for this “principle of unsportsmanlike attitude”, the reserve of the right will be made public to take all the measures that it deems appropriate, in order to correct or eliminate These acts and behaviors are mental.</p>
                        <p>For the benefit of all and agraint your collaboration, receive our greetings.</p>

                        <h3>01.- OFFICIAL SCHEDULE OF ACTIVITIES</h3>
                        <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
                            <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E' }}>
                            <TablaActividadesEn></TablaActividadesEn>
                        </Grid>
                        </Grid>

                        <h3>02.- ORGANIZATION, GENERAL PROVISIONS</h3>

                        <h4>Art.02-01 – GENERALITIES</h4>
                        <p>The organizing entity is preparing a sports event for motocultors that will be held on April 5, 6 and 7, 2024, named XXXIV RAL·LI TRANSBETXÍ 2024</p>

                        <h4>Art.02-02 - GENERALITIES APPLICATION REGULATIONS</h4>
                        <p>This checks the organization of the agreement with the regulations</p>
                        <ul>
                            <li>FMCV Sports Regulations</li>
                            <li>General Regulations for Enduro FMCV or RALLI TT</li>
                            <li>The present Particular Regulation.</li>
                        </ul>

                        <h4>Art.02- 03.- SEU OF THE ORGANIZATION COMMITTEE</h4>
                        <p>Organizing entity:</p>
                        <ul>
                            <li>Most illustrious BETXÍ CITY COUNCIL.</li>
                            <li>The secretary of the organizing committee will be located at the Betxí Town Hall. Tel. 964 620 002.</li>
                        </ul>
                        <h4>Art. 02-04.- OFFICIAL CAREERS</h4>
                        <p>judge / referee: Fco. Javier Ferrandis Bordería / Lic. 500547-RF</p>
                        <p>Technical Commissioner: Josep Manel Adsuara Vicent / Lic. In process</p>
                        <p>Course Director: David Brisach Miravet / Lic. In process</p>
                        <p>Timekeeper: Agustín Ferreres Beltrán / Lic. 622497-CS</p>

                        <h4>Art. 02-05.- OFFICIAL</h4>
                        <p>The organizer has two types of officers for probation.</p>

                        <h4>Art. 02-05-01 SPORTS OFFICIAL</h4>
                        <p>They are those people who have the power to make decisions about the operation of the course.
                            These officers will be distinguished from the rest in some way to make it easier for participants to resolve any problems or doubts.
                        </p>

                        <h4>Art. 02-05-02 ORGANIZATION OFFICERS</h4>
                        <p>They are those people responsible for all the extra sports tasks and necessaries to be able to carry out the test. (Flags, country controls, help personnel, etc...)
                            The official organizational chapter gives you the power to decide on the sports topic.
                            Yes, they can take advantage of their internal communication mechanisms so that a Sports Official can inform a participant about any question or doubt that is still necessary for the test.
                        </p>

                        <h4>Art. 02-06.- OFFICIAL ANNOUNCEMENTS BOARD – MODIFICATIONS</h4>
                        <p>All modifications or complementary provisions are to be presented to the participants as soon as possible, with complementary data and numbers that will form part of this Particular Regulation.
                            These complements, together with other resolutions, citations, penalties, and other documents, will be exposed to the Official Notice Board in a press room enabled by the public.
                        </p>
                        <p>All modifications will include the time of publication. </p>

                        <h3>03.- ROUTE</h3>
                        <h4>Art. 03-01.- ROUTE</h4>
                        <p>The XXXIV RALLY TRANSBETXÍ 2024, consists of a common route for all participants, with (6) timed trams, distributed as follows:</p>                        <table>
                            <thead>
                                <tr>
                                    <th>SATURDAY APRIL 15</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>09:30 H.</td>
                                    <td>TC1</td>
                                    <td>TIMED STRETCH</td>
                                </tr>
                                <tr>
                                    <td>10:30 a.m.</td>
                                    <td>TC2</td>
                                    <td>TIMED STRETCH</td>
                                </tr>
                                <tr>
                                    <td>15:30 H.</td>
                                    <td>TC3</td>
                                    <td>TIMED STRETCH</td>
                                </tr>
                                <tr>
                                    <td>16:30 H.</td>
                                    <td>TC4</td>
                                    <td>TIMED STRETCH</td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <table>
                            <thead>
                                <tr>
                                    <th>SUNDAY APRIL 16</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>09:30 H.</td>
                                    <td>TC5</td>
                                    <td>TIMED STRETCH</td>
                                </tr>
                                <tr>
                                    <td>10:30 a.m.</td>
                                    <td>TC6</td>
                                    <td>TIMED STRETCH</td>
                                </tr>
                            </tbody>
                        </table>

                        <p>The classification of the 2023 edition by categories will be used to establish the starting order for the stage on Saturday, April 6, 2024.
                            It is totally forbidden to make marks on traffic signs, hits, pavements, buildings or roadsides by contestants or other people related to them.
                        </p>
                        <p>On the other hand, also the training of the route carried out on the sidelines of the test by the teams from Sunday 31 March 2024, are PROHIBITED. The same complaint procedure and ex officio actions established in article 7 of this regulation are hereby established, which, in addition to any infraction reported by the Local Police or any other governmental body, implies the non-compliance of this article, considered as a VERY SERIOUS OFFENSE, contemplated in the disciplinary regime of this regulation.</p>

                        <h3>04.- REGISTRATION OF PARTICIPANTS</h3>
                        <h4>Art. 04-01.- ADMITTED VEHICLES</h4>
                        <p>Motor cultivators are admitted to participate in this test without exception, but it must be taken into account that they must be included within the following limitations and/or technical characteristics</p>
                        <ul>
                            <li>Be serial or traditional cultivators, evolved or not, that retain their brand's original engine (without external changes or liquid cooling), clutch and gearbox.</li>
                            <li>Other traction formulas are not accepted (except at the invitation of the organization) and, in general, it is required that all participating vehicles retain the rest of the characteristics of the traditional vehicle, such as the steering, the system of motor-carriage connection, etc.</li>
                        </ul>

                        <h4>Art. 04-02.- SECURITY MEASURES</h4>
                        <p>Each participating team, in order to be located, receive notifications, as well as being able to report accidents and other incidents, must carry a mobile phone with WhatsApp.
                            All participants must be equipped with the following protective equipment:
                        </p>

                        <ol>
                            <li>Approved enduro, cross or integral helmet (these are those that carry a specific number, (starting with 05, P or J) the helmet correctly placed and adjusted with the strap well passed). The numbering (labeling of the helmet) must be clearly visible.
                                A document with the regulations will be sent to all members to check if they are approved.
                                <ul>a. All will be checked and none will be accepted that is in poor condition, does not comply with the regulations or has a label in poor condition or illegible. Safety collar, rigid or orthopedic motocross collar is mandatory.</ul>
                            </li>
                            <li>Chest, shoulder and shoulder protector with elbow pads.</li>
                            <li>It will be mandatory for the integral breastplate or cuirass, with integrated elbow and shoulder pads. </li>
                            <li>It is recommended to have the features they must meet and check if they are compatible with the rigid collars, before buying or updating their protections.</li>
                            <li>Protective gloves (minimum required: occupational PPE for mechanical risks NP3). </li>
                            <li>Kneepads</li>
                            <li>Enduro or cross-country boots (required minimum: Half cane).</li>
                            <li>A fire extinguisher of 1kg of "ABC chemical powder" properly attached to the vehicle will be mandatory.</li>
                            <li><strong>The "man in the water" system is MANDATORY to stop the motorbike in the event of an accident </strong></li>
                            <li>During the entire route of the XXXIV TRANSBETXÍ rally 2024, the tillers must be protected with the roll bar. </li>
                            <li>They must protect all levers and tips with balls or protective elements. </li>
                            <li>Slabs or sheets with sharp corners must be covered with rubber or plastic profiles.</li>
                            <li><strong>13. If carrying a battery for the operation of the machine, the positive terminal must be covered to avoid coming into contact with the metal parts of the motor and being able to cause a spark that causes a fire.</strong></ li>
                        </ol>

                        <h4>Art. 04-03.- REGISTRATIONS</h4>
                        <p>Registration for the XXXIV TRANSBETXÍ RALLY 2024 is permitted for anyone over 18 years of age, holder of a sports license and who has completed the registration application for the test, duly signed on the form that will facilitate the organization and that the registration rights have been met.
                            Registration starts on February 18 and ends on March 16, 2024 at 9:00 p.m., and can be done every day on the <strong>www.transbetxi.com</strong> page.
                        </p>
                        <p>The correct registration procedure consists of the following parts and must be followed in the same order:</p>
                        <ol>
                            <li>Registration by the team of the pilot, co-pilot and machine on the form that the www.transbetxi.com page has in order to be able to do it.</li>
                            <li>Registration check for the official list of registrants.</li>
                            <p>It will be done, within the registration deadline, by sending the following documentation, duly signed and scanned, to transbetxi89@gmail.com:</p>
                            <strong>PILOT:</strong>
                            <ol>
                                <li>PRINTED COPY OF THE REGISTRATION MADE ON THE WEBSITE.</li>
                                <li>1 PHOTOCOPY AND ORIGINAL OF THE FEDERAL LICENSE (front and back).</li>
                                <li>1 PHOTOCOPY AND ORIGINAL OF THE NATIONAL IDENTITY DOCUMENT (front and back).</li>
                                <li>1 PHOTOCOPY AND ORIGINAL OF DRIVER'S LICENSE: B1 (front and back).</li>
                            </ol>
                            <strong>COPILOT:</strong>
                            <ol>
                                <li>PRINTED COPY OF THE REGISTRATION MADE ON THE WEBSITE.</li>
                                <li>1 PHOTOCOPY AND ORIGINAL OF THE FEDERAL LICENSE (front and back).</li>
                                <li>1 PHOTOCOPY AND ORIGINAL OF THE NATIONAL IDENTITY DOCUMENT (front and back).</li>
                                <li>1 PHOTOCOPY AND ORIGINAL OF THE DRIVER'S LICENSE: B1 (front and back) OR MEDICAL RECOGNIZATION IN FORCE FOR THE CURRENT YEAR.</li>
                            </ol>
                        </ol>
                        <p>The organization will provide correctly registered participants with the following documents:</p>
                        <ol>
                            <li>TEAM REGISTRATION SHEET. </li>
                            <li>COPY OF THE REGULATIONS.</li>
                        </ol>
                        <p>The amount, contemplated in article 10, must be paid at the time of official registration.
                            If there are changes to the registration, the team must communicate this by the deadline of March 24, 2024 and will attach all the data that the organization deems appropriate.
                        </p>
                        <ul>
                            <li>Tf. contact number 696598253. Ref.: Juanma Moreno.</li>
                            <li>Tf. contact number 666177845. Ref.: En Xavi Cabedo.</li>
                        </ul>
                        <p>Double registrations of vehicles and participants are prohibited.</p>

                        <h4>Art. 04-05.- REGISTRATION RIGHTS</h4>
                        <p>Registration fees are set at <strong>420.-Euros </strong>per vehicle (fees for federal licenses are included).
                            The registration cannot be accepted if it is not accompanied by the registration rights within the period set in these regulations.
                        </p>

                        <h4>Art. 04-06.- INSURANCE</h4>
                        <p>The organization reserves the right to complete the particular regulation and/or issue additional provisions or instructions, which will be an integral part of it. The right is also reserved to cancel or stop the test in case of force majeure or unforeseeable event, without any obligation of compensation.
                            All modifications and additional provisions will be known to the participants as soon as possible, through dated and numbered annexes and through the medium deemed appropriate.
                        </p>
                        <p>At least all modifications and additional provisions will be published on the Official Notice Board.</p>
                        <strong>(See: Art. 02-05.- OFFICIAL NOTICE BOARD - CHANGES)</strong>
                        <p>The organization reserves the right to refuse the registration of a team; due to defects in form and deadlines, lack of documentation provided, supposed to be covered by these regulations and other relevant issues, which may threaten the integrity, public image and safety of the race.
                            The Jury is authorized to decide on any case not foreseen in the Special Regulations of the test
                        </p>

                        <h3>05.- OBLIGATIONS OF PARTICIPANTS</h3>

                        <h4>Art. 05-01.- ATTENDANCE AT THE BRIEFING</h4>
                        <p>Attendance at the initial briefing on Wednesday, April 3, 2024 at 10:00 p.m. at the Betxí Municipal Auditorium is mandatory for all teams.
                            With the exception of cases duly justified and communicated to the organization, at least one member of the team (pilot, co-pilot or accredited team leader) must attend, collect all the prepared documentation and remain to listen to the description and warnings of the race route, the accreditations will also be handed out.
                        </p>

                        <h4>Art. 05-02.- NUMBERS</h4>
                        <p>For each participating motorcycle, the organization will provide a double set of numbers, which will be placed visibly on the sides of the vehicle, throughout the test.
                            Motorcycles that do not have the numbers, which are not correct or are not correctly placed, will not be authorized to take the exit.
                            The numbers must have, at all times, the original shape, size and composition.
                            No stickers or advertising may be cut or added to them
                            The granting of numbers to each vehicle is the responsibility of the organization.
                        </p>

                        <h4>Art. 05-03.- ADVERTISING</h4>
                        <p>Vehicles may be advertised at the choice of their drivers.
                            However, it is mandatory to wear the numbers provided by the organization.
                            Thus, two spaces of 20x20 cm will be reserved (one on each side), which will allow a perfect view of the sides of the vehicle.
                            The placement must be correct, that is to say, adhered to a flat surface, never curved, with a smooth metal support, completely attached to the side and without any protruding corners.
                            Failure to comply with this article by a participating team is considered a SERIOUS FAULT, contemplated in the disciplinary regime of this regulation.
                        </p>

                        <h4>Art. 05-04.- PRESENTATION AT THE EXIT </h4>
                        <p>Participants and their vehicles must present themselves in the Pre-race Closed Park and must be available to the organization at the time established in the Official Schedule.
                            Participants will assume the consequences of ignorance of provisions or schedule changes, which could be decided during the hour before the start of the test.
                            Participants will be placed in the exit line at least 10 minutes before their assigned exit time.
                            The motor cultivator who does not show up on time loses the right to the exit order in the stage.
                            For security reasons, this right is revoked when the exit row is formed, and even the offending team could be excluded from the competition, if the organization considers it so.
                            Failure to comply with this article by a participating team is considered a MINOR FAULT, contemplated in the disciplinary regime of this regulation.
                        </p>

                        <h4>Art. 05-06.- SIGNAGE. BEHAVIOR IN THE RACE.</h4>
                        <p>From their presentation in the Pre-Exit Park until the end of the test, participants must strictly follow the instructions of the organization.
                            It is absolutely forbidden to drive in the opposite direction to the race.
                            Any violation of this rule will result in immediate expulsion from the test. Failure to comply with this article by a team (participants or assistants) is considered a VERY SERIOUS OFFENSE, contemplated in the disciplinary regime of this regulation.
                        </p>
                        <p>In the event that a pilot has to interrupt his route, in a timed section or anywhere else, due to mechanical or other problems, he will immediately park the vehicle outside the road or track, so that he does not pose no problem for the rest of the participating vehicles and will carry out the relevant checks and repairs.
                        </p>
                        <p>In case of not being able to repair the motor on the section or in the place where it is: </p>
                        <ol>
                            <li>Inform the security delegate, David Brisach, of the state they are in and receive instructions on what to do. </li>
                            <li>At no time must they leave the motorbike and the place where they are, until they are authorized to do so by the security delegate. </li>
                        </ol>
                        <strong><small>Each of the vehicles caught from behind by a fast month in a timed section must slow down. And leave a clear path as soon as possible, always without creating any dangerous situation for anyone and at no time during the route they can split and thus recover their initial position.</small></strong>
                        <p>Breach of this article by a participating team is considered a MINOR FAILURE, contemplated in the disciplinary regime of this regulation.
                            It is not permitted for any participating vehicle to be dragged and/or pushed by another, regardless of its nature and the towing system.
                            Failure to comply with this article by a participating team is considered a SERIOUS FAULT, contemplated in the disciplinary regime of this regulation.
                        </p>

                        <h3>06.- TECHNICAL AND ADMINISTRATIVE CONTROL</h3>
                        <h4>Art. 06-01.- TECHNICAL AND ADMINISTRATIVE CONTROL</h4>
                        <p>The technical and administrative control will take place in the PTP closed park, Friday, April 5, 2024 from 6:00 p.m. until 8:00 p.m., with the gradual appearance of the vehicles, driver or co-driver, according to the schedule that the organization will make public in the form of a complementary provision in this regulation.
                            Failure to comply with this article by a participating team is considered a MINOR FAULT, contemplated in the disciplinary regime of this regulation.
                            This check will be carried out on the vehicle and the helmets; all of them will be validated with official signs, which must remain intact throughout the entire race. Failure to comply with this article by a participating team is considered a SERIOUS FAULT, contemplated in the disciplinary regime of this regulation.
                            At this control, the Official Race Road Book (Road Book) is given to all the participating teams. Where they will have a sheet to be able to present a claim; if they think it appropriate, following the procedure set out in the regulations.
                        </p>

                        <h3>TECHNICAL AND ADMINISTRATIVE CONTROL / APPEARANCE SHIFT</h3>
                        <big>
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ paddingRight: '10em' }}>HORA</th>
                                        <th>EQUIPMENT-BACK</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>18:00</td>
                                        <td>53 52 51 50 49 48</td>
                                    </tr>

                                    <tr>
                                        <td>18:15</td>
                                        <td>47	46	45	44	43	42</td>
                                    </tr>

                                    <tr>
                                        <td>18:30</td>
                                        <td>41	40	39	38	37	36</td>
                                    </tr>

                                    <tr>
                                        <td>18:45</td>
                                        <td>35	34	33	32	31	30</td>
                                    </tr>

                                    <tr>
                                        <td>19:00</td>
                                        <td>29	28	27	26	25	24</td>
                                    </tr>

                                    <tr>
                                        <td>19:15</td>
                                        <td>23	22	21	20	19	18</td>
                                    </tr>

                                    <tr>
                                        <td>19:30</td>
                                        <td>17	16	15	14	13	12</td>
                                    </tr>

                                    <tr>
                                        <td>19:45</td>
                                        <td>11	10	9	8	7	6</td>
                                    </tr>
                                    <tr>
                                        <td>20:00</td>
                                        <td>5	4	3	2	1	</td>
                                    </tr>
                                </tbody>
                            </table>
                        </big>

                        <h3>07.- TEST DEVELOPMENT</h3>
                        <h4>Art. 07-01.- DEPARTURE, ARRIVAL, TIME TIMING</h4>
                        <p>The exit will take place individually with the pilot and co-pilot in position (in contact with the tiller), with the vehicle stopped and the engine running.
                            the exit order is given in ascending order of numbering (according to their classification, by categories, in the XXXIII TRANSBETXÍ 2023).
                            If you have not finished the previous rally or are new to it, the next number will be assigned to the winner of the last classified of the previous rally. This is how the first series participant will leave, followed by the rest of the vehicles minute by minute.
                        </p>
                        <p>For the stage on Saturday 06 April 2024, the first classified will leave at 09:30, followed by the rest of the classifieds minute by minute.
                            Participants are obliged to follow the route indicated in the Race Book without passing any control.
                            The rest times provided by the organization must be respected.
                            The organization declines all responsibility in the event of a mistake in following the planned route.
                            Timing will be done in the second, with timers that activate a printing system.
                            No claim against this will be accepted.
                            The time sheets provided to the teams are for information purposes only and under the responsibility of the participants.
                            A vehicle that has been given the exit and does not take it immediately does not, under any circumstances, have the right to a second exit and is counted from the first time given.
                            The refusal or delay of more than 15 seconds when taking the exit, at any Time Control or Timed Section, will mean the exclusion of the section.
                            The Organization's instructions must be taken to the letter.
                            Failure to comply with this article by a participating team is considered a MINOR FAULT, contemplated in the disciplinary regime of this regulation.
                            The arrival in the timed sections will be recorded as the vehicle passes, with the pilot and co-pilot.
                        </p>
                        <strong>The timed section ends when the finish line is passed and the speed must be reduced immediately, in addition to not obstructing the braking zone. </strong>
                        <p>Breach of this article by a participating team is considered a SERIOUS FAULT, contemplated in the disciplinary regime of this regulation.
                            In case of forced interruption of the race by any member of the organization, the participating teams affected by this measure will be given the time of the best participant who reached the finish line (adhering to the International Sports Code and the Concept of Attitude Unsportsmanlike).
                            Each timed section closes after as many minutes have elapsed as there are teams registered in the race, plus five additional minutes, after the first participant's departure time.
                            Any interruption authorized by the race management involves an added time equal to that of the stop.
                        </p>

                        <h4>Art. 07-02.- RACE</h4>
                        The race will take place according to the timetable detailed by the organization in point 1 of these regulations.
                        There will be 6 timed sections <strong>(detailed in article 03-01.- ROUTE) </strong>with their connecting stages.
                        Each violation of the rules established in these regulations will be reported to the Jury, and by its decision, may be grounds for sanction and may even lead to the exclusion of the team from the event.
                        Any violation reported by the Civil Guard, Regional Police, Local Police, or any other governmental body implies the immediate exclusion of the reported team from the test.
                        This assumption includes blood alcohol tests and others, which will be carried out in the event of an accident or when deemed appropriate.
                        <strong>In the same way it will be transferred to the Disciplinary Committee of the FMCV in case they have some kind of sanction from the FMCV.</strong>

                        <h3>08.-CLASSIFICATIONS, CLAIMS, APPEALS</h3>
                        <h4>Art. 08-01.- CLASSIFICATIONS </h4>
                        <p>They will be issued by the organization no later than two hours after the race indicating the time of publication or, in the case of a technical complaint, when it has been resolved.
                            Half an hour after this classification is published it is automatically approved.
                            In the event that a claim of any kind is pending, it will not be approved until all the cases have been resolved and the new classification has been published.
                        </p>
                        <p>The first place in a Timed Section will be the team that invests the least time and so on.
                            The times will be cumulative and the team that has started and finished all the timed sections with the least amount of time will be considered the winner of the test.
                            In the event of a tie, the criterion to be followed will be the greater number of sections won.
                        </p>

                        <h4>Art. 08-02.- CLAIMS</h4>
                        <h4>Art.- 08.02.01 General rules</h4>
                        <p>Each claim can only deal with a specific point.
                            Claims will be public and personal (the claimant will do so in his own name)
                            Collective claims, as well as those made against timing checks or against the decisions of fact judges, are not accepted.
                            It should be remembered that the right to claim belongs only to the participants.
                        </p>
                        <p>Both the notification of the claim and the resolution will be communicated in writing to the interested parties and then published on the Official Notice Board.
                            If the claim is substantiated and the anomaly is verified, the bond and the deposit will be returned to the claimant and the claimant will pay the costs of the inspection, if any.
                            If the claim is unfounded and no anomaly is found, the bond will be held by the organization and the claimant will pay the costs of the inspection, if any.
                            The Jury can act ex officio and initiate any sporting or technical verification action on as many vehicles and/or participants as it deems appropriate and whenever it finds signs of an irregularity.
                        </p>
                        <h4>Art.- 08.02.02 Sports claims</h4>
                        <p>Claims for sports issues must be formulated and presented in writing to the Jury and be accompanied by a deposit of 100.-Eur.
                            The maximum time to submit a claim is 30 minutes after the end of the last test of the day.
                            The Jury, considering the claim to be founded and legitimate, will start a procedure, which will end in the drafting of a final resolution, with the conclusions and the possible sanctions.
                        </p>
                        <h4>Art.- 08.02.03 Claims against classification</h4>
                        <p>The deadline for claims against the results of the sports classification is 30 minutes, after the publication of the day's results.
                            They must be formulated and presented in writing to the Jury and be accompanied by a bond of <strong>100.-Eur</strong>.
                        </p>
                        <h4>Art.- 08.02.04 Technical claims</h4>
                        <p>Possible claims against a machine that could present components that breach any of the technical specifications must be formulated and presented in writing to the Organization before the end of the last test of the day, in writing and accompanied by a bond of 100.-Eur., in addition to a deposit for mechanical expenses of 100.00.-Eur., paid by the team making a claim. </p>
                        <h4>Art. 08-03.- DOPING CONTROL</h4>
                        <p>When the test is carried out on public roads and roads, the public enforcement forces have the power to carry out tests that they deem appropriate.
                            The fact of neutralizing the trip between sections does not remove the obligation to comply with this sporting rule.
                            In the face of a possible accident, if you test positive for alcohol or some type of drug, the payment of health care by the insurer can be complicated.
                        </p>
                        <h4>Art. 08-04.-PENALTIES</h4>
                        <p>Participating teams are penalized in the cases and amounts detailed below.
                            The Jury reserves the right to penalize other cases, not contemplated in this article, with other charges
                        </p>
                        <small><strong>REMOVAL OF CLASSIFICATION:</strong></small>
                        <ol>
                            <li>When a team does not start a timed section.</li>
                            <li>For changes from the participating machine to another non-registered one</li>
                        </ol>
                        <small><strong>EXCLUSION FROM THE RACE:</strong></small>
                        <ol>
                            <li>In cases of access by a team's assistants to sections not permitted by the organization, in addition to being punished with a serious foul.</li>
                            <li>In the change of driver or co-driver for others not registered in the present edition of the XXXIV rally TRASBETXÍ 2024, in addition to being punished with a very serious fault.</li>
                        </ol>
                        <small><strong>TWENTY SECONDS PER SECOND ADVANCED:</strong></small>
                        <ol>
                            <li>For those teams that, disobeying the official timekeepers, take the exit before it is given to them.</li>
                        </ol>
                        <small><strong>FIVE SECONDS MORE:</strong></small>
                        <ol>
                            <li>In the TCP timed sections (Friday) for teams that cross or knock down, with one or more wheels, the markings that mark the circuit.</li>
                        </ol>
                        <p>In the TCP and TCD sections, when a vehicle accidentally leaves the circuit and does not join it through the same exit point, in involuntary cases it will be penalized with MORE FIFTEEN SECONDS and in intentional cases it will be penalized with ONE MORE MINUTE, in addition to being punished with a minor foul.</p>
                        <small><strong>TWO MORE MINUTES: </strong></small>
                        <ol>
                            <li>For those participants who when they are going to be doubled do not move away and facilitate the passage, in addition to being punished with a minor foul.</li>
                        </ol>
                        <small><strong>ONE MORE MINUTE AND THE MINIMUM TIME ESTABLISHED BY THE ORGANIZATION:</strong></small>
                        <ol>
                            <li>For those teams that, for various reasons (mechanical, physical, etc.), in a timed section, are given the exit and cannot reach the finish line, regardless of the distance covered. However, they also have the right to continue competing for the best absolute rankings.</li>
                        </ol>
                        <p>In cases where the pilot and co-pilot do not reach the finish line at the same time, the time marked by the last member of the team to reach the finish line will be recorded as the time achieved; in cases where one of the two does not finish the timed section, the previous point will apply</p>
                        <small><strong>MONTH TEN MINUTES:</strong></small>
                        <ol>
                            <li>Cylinder change due to breakdown. </li>
                            <li>The spare cylinder must have been verified and sealed by the organization, in the administrative control</li>
                        </ol>
                        <small><strong>MONTH TWENTY MINUTES:</strong></small>
                        <ol>
                            <li>For loss of the official seal and not communicating it to the organization.</li>
                        </ol>
                        <h4>Art. 08-04 DISCIPLINARY REGIME </h4>
                        <p>Participating teams are punished with the application of this disciplinary regime in the cases and consequences detailed below.
                            The Jury reserves the right to penalize other cases not contemplated in this article with other charges.
                        </p>
                        <p>A <strong>VERY SERIOUS FAULT</strong> means exclusion from the race, the loss of the deposit paid and the impossibility of being registered for the edition of the Transbetxí rally of the year following any of the team members.</p>
                        <p>A <strong>SERIOUS FAULT</strong> means exclusion from the race and loss of the deposit paid. </p>
                        <p>The accumulation of five <strong>MINOR FAULTS</strong> implies the commission of a serious fault.</p>
                        <p>Other cases not contemplated in this particular regulation, are subject to the disciplinary regime of the FMCV</p>

                        <h3>09.-AWARDS AND TROPHIES, DELIVERY OF AWARDS</h3>
                        <h4>Art. 09-01.-AWARDS</h4>
                        <p>The prizes will be:</p>
                        <ol>
                            <li>To the first Absolute Classified (less time invested at the end of all timed stages).</li>
                            <li>In the second Absolute Classified (id.).</li>
                            <li>To the third Absolute Classified (id.).</li>
                            <li>To the top 3 classified in each category</li>
                        </ol>
                        <p>The award ceremony will take place on Sunday, April 7 at 6:30 p.m. at the MUNICIPAL FRONTÓ.</p>
                    </div>
                </Grid>
            </Grid>

            <PiePagina></PiePagina>
        </div>
    )
}
