import { Grid } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { CompnenteCard } from './CompnenteCard';
import { AnadirComponente } from './AnadirComponente';

export const Componente = () => {

    const [componentes, setComponentes] = useState([]);
    const [nuevocomponente, setNuevoComponente] = useState(false)
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        cargarComponentes();
    }, [])


    const cargarComponentes = () => {
        axios.get('https://www.transbetxi.com/WS/componente.php', {
            headers: {
                'code': 'pass23sd2aASED6',

            }
        })
            .then(function (response) {
                setComponentes(response.data);
                console.log("waa", response.data);
                setLoaded(true);
            })
            .catch(function (error) {
                // manejar error
                console.log(error);
            })
            .finally(function () {
                // siempre sera executado
            });
    }

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ gap: "1em" }}
                spacing={0}
            >
                <Grid container xs={6} md={4} lg={2} >
                    <div className="button-adm" onClick={() => setNuevoComponente(true)}>
                        Nuevo Componente
                    </div>
                </Grid>

            </Grid>
            <div className="spacer"></div>

            {loaded === false && (
                <div className='flex-container spinner'>
                    <div className='loading-spinner'>
                    </div>
                </div>
            )}
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ gap: "1em" }}
                spacing={0}
            >

                {loaded === true && (

                    componentes.map((componente, index) => (
                        <div>
                            <CompnenteCard componente={componente} index={index} componentes={componentes} setComponentes={setComponentes} />
                        </div>
                    ))

                )}

            </Grid>

            {nuevocomponente && (
                <div className="overlay">
                    <AnadirComponente setNuevoComponente={setNuevoComponente} />
                </div>
            )}
        </>
    )
}
