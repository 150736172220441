import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Header } from '../../Header'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { TramoCard } from './TramoCard';

export const GestionTIempoEd = () => {
    const Navigate = useNavigate();
    const param = useParams();

    const [tramos, setTramos] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        cargarTramosEdicion();
    }, [])


    const cargarTramosEdicion = () => {

        axios.get('https://www.transbetxi.com/WS/tramos.php?edicion=' + param.id)
            .then(function (response) {
                if (response.data.length > 0) {
                    setTramos(response.data);
                    setLoaded(true);
                }
            })
            .catch(function (error) {
                console.error(error)
            })

    }

    console.log(param)
    return (
        <div>
            <Header></Header>
            <div>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0}
                >
                    <Grid container xs={11}>
                        <div className="header-gest-tiemp">
                            Edicion {param.id}
                        </div>
                    </Grid>
                    <Grid container xs={6} md={4} lg={2}>
                        <div className="button-adm" onClick={() => Navigate('/edicion/'+param.id)}>
                            Volver
                        </div>
                    </Grid>
                    {tramos.map((tramo, index) => (
                        <TramoCard tramo={tramo} index={index} edicion={param.id} />
                    ))}
                </Grid>
            </div>
        </div>


    )
}
