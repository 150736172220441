import React from 'react';
import { SelectorIdioma } from '../components/SelectorIdioma';
import { HistoriaEs } from '../landing_page/historia/HistoriaEs';
import { useMyContext } from '../MyProvider '; //Variable global del idioma
import { HistoriaVal } from '../landing_page/historia/HistoriaVal';
import { HistoriaEn } from '../landing_page/historia/HistoriaEn';
import { HistoriaGer } from '../landing_page/historia/HistoriaGer';
import Grid from '@mui/material/Grid';



export const Historia = () => {

    const { miVariableGlobal, setMiVariableGlobal } = useMyContext();

    return (
        <div className="fade-in" >
            <SelectorIdioma idioma={miVariableGlobal} ></SelectorIdioma>

            {(miVariableGlobal == 1) && (
                <HistoriaEs></HistoriaEs>
            )}

            {(miVariableGlobal == 2) && (
                <HistoriaVal></HistoriaVal>
            )}

            {(miVariableGlobal == 3) && (
                <HistoriaEn></HistoriaEn>
            )}

            {(miVariableGlobal == 4) && (
                <HistoriaGer></HistoriaGer>
            )}


        </div>
    )
}
