import React, { useEffect } from 'react';
import { HeaderGer } from '../../components/HeaderGer';
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';

export const ReglamentoTecnicoGer = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div className="fade-in" >
            <HeaderGer></HeaderGer>
            <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E' }}>
                <h1>TECHNISCHE VORSCHRIFTEN</h1>
            </Grid>

            <Grid container xs={12} md={12} direction="column" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E' }}>
                <Grid container xs={10} md={10} direction="row" justifyContent="center" alignItems="center">

                    {/* <object data="https://transbetxi.com//REGLAMENTO_TÉCNICO_de.pdf" type="application/pdf" style={{ minHeight: '100vh', minWidth: '100%' }}>
                        <p><a href="https://transbetxi.com//REGLAMENTO_TÉCNICO_de.pdf"></a></p>
                    </object> */}

                    <iframe src="https://drive.google.com/viewerng/viewer?embedded=true&url=https://transbetxi.com//REGLAMENTO_TÉCNICO_de.pdf" style={{ minHeight: '100vh', minWidth: '100%' }}></iframe>




                    {/* <header id="header" className="header-blog" style={{ maxWidth: '100%', color: '#EAC474' }}>
                        <div className="top-header">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-4 col-xs-8">
                                        <header id="header" className="header-blog">
                                            <div className="top-header">
                                                <div className="container">
                                                    <div className="row" />
                                                </div>
                                            </div>
                                            <div className="bottom-header blog">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <h1 />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </header>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm-8 col-sm-offset-2">
                                                    <section id="blog">
                                                        <article
                                                            id="post-233"
                                                            className="blog-post post-233 page type-page status-publish hentry"
                                                        >
                                                            <div className="blog-post-entry markup-format">
                                                                <h3>
                                                                    1.&nbsp;&nbsp;&nbsp;&nbsp; ALLGEMEINE VORSCHRIFTEN
                                                                </h3>
                                                                <h4>1.1) Zugelassene Fahrzeuge:</h4>
                                                                <p>Fahrzeuge, die unter dem Namen motocultor vermarktet werden und der Definition in Punkt 1 entsprechen, sind zur Teilnahme an diesem Test zugelassen. Es ist zu beachten, dass sie die folgenden Einschränkungen und/oder technischen Merkmale erfüllen müssen:</p >
                                                                <ul>
                                                                    <li>
                                                                        Es handelt sich um Serien- oder traditionelle Motokultoren, die unter dem Namen Motokultor für den landwirtschaftlichen Einsatz vermarktet wurden, weiterentwickelt oder nicht, und die den Originalmotor und das Originalgetriebe beibehalten.
                                                                    </li>
                                                                    <li>
                                                                        Der Motor darf keine äußeren Veränderungen aufweisen, die nicht in den Vorschriften enthalten sind, oder Flüssigkeitskühlung jeglicher Art.
                                                                    </li>
                                                                    <li>
                                                                        Das Herstellungsdatum muss vor dem Jahr 2000 liegen.
                                                                    </li>
                                                                    <li>
                                                                        Zugelassen sind Motorgrubber mit einer einzigen Antriebsachse. Diese Antriebsachse muss die vordere sein. Andere Formen der Traktion sind nicht gestattet (außer für Gäste der Organisation).
                                                                    </li>
                                                                </ul>

                                                                <ul>
                                                                    <li>
                                                                        Die maximal zulässigen Maße sind:
                                                                        <ul>
                                                                            <li>
                                                                                1200 Millimeter für die vordere Profilbreite, gerechnet von der Außenseite des Reifens.
                                                                            </li>
                                                                            <li>
                                                                                1500 Millimeter für die hintere Spurweite, gerechnet von der Außenseite des Reifens.
                                                                            </li>
                                                                            <li>
                                                                                2200 Millimeter Kampf, zwischen Vorder- und Hinterachsmitte.
                                                                            </li>
                                                                            <li>
                                                                                14 Zoll Felgendurchmesser. Zugelassen sind nur Reifen mit Schlauch, „Tubeless“ und „Musse“, niemals Vollreifen. </li>
                                                                        </ul>
                                                                    </li>
                                                                    <li>
                                                                        Elektrische oder mechanische Hilfsmittel zur Steigerung der Antriebskraft sind nicht zulässig. Zum Beispiel Elektromotoren, die durch Batterien unterstützt werden, die durch regeneratives Bremsen oder auf andere Weise aufgeladen werden (KERS-Stil).
                                                                    </li>
                                                                    <li>
                                                                        Es ist keine elektrische oder mechanische Lenkunterstützung gestattet. Zum Beispiel hydraulische Servolenkung.
                                                                    </li>
                                                                </ul>

                                                                <ul>
                                                                    <li>
                                                                        Generell ist es zwingend erforderlich, dass alle teilnehmenden Fahrzeuge die übrigen traditionellen Fahrzeugeigenschaften, wie z. B. die Lenkung, das Antriebssystem usw., beibehalten.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Der maximale Hubraum ist wie folgt begrenzt
                                                                        Werte:
                                                                        <ul>
                                                                            <li>Zweitakt-Benzinmotoren: maximal 260 cm³.</li>
                                                                            <li>4-Takt-Benzinmotoren: maximal 260 cm³.</li>
                                                                            <li>4-Takt-Dieselmotoren: maximal 500 ccm</li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                                Hinweis: Der ursprüngliche Hub und die ursprünglichen Durchmesser werden beibehalten.
                                                                <ul>
                                                                    <li>
                                                                        Es obliegt den Teilnehmern, dies zu beweisen
                                                                        Technische Sportkommissare und Sportkommissare
                                                                        vollständige Übereinstimmung Ihres Motocultor mit den Vorschriften
                                                                        zu jedem Zeitpunkt des Tests. Die Teilnehmer müssen
                                                                        Stellen Sie sicher, dass Ihre Motocultors die Bedingungen erfüllen
                                                                        Compliance und Sicherheit während der gesamten Dauer
                                                                        der Beweis.
                                                                    </li>
                                                                </ul>
                                                                <h3>2.&nbsp;&nbsp;&nbsp;&nbsp; ÄNDERUNGEN</h3>
                                                                <ul>
                                                                    <li>
                                                                        Unter Änderung wird jeder Vorgang verstanden
                                                                        neigt dazu, das ursprüngliche Aussehen, die Abmessungen,
                                                                        Pläne, Zusammensetzung oder Funktion eines Originalteils.
                                                                    </li>
                                                                    <li>
                                                                        Unter einer verbotenen Änderung wird eine Änderung verstanden, die in dieser Verordnung nicht zulässig ist.
                                                                        den Kriterien des CC.DD nicht entspricht oder dies nicht der Fall ist. Vom Test. Bestraft werden
                                                                        entsprechend der Prüfordnung.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.1) Aussetzung</h4>
                                                                <ul>
                                                                    <li>
                                                                        Mechanische, hydraulische oder Federungselemente
                                                                        Reifen sind auf allen Motocultor erlaubt,
                                                                        Sie müssen ordnungsgemäß verschweißt und/oder verankert sein.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.2) Bremsen</h4>
                                                                <ul>
                                                                    <li>
                                                                        Die Bremsen müssen sowohl an der Vorder- als auch an der Hinterachse in unabhängigen Bremskreisen montiert werden.
                                                                    </li>
                                                                    <li>
                                                                        Sie können hydraulisch sein. Bei Verwendung eines Kabels muss dieses einen Mindestdurchmesser von 1,8 mm haben und mit Kabelbindern befestigt werden.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Die vordere Bremsanlage muss montiert sein
                                                                        oben auf dem Getriebe oder festsitzende Antriebswelle
                                                                        am Rad, um eine Verunreinigung mit Getriebeöl zu vermeiden.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.3) Adresse</h4>
                                                                <ul>
                                                                    <li>
                                                                        Es ist die Verbindung zwischen dem Motorkopf und dem
                                                                        Anhänger (wo die Insassen hingehen), der sich drehen lässt
                                                                        eine einzelne Achse, die die Verbindungskupplung sein kann, doppelt
                                                                        oder einfach.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Die Bedienung muss am Lenker erfolgen und die Einhaltung der Vorschriften ist zu beachten
                                                                        Originales Fahr- und/oder Handlingsystem
                                                                        Motorradkult.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Jegliche flexible Steuerung per Kabel oder Kette ist ebenso verboten wie die Richtung oder der Antrieb der Motorhacke durch Drehen der Räder.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Es muss über die gesamte Länge einen durchgehenden Radius aufweisen
                                                                        Drehwinkel, mit kontinuierlichem Umfang.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Aus Sicherheitsgründen darf der Lenker keine kantigen oder scharfen Teile aufweisen.
                                                                        Am Lenker montierte Geräte dürfen keine scharfen Kanten aufweisen. Alle Elemente
                                                                        Die Lenkung muss über ein Befestigungssystem verfügen, das absolute Sicherheit bietet. Haltestellen werden eingebaut
                                                                        darin und der Anbau eines Lenkungsdämpfersystems ist zulässig.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.4) Beschleuniger</h4>
                                                                <ul>
                                                                    <li>
                                                                        Zugelassen sind nur Gasabzug oder Griff, sofern sie über eine Rückholfeder verfügen.
                                                                        und das Kabel, das gut an der Struktur des Maschinenchassis befestigt ist.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.5) Motor</h4>
                                                                <ul>
                                                                    <li>
                                                                        Der Motor muss original sein. Die Gewerkschaft wird respektiert
                                                                        Originaler Motorzylinderkopf mit Stehbolzen
                                                                        über 8, 9 oder 10 mm hinaus. kann nicht variiert werden
                                                                        seine Position oder Verteilung.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Es ist nicht gestattet, einen Kompressor oder ein anderes System zu installieren
                                                                        Aufladung bei Ottomotoren, ggf
                                                                        in Dieselmotoren erlaubt.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.6) Kupplung</h4>
                                                                <ul>
                                                                    <li>
                                                                        Original oder modifiziert, die
                                                                        Austausch der Kupplung und ihres umgebenden Gehäuses oder
                                                                        Sumpf.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Antriebe mit kontinuierlicher Geschwindigkeit sind nicht zulässig.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.7)&nbsp; Kraftstofftank</h4>
                                                                <ul>
                                                                    <li>
                                                                        Es muss fest und ohne Befestigung befestigt sein
                                                                        ist vorläufiger Natur und stellt kein Risiko dar
                                                                        Leck während des Tests.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.8)&nbsp; Getriebe</h4>
                                                                <ul>
                                                                    <li>Nur das Originalgetriebe wird akzeptiert.</li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Eine Veränderung des äußeren Erscheinungsbildes ist im erlaubt
                                                                        Achsantriebsgetriebe bei
                                                                        Räder, die eine Höhenverstellung ermöglichen
                                                                        Insgesamt der Box.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Das obere Fach des Getriebes muss
                                                                        Bewahren Sie die ursprünglichen Maße und das ursprüngliche Erscheinungsbild. NEIN
                                                                        Allerdings ist eine teilweise Änderung der
                                                                        Wände des oberen Gehäuses, wann immer es ist
                                                                        Eine Änderung bedeutet keine Änderung des Auftriebs
                                                                        der Antriebsachsen.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Jedes Element kann intern und geändert werden
                                                                        Mechanismus so lang wie die Wände des Fahrgastraums
                                                                        des oberen Gehäuses sind diejenigen, die die Achsen tragen
                                                                        ändern. Und diese bleiben in Position
                                                                        Original.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Die Änderung des Übersetzungsverhältnisses ist zulässig.
                                                                        Ursprung sowie das Hinzufügen oder Entfernen interner Pins
                                                                        beinhalten eine mehr oder weniger große Anzahl von Geschwindigkeiten. Er
                                                                        Die Anzahl der Gänge ist nicht begrenzt.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Das Einfügen einer Reduktionsbox ist nicht zulässig.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Das Getriebeantriebssystem ist frei,
                                                                        Es kann mit dem Fuß oder der Hand erfolgen, servounterstützt oder nicht.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Der Einsatz von Achsdifferenzialen ist erlaubt
                                                                        Traktion.
                                                                    </li>
                                                                </ul>

                                                                <h4>2.9) Fans</h4>
                                                                <ul>
                                                                    <li>
                                                                        Die Ventilatoren müssen ohne manuellen Zugriff durch ein Lüftungsgitter ordnungsgemäß geschützt und ordnungsgemäß gesichert werden.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.10) Kotflügel</h4>
                                                                <ul>
                                                                    <li>
                                                                        An allen vier Rädern sind Schutzbleche vorgeschrieben, die 180° in umgekehrter U-Form abdecken und an den Trapezen befestigt werden müssen.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.11) Batterie </h4>
                                                                <ul>
                                                                    <li>
                                                                        Wenn Sie eine Batterie mitführen, ist es zwingend erforderlich, dass diese sicher befestigt ist, die Anschlüsse vor jeglicher Berührung geschützt sind und ein ordnungsgemäß gekennzeichneter Kurzschluss vorhanden ist.
                                                                    </li>
                                                                </ul>
                                                                <h4>2.12) Feuerlöscher </h4>
                                                                <ul>
                                                                    <li>
                                                                        Feuerlöscher müssen über eine zugelassene, leicht zu entfernende Halterung verfügen, die keine Befestigung mit Kabelbindern, Gurten oder anderen Arten von fester Befestigung erlaubt, und sie müssen über ein sichtbares Manometer verfügen.
                                                                    </li>
                                                                </ul>

                                                                <h4>2.13) Mann über Bord</h4>
                                                                <ul>
                                                                    <li>
                                                                        Das Mann-über-Bord-System wird obligatorisch sein, es kann kein System eingerichtet werden, um es aufzuheben.
                                                                    </li>
                                                                </ul>
                                                                <h3>3. KATEGORIEN</h3>
                                                                <h4>3.1) Prototypenkategorie</h4>
                                                                <ul>
                                                                    <li>
                                                                        Diese Kategorie muss den technischen Vorschriften entsprechen
                                                                        gemäß den Punkten 1 und 2 dieser Verordnung
                                                                        zusätzlich zu den folgenden Indikationen:
                                                                    </li>
                                                                </ul>
                                                                <h4>3.1.1) Zylinderkopf</h4>
                                                                <ul>
                                                                    <li>
                                                                        Es können Original- oder Gusszylinderköpfe verwendet werden,
                                                                        solange sie die Eigenschaften beibehalten und respektieren
                                                                        ursprüngliches Aussehen.
                                                                    </li>
                                                                    <li />
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-355"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico1.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        Die Verwendung von Nocken ist erlaubt und muss in den Originalschaft eingesetzt werden. Eine Veränderung der Brennkammer in Form und Volumen ist zulässig.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Zur Befestigung des Zylinderkopfes am Zylinder ist nur die Verwendung zulässig
                                                                        die Originallöcher. Die Möglichkeit, zwei weitere hinzuzufügen, um eine Drehung um 90° zu ermöglichen,
                                                                        Immer die gleiche Position wie die Originale und deren Maße einhalten.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-359"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico2.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-360"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico3.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        Im vorderen Teil wird ein Durchgangsloch gebohrt
                                                                        in jedem Bereich in der Nähe der 2-mm-Auspuffdüse
                                                                        ungefähr, mit dem Zweck, für die zu dienen
                                                                        Anbringung des Prüfsiegels.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-361"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico4.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h4>3.1.2) Zylinder</h4>
                                                                <ul>
                                                                    <li>
                                                                        Es ist nur die Verwendung eines Originalzylinders zulässig.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-363"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico5.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        Es ist nicht gestattet, Außenelemente hinzuzufügen oder bestehende zu vergrößern, um die Kühlleistung zu erhöhen.
                                                                        (z. B. Schweißen oder Modifizieren von Zylinderrippen). Ja, es ist erlaubt, diese Elemente zu entfernen oder auszuschneiden.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Es ist erlaubt, am Zylinder befestigte Elemente hinzuzufügen
                                                                        Der Zweck der Montage eines Ansaugsystems durch
                                                                        Bleche zum Zylinder.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Es besteht die Möglichkeit, optional ein System hinzuzufügen
                                                                        vordere Belüftung, die gut abgedeckt sein muss und
                                                                        durch ein Gitter geschützt, um nicht freigelegt zu werden oder
                                                                        stellen keine Gefahr dar.
                                                                    </li>
                                                                    <li>
                                                                        Es ist zulässig, die Anzahl der internen Leitungen oder Übergänge zu variieren. Es ist auch erlaubt
                                                                        Durch die Modifikation dieser Kanäle zusätzlich zu den Einlass- und Auslasskanälen wird Material eingespart
                                                                        oder mit anderem Material füllen oder
                                                                        Technik, wobei stets darauf zu achten ist, dass der Hauptkörper der Originalzylinder ist.
                                                                    </li>
                                                                    <li>
                                                                        Die Verwendung von Stahl- oder Aluminiummänteln mit Behandlungen ist zulässig.
                                                                        Oberfläche (nicasiliert/chrom) der Wände. Das äußere Maß des
                                                                        Die Hülse muss 83 mm (+-2 mm) betragen. An der Spitze muss man respektieren
                                                                        die Dicke des Originals (ca. 8 mm),
                                                                        Die Oberfläche kann auf maximal 130 mm erweitert werden.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-364"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico5.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        Das Schleifen und Ersetzen von Innenauskleidungen oder Beschichtungen ist zulässig.
                                                                    </li>
                                                                    <li>
                                                                        Eine Anpassung ist durch Ersetzen des möglich
                                                                        Zylinderdichtung. Die Kopfdichtung ist erlaubt.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        Es ist nicht gestattet, die Nummer, Position oder zu ändern
                                                                        Originalverteilung der Bolzen, die das befestigen
                                                                        Motor, die ursprüngliche Verbindung wird respektiert
                                                                        Motor-Zylinder-Zylinderkopf mittels durchgesteckter Stehbolzen
                                                                        metrisch 8, 9 oder 10 mm, diese müssen die gleiche Leistung erbringen
                                                                        Funktion als im Originalzylinder.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-366"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico6.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        Im vorderen Teil wird ein Durchgangsloch gebohrt
                                                                        in jedem Bereich in der Nähe der 2-mm-Auspuffdüse
                                                                        ungefähr, und ein anderer an jedem anderen Ort
                                                                        bleibt sichtbar und stört nicht, dient dem Zweck des Servierens
                                                                        zum Anbringen des Eichsiegels.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image size-full">
                                                                    <img
                                                                        className="wp-image-638"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico7.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h4>3.1.3) Kurbelgehäuse</h4>
                                                                <ul>
                                                                    <li>Nur das Original-Kurbelgehäuse wird akzeptiert.</li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-370"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico8.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-371"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico9.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        Es ist erlaubt, Material von seinem äußeren Teil zu entfernen.
                                                                    </li>
                                                                    <li>
                                                                        Es dürfen nur angehängte Elemente hinzugefügt werden
                                                                        Kurbelgehäuse zum Zusammenbauen von a
                                                                        Zugang zum Kurbelgehäuse.
                                                                    </li>
                                                                    <li>
                                                                        Mit dem ist eine interne Modifikation des Gesichts möglich
                                                                        Zylindertransfers.
                                                                    </li>
                                                                    <li>Eine Reparatur des Kurbelgehäuses ist erlaubt.</li>
                                                                    <li>
                                                                        Jede andere externe Änderung muss sein
                                                                        vom CC.DD überprüft und genehmigt. des Tests für
                                                                        Stellen Sie sicher, dass das Kurbelgehäuse dem Original entspricht und nicht
                                                                        Dies stellt eine Leistungsverbesserung dar.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.1.4)&nbsp;&nbsp;Kurbelwelle, Pleuel und Kurbelzapfen</h4>
                                                                <ul>
                                                                    <li>
                                                                        Original oder modifiziert, solange es erhalten bleibt
                                                                        der Zylinderhub.
                                                                    </li>
                                                                    <li>
                                                                        Der Ersatz dieser Elemente durch
                                                                        andere von besserer Qualität, solange die
                                                                        Originaler Kolbenhub. Die Messung der Pleuelstange
                                                                        Es wird 116 mm sein.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.1.5) Kolben, Ring und Stift</h4>
                                                                <ul>
                                                                    <li>
                                                                        Original oder modifiziert, Modifikationen erlaubt
                                                                        des Kolbens.
                                                                    </li>
                                                                    <li>
                                                                        Die Kolben können durch andere größerer Größe ersetzt werden.
                                                                        Qualität, die akzeptierten Maße sind: mindestens 72 mm und
                                                                        maximal 74mm.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.1.6) Zulassung</h4>
                                                                <ul>
                                                                    <li>
                                                                        Änderungen am Ansaugsystem sind zulässig
                                                                        Original.
                                                                    </li>
                                                                    <li>
                                                                        Der Einbau von Einspritzsystemen ist erlaubt
                                                                        Kraftstoff für Dieselmotoren.
                                                                    </li>
                                                                    <li>
                                                                        Druckhalteelemente dürfen nicht verwendet werden. Der
                                                                        Versprühen anderer Produkte als Kraftstoff
                                                                        Es ist verboten.
                                                                    </li>
                                                                    <li>
                                                                        Die Verankerung muss fest sein und die
                                                                        Verteilung und die Verbindung mit der Abgasdüse,
                                                                        Möglichkeit, den Durchmesser der Bolzen zu ändern.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-372"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico10.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h4>3.1.7)&nbsp;&nbsp;Vergaser</h4>
                                                                <ul>
                                                                    <li>
                                                                        Der Vergaser ist unabhängig von seinem Funktionsprinzip frei.
                                                                        Funktion.
                                                                    </li>
                                                                    <li>Die Positionierung des Vergasers ist frei.</li>
                                                                    <li>Der Vergaser muss gut am Zylinder befestigt sein, ohne dass die Möglichkeit besteht, ihn zu verlieren.</li>
                                                                    <li>Der Luftfilter ist kostenlos.</li>

                                                                </ul>
                                                                <h4>3.1.8)&nbsp;&nbsp;Einschalten</h4>
                                                                <ul>
                                                                    <li>
                                                                        Variable Zündsysteme (Vorverstellungssysteme).
                                                                        und progressiver Rückstoß) sind zulässig, ebenso
                                                                        jedes elektronische System, das eine Selbstkontrolle ermöglicht
                                                                        die Parameter, die der Motor für seine verwendet
                                                                        Betrieb bei laufender Motorhacke.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.1.9)&nbsp;&nbsp;Elektronik</h4>
                                                                <ul>
                                                                    <li>
                                                                        Jedes Datenerfassungssystem ist zulässig.
                                                                    </li>
                                                                    <li>
                                                                        Fernmodifikation von beliebigen
                                                                        Motorparameter, diese können nur durchgeführt werden
                                                                        lokal von der Maschine selbst durch den Piloten bzw
                                                                        Kopilot.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.1.10)&nbsp; Flucht</h4>
                                                                <ul>
                                                                    <li>
                                                                        Teilweise oder vollständige Modifikationen des Auspuffs sind zulässig.
                                                                    </li>
                                                                    <li>
                                                                        Die Verwendung von Auslassventilen ist nicht gestattet.
                                                                    </li>
                                                                    <li>
                                                                        Die Verankerung muss fest sein und die
                                                                        Verteilung und die Verbindung mit der Abgasdüse,
                                                                        Möglichkeit, den Durchmesser der Bolzen zu ändern.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-373"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico11.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h3>3.2)&nbsp; Kategorie Serie</h3>
                                                                <ul>
                                                                    <li>
                                                                        Diese Kategorie muss den technischen Vorschriften entsprechen
                                                                        gemäß den Punkten 1 und 2 dieser Verordnung
                                                                        zusätzlich zu den folgenden Indikationen:
                                                                    </li>
                                                                </ul>
                                                                <h4>3.2.1)&nbsp;&nbsp;&nbsp;Zylinderkopf</h4>
                                                                <ul>
                                                                    <li>
                                                                        Es können Original- oder Gusszylinderköpfe verwendet werden,
                                                                        solange sie die Eigenschaften beibehalten und respektieren
                                                                        ursprüngliches Aussehen, das möglicherweise an der Form der Kamera liegt
                                                                        freie Verbrennung.
                                                                        <ul>
                                                                            <li>
                                                                                Die Verwendung von Nocken ist unter keinen Umständen gestattet.
                                                                            </li>
                                                                            <li>
                                                                                Es ist erlaubt, bei der Herstellung auf Material zu verzichten
                                                                                Anpassungen, nicht hinzufügen.
                                                                            </li>
                                                                            <li>
                                                                                Nur zur Befestigung des Zylinderkopfes am Zylinder
                                                                                Damit können Sie die 6 Originallöcher nutzen.
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-375"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico12.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        Im vorderen Teil wird ein Durchgangsloch gebohrt
                                                                        in jedem Bereich in der Nähe der 2-mm-Auspuffdüse
                                                                        ungefähr, entweder an der Basis oder in einem der
                                                                        Flossen, die zur Platzierung dienen
                                                                        des Eichsiegels.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-377"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico13.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h4>3.2.2)&nbsp;&nbsp;Zylinder</h4>
                                                                <ul>
                                                                    <li>Nur der Originalzylinder wird akzeptiert.</li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-379"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico14.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-381"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico15.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-382"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico16.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        Der Liner kann repariert und ersetzt werden, allerdings nicht
                                                                        Es darf nicht aus Aluminium bestehen oder irgendeine Art von Aluminium tragen
                                                                        Abdeckung. Die Außenmaße des Hemdes müssen angegeben werden
                                                                        83 mm (+-2 mm) betragen.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-384"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico17.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        In keinem Fall darf die Anzahl der Überweisungen verändert werden, diese müssen ihre ursprüngliche Position beibehalten und ihre Verteilung kann variieren.
                                                                        Es ist nicht möglich, die Einlasskanäle vollständig zu bohren, sondern nur zum Kurbelgehäuse hin zu bohren und maximal 15 mm zu vertiefen.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-385"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico18.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-386"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico19.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-387"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico20.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-388"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico21.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-389"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico22.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        In keinem Fall darf Material oder neues Material hinzugefügt werden.
                                                                        Löcher, sowohl im Zylinder als auch in der Hülse.
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        In keinem Fall dürfen Außenelemente hinzugefügt oder hinzugefügt werden
                                                                        Erhöhen Sie die vorhandenen mit dem Ziel,
                                                                        Kühlleistung erhöhen.
                                                                    </li>
                                                                    <li>
                                                                        Im vorderen Teil wird ein Durchgangsloch gebohrt
                                                                        und eine weitere hinten, in jedem Bereich in der Nähe
                                                                        Auslassdüse und Einlassdüse, 2 mm
                                                                        ungefähr, entweder an der Basis oder in einem der
                                                                        Flossen und eine weitere an jeder anderen Stelle, die noch übrig ist
                                                                        sichtbar und nicht störend, mit dem Zweck, zu dienen
                                                                        die Anbringung des Prüfsiegels.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image size-full">
                                                                    <img
                                                                        className="wp-image-640"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico23.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h4>3.2.3)&nbsp;&nbsp;Kurbelgehäuse</h4>
                                                                <ul>
                                                                    <li>Nur das Original-Kurbelgehäuse wird akzeptiert.</li>
                                                                    <li>
                                                                        Es ist nicht gestattet, den Kanälen Material hinzuzufügen
                                                                        Kurbelgehäuse oder ändern Sie die Übertragungsrate mit dem
                                                                        Zylinder reinigen und das Kurbelgehäuse nicht entleeren.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-393"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico24.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-394"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico25.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        Eine Reparatur des Kurbelgehäuses ist zulässig
                                                                        Originalmaße.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.2.4)&nbsp;&nbsp;Kurbelwelle, Pleuel und Kurbelzapfen</h4>
                                                                <ul>
                                                                    <li>
                                                                        Eine Änderung und/oder ein Austausch dieser Elemente durch andere von besserer Qualität ist zulässig, sofern sie ihren ursprünglichen Hub und ihre ursprünglichen Abmessungen beibehalten.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.2.5)&nbsp;&nbsp;Kolben, Ring und Bolzen</h4>
                                                                <ul>
                                                                    <li>
                                                                        Original oder modifiziert, Modifikationen sind erlaubt
                                                                        der Kolben.
                                                                    </li>
                                                                    <li>
                                                                        Die Kolben können durch andere größerer Größe ersetzt werden.
                                                                        Qualität, die akzeptierten Maße sind: mindestens 72 mm und
                                                                        maximal 74mm.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.2.6)&nbsp;&nbsp;Eintritt</h4>
                                                                <ul>
                                                                    <li>
                                                                        Änderungen am Ansaugsystem sind nicht zulässig
                                                                        Original.
                                                                    </li>
                                                                    <li>
                                                                        Eine Anpassung des Ansaugkanals ist zulässig. Sein
                                                                        der Einlass zum Vergaser mit einem maximalen Durchmesser von 38 mm.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-395"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico26.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        Druckhalteelemente dürfen nicht verwendet werden. Der
                                                                        Versprühen anderer Produkte als Kraftstoff
                                                                        Es ist verboten.
                                                                    </li>
                                                                    <li>
                                                                        Durch Schweißen wird ein Schweißstück mit Ringfunktion hinzugefügt
                                                                        (Ähnlich wie im Beispiel ist auch eine Nuss erlaubt)
                                                                        mit einem Mindestloch von ca. 2 mm in jedem Bereich der Düse.
                                                                        Zulassung mit dem Zweck, der Anbringung des Eignungssiegels zu dienen.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-396"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico27.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h4>3.2.7)&nbsp;&nbsp;Vergaser</h4>
                                                                <ul>
                                                                    <li>
                                                                        Das Funktionsprinzip ist kostenlos
                                                                        maximales Maß 38mm.
                                                                    </li>
                                                                    <li>Die Positionierung des Vergasers ist frei.</li>
                                                                    <li>Der Luftfilter ist kostenlos.</li>
                                                                </ul>
                                                                <h4>3.2.8)&nbsp;&nbsp;Einschalten</h4>
                                                                <ul>
                                                                    <li>
                                                                        Es sind nur feste Vorzündungen zulässig.
                                                                        Dies wird am Ende des Tests überprüft
                                                                        Einstufung.
                                                                    </li>
                                                                </ul>
                                                                <h4>3.2.9) Flucht</h4>
                                                                <ul>
                                                                    <li>
                                                                        Teilweise oder vollständige Modifikationen des Auspuffs sind zulässig.
                                                                        Das Hinzufügen von Material ist nicht gestattet.
                                                                    </li>
                                                                    <li>
                                                                        Die Abgasdüse muss einen maximalen Durchmesser haben
                                                                        von 44m.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-398"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico28.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        Die Verwendung von Auslassventilen ist nicht gestattet.
                                                                    </li>
                                                                    <li>
                                                                        Ein Stück mit Ringfunktion wird durch Schweißen hinzugefügt (ähnlich wie im Beispiel wird auch eine Mutter akzeptiert) mit einem Mindestloch von 2 mm
                                                                        ungefähr, in jedem Bereich der Abgasdüse, mit dem Zweck, das Eichsiegel anzubringen.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-399"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico29.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        Die Verankerung muss fest sein und die
                                                                        Verteilung und die Verbindung mit der Abgasdüse,
                                                                        Möglichkeit, den Durchmesser der Bolzen zu ändern.
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-401"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico30.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h3>3.3)&nbsp; Saure Kategorie</h3>
                                                                <ul>
                                                                    <li>
                                                                        Der Motorradfahrer muss die technischen Vorschriften einhalten
                                                                        Markieren Sie die deutschen Rennen
                                                                    </li>
                                                                </ul>
                                                                <h3>4. VERIFIZIERUNGS- UND KONTROLLMETHODEN</h3>
                                                                <h3>4.1) Allgemeine Überprüfung.</h3>
                                                                <ul>
                                                                    <li>In der Konzentration nach dem Aufprall von Rücken und
                                                                        Zur Helmüberprüfung wird eine Überprüfung durchgeführt
                                                                        außerhalb der vom CC.DD berücksichtigten Punkte. für
                                                                        sorgen für die Einhaltung dieser Verordnung. ER
                                                                        Wir werden die Motoren je nach Kategorie versiegeln. falls
                                                                        Reparaturen müssen Sie während des Wettbewerbs mitteilen
                                                                        zum CC.DD. Dadurch wird der Motor wieder abgedichtet.
                                                                    </li>
                                                                    <li>
                                                                        Die Siegel werden der verwaltungstechnischen Kontrolle übergeben, damit die teilnehmenden Teams sie gegebenenfalls anbringen können.
                                                                    </li>
                                                                    <li>
                                                                        In
                                                                        Falls klassifiziert, zum Zeitpunkt der
                                                                        Nach Abschlussprüfungen müssen die Teile angeliefert werden
                                                                        ersetzt und während des Rennens für ihre verwendet
                                                                        Verifizierung. </li></ul>
                                                                <h3>4.2)&nbsp;&nbsp;Endgültige Überprüfung.</h3>
                                                                Sobald die Prüfung abgeschlossen ist, führt die technische Kommission die Prüfung durch
                                                                Überprüfung von 5 Motokulturen, die ersten 3
                                                                klassifiziert und 2 weitere nach Ermessen der Organisation,
                                                                Die Referenzwerte sind die in der angegebenen
                                                                Planke. Es wird überprüft, ob sie den Spezifikationen von entsprechen
                                                                jede in dieser Verordnung festgelegte Kategorie.
                                                                <figure className="wp-block-table">
                                                                    <table>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td />
                                                                                <td>Maximal zulässiger Durchmesser</td>
                                                                                <td>Ursprünglicher Nennhub</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Hispanische Villiers</td>
                                                                                <td>?</td>
                                                                                <td>?</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Bultaco</td>
                                                                                <td>74±0,2 mm</td>
                                                                                <td>60 ± 0,2 mm</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </figure>
                                                                <h3>4.3) Beispiele für Versiegelungen</h3>
                                                                <ul>
                                                                    <li>
                                                                        <strong>SERIE</strong>
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-404"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico31.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-405"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico32.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <ul>
                                                                    <li>
                                                                        <strong>PROTOTYPEN</strong>
                                                                    </li>
                                                                </ul>
                                                                <figure className="wp-block-image">
                                                                    <img
                                                                        className="wp-image-406"
                                                                        src="http://www.transbetxi.com/img/rtecnico/tecnico33.png"
                                                                        alt=""
                                                                    // width={1181}
                                                                    // height={1181}
                                                                    />
                                                                </figure>
                                                                <h3>4.4)&nbsp;&nbsp;&nbsp;SANKTIONEN</h3>Verantwortlich für die
                                                                Organisation.
                                                                <ul>
                                                                    <li>
                                                                        4.5) Die Organisation behält sich das Recht vor, jedes teilnehmende Team vom Rennen auszuschließen, das sich nicht an diese Vorschriften hält.
                                                                    </li>
                                                                </ul>
                                                            </div>


                                                        </article>
                                                    </section>
                                                </div>
                                            </div>
                                        </div>
                                        <footer id="footer">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-3 col-sm-6 col-xs-12">
                                                        &nbsp;<span style={{ fontSize: "1rem" }}>&nbsp;</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </footer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header> */}

                </Grid>
            </Grid>

            <PiePagina></PiePagina>
        </div>
    )
}
