import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from '../../components/Header';
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';
import fotoRe from '../../media/DSC_0406-scaled.jpg';

export const ReglamentosEs = () => {

    const Navigate = useNavigate();


    return (
        <div className="fade-in" >
            <Header></Header>
            <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E' }}>
                <h1>REGLAMENTOS</h1>
                <img src={fotoRe} alt="Foto" style={{ maxWidth: '100%', minWidth: '100%' }} />
            </Grid>

            <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
                <Grid container xs={10} md={4} direction="column" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', textAlign: 'center', padding: '1em' }}>
                    <h1>REGLAMENTO TÉCNICO</h1>
                    <strong>El reglamento técnico se refiere a todas las normas, especificaciones y cotas que una maquina debe cumplir dentro de su categoría para que no sea penalizada</strong>
                    <div class="sirk2"  onClick={() => Navigate('/reglamento-tecnico')}>
                        Ver
                    </div>
                </Grid>

                <Grid container xs={10} md={4} direction="column" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', textAlign: 'center', padding: '1em'  }}>
                    <h1>REGLAMENTO DE LA PRUEBA</h1>
                    <strong>Queridos participantes, con esta introducción, queremos haceros conscientes que, además de tener unos derechos y servicios que os asisten, al participar en el rally TRANSBETXÍ, tenéis también una serie de normas de obligado cumplimiento. Estas normas constituyen un garante para el correcto devenir de la carrera y os ofrecen la asistencia y el mismo tratamiento a todos los equipos que tomáis parte</strong>
                    <div class="sirk2"  onClick={() => Navigate('/reglamento-de-la-prueba')}>
                        Ver
                    </div>
                </Grid>

            </Grid>
            <PiePagina></PiePagina>
        </div>
    )
}
