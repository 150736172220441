import React, { useEffect, useState } from 'react'
import axios from 'axios'; import logo from '../../media/logo.png';
import { HeaderGer } from '../../components/HeaderGer';
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Galeria } from '../../components/Galeria';


export const HistoriaGer = () => {

    const [ganadores, setGanadores] = useState([])

    const getGanadores = () => {
        axios.get('https://www.transbetxi.com/WS/ganadores.php')
            .then(function (response) {
                // manejar respuesta exitosa
                // console.log(response.data)
                setGanadores(response.data);

            })
            .catch(function (error) {
                // manejar error
                console.log(error);
            })
            .finally(function () {
                // siempre sera executado
            });
    }

    useEffect(() => {
        getGanadores();
    }, [])


    return (
        <div>
            <HeaderGer></HeaderGer>
            <Grid container xs={12} md={12} direction="column" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
                <Grid container direction="column" xs={10} md={7} style={{ alignItems: 'flex-start', justifyContent: 'flex-start', textAlign: 'left', marginTop: '1em', color: '#EAC474' }}>
                    <h2>Was ist TransBetxi?</h2>

                    <p>Das „Trans-Betxi“ ist ein Rennen, das seit 1988 auf Straßen und Strecken in der Gegend von Betxi (Castellón) und den angrenzenden Städten ausgetragen wird. Die Teams bestehen aus einem Piloten und einem Co-Piloten, die Maschine, mit der sie teilnehmen, ist ein Motocultor (normalerweise benzinbetrieben).</p>
                    <p>Es handelt sich nicht nur um einen typischen Sportwettkampf, sondern es hat eine kulturelle Komponente, die sich darin zeigt, was die Maschine in der Vergangenheit für die Landwirte bedeutete und weil die Menschen sie als ein festliches Ereignis wahrnahmen, bei dem sie praktisch der gesamten Bevölkerung bewusst waren dieser Veranstaltung Jahr für Jahr.</p>
                    <p>Offensichtlich entstand dieses Rennen durch Zufall, aus der Fantasie einer Gruppe von Freunden, die mit einem so eigenartigen Fahrzeug ihre eigene Dakar bestreiten wollten.</p>
                    <p>Das Rennen begann mit 9 teilnehmenden Teams, bei denen das Hauptziel darin bestand, eine gute Zeit zu haben, und bei dem sich dieselben Motocultoren (damals ohne technische Verbesserung) am Tag nach dem Rennen von einem Fahrzeug für den Sportgebrauch in eine Maschine verwandelten .um das Land der Orangenbäume zu bestellen. Dies hat sich zu dem Niveau entwickelt, auf dem es heute um den Sieg geht, da sich die Maschinen zu authentischen Formeln auf diesem Gebiet entwickelt haben. Sie werden normalerweise von den Teammitgliedern selbst sowie deren Familie und Freunden hergestellt.</p>
                    <p>Der XXXIV TransBetxí besteht aus einem Prolog, der am Samstag, 6. April und Sonntag, 7. April 2024 um 9:00 Uhr beginnt, in dem die Startreihenfolge der folgenden Abschnitte festgelegt wird, die am selben Samstag stattfinden (zwei Abschnitte). morgens und zwei nachmittags) und am Sonntag (zwei Abschnitte vormittags).</p>

                    <br />
                    <br />

                    <strong>Abbildung 1.- TransBetxí-Logo</strong>
                    <br />
                    <Grid container direction="column" xs={12} md={12} justifyContent="center" alignItems="center">
                        <img src={logo} alt="" />
                    </Grid>
                    <br />

                    <p>Der TransBetxí ist ein vom Motorradverband der Valencianischen Gemeinschaft (FMCV) anerkannter Test, bei dem alle Teilnehmer und ihre Begleitfahrzeuge über die Verbandslizenz zur Teilnahme an dieser Art von Rennen verfügen. Dies bedeutet, dass die Teilnehmer über eine unbegrenzte Krankenversicherung verfügen und dass das Rennen über eine dieser Art von Sportveranstaltung entsprechende Haftpflichtversicherung verfügt. Seine Kontrolle und Leitung wird von Personen geregelt, die über die entsprechende föderale Lizenz verfügen.</p>

                    <br />
                    <br />
                    <strong>Abbildung 2.- Foto eines Motorradfahrers im Rennen.</strong>
                    <br />
                    <Grid container direction="column" xs={12} md={12} justifyContent="center" alignItems="center">
                        <img src="https://www.transbetxi.com/img/historia/historia3.jpg" alt="" />
                    </Grid>
                    <p>Der Stadtrat von Betxí ist der Organisator des XXXIV TransBetxí 2024 und verantwortlich für die Koordinierung aller Mitarbeiter und Freiwilligen, die an der Sportveranstaltung teilnehmen.</p>
                    <p>Der Stadtrat von Betxí ist für die Beantragung der entsprechenden Genehmigungen für den Zugang zu den Abschnitten, durch die die Maschinen verkehren, zuständig. Außerdem ist er für die Koordinierung der medizinischen Dienste, der örtlichen Polizei und anderer Mitglieder der Sicherheit und öffentlichen Ordnung zuständig. </p >
                    <p>Der Motorradverband der valencianischen Gemeinschaft stellt durch seine Sportkommissare die Anwendung der Vorschriften sicher. Unter dem Schutz des FMCV steht auch die Figur des Rennleiters, der während des Wettbewerbs verantwortlich ist.</p>
                    <p>Eine weitere wichtige Person, die unter der Aufsicht des Renndirektors handelt, ist der Kommunikationsmanager, der das gesamte Netzwerk mit einem doppelten Kommunikationskanal durch Repeater für Walkies und Mobiltelefone gestaltet, die es dem Renndirektor ermöglichen, mit den Verantwortlichen in Kontakt zu bleiben für jeden Abschnitt und mit Mitgliedern der Sicherheit und der öffentlichen Ordnung. Der Kommunikationsmanager verteilt seine Assistenten auf alle während des Wettbewerbs durchgeführten Abschnitte. Sie befinden sich in der Regel am Anfang und Ende von Abschnitten und in Bereichen, die aufgrund besonderer Komplexität zu Unfällen führen können, um bei Gefahr durch einen Unfall das Rennen abbrechen zu können.</p>
                    <p>Und schließlich haben wir die Sektionsleiter, die normalerweise am Anfang und am Ende der Sektion platziert sind, um im Falle einer Gefahr zu entscheiden, den Start zu unterbrechen, sobald sie durch den Kommunikationsmanager informiert werden und die Bestätigung von erhalten der Rennleiter.</p>
                    <br />
                    <p>Daher sieht das Organigramm während der TransBetxí-Feier wie folgt aus:</p>
                    <small><strong>Ein doppelter Kommunikationskanal wird verwendet, um die Kommunikation in Abschnitten sicherzustellen, in denen es keine Mobilfunkabdeckung gibt, da es mehrere Abschnitte gibt, die über Waldwege verlaufen.</strong></small>
                    <br />
                    <br />
                    <Grid container direction="column" xs={12} md={12} justifyContent="center" alignItems="center">
                        <Grid container direction="column" xs={12} sm={10} md={8} justifyContent="center" alignItems="center">
                            <img src="https://www.transbetxi.com/img/historia/historia4_ger.png" alt="" />
                        </Grid>

                    </Grid>
                    <br />
                    <br />
                    <Grid container direction="column" xs={12} md={12} justifyContent="center" alignItems="center">
                        <h2>Ehrungen</h2>
                    </Grid>

                    <TableContainer component={Paper} style={{ backgroundColor: '#C9C9C9', borderRadius: '15px' }} elevation={12}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow style={{ backgroundColor: '#EAC474' }}>
                                    <TableCell><strong style={{ color: '#2E2E2E' }}>JAHR</strong></TableCell>
                                    <TableCell><strong style={{ color: '#2E2E2E' }}>AUSRÜSTUNG</strong></TableCell>
                                    <TableCell><strong style={{ color: '#2E2E2E' }}>PILOT-COPILOT</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {ganadores.map((ganador, index) => (
                                    <TableRow>
                                        <TableCell align="left">{ganador.ano} </TableCell>
                                        <TableCell align="left">{ganador.equipo}</TableCell>
                                        <TableCell align="left">{ganador.pilotos}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br />
                    <br />

                    <Grid container direction="column" xs={12} md={12} justifyContent="center" alignItems="center">
                        <h2>historische Bilder</h2>
                    </Grid>

                </Grid>

                <Galeria></Galeria>
            </Grid>

            <PiePagina></PiePagina>
        </div>
    )
}
