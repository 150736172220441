import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HeaderEn } from '../../components/HeaderEn';
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';
import fotoRe from '../../media/DSC_0406-scaled.jpg';

export const ReglamentosEn = () => {

    const Navigate = useNavigate();


    return (
        <div className="fade-in" >
            <HeaderEn></HeaderEn>
            <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E' }}>
                <h1>REGULATIONS</h1>
                <img src={fotoRe} alt="Foto" style={{ maxWidth: '100%', minWidth: '100%' }} />
            </Grid>

            <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
                <Grid container xs={10} md={4} direction="column" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', textAlign: 'center', padding: '1em' }}>
                    <h1>TECHNICAL REGULATION</h1>
                    <strong>The technical regulations refer to all the standards, specifications and levels that a machine must comply with within its category so that it is not penalized.</strong>
                    <div class="sirk2"  onClick={() => Navigate('/reglamento-tecnico')}>
                        See
                    </div>
                </Grid>

                <Grid container xs={10} md={4} direction="column" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', textAlign: 'center', padding: '1em'  }}>
                    <h1>RALLY REGULATIONS</h1>
                    <strong>Dear participants, with this introduction, we want to make you aware that, in addition to having some rights and services that assist you, when participating in the TRANSBETXÍ rally, you also have a series of mandatory rules. These rules constitute a guarantor for the correct development of the race and offer assistance and the same treatment to all the teams that take part.</strong>
                    <div class="sirk2" onClick={() => Navigate('/reglamento-de-la-prueba')}>
                        See
                    </div>
                </Grid>

            </Grid>
            <PiePagina></PiePagina>
        </div>
    )
}
