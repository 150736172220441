import { Grid } from '@mui/material';
import React from 'react';
import { redirect } from 'react-router-dom';
import PlaceIcon from '@mui/icons-material/Place';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import axios from 'axios';
import Swal from 'sweetalert2';


export const PatroCard = ({ patro, innerIndex , eliminar, patros, setPatros}) => {

    const eleminarPatro = () => {
        console.log(patro.id)
        axios.delete('https://www.transbetxi.com/WS/patrocinador.php',{ 
            data: {
            id: patro.id,
            code: 'pass23sd2aASED/6&'
            }
        })
        .then(function(response){
            console.log(response.data.success);
            if(response.data.success === true){
                let temp = [...patros];
                console.log(temp)
                setPatros(temp.filter((tmp) => tmp.id != response.data.id))
                Swal.fire({
                    icon: "success",
                    title: "El Patrocinador ha sido eliminado correctamente",
                    showConfirmButton: false,
                    timer: 1500
                });
            } else { 
                Swal.fire({
                icon: "error",
                title: "Error inesperado al intentar eliminar ",
                showConfirmButton: false,
                timer: 1500
            }); }
        })
        .catch(function(response){
            console.error(response);
        })
    }

    return (
        <Grid container xs={12} sm={8} md={6} xl={4} direction="row" justifyContent="center" alignItems="center" key={innerIndex}>
            <div className='equipo-card column'>
                <img className={patro.tarifa == 10 ? 'patro-normal' : 'patro-grande' } src={patro.imagen} />
                <div className='equipo-desc'>
                    <div className='equipo-element'>Nombre: {patro.nombre}</div>
                    <div className='equipo-element'>Tarifa: {patro.tarifa == 10 ? "NORMAL" : "GRANDE"} </div>
                    <div className='equipo-element'>{patro.nombre.toUpperCase()}</div>
                    <div className='equipo-element'>{patro.descalificado ? 'Descalificado' : ''}</div>
                    <div className='flex-even'>
                        {patro.ubicacion ? <div className='boton-equipo' onClick={() => redirect(patro.ubicacion)}><PlaceIcon className='sm-icon' /> Ubicación</div> : null}
                    </div>
                </div>
                {eliminar ? <div className='button-redondo' onClick={() => eleminarPatro()}><DeleteForeverIcon/></div> : ''}
            </div>
        </Grid>
    )
}

