import React, { useEffect, useState } from 'react'
import { Header } from '../../Header'
import { Grid } from '@mui/material';
import { redirect, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { CrearPatro } from './CrearPatro';
import { PiePagina } from '../../PiePagina';
import { PatroCard } from './PatroCard';
import { Shuffle } from '@mui/icons-material';


export const Patrocinadoresadm = () => {

	const [eliminar, setEliminar] = useState(false);
	const [anadirPatro, setAnadirPatro] = useState(false);
	const [edicion, setEdicion] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const [patros, setPatros] = useState([]);
	const [patrosGrandes, setPatrosGrandes] = useState([]);
	const [colaboradores, setcolaboradores] = useState([]);

	const Navigate = useNavigate();
	const param = useParams();

	console.log(param);

	const firstLoad = () => {
		cargarPatros();
		cargarEdiciones();
	}

	useEffect(() => {
		firstLoad();
	}, []);


	function shuffleArray(array) {
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[array[i], array[j]] = [array[j], array[i]];
		}
		return array;
	}

	const cargarEdiciones = () => {
		axios.get('https://www.transbetxi.com/WS/edicion.php')
			.then(function (response) {
				let tmp = response.data;
				tmp = tmp.find((tm) => tm.codigoedicion == param.edicion);
				setEdicion(tmp);
			})
			.catch(function (error) {
				console.error(error)
			})
	}

	const cargarPatros = () => {
		//resvisar que en un futuro entre solo la edicion pasada por param en el ws
		// axios.get('https://www.transbetxi.com/WS/patrocinador.php/?edicion=' + param.edicion)
		// 	.then(function (response) {
		// 		// manejar respuesta exitosa
		// 		console.log(response)
		axios.get(`https://www.transbetxi.com/WS/patrocinador.php/?edicion=${param.edicion}`)
			.then(function (response) {
				console.log(response);
				if (response.status === 200) {
					response.data.forEach(item => {
						if (item.tarifa === 10) {
							setPatros(shuffleArray(item.patrocinadores));
						} else if (item.tarifa === 20) {
							setPatrosGrandes(shuffleArray(item.patrocinadores));
						} else if (response.data[2].tarifa == 30) {
							let barajado = shuffleArray(response.data[2].patrocinadores);
							setcolaboradores(barajado);
							console.log(barajado);
						}
					});
				}
			})
			.catch(function (error) {
				console.log(error);
			})
			.finally(function () {
				setLoaded(true);
			});


		// })
		// .catch(function (error) {
		// 	// manejar error
		// 	console.log(error);
		// })
		// .finally(function () {
		// 	// siempre sera executado
		// });
	}

	return (
		<div>
			<Header />
			<Grid
				direction="column"
				justifyContent="center"
				alignItems="left"
				spacing={0}
			>
				<Grid container xs={2} md={2} lg={2} >
					<div className="button-adm" onClick={() => Navigate('/edicion/' + param.edicion)}>
						Volver
					</div>
				</Grid>
			</Grid>
			<div className='flex-center'>
				<h1>Patrocinadores de la edición {edicion.nombre}</h1>
			</div>
			<Grid
				container
				direction="row"
				justifyContent="center"
				alignItems="center"
				style={{ gap: '1em' }}
				xs={12}
			>
				<Grid container xs={2} className="button-aux" onClick={() => setAnadirPatro(true)}>
					Nuevo Patrocinador
				</Grid>
				<Grid container xs={2} className="button-aux" onClick={eliminar ? () => setEliminar(false) : () => setEliminar(true)} >
					{eliminar === true ? "CANCELAR" : "ELIMINAR"}
				</Grid>
				<Grid container xs={2} className="button-aux" onClick={() => setAnadirPatro(true)} >
					Editar
				</Grid>

			</Grid >
			{anadirPatro === true && (
				<CrearPatro setAnadirPatro={setAnadirPatro} edicion={edicion} patros={patros} setPatros={setPatros} setLoadedList={setLoaded} />
			)}
			<Grid
				container
				direction="column"
				justifyContent="space-evenly"
				alignItems="center"
				spacing={0}
			>
				{loaded === true && (

					<>
						<Grid container direction="row" xs={12} md={12} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
							{colaboradores.map((colaborador, index) => (
									<PatroCard patro={colaborador} innerIndex={index} eliminar={eliminar} patros={colaboradores} setPatros={setcolaboradores} />							
							))}
						</Grid>

						<Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center">
							{patrosGrandes.map((patro, innerIndex) => (
								<PatroCard patro={patro} innerIndex={innerIndex} eliminar={eliminar} patros={patrosGrandes} setPatros={setPatrosGrandes} />
							))}
						</Grid>
						<Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center">
							{patros.map((patro, innerIndex) => (
								<PatroCard patro={patro} innerIndex={innerIndex} eliminar={eliminar} patros={patros} setPatros={setPatros} />
							))}
						</Grid>
						{patros.length <= 0 && (
							<h1>
								UPS... No hay equipos de esta edicion todavia
							</h1>)}
					</>

				)}
				{loaded === false && (
					<div className='flex-container spinner'>
						<div className='loading-spinner'>
						</div>
					</div>
				)}
			</Grid >
			<PiePagina></PiePagina>

		</div>
	)
}
